import React, {useCallback, useEffect, useState} from 'react';
import './dashboardActivities.scss';
import {observer} from 'mobx-react';
import DashboardActivitiesConversations
    from './component/dashboardActivitiesConversations/DashboardActivitiesConversations.component';
import DashboardActivitiesJobs from './component/dashboardActivitiesJobs/DashboardActivitiesJobs.component';
import DashboardActivitiesActivities
    from './component/dashboardActivitiesActivities/DashboardActivitiesActivities.component';
import DashboardActivitiesPeople from './component/dashboardActivitiesPeople/DashboardActivitiesPeople.component';
import DashboardActivitiesAll from './component/dashboardActivitiesAll/DashboardActivitiesAll.component';
import jobStore from '../../../../../stores/job.store';
import DashboardCandidatesTodayJobs
    from './component/dashboardCandidatesTodayJobs/DashboardCandidatesTodayJobs.component';
import RecruitersMultiSelectBox from '../../../../components/recruitersSelectBox/RecruitersMultiSelectBox.component';
import userStore from '../../../../../stores/user.store';
import {useHistory} from 'react-router-dom';
import Loader from '../../../../components/loader/Loader.component';
import {QueryParamUtils} from '../../../../../shared/utils/params.utils';

const QUERY_ALL = 'all';

const DashboardActivities = observer((props) => {
    const { recruiterIdQuery } = props

    const [selectedRecruiterSlugs, setSelectedRecruiterSlugs] = useState(null);
    const [jobs, setJobs] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { replace } = useHistory();

    const handleSelectRecruiters = useCallback((recruiterSlugs) => {
        const recruitersIds = recruiterSlugs && recruiterSlugs.length > 0
          ? QueryParamUtils.packMany(recruiterSlugs)
          : QUERY_ALL;

        replace(`/dashboard?recruiterId=${recruitersIds}`);
    }, [replace]);

    useEffect(() => {
        const loadData = async () => {
            if (!recruiterIdQuery) {
                setSelectedRecruiterSlugs([userStore.user.slug]);
                handleSelectRecruiters([userStore.user.slug]);
                return;
            }

            setIsLoading(true);

            const recruiters = QUERY_ALL !== recruiterIdQuery
              ? await userStore.getUsersBySlugs(QueryParamUtils.unpackMany(recruiterIdQuery))
              : null;

            const recruiterSlugs = recruiters ? recruiters.map(item => item.slug) : [];

            setSelectedRecruiterSlugs(recruiterSlugs);

            const jobQueries = {
                isExternal: false,
                isAvailable: true, // for matches
                ...(recruiters && recruiters.length > 0
                  ? { recruiterSlugs }
                  : {})
            }

            const jobs = await jobStore.queryJobs(jobQueries)

            setJobs(jobs);
            setIsLoading(false);
        }

        loadData();
    }, [recruiterIdQuery]);

    if (isLoading) {
        return <Loader />
    }

    return (
      <>
          <div className="top">
              <div className="top-inner">
                  <RecruitersMultiSelectBox
                    selectedItemSlugs={!!selectedRecruiterSlugs && selectedRecruiterSlugs}
                    selectEvent={(recruiterSlugs) => handleSelectRecruiters(recruiterSlugs)}
                  />
              </div>
          </div>

          <div className="dashboard-activities-wrapper">
              <div>
                  <div className="dashboard-wrapper-top">
                      <div className="all">
                          <DashboardActivitiesAll
                            selectedRecruiterSlugs={selectedRecruiterSlugs}
                            jobs={jobs}
                          />
                      </div>
                      <div className="conversations">
                          <DashboardActivitiesConversations selectedRecruiterSlugs={selectedRecruiterSlugs}/>
                      </div>
                      <div className="today-jobs">
                          <DashboardCandidatesTodayJobs
                            selectedRecruiterSlugs={selectedRecruiterSlugs}
                            jobs={jobs}
                          />
                      </div>

                      <div className="jobs">
                          <DashboardActivitiesJobs
                            selectedRecruiterSlugs={selectedRecruiterSlugs}
                            jobs={jobs}
                          />
                      </div>
                  </div>

                  <div className="dashboard-wrapper-bottom">
                      <div className="activities">
                          <DashboardActivitiesActivities selectedRecruiterSlugs={selectedRecruiterSlugs}/>
                      </div>
                      <div className="people">
                          <DashboardActivitiesPeople selectedRecruiterSlugs={selectedRecruiterSlugs}/>
                      </div>
                  </div>
              </div>
          </div>
      </>
    )
});

DashboardActivities.propTypes = {
    // bla: PropTypes.string,
};

DashboardActivities.defaultProps = {
    // bla: 'test',
};

export default DashboardActivities;
