import React, {Component} from 'react';
import profileStore from '../../../stores/profile.store';
import RecruitersSelectBoxComponent from '../../components/recruitersSelectBox/RecruitersSelectBox.component';
import userStore from '../../../stores/user.store';
import LoaderComponent from '../../components/loader/Loader.component';
import 'react-trumbowyg/dist/trumbowyg.min.css'
import Trumbowyg from 'react-trumbowyg'
import $ from 'jquery'
import './doctest.scss';

class DocTest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            loaderRecruiters: false,
            loaderCvEdit: false,
            loaderCvGoogle: false,
            loaderSavingAndDownloading: false,
            selectedUser: undefined,
            html: '',
            changedHtml: '',
            isRefresh: 831,
            selectedProfile: undefined,
            cvUrl: undefined,
            profiles: [],
        }

        this.selectedRecruiterId = userStore.user._id;
        this._interval = null;
        this.frame = undefined;
    }

    async componentDidMount() {
        this.ShowProfiles();
        this.setState({loaderCvEdit: false});
    }
    componentWillUnmount() {
        clearInterval(this._interval);
    }

    async UpdateCvEdit(profile) {
        this.setState({loaderCvEdit: true});

        const profileSlug = profile.slug;
        const cvDataHtml = await profileStore.getCvForEdit(profileSlug);
        if (cvDataHtml === false) {
            this.setState({ loaderCvEdit: false });
            return;
        }

        this.setState({html: cvDataHtml, loaderCvEdit: false});
    }
    async UpdateCvGoogle(profile) {

        this.setState({loaderCvGoogle: true});

        const profileSlug = profile.slug;
        const cvUrl = await profileStore.getCv(profileSlug, '');
        if (cvUrl === false) {
            this.setState({ loaderCvGoogle: false, cvUrl: '' });
            return;
        }

        this.setState({
            cvUrl: cvUrl,
        });
        this._interval = setInterval(() => {
            this.makeRefresh();
        }, 2000);
    }
    makeRefresh = () => {
        this.setState({ isRefresh: this.state.isRefresh + 1 });
    };
    loadedIframe = () => {
        clearInterval(this._interval);
        this.setState({ loaderCvGoogle: false });
    };

    handleSelectRecruiter = async (user) => {
        this.selectedRecruiterId = user._id;
        this.ShowProfiles();

        const recruiter = await userStore.getUserById(this.selectedRecruiterId);
        this.setState({selectedUser: recruiter});
    }

    async ShowProfiles() {
        this.setState({loaderRecruiters: true});

        const profiles = await profileStore.getProfilesByRecruiterId(this.selectedRecruiterId);
        
        this.setState({profiles: profiles, loaderRecruiters: false});
    }
    onCvChanged = (evt) => {
        this.setState({html: evt.target.value});
    }
    handleProfileClick(profile) {
        this.setState({errorMessage: '', html: '', changedHtml: ''});

        this.loadedIframe();

        this.UpdateCvEdit(profile);
        this.UpdateCvGoogle(profile);
        this.setState({selectedProfile: profile});
    }
    handleDownload = () => {
        const {cvUrl} = this.state;
        if(!cvUrl) {
            this.setState({errorMessage: 'You must first select a profile!'});
            return;
        }
        window.open(cvUrl, "_blank");
    }
    createMarkup = (html) => {
        return {__html: html};
    }
    handleSaveAndDownload = async () => {
        this.setState({loaderSavingAndDownloading: true});

        const {selectedProfile, html, changedHtml} = this.state;
        if(!selectedProfile) {
            this.setState({loaderSavingAndDownloading: false, errorMessage: 'You must first select a profile!'});
            return;
        }

        const profileSlug = selectedProfile.slug;
        let htmlToSave = changedHtml;
        if(htmlToSave === '') {
            htmlToSave = html;
        }
        const cvDataHtml = await profileStore.saveCv(profileSlug, htmlToSave);
        console.log(cvDataHtml);
        window.open(cvDataHtml, "_blank");

        this.setState({loaderSavingAndDownloading: false});
    }
    handleAddReview = () => {
        const {selectedProfile, html, changedHtml} = this.state;
        if(!selectedProfile) {
            this.setState({errorMessage: 'You must first select a profile!'});
            return;
        }
        if(selectedProfile.summary === '') {
            this.setState({errorMessage: 'Pay attention that candidate does not have summary!'});
        }

        let currentHtml = changedHtml;
        if(currentHtml === '') {
            currentHtml = html;
        }

        currentHtml = `<div style='padding: 20px; border: 1px solid black; margin: 10px 10px 20px 10px;'><div dir="rtl">חו"ד למועמד:</div>${selectedProfile.summary}</div>` + currentHtml;
        this.setState({html: currentHtml});
    }
    handleHtmlChanged = (e) => {
        const changedHtml = $(e.target).html();
        this.setState({changedHtml: changedHtml});
    }

    render() {

        const {profiles, selectedUser, html, isRefresh, cvUrl, loaderRecruiters, loaderCvEdit, loaderCvGoogle, loaderSavingAndDownloading, selectedProfile} = this.state;

        return (
            <div className="doc-test-container">
                <div className="header">
                    <div className="search-profile-container">
                        <RecruitersSelectBoxComponent
                            selectEvent={this.handleSelectRecruiter}
                            selectedItem={selectedUser.slug} />
                    </div>
                    <div className="download-button-container">
                        <button className="button black" onClick={this.handleDownload}>
                            Download Original CV
                        </button>
                    </div>
                    <div className="review-button-container">
                        <button className="button black" onClick={this.handleAddReview}>
                            Add Review
                        </button>
                    </div>
                    <div className="save-button-container">
                        <button className={'button black ' + (loaderSavingAndDownloading ? 'loading' : '')} onClick={this.handleSaveAndDownload}>
                            Save & Download Modified CV
                        </button>
                    </div>
                    <div className="error-message-container">
                        <div>{this.state.errorMessage}</div>
                    </div>
                </div>
                <div className="body">
                    <div className="profiles-list-container">
                        {loaderRecruiters && (
                            <LoaderComponent />
                        )}
                        {!loaderRecruiters && profiles && profiles.length > 0 && profiles.map(profile => {
                            return (
                                <div className={'profile-container ' + (selectedProfile && selectedProfile._id === profile._id ? 'selected' : '')} onClick={(e) => this.handleProfileClick(profile)}>{`${profile.firstName} ${profile.lastName} -> ${profile.emsId}`}</div>
                            )
                        })}
                    </div>
                    <div className="cv-edit-container">
                        {loaderCvEdit && (
                            <LoaderComponent/>
                        )}
                        {!loaderCvEdit && (
                            <div className="bs-cv-edit-container">
                                {/* <ContentEditable 
                                    html={html} 
                                    onChange={this.onCvChanged}
                                /> */}
                                <Trumbowyg 
                                    id='react-trumbowyg'
                                    data={html}
                                    autogrow={true}
                                    onChange={this.handleHtmlChanged}
                                />
                                {/* <div dangerouslySetInnerHTML={this.createMarkup(html)}>
                                </div> */}
                            </div>
                        )}
                        <div className="google-cv">
                            {loaderCvGoogle && (
                                <LoaderComponent/>
                            )}
                            {cvUrl && (
                                <div className="cv-tab">
                                    <iframe
                                        key={isRefresh}
                                        onLoad={this.loadedIframe}
                                        ref={(ref) => this.frame = ref}
                                        title='cv-frame'
                                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(cvUrl)}&pid=explorer&efh=false&a=v&embedded=true`}
                                        width='100%' frameBorder='0'>No CV found
                                    </iframe>
                                </div>
                            )}
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        );
    }

}

export default DocTest;
