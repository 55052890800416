import React, { Component } from 'react';
import { observer } from 'mobx-react';
import SuggestComponent from './Suggest.component';

@observer
class SuggestWithNetworkComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        }
        this.typingTimeout = 0;
    }

    query = (text) => {
        const { networkRequest, numberOfCharsToPredicate, moreParams } = this.props;

        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        if(text.length >= numberOfCharsToPredicate) {
            this.typingTimeout = setTimeout(async () => { 
                const data = await networkRequest(text, moreParams);
                this.setState({items: data});
            }, 300);
        }
    }

    render() {

        const { items } = this.state;

        return (
            <SuggestComponent
                {...{...this.props}}
                items={items}
                query={this.query}
            />
        );
    }
}

SuggestWithNetworkComponent.propTypes = {

};

SuggestWithNetworkComponent.defaultProps = {
    numberOfCharsToPredicate: 1,
};

export default SuggestWithNetworkComponent;
