import React, { Component } from 'react';
import { CloseIcon } from '../svg';

class EmployerInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }
    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    render() {
        const { data } = this.props
        return (
            <div className="employment-info">
                <header>
                    <h2><span
                        className='capitalized'>Employment</span>
                    </h2>
                    {this.props.isModal && (
                        <div onClick={this.handleCloseClick
                        }
                            className='circle-close modal-close'>
                            <CloseIcon />
                        </div>)}

                </header>

                <section className="current-body">
                    <div className="current-header">Current</div>
                    <label className="current-role">{data && data[0] ? data[0].position : ''}</label>
                    <label className="current-company">{data && data[0] ? data[0].companyName : ''}</label>
                </section>
                <section className="past-body">
                    <div className="past-header">Past</div>
                    {data && data.map((item, idx) => {
                        return (
                            <React.Fragment>
                                <div className="past" key={idx}>
                                    <label className="past-role">{item.position}</label>
                                    <label className="past-company">{item.companyName}</label>
                                </div>
                            </React.Fragment>
                        );
                    })}

                </section>


            </div>
        );
    }
}

export default EmployerInfoComponent;
