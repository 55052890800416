import React, { Component } from 'react'
import NavigationBar from '../../components/navigationBar/NavigationBar.component';
import NotificationItems from './components/NotificationItems.component';
import RecruitersSelectBoxComponent from '../../components/recruitersSelectBox/RecruitersSelectBox.component';
import CandidateOverviewComponent2 from '../talent/components/CandidateOverview.component.2';
import profileStore from '../../../stores/profile.store';
import "./notifications.scss"
import notificationsBellStore from '../../../stores/notificationsAlerts.store';
import jobStore from '../../../stores/job.store';
import JobSummaryComponent from '../jobs/components/singleJob/JobSummary.component';
import companyStore from '../../../stores/company.store';
import userStore from '../../../stores/user.store';
import DropdownWithSubDirectories from '../../components/dropdown/dropdownWithSubDirectories/DropdownWithSubDirectories.component';
import utilsService from '../../../services/utils.service';
class NotificationsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: undefined,
            job: undefined,
            company: undefined,
            selectedRecruiter: notificationsBellStore.selectedRecruiter,
            selectedNotificationFilter: 0
        }
    }

    async componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        let recruiterId = params.get('recruiterId');
        let recruiter = null;
        if(recruiterId === 'all') {
            recruiter = { displayName: 'Show All', slug: 'all', _id: 'all',
                firstName: 'Show', lastName: 'All' };
        }
        else {
            if(!recruiterId) {
                recruiterId = userStore.user._id;
            }
            recruiter = await userStore.getUserById(recruiterId);
        }
        this.state = { selectedRecruiter: recruiter }
        this.handleRecruiterSelected(recruiter);
    }

    async handleRecruiterSelected(item) {
        if (item) {
            notificationsBellStore.selectedRecruiter = item;
            this.setState({ selectedRecruiter: item, showLoader: true, selectedNotificationFilter : 0, profile: null, job: null });
            await notificationsBellStore.getNotifications();
            const notifications = notificationsBellStore.notifications;
            if (notifications && notifications.length > 0) {
                const firstNotification = notifications[0];
                if (firstNotification) {
                    await this.handleSelectNotification(firstNotification);
                }
            }
            this.setState({ selectedRecruiter: item, showLoader: false });
        }

        const history = this.props.history;
        if (item._id === 'all') {
            history.replace(`/notifications?recruiterId=all`);
        }
        else {
            history.replace(`/notifications?recruiterId=${item._id}`);
        }
    }

    handleSelectNotification = async (notification) => {
        if (notification.type == 1 || notification.type == 2 || notification.type == 3 || (notification.type >= 5 && notification.type <= 8)) {
            this.handleNotificationByProfile(notification);
        }
        else {
            this.handleNotificationByJob(notification);
        }
    }

    handleNotificationByProfile = async (notification) => {
        const profileData = notification.profileData;
        if (!profileData) {
            return;
        }
        const profile = await profileStore.getProfileById(profileData._id);

        this.setState({ profile: profile, job: undefined });
    }

    handleNotificationByJob = async (notification) => {
        const jobData = notification.jobData;
        if (!jobData) {
            return;
        }
        const job = await jobStore.getJobBySlug(jobData.slug);
        let company = undefined;
        if (job && job.companySlug) {
            company = await companyStore.getCompanyBySlug(job.companySlug);
        }

        this.setState({ job: job, company: company, profile: undefined });
    }

    getNotificationTypes() {
        return [
            { elId: 1, value: 'To Approve' },
            { elId: 2, value: 'Request For Details' },
            { elId: 3, value: 'Upload CV To ATS' },
            { elId: 4, value: 'Create New Job' },
            { elId: 5, value: 'New Discussion' },
            { elId: 6, value: 'Message Blocked', subItems: [
                { elId: 6, value: 'All', parent: 'Message Blocked (All)' },
                { elId: 61, value: 'Chat', parent: 'Message Blocked (Chat)' },
                { elId: 62, value: 'Send CV', parent: 'Message Blocked (Send CV)' },
                { elId: 63, value: 'Exposed', parent: 'Message Blocked (Exposed)' }
            ]},
            { elId: 7, value: 'Decline after Exposure' },
            { elId: 8, value: 'Chat Blocked' },
        ];
    }

    updateNotificationFilter = (e) => {
        const newNotificationFilter = e.target.value;
        if (this.state.selectedNotificationFilter !== newNotificationFilter) {
            this.setState({ selectedNotificationFilter: newNotificationFilter });
        }
    }

    prepareAsideActionsFilter = () => {
        const { selectedNotificationFilter } = this.state;
        const conversationFilter = utilsService.doMeAFavor( this.getNotificationTypes());
        const item = conversationFilter.find(item => {
            if (item.subItems) {
                const selectedSubItem = item.subItems.find(subItem =>
                    subItem.elId === selectedNotificationFilter.elId);
                if (selectedSubItem) {
                    selectedSubItem.isSelected = true;
                    return true;
                }
                return false;
            }
            else {
                return item && item.elId === selectedNotificationFilter.elId
            }
        });

        if (item) {
            item.isSelected = true;
        }

        return conversationFilter;
    }

    handleStatusMenuFilterChanged = async (column, item, subItem) => {
        const { selectedNotificationFilter } = this.state;
        if (column === 'filter') {
            const tempItem = subItem ? subItem : item;
            if (!selectedNotificationFilter || selectedNotificationFilter.elId !== tempItem.elId) {
                this.setState({ selectedNotificationFilter: tempItem });
            }
            else {
                this.setState({ selectedNotificationFilter: 0 });
            }
        }
    }

    getCurrentColumnName = () => {
        const { selectedNotificationFilter } = this.state
        if(selectedNotificationFilter) {
            if(selectedNotificationFilter.parent) {
                return selectedNotificationFilter.parent;
            }
            else {
                return selectedNotificationFilter.value;
            }
        }

        return 'All';
    }

    render() {

        const { profile, selectedRecruiter, selectedNotificationFilter, job, company, showLoader } = this.state;
        notificationsBellStore.selectedRecruiter = selectedRecruiter;

        return (
            <div className='main-content-space notifications-view'>
                <NavigationBar />
                <div className="notifications-view-wrapper">
                    <div className={"notifications-view-wrapper-top view-all-template-column"}>
                        <div>Notifications</div>
                        <div className="select-box-container">
                            <RecruitersSelectBoxComponent
                                showMyInboxLabel={false}
                                selectedItem={selectedRecruiter ? selectedRecruiter.slug : undefined}
                                selectEvent={(item) => this.handleRecruiterSelected(item)}
                                showAll={true}
                                disableOnClickOutside={true}
                            />
                        </div>
                        <div className="notification-filter-container">
                            <DropdownWithSubDirectories
                                column={'filter'}
                                list={this.prepareAsideActionsFilter()}
                                titleFilterClick={this.handleStatusMenuFilterChanged}
                                currentColumnName={this.getCurrentColumnName()}
                                rightIcon='arrow'
                                viewType={'normal'}
                            />
                        </div>
                    </div>
                </div>

                <div className="notifications-area">

                    <div className="notification-items-container">
                        <div className="new-container">
                            <NotificationItems
                                onSelectNotification={this.handleSelectNotification}
                                notificationFilter={selectedNotificationFilter ? selectedNotificationFilter.elId : 0}
                                showLoader={showLoader}
                            />
                        </div>
                    </div>
                    <div className="notification-details-container">
                        {profile && (
                            <CandidateOverviewComponent2
                                showEditIcon={false}
                                profile={profile}
                                disableActions={true}
                            />
                        )}
                        {job && (
                            <JobSummaryComponent
                                job={job}
                                company={company}
                            />
                        )}
                    </div>

                </div>
            </div>
        )
    }
}
export default NotificationsView;