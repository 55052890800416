import { Autocomplete } from '@brightsource/brightsource-ui-lib';
import React from 'react';

export function BsAutocomplete({ label, options = [], value, onChange, disabled, placeholder = '', ...props }) {
  return (
    <Autocomplete
      label={label}
      options={options}
      getOptionLabel={(option) => option.value ?? option}
      isOptionEqualToValue={(option, value) => option.elId === value.elId}
      value={value}
      onChange={(event, value, reason, details) => onChange(value)}
      multiple={Array.isArray(value)}
      disabled={disabled}
      placeholder={placeholder}
      {...props}
    />
  );
}
