import React, {Component} from 'react';
import userStore from '../../../stores/user.store';
import processStore from '../../../stores/process.store';
import {InputForm} from '../forms';
import messagingStore from '../../../stores/messaging.store';
import utilsService from '../../../services/utils.service';
import {getSafeLatestInterview} from '../../../entities/profiles';

class ExposeRecruiterProcessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            message: 'Would you like to send a request to reveal their contact information?',
            exposed: false,
            emailToSend: ''
        };
    }

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ updating: true });
        const { job, company, profile, process } = this.props.data;
        const { emailToSend } = this.state;

        const user = userStore.user;
        const id = this.props.data.process._id;

        const data = {
            status: 34,
            profileId: process.profileId,
            eventTypeId: 34,
            decisionMakerEmail: user.email,
            decisionMakerName: user.displayName,
            previousStatus: process.status
        };

        const sendCvData = {
            company: {
                id: job.company,
                slug: job.companySlug
            },
            email: {
                messageBody: '',
                messageRawBody: '',
                toEmail: [emailToSend],
                senderUserId: user._id,
                senderUserSlug: user.slug,
                senderUserEmail: user ? user.email : null,
                targetParticipantProfileId: profile._id,
                targetParticipantProfileSlug: profile.slug,
                bcc: '',
                cc: '',
                conversationType: 1,
                jobSlug: job.slug,
                targetParticipantType: 2,
                senderType: user.userType,
                profileSlug: profile.slug,
                profileId: profile._id,
                cvFileId: profile.emsId,
                action: 'send_cv_message',
                notificationType: 'mail',
                isExposed: true
            },
            info: {
                jobTitle: `${job.title} ${job.positionCode ? job.positionCode : ''}`,
                profileName: utilsService.getDisplayName(profile, true),
                profileNameHeb: utilsService.getDisplayNameHebrew(profile),
                email: utilsService.getPrimaryValue(profile.emails, 'email'),
                phone: utilsService.getPrimaryValue(profile.phones, 'phone'),
                profileIdNumber: profile.idNumber,
                summary: getSafeLatestInterview(profile).summary,
                id: profile._id,
                slug: profile.slug,
                user: userStore.user
            }
        };

        const promises = [messagingStore.sendCvs([sendCvData])];
        if(utilsService.checkIfNew(this.props.data.process) || utilsService.checkIfDeclineOrWithdrawnOrHired(this.props.data.process) || this.props.data.process.status === 3) {
            promises.push(processStore.changeStatus(process._id, data));
        }
        await Promise.all(promises);

        this.handleCloseClick();
    };

    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    handleSuccessClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.successEvent();
        }
    };

    componentDidMount() {
        const { job } = this.props.data;
        const emails = job.emailsForCv;
        let email = null;
        if (emails && emails.length > 0) {
            email = emails[0];
        }

        if (email) {
            this.setState({
                emailToSend: email
            });
        }
    }

    inputChange = e => {
        this.setState({
            emailToSend: e.target.value
        });
    };

    render() {
        const { message, updating, exposed, emailToSend } = this.state;

        return (
            <div
                className={exposed ? 'action-process expose-component exposed' : 'action-process expose-component'}>
                <div>
                    <h3>Reveal candidates details?</h3>
                    <h4>{message}</h4>
                </div>

                <InputForm
                    label={'Email'}
                    value={emailToSend}
                    type={'text'}
                    inputChange={this.inputChange}
                />

                {exposed && (
                    <footer className='center'>
                        <button
                            onClick={this.handleSuccessClick}
                            className={`button blue okbutton`}
                        >
                            Ok
                        </button>
                    </footer>)}

                {!exposed && (
                    <footer>
                        <button
                            onClick={this.handleCloseClick}
                            className='button gray'>
                            Cancel
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className={`button blue handler ${updating ? 'loading' : ''}`}
                        >
                            Send request
                        </button>
                    </footer>)}

            </div>
        );
    }
}

export default ExposeRecruiterProcessComponent;
