import React from 'react';
import {LogoIcon, PasswordHideIcon, PasswordShowIcon, SignupInternalServerError} from '../../components/svg';
import './signup.scss';
import userStore from '../../../stores/user.store';
import settingsStore from '../../../stores/settings.store';
import {
    doSignInCustomToken
} from '../../../services/auth.service';
import Loader from '../../components/loader/Loader.component';
import utilsService from '../../../services/utils.service';

class SignupView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            isError: false,
            isShowPassword: false,
            isShowLoader: false,
            isPasswordValid: false,
            isAtLeast8Characters: false,
            isAtLeastOneUpperCase: false,
            isAtLeastOneLowerCase: false,
            isAtLeastOneNumber: false,
            isAtLeastOneSpecialCharacter: false,
            internalServerError: false,
            isLinkValid: true
        };
    }

    proceedToUrl = () => {
        this.props.history.push(
            settingsStore.redirectRoute ? settingsStore.redirectRoute : '/pipelines'
        );
    };

    allowSignInToUser = async (jwt) => {
        await doSignInCustomToken(jwt);
        this.proceedToUrl();
    };

    async componentDidMount() {
        document.title = 'Signup - Ethosia';

        const { token } = this.props.match.params;
        const userEmail = await userStore.getUserEmailByToken(token);
        
        if(!userEmail) {
            this.setState({loader: false, isLinkValid: false});
        }
        else
            this.setState({loader: false, email: userEmail, isLinkValid: true});
    }

    validatePassword(e) {
        const password = e.target.value;
        const isAtLeast8Characters = password.length >= 8;
        this.setState({isAtLeast8Characters: isAtLeast8Characters});
        const isAtLeastOneUpperCase = utilsService.validPasswordOneUpperCaseLatter(password);
        this.setState({isAtLeastOneUpperCase: isAtLeastOneUpperCase});
        const isAtLeastOneLowerCase = utilsService.validPasswordOneLowerCaseLatter(password);
        this.setState({isAtLeastOneLowerCase: isAtLeastOneLowerCase});
        const isAtLeastOneNumber = utilsService.validPasswordOneNumber(password);
        this.setState({isAtLeastOneNumber: isAtLeastOneNumber});
        const isAtLeastOneSpecialCharacter = utilsService.validPasswordSpecialCharacter(password);
        this.setState({isAtLeastOneSpecialCharacter: isAtLeastOneSpecialCharacter});

        if(isAtLeast8Characters && isAtLeastOneUpperCase && isAtLeastOneLowerCase && isAtLeastOneNumber && isAtLeastOneSpecialCharacter) {
            this.setState({isPasswordValid: true})
        }
        else {
            this.setState({isPasswordValid: false})
        }

        this.setState({password: password})
    }

    async onCreateAccount() {

        const { token } = this.props.match.params;
        const { firstName, lastName, password } = this.state;

        if(!firstName || !lastName || !password || !this.state.isPasswordValid) {
            this.setState({isError: true, isShowLoader: false});
            return;
        }

        this.setState({isShowLoader: true});

        const jwt = await userStore.setUserPasswordByToken(token, firstName, lastName, password);
        if(!jwt.error) {
            this.allowSignInToUser(jwt)
        }
        else {
            this.setState({internalServerError: true, isShowLoader: false})
        }
    }

    addContainerClass() {
        if(this.state.internalServerError) {
            return " internal-server-error";
        }
        else if(!this.state.isLinkValid) {
            return " auth-invalid-link";
        }
        else
            return "";
    }

    selectContainerClass() {
        if(this.state.internalServerError)
            return "signup-internal-server-error";
        else if(!this.state.isLinkValid)
            return "signup-invalid-link";
        else
            return "signup";
    }

    render() {
        return (
            <div>
                {this.state.loader && (
                    <Loader/>
                )}
                {!this.state.loader && (
                    <main className={"auth border-radius shadow4" + (this.addContainerClass())}>
                    <div className={this.selectContainerClass()}>
                        {!this.state.internalServerError && (
                            <div className="login-icon-wrapper">
                                <LogoIcon/>
                            </div>
                        )}
                        {this.state.isLinkValid && !this.state.internalServerError && (
                            <React.Fragment>
                                <span>Welcome, one small thing before we start</span>
                                <span>{this.state.email}</span>
                                <div className="name">
                                    <div>
                                        <label className="lbl">First Name</label>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className={this.state.isError && !this.state.firstName ? 'error-border' : ''}
                                            value={this.state.firstName}
                                            onChange={e => this.setState({firstName: e.target.value})}
                                            disabled={this.state.isShowLoader}
                                        />
                                        {this.state.isError && !this.state.firstName && (
                                            <label className="error">Missing</label>
                                        )}
                                    </div>
                                    <div>
                                        <label className="lbl">Last Name</label>
                                        <input
                                            autoComplete="none"
                                            type="text"
                                            className={this.state.isError && !this.state.lastName ? 'error-border' : ''}
                                            value={this.state.lastName}
                                            onChange={e => this.setState({lastName: e.target.value})}
                                            disabled={this.state.isShowLoader}
                                        />
                                        {this.state.isError && !this.state.lastName && (
                                            <label className="error">Missing</label>
                                        )}
                                    </div>
                                </div>
                                <div className="password">
                                    <label className="lbl">Password</label>
                                    <div className="password-container">
                                        <input
                                            autoComplete="new-password"
                                            type={this.state.isShowPassword ? "text" : "password"}
                                            className={"password " + (this.state.isError && !this.state.password ? 'error-border' : '')}
                                            value={this.state.password}
                                            onChange={e => this.validatePassword(e)}
                                            disabled={this.state.isShowLoader}
                                        />
                                        {this.state.isShowPassword && (
                                            <div onClick={(e) => this.setState({isShowPassword: false})}>
                                                <PasswordShowIcon 
                                                    className="password-icon" 
                                                    disabled={this.state.isShowLoader}
                                                />
                                            </div>
                                        )}
                                        {!this.state.isShowPassword && (
                                            <div onClick={(e) => this.setState({isShowPassword: true})}>
                                                <PasswordHideIcon 
                                                    className="password-icon"
                                                    disabled={this.state.isShowLoader}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {this.state.isError && !this.state.password && (
                                        <label className="error">Missing</label>
                                    )}
                                </div>
                                <div className="password-hint-container">
                                    <div className="hint-left">
                                        <div className={this.state.isAtLeast8Characters ? 'hint-pass-ok' : (this.state.isError ? ' hint-error' : '')}>Minimum 8 characters</div>
                                        <div className={this.state.isAtLeastOneUpperCase ? 'hint-pass-ok' : (this.state.isError ? ' hint-error' : '')}>One upper case character</div>
                                        <div className={this.state.isAtLeastOneNumber ? 'hint-pass-ok' : (this.state.isError ? ' hint-error' : '')}>One number</div>
                                    </div>
                                    <div className="hint-right">
                                        <div className={this.state.isAtLeastOneLowerCase ? 'hint-pass-ok' : (this.state.isError ? ' hint-error' : '')}>One lower case character</div>
                                        <div className={this.state.isAtLeastOneSpecialCharacter ? 'hint-pass-ok' : (this.state.isError ? ' hint-error' : '')}>One special character</div>
                                    </div>
                                </div>
                                <button 
                                    onClick={() => this.onCreateAccount()} 
                                    className={"black" + (this.state.isShowLoader ? " disabled-button" : " button")}
                                    disabled={this.state.isShowLoader}>
                                    Create account
                                </button>
                            </React.Fragment>
                        )}
                        {!this.state.isLinkValid && !this.state.internalServerError && (
                            <div className="invalid-link-container">
                                <div className="invalid-link-header">Invalid link</div>
                                <div className="invalid-link-body">This link is no longer valid. Please contact Ethosia for a new link.</div>
                            </div>
                        )}
                        {this.state.internalServerError && (
                            <div className="internal-server-error-container">
                                <SignupInternalServerError className="internal-server-error-icon" />
                                <div className="internal-server-error-header">Sorry</div>
                                <div className="internal-server-error-body">An internal server error has occured. Please try again later.</div>
                                <button className="button black retry-button"
                                    onClick={(e) => this.setState({internalServerError: false})}>
                                    Retry
                                </button>
                            </div>
                        )}
                    </div>
                    {this.state.isShowLoader && (
                        <div className="signup-loader">
                            <Loader/>
                        </div>
                    )}
                </main>
                )}
            </div>
        );
    }
}

export default SignupView;
