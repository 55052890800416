import React, {Component} from 'react';
import {MultiSelect} from '@blueprintjs/select';
import {MenuItem} from '@blueprintjs/core';
import './multiSelect.scss';
import utilsService from '../../../services/utils.service';
import onClickOutside from 'react-onclickoutside';

class MultiSelectComponent extends Component {

    constructor(props) {
        super(props);
        this.lastSearchText = ''
    }

    multiSelectHandle = item => {
        this.props.selectItem(item);
    };

    renderMultiItem = (item, { handleClick, modifiers, index }) => {
        const selectedItems = this.props.selectedItems;
            let classes = 'multi-item';
            if (selectedItems.some(objItem => objItem && objItem.elId === item.elId)) {
                classes += ' selected';
            }
            if (modifiers.active) {
                classes += ' active';
            }

            return (
                <div
                    className={classes}
                    key={index}
                    onClick={handleClick}
                >
                    {item.value}
                </div>
            );
    };

    handleTagMultiRender = (e, item) => {
        e.stopPropagation();
        this.props.itemClicked(item);
    }

    getTagsMultiRenderClass = (item) => {
        let classes = "tag-multi-render";
        if(item.type === 1) {
            classes = classes + ' mandatory'
        }
        
        else if(item.type === 2) {
            classes = classes + ' excluded'
        }

        return classes;
    }

    tagsMultiRender = item => {
        const { supportSpecialTags } = this.props
        if(supportSpecialTags) {
            return <div className={this.getTagsMultiRenderClass(item)}
                onClick={(e) => this.handleTagMultiRender(e, item)}>{item ? (item.value) : ''}
            </div>
        }
        else {
            return item ? (item.value) : '';
        }
    };

    tagRemoveHandle = (tag, index) => {
        const { supportSpecialTags } = this.props
        const tempTag = supportSpecialTags ? tag.props.children : tag; 
        this.props.removeItem(tempTag, index);
    };

    // SEARCH ITEMS
    itemPredicate = (item, cat) => {
        const { numberOfCharsToPredicate } = this.props;
        return item.length >= numberOfCharsToPredicate && cat.value &&
            cat.value.toLowerCase().indexOf(item.toLowerCase()) >= 0;
    };

    createNewItem(item) {
        return {
            value: item,
            elId: item
        };
    }

    createNewItemRenderer(query, active, handleClick) {
        return (<MenuItem
            icon="add"
            text={`Create "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />);
    }

    getRelevantClasses(classes, validation, valid, isLoading) {
        let res = '';

        if (classes) {
            res += ` ${classes} `;
        }

        if (validation) {
            res += ` ${valid ? 'valid' : 'invalid'} `;
        }

        if(isLoading) {
            res += 'loading';
        }

        return res;
    }

    onQueryChange = text => {
        if (this.props.query && this.lastSearchText !== text) {

            // to resolve a bug while query calls twice
            this.lastSearchText = text;
            this.props.query(text);
        }
    };

    shouldComponentUpdate(nextProps) {
        const {
            items, selectedItems
        } = this.props;

        if (this.returnable(this.props, nextProps, 'allowCreate')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'valid')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'validation')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'classes')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'placeholder')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'label')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'isLoading')) {
            return true;
        }

        if (nextProps.items !== items) {
            if (items && nextProps.items) {
                if (items.length !== nextProps.items.length) {
                    return true;
                }
                else if(items.length > 0) {
                    const itemKeys = Object.keys(items[0]);
                    const nextItemKeys = Object.keys(nextProps.items[0]);

                    if(itemKeys.length !== nextItemKeys.length) {
                        return true;
                    }
                    if(itemKeys.length > 0) {
                        if(items[0][itemKeys[0]] !== nextItemKeys[0][nextItemKeys[0]]) {
                            return true;
                        }
                    }

                    if(items.length > 1) {
                        const itemKeys = Object.keys(items[items.length - 1]);
                        const nextItemKeys = Object.keys(nextProps.items[nextProps.length - 1]);
                        if(itemKeys.length > 0) {
                            if(items[0][itemKeys[0]] !== nextItemKeys[0][nextItemKeys[0]]) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        if(nextProps.selectedItems.length === 0) {
            return true;
        }
        
        if (nextProps.selectedItems !== selectedItems) {
            if (selectedItems && nextProps.selectedItems) {
                if (selectedItems.length !== nextProps.selectedItems.length) {
                    return true;
                }
                
                if(!utilsService.isTwoObjectsEquivalent(nextProps.selectedItems, selectedItems)) {
                    return true;
                }
            }
        }

        return false;
    }

    returnable(props, nextProps, val) {
        return props[val] !== nextProps[val];
    }

    handleClickOutside = evt => {
        this.props.onClickOutside && this.props.onClickOutside();
        this.setState({ opened: false });
    };

    render() {
        const {
            allowCreate,
            valid,
            validation,
            classes,
            items,
            selectedItems,
            openOnKeyDown,
            placeholder,
            customRenderer,
            customPredicate,
            customTagsMultiRender,
            isLoading,
            mainOuterClass,
            scrollToActiveItem
        } = this.props;

        const maybeCreateNewItemFromQuery = allowCreate ? this.createNewItem : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? this.createNewItemRenderer : null;
        const scrollToActiveItemValue = scrollToActiveItem == undefined ? true : scrollToActiveItem

        const multiClasses = this.getRelevantClasses(classes, validation, valid, isLoading);
        return (
            <div className={'multiselect-wrapper ' + mainOuterClass}>
                <MultiSelect
                    placeholder={placeholder ? placeholder : 'Add'}
                    className={'multi ' + multiClasses}
                    items={items}
                    onItemSelect={this.multiSelectHandle}
                    itemRenderer={customRenderer ? customRenderer : this.renderMultiItem}
                    tagRenderer={customTagsMultiRender ? customTagsMultiRender : this.tagsMultiRender}
                    selectedItems={selectedItems}
                    tagInputProps={{ onRemove: this.tagRemoveHandle }}
                    itemPredicate={customPredicate ? customPredicate : this.itemPredicate}
                    resetOnQuery={true}
                    resetOnSelect={true}
                    scrollToActiveItem={scrollToActiveItemValue}
                    openOnKeyDown={openOnKeyDown}
                    onQueryChange={this.onQueryChange}
                    createNewItemFromQuery={maybeCreateNewItemFromQuery}
                    createNewItemRenderer={maybeCreateNewItemRenderer}
                    noResults={<MenuItem disabled={true} text="No results."/>}
                />
            </div>
        );
    }
}

MultiSelectComponent.propTypes = {
    // bla: PropTypes.string,
};

MultiSelectComponent.defaultProps = {
    numberOfCharsToPredicate: 0
};

export default onClickOutside(MultiSelectComponent);
