import React from 'react';
import './fancySwitchButton.scss';

const FancySwitchButtonComponent = props => {

    const { checked, readOnly, checkAction, id, isLeftSideLabel } = props;
    return (
        <div className='fancy-switch-button'>
            {!isLeftSideLabel && (
                <span className="text">{checked ? props.lables[0] :  props.lables[1]}</span>    
            )}
           
            <div className="onoffswitch">
                <input
                    type="checkbox"
                    name="onoffswitch"
                    className="onoffswitch-checkbox"
                    id={'myonoffswitch' + id}
                    checked={checked}
                    readOnly={readOnly}
                    onChange={event => checkAction(event)}

                />
                <label className="onoffswitch-label" htmlFor={'myonoffswitch' + id}/>
                {isLeftSideLabel && (
                    <span className="text">{checked ? props.lables[0] :  props.lables[1]}</span>    
                )}
            </div>

        </div>
    );
};

FancySwitchButtonComponent.propTypes = {

};
  
FancySwitchButtonComponent.defaultProps = {
    
lables: ['On','Off']
};

export default React.memo(FancySwitchButtonComponent);
