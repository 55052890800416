import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import userStore from '../../../stores/user.store';
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {CloseIcon} from '../svg';
import utilsService from '../../../services/utils.service';
import NoteItemComponent from '../note/components/NoteItem.component';
import profileStore from '../../../stores/profile.store';
import notesStore from '../../../stores/notes.store';
import Loader from '../loader/Loader.component';
import notificationsStore from '../../../stores/notifications.store';

class AddNoteModal2 extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            note: EditorState.createEmpty(),
            title: '',
            creatorProfile: null,
            loader: false
        };
    }

    async componentDidMount() {
        if(userStore.user) {
            const creatorProfile = await profileStore.getProfileById(userStore.user.profileId);
            this.setState({creatorProfile : creatorProfile})
        }
    }

    handleSubmit = (e) => {
        const note = stateToHTML((this.state.note.getCurrentContent()));
        if (note === '<p><br></p>' && !this.state.title) return;
        e.preventDefault();
        const body = {
            creator: userStore.user._id,
            creatorType: userStore.users.userType,
            creatorSlug: userStore.user.slug,
            text: note,
            title: this.state.title
        };

        this.props.data(body);
    };

    onEditorStateChange = note => {
        this.setState({note});
    };

    initEditorHtml = () => {
        const { isSummary } = this.props;
        if(!isSummary) {
            return notesStore.profilesNotesEditorHtml.notes;
        }
    }

    async addCreatorUserToNotes() {
        const creatorProfilePromises = notesStore.notesToShow.map(async note => {
            const creatorUserID = note.creator;
            if(creatorUserID) {
                const user = await userStore.getUserById(creatorUserID);
                if(user) {
                    note.creatorUser = user;
                }
            }
        });
        Promise.all(creatorProfilePromises);
    }

    onClickSaveNote = async (text, noteId, isNewNote, isUpdateTackStatus, tagId) => {
        const {profileId, closeEvent} = this.props;

        if(tagId === 1 && isUpdateTackStatus) { // tagId === 1 -> primary note.
            this.setState({ loader: false });
            return; // We can not change the tack for primary note. It is always on.
        }

        this.setState({ loader: true });

        const currentUser = userStore.user;

        let data = {
            text: text, 
            syncEms: false, 
            creator: currentUser._id, 
            creatorType: 3,
            creatorName: userStore.user.displayName,
            eventTypeId: tagId,
            isTackOn: tagId === 1 ? true : false,
        }

        let id = profileId;
        let noteType = 'profile';
        data.profileSlug = profileStore.findProfileSlugById(profileId);
        data.profile = profileId;
        data.noteType = 'profile';

        if(tagId === 1) {
            notesStore.resetPrimaryNote(noteId, id, noteType);
        }

        await notesStore.createNote(data, id, noteType);

        notesStore.buildData(id, noteType);

        await this.addCreatorUserToNotes();

        notificationsStore.showToast('Note added', 'success');
        closeEvent();

        this.setState({ loader: false});
    }


    render() {
        const { creatorProfile, loader } = this.state
        const { closeEvent } = this.props
        return (
            <ModalComponent onClose={() => this.props.closeEvent()}>
                <div className='modal-box add-note-modal2'>
                    <header>
                        <h2>{'Add a note'}</h2>
                        <div onClick={() => this.props.closeEvent()}
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>
                    </header>
                    {loader && <Loader/>}
                    {!loader && <section className="modal-body">

                         <NoteItemComponent
                            isSummary={false}
                            isNewNote={true}
                            isShowTags={true}
                            tagId={2}
                            text={this.initEditorHtml()}
                            creatorName={userStore.user.displayName}
                            creatorImage={utilsService.getUserImage(creatorProfile)}
                            onClickClose={closeEvent}
                            onClickSave={this.onClickSaveNote}
                        />

                    </section>}
                </div>
            </ModalComponent>
        );
    }
}

export default AddNoteModal2;
