import React from 'react';
import utilsService from '../../../../services/utils.service';

const Template = props => {
    const { item, removeSelection, isEmail } = props;
    return (
        <div
            key={item._id}
            className="email-address"
            title={isEmail ? utilsService.getPrimaryValue(item.emails, 'email') : utilsService.getPrimaryValue(item.phones,
                'phone')}>
            {utilsService.getDisplayName(item)}
            <span
                onClick={() => removeSelection(item.slug)}
            >
                X
            </span>
        </div>
    );
};

export default Template;
