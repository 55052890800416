import React from 'react';
import ModalComponent from '../modal/Modal.component';
import EmployerInfoComponent from '../actions/EmployerInfo.component';

const EmployerInfoModalComponent = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box terminate-process-modal modal-box-employment-info'>
                <EmployerInfoComponent
                    isSingleJobView={props.isSingleJobView}
                    isModal={true}
                    closeEvent={() => props.closeEvent()}
                    data={props.data}
                />
            </div>
        </ModalComponent>

    );
};

export default EmployerInfoModalComponent;
