import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import NavigationBar from '../../components/navigationBar/NavigationBar.component';
import StyledRadioButtonsComponent from '../../components/styledRadioButttons/StyledRadioButtons.component';
import './advancedSearch.scss';
import metaDataStore from '../../../stores/meta-data.store';
import Loader from '../../components/loader/Loader.component';
import utilsService from '../../../services/utils.service';
import RolesComponent from '../../components/forms/Roles.component';
import { InputForm, MultiSelectComponent, SelectForm, SuggestComponent } from '../../components/forms';
import SuggestSkillsComponent from '../../components/forms/SuggestSkills.component';
import data from '../../../constants/data';
import searchStore from '../../../stores/search.store';
import userStore from '../../../stores/user.store';
import jobStore from '../../../stores/job.store';
import companyStore from '../../../stores/company.store';
import DefaultImageComponent from '../../components/DefaultImage.component';
import { Link } from 'react-router-dom';
import SuggestWithNetworkComponent from '../../components/forms/SuggestWithNetwork.component';
import errorStore from '../../../stores/error.store';

@observer
class AdvancedSearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
        };

        this._categories = [];
        this._normalRoles = {};
        this._multiSkills = [];
        this._normalDLocations = [];
        this._normalLocations = [];
        this._positionTypes = [];
        this._seniorityLevels = [];
        this._languages = [];
        this._degreeTypes = [];
        this._degreeNames = [];
        this._educationInstitutions = [];
        this._sectors = [];
        this._industries = {};
        this._companySizes = [];
        this._companyMarket = [];
        this._products = [];
        this._customerStatuses = [];
        this._jobStatus = [];
        this._recruitersList = [];
        this._companyNames = [];

        if (!searchStore.backFromResults) {
            searchStore.initAllAdvancedSearch();
        }
    }

    rolesHandle = (e, item, type) => {
        const rightTop = searchStore.selectRightTop();
        if (type === 'selectedCategories' || type === 'selectedRoles' || type === 'industries') {
            const currentList = searchStore[rightTop][type];
            if (currentList.some(element => element.elId === item.elId)) {
                if (item.type === 2) {
                    item.type = -1;
                    searchStore.setSearchValue(type, currentList.filter(q => q.elId !== item.elId));
                }
                else {
                    searchStore.setSearchValue(type, currentList.filter(q => q.elId !== item.elId));
                    if (type !== 'selectedCategories' && searchStore.selectedTopAdvanced === 0) {
                        item.type = 2;
                        searchStore.setSearchValue(type, [...searchStore[rightTop][type], item]);
                    }
                }

                // remove rules from selected category
                if (type === 'selectedCategories') {
                    const selectedRolesList = searchStore[rightTop]['selectedRoles'];
                    const filteredRules = this.buildRulesBasedOnCategory([item])
                    searchStore.setSearchValue('selectedRoles', selectedRolesList.filter(q => {
                        return !filteredRules.some(item => item.elId === q.elId);
                    }));
                }
            } else {
                searchStore.setSearchValue(type, [...searchStore[rightTop][type], item]);
            }
        } else {
            searchStore.setSearchValue(type, item);
        }
    };

    removeMultiItem = (text, type) => {
        const rightTop = searchStore.selectRightTop();
        const update = searchStore[rightTop][type].filter((objItem) => objItem.value !== text);
        if (type === 'selectedSkills') {
            let { hiddenSuggestedList } = searchStore[rightTop];
            hiddenSuggestedList = update.map(item => item.elId)
            searchStore.setSearchValue('hiddenSuggestedList', hiddenSuggestedList);
        }
        searchStore.setSearchValue(type, update);
    };

    selectMultiItem = (item, type) => {
        const rightTop = searchStore.selectRightTop();
        // If exist then remove
        let update = null;
        if (searchStore[rightTop][type].some(objItem => objItem.elId === item.elId)) {
            update = searchStore[rightTop][type].filter((objItem) => objItem.elId !== item.elId);
        } else {
            update = [...searchStore[rightTop][type], item];
        }

        searchStore.setSearchValue(type, update);
    };

    multiItemClicked = (item, type) => {
        const rightTop = searchStore.selectRightTop();

        let update = utilsService.doMeAFavor(searchStore[rightTop][type]);
        let tempItem = update.find(updateItem => updateItem.elId === item.elId);
        if (type === 'selectedSkills') {
            if (searchStore.selectedTopAdvanced === 0) {
                if (!tempItem.type || tempItem.type === 2) {
                    tempItem.type = 1;
                }
                else {
                    tempItem.type = 2;
                }
            }
            else {
                tempItem.type = -1;
            }

        }
        else {
            if (!tempItem.type || tempItem.type === -1) {
                tempItem.type = 2;
            }
            else {
                tempItem.type = -1;
            }
        }
        searchStore.setSearchValue(type, update);
    };

    inputChange = (e, type) => {
        searchStore.setSearchValue(type, e.target.value);
    };

    static formatIndustries(industries) {
        const temp = {};
        const arr = Object.values(industries);

        arr.forEach(ind => {
            const secId = ind.sectorId;
            if (!temp[secId]) {
                temp[secId] = [];
            }

            temp[secId].push(ind);
        });
        return temp;
    }

    addRelevantSkill = item => {
        if (searchStore.selectedTopAdvanced === 0) {
            item.type = 1;
        }
        const rightTop = searchStore.selectRightTop();
        let { selectedSkills, hiddenSuggestedList } = searchStore[rightTop];
        if (Array.isArray(item)) {
            item.forEach(skill => {
                selectedSkills = this.addRelevantSkillHandle(selectedSkills, skill);
                hiddenSuggestedList.push(skill.elId);
            });

        } else {
            selectedSkills = this.addRelevantSkillHandle(selectedSkills, item);
            hiddenSuggestedList.push(item.elId);
        }

        searchStore.setSearchValue('selectedSkills', selectedSkills);
        searchStore.setSearchValue('hiddenSuggestedList', hiddenSuggestedList);
    };

    addRelevantSkillHandle(array, item) {
        if (!array.some(objItem => objItem.elId === item.elId)) {
            array = [...array, item];
        }
        return array;
    }

    radioSelect = (index, type) => {
        if (type === 'selectedSalary') {
            if (index) {
                searchStore.setSearchValue(type, index);
                searchStore.setSearchValue('salaryRange', {
                    min: 0,
                    max: 1000
                });
            } else {
                searchStore.setSearchValue(type, index);
                searchStore.setSearchValue('salaryRange', {
                    min: 0,
                    max: 35000
                });
            }
        } else {
            searchStore.setSearchValue(type, index);
        }

    };

    radioSelectMulti = (item, type) => {
        if (type === 'selectedAvailability') {
            const rightTop = searchStore.selectRightTop();
            let update = utilsService.doMeAFavor(searchStore[rightTop][type]);
            const buttonAlreadyPressed = update.find(stat => stat.elId === item.elId);
            if (buttonAlreadyPressed) {
                update = update.filter(stat => stat.elId !== item.elId);
            }
            else {
                update.push(item);
            }
            searchStore.setSearchValue(type, update);
        }
    };

    getMultiSelectedRadio = (type) => {
        if (type === 'selectedAvailability') {
            const rightTop = searchStore.selectRightTop();
            let update = utilsService.doMeAFavor(searchStore[rightTop][type]);
            return update && update.map(item => {
                if (item.elId === 1) {
                    return 0;
                }
                else if (item.elId === 0) {
                    return 1;
                }
                else {
                    return item.elId
                }
            });

        }
    }

    slideEv = (value, type) => {
        searchStore.setSearchValue(type, value);
    };

    bringMetaDataCategories() {
        const categories = [
            'seniorityLevels',
            'skills',
            'roles',
            'categories',
            'rolesParent',
            'industries',
            'subRoles',
            'topSkills',
            'languages',
            'degreeTypes',
            'degreeNames',
            'educationInstitutions',
            'companySizes',
            'specialties',
            'positionTypes',
            'projectTypes',
            'sectors',
            'companyMarket',
            'desiredLocations',
            'customerStatuses',
            'locations',
            'jobStatus'];
        return categories.map(cat => {
            return metaDataStore.getCategory(cat, true);
        });
    }

    setDefaults = (job, people, company) => {
        const {
            roles
        } = metaDataStore.categories;
        if (job) {
            const rightTop = searchStore.selectRightTop();
            const theStore = searchStore[rightTop];

            if (job.roles && job.roles.length > 0) {
                theStore.selectedRoles = job.roles;

                const categories = utilsService.getCategoriesByRoles(job.roles, Object.values(roles));
                theStore.selectedCategories = categories;
            }

            if (job.skills && job.skills.length > 0) {
                theStore.selectedSkills = job.skills;
            }

            if (job.positionType) {
                theStore.selectedPositions = [job.positionType];
            }

            if (job.seniorityLevel && job.seniorityLevel.length > 0) {
                theStore.selectedSeniorities = job.seniorityLevel;
            }

            if (job.languages && job.languages.length > 0) {
                theStore.selectedLanguages = job.languages;
            }

            // if (job.industries && job.industries.length > 0) {
            //     theStore.industries = job.industries;
            // }
        }

    };

    /**
     Query params:
     tabIndex = index of tab (jobs, people, companies)
     hideTabs = hide tabs component
     forJob = slug id for job related the search

     */
    async componentDidMount() {
        document.title = 'Advanced search - Ethosia';

        if (!utilsService.isRecruiter(userStore.user)) {
            this.props.history.push('/pipelines');
            return;
        }

        const promises = this.bringMetaDataCategories();

        const parsedSearch = utilsService.queryParamsParser(this.props.location.search);
        if (parsedSearch.forJob) {
            promises.push(jobStore.getJobBySlug(parsedSearch.forJob));
        }

        if (parsedSearch.tabIndex) {
            searchStore.selectedTopAdvanced = parseInt(parsedSearch.tabIndex);
        }

        await Promise.all(promises);

        if (parsedSearch.forJob && !searchStore.backFromResults) {
            const job = jobStore.jobs[parsedSearch.forJob];
            if (job) {
                await companyStore.getCompanyBySlug(job.companySlug);
                this.setDefaults(job);
            }
        }

        const {
            subRoles, roles, desiredLocations, locations, skills, positionTypes, projectTypes,
            seniorityLevels, categories, languages, sectors, degreeTypes, degreeNames, 
            educationInstitutions, industries, companySizes, companyMarket, customerStatuses, jobStatus,
            specialties
        } = metaDataStore.categories;
        const {
            selectedSkills
        } = searchStore[searchStore.selectRightTop()];

        this._normalRoles = utilsService.normalizeRoles(roles, subRoles);
        this._categories = Object.values(categories);

        let advancedSkills = utilsService.doMeAFavor(skills);
        if (searchStore.selectedTopAdvanced === 0) {
            Object.values(advancedSkills).forEach(obj => {
                obj.type = 1;
            });
            selectedSkills.forEach(item => {
                if (!item.type) {
                    item.type = 1;
                }
            })
        }
        this._multiSkills = Object.values(advancedSkills).sort((a, b) =>
            a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
        this._normalDLocations = Object.values(desiredLocations);
        //this._normalLocations = utilsService.normalizeMetaLocations(locations, true);
        const locationsFromDB = await metaDataStore.getLocations();
        this._normalLocations = utilsService.normalizeMetaLocationsFromDB(locationsFromDB, true);        
        this._positionTypes = Object.values(positionTypes);
        this._seniorityLevels = utilsService.sortSeniorityLevels(seniorityLevels);
        this._projectTypes = Object.values(projectTypes);
        this._languages = Object.values(languages);
        this._degreeTypes = Object.values(degreeTypes);
        this._degreeNames = Object.values(degreeNames);
        this._educationInstitutions = Object.values(educationInstitutions);
        this._sectors = Object.values(sectors);
        this._industries = AdvancedSearchView.formatIndustries(industries);
        this._companySizes = Object.values(companySizes).sort((a,b) => a.order - b.order);
        this._companyMarket = Object.values(companyMarket);
        this._specialties = Object.values(specialties);
        this._customerStatuses = Object.values(customerStatuses);
        this._jobStatus = Object.values(jobStatus);
        this._recruitersList = AdvancedSearchView.normalizeRecruiters(userStore.recruitersList);

        this.setState({ loader: false });
        searchStore.backFromResults = false;

    }

    static normalizeRecruiters(list) {
        return list.map(item => {
            return (
                {
                    elId: item._id,
                    value: utilsService.getDisplayName(item)
                }
            );
        });
    }

    selectSuggestItem = (item, type) => {
        delete item.isExcluded;
        searchStore.setSearchValue(type, item);
    };

    selectExcludedSuggestItem = (item, type) => {
        item.isExcluded = true;
        searchStore.setSearchValue(type, item);
    }

    buildRulesBasedOnCategory(categories) {
        let roles = [];
        for (let i = 0; i < categories.length; ++i) {
            const categoryId = categories[i].elId;
            const selectedRoles = this._normalRoles.parentRoles[categoryId];
            roles = selectedRoles ? roles.concat(selectedRoles) : roles;
        }
        return roles;
    }


    submitHandle = async () => {
        this.setState({
            loader: true
        });
        let queryParams = '';
        const parsedSearch = utilsService.queryParamsParser(this.props.location.search);
        if (parsedSearch.forJob) {
            queryParams = '?job=' + parsedSearch.forJob;
        }

        // await searchStore.doSearch();
        // this.props.history.push('/search-advanced-results' + queryParams);

        const isPeopleSearch = searchStore.selectedTopAdvanced === 0;
        const isJobSearch = searchStore.selectedTopAdvanced === 1;

        if (isPeopleSearch || isJobSearch) {
            searchStore.searched = true;
            this.props.history.push({
                pathname: '/search-results2',
                search: '',
                state: { screen: 'advanced-search', jobMatchSlug: parsedSearch.forJob}
            });
        }
        else {
            await searchStore.doSearch();
            this.props.history.push('/search-advanced-results' + queryParams);
        }
    };

    handleSelectTop = (index) => {
        const { skills } = metaDataStore.categories;
        let advancedSkills = utilsService.doMeAFavor(skills);
        if (index === 0) {
            Object.values(advancedSkills).forEach(obj => {
                obj.type = 1;
            });
        }
        this._multiSkills = Object.values(advancedSkills).sort((a, b) =>
            a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
        searchStore.changeAdvancedTop(index);
    }

    handleStyledRadioButtons = (button, type) => {
        searchStore.setSearchValue(type, button.value);
    }

    render() {

        const {
            keyWords, selectedCategories, hiddenSuggestedList,
            selectedRoles, selectedSkills,
            selectedDLocations, selectedSalary, selectedLinkedin,
            selectedPositions, selectedSeniorities, selectedProjectTypes, selectedLanguages,
            recruiterInCharge, firstName, lastName, email, phone,
            currentCompany, previousCompany, schoolType, salaryRange, summary,
            ageRange, selectedDegreeTypes, industries, sector, selectedLocations,
            name, companySizes, companyMarket, customerStatus, tags, followUpDateFrom, followUpDateTo, jobStatus, emsBsId,
            idNumber, operatorRoles, operatorSkills, selectedStages, selectedDegreeNames,
            selectedEducationInstitutions
        } = searchStore[searchStore.selectRightTop()];

        const { loader } = this.state;

        const {
            _normalRoles, _categories, _multiSkills,
            _normalDLocations, _positionTypes, _projectTypes, _seniorityLevels,
            _languages, _degreeTypes, _degreeNames, _educationInstitutions, _sectors, _industries,
            _normalLocations, _companySizes, _companyMarket,
            _products, _customerStatuses, _recruitersList, _jobStatus, _companyNames
        } = this;

        const { skills, topSkills } = metaDataStore.categories;

        // switch top
        const isPeopleSearch = searchStore.selectedTopAdvanced === 0;
        const isJobSearch = searchStore.selectedTopAdvanced === 1;
        const isCompanySearch = searchStore.selectedTopAdvanced === 2;

        const salaryMinMax = utilsService.getSalaryRanges(selectedSalary + 1);
        const salaryStep = selectedSalary ? 10 : 500;

        const parsedSearch = utilsService.queryParamsParser(this.props.location.search);

        const job = jobStore.jobs[parsedSearch.forJob];
        let company = null;
        if (job) {
            company = companyStore.companies[job.companySlug];
        }

        return (
            <div className='advanced-search'>
                <NavigationBar />
                {!loader && (
                    <Top
                        selectTop={this.handleSelectTop}
                        selectedTop={searchStore.selectedTopAdvanced}
                        loading={loader}
                        hideTabs={parsedSearch.hideTabs}
                        submitHandle={this.submitHandle}
                        job={job}
                        company={company}
                        inputChange={(e, type) => this.inputChange(e, type)}
                        keyWordsValue={keyWords}
                    />)}
                {loader && (
                    <Loader />
                )}

                {!loader && (
                    <div className={`max-width main-search-component ${isPeopleSearch ? 'people-search' : ''}`}>

                        {/* <div className="general">
                            <div className="max-width free-text-search-item">
                                <InputForm
                                    label={'Free Text Search'}
                                    value={emsBsId}
                                    type={'text'}
                                    inputChange={e => this.inputChange(e, 'emsBsId')}
                                />
                            </div>
                        </div> */}

                        {!isCompanySearch && (
                            <div className="item roles-item">
                                <div className="roles-roles category">
                                    <div className="row">
                                        <label>
                                            Category
                                        </label>
                                        <StyledRadioButtonsComponent
                                            buttons={data.operators}
                                            //operatorRoles since in the end we query roles, and not categories
                                            activeIndex={operatorRoles ? data.operators.findIndex(item => item.value === operatorRoles) : -1}
                                            clickHandle={(e, button, idx) => this.handleStyledRadioButtons(button, 'operatorRoles')}
                                        />
                                    </div>
                                    <RolesComponent
                                        roleSelectEvent={(e, item) => this.rolesHandle(e, item, 'selectedCategories')}
                                        selectedRoles={selectedCategories}
                                        rolesList={_categories}
                                    />
                                </div>
                                <div className="border-radius roles-roles roles">
                                    <label>
                                        Roles
                                    </label>
                                    {_normalRoles && _normalRoles.parentRoles && (
                                        <RolesComponent
                                            roleSelectEvent={(e, item) => this.rolesHandle(e, item, 'selectedRoles')}
                                            selectedRoles={selectedRoles}
                                            rolesList={this.buildRulesBasedOnCategory(selectedCategories)}
                                        />
                                    )}

                                </div>
                            </div>
                        )}

                        {!isCompanySearch && (
                            <div className="item skills-item">
                                <MultiComponent
                                    label={'Skills'}
                                    items={_multiSkills}
                                    selectedItems={selectedSkills}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    itemClicked={this.multiItemClicked}
                                    supportSpecialTags={true}
                                    type={'selectedSkills'}
                                    classes={'skills'}
                                    
                                    styledRadioButtons={data.operators}
                                    styledRadioActiveIndex={operatorSkills ? data.operators.findIndex(item => item.value === operatorSkills) : -1}
                                    styledRadioButtonClick={(e, button, idx) => this.handleStyledRadioButtons(button, 'operatorSkills')}
                                />
                                <div className="suggestions">
                                    <div className='suggestions-title'>Suggestions</div>
                                    <div className="suggestions-list">
                                        <SuggestSkillsComponent
                                            hiddenList={hiddenSuggestedList}
                                            selectedRoles={selectedRoles.map(item => item.elId)}
                                            topSkills={topSkills}
                                            skills={skills}
                                            addRelevantSkill={item => this.addRelevantSkill(item)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}


                        <div className="item locations-item border-item">
                            {isPeopleSearch && (
                                <InputForm
                                    label={'Country'}
                                    value={'Israel'}
                                    type={'text'}
                                    disabled={true}
                                />
                            )}

                            {isCompanySearch && (
                                <MultiComponent
                                    label={'Company Size'}
                                    items={_companySizes}
                                    selectedItems={companySizes}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    type={'companySizes'}
                                    classes={'locations form-item'}
                                />
                            )}

                            {isCompanySearch && (
                                <MultiComponent
                                    label={'Company Type'}
                                    items={_companyMarket}
                                    selectedItems={companyMarket}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    type={'companyMarket'}
                                    classes={'locations form-item'}
                                />
                            )}

                            {isPeopleSearch && (<MultiComponent
                                label={'Current Address'}
                                items={_normalLocations}
                                selectedItems={selectedLocations}
                                removeItem={this.removeMultiItem}
                                selectItem={this.selectMultiItem}
                                itemClicked={this.multiItemClicked}
                                type={'selectedLocations'}
                                classes={'locations form-item'}
                                supportSpecialTags={true}
                            />)}

                            <MultiComponent
                                label={'Areas'}
                                items={_normalDLocations}
                                selectedItems={selectedDLocations}
                                removeItem={this.removeMultiItem}
                                selectItem={this.selectMultiItem}
                                itemClicked={this.multiItemClicked}
                                supportSpecialTags={isPeopleSearch}
                                type={'selectedDLocations'}
                                classes={'locations form-item'}
                            />

                            {/*{isJobSearch || isCompanySearch && (*/}
                            {/*    <div className="locations form-item ">*/}
                            {/*        <label>Location</label>*/}
                            {/*        <SuggestComponent*/}
                            {/*            items={_normalLocations}*/}
                            {/*            selectedItem={selectedLocation}*/}
                            {/*            removeItem={item => this.selectSuggestItem(item, 'selectedLocation')}*/}
                            {/*            selectItem={item => this.selectSuggestItem(item, 'selectedLocation')}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {isCompanySearch && (
                                <InputForm
                                    label={'Company Name'}
                                    value={name}
                                    type={'text'}
                                    inputChange={e => this.inputChange(e, 'name')}
                                />
                            )}

                            {!isCompanySearch && (
                                <RangerChuck
                                    classes={'salary-range form-item ranger-chuck-wrapper'}
                                    label={'Salary'}
                                    max={salaryMinMax.max}
                                    min={salaryMinMax.min}
                                    step={salaryStep}
                                    value={salaryRange}
                                    isSalary={true}
                                    type={'salaryRange'}
                                    radioSelect={this.radioSelect}
                                    selectedSalary={selectedSalary}
                                    slideEv={this.slideEv}
                                />
                            )}

                            {isPeopleSearch && (
                                <React.Fragment>
                                    <RadioButtonComponent
                                        isMultiSelect={true}
                                        selectedIndexes={this.getMultiSelectedRadio('selectedAvailability')}
                                        radioSelectEv={(item) =>
                                            this.radioSelectMulti(item, 'selectedAvailability')}
                                        data={data.availabilityOptionsSearch}
                                        label={'Availability'}
                                        type={'selectedAvailability'}
                                        classes={'availability'}
                                    />

                                    <RadioButtonComponent
                                        selected={selectedLinkedin}
                                        radioSelectEv={this.radioSelect}
                                        data={data.LinkedinOptions}
                                        label={'Linkedin'}
                                        type={'selectedLinkedin'}
                                        classes={'availability'}
                                    />
                                </React.Fragment>
                            )}

                            {!isCompanySearch && (
                                <MultiComponent
                                    label={'Position Types'}
                                    items={_positionTypes}
                                    selectedItems={selectedPositions}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    itemClicked={this.multiItemClicked}
                                    supportSpecialTags={isPeopleSearch}
                                    type={'selectedPositions'}
                                    classes={'position-types form-item'}
                                />
                            )}

                            {!isCompanySearch && (
                                <MultiComponent
                                    label={'Seniority Levels'}
                                    items={_seniorityLevels}
                                    selectedItems={selectedSeniorities}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    itemClicked={this.multiItemClicked}
                                    supportSpecialTags={isPeopleSearch}
                                    type={'selectedSeniorities'}
                                    classes={'form-item'}
                                />
                            )}

                            {!isCompanySearch && (
                                <MultiComponent
                                    label={'Languages'}
                                    items={_languages}
                                    selectedItems={selectedLanguages}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    itemClicked={this.multiItemClicked}
                                    supportSpecialTags={isPeopleSearch}
                                    type={'selectedLanguages'}
                                    classes={'languages form-item'}
                                />
                            )}

                            {isJobSearch && (
                                <MultiComponent
                                    label={'Project Type'}
                                    items={_projectTypes}
                                    selectedItems={selectedProjectTypes}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    type={'selectedProjectTypes'}
                                    classes={'form-item'}
                                />
                            )}

                            {isPeopleSearch && (
                                <RangerChuck
                                    classes={'age-range form-item ranger-chuck-wrapper'}
                                    label={'Age Range'}
                                    max={90}
                                    min={0}
                                    step={1}
                                    value={ageRange}
                                    type={'ageRange'}
                                    radioSelect={this.radioSelect}
                                    slideEv={this.slideEv}
                                />
                            )}
                        
                            {isPeopleSearch && (
                                <MultiComponent
                                    label={'Stage'}
                                    items={data.stage}
                                    selectedItems={selectedStages}
                                    removeItem={this.removeMultiItem}
                                    selectItem={this.selectMultiItem}
                                    itemClicked={this.multiItemClicked}
                                    supportSpecialTags={isPeopleSearch}
                                    type={'selectedStages'}
                                    classes={'form-item'}
                                />
                            )} 

                            {isCompanySearch && (
                                <SuggestComponent
                                    label={'Recruiter in charge'}
                                    items={_recruitersList.length > 0 ? _recruitersList : AdvancedSearchView.normalizeRecruiters(userStore.recruitersList)}
                                    selectedItem={recruiterInCharge}
                                    removeItem={item => this.selectSuggestItem(item, 'recruiterInCharge')}
                                    selectItem={item => this.selectSuggestItem(item, 'recruiterInCharge')}
                                />
                            )}

                            {isCompanySearch && (
                                <SelectForm
                                    label={'Contract'}
                                    name={'customerStatus'}
                                    value={customerStatus}
                                    defaultOption={'All'}
                                    selectList={_customerStatuses}
                                    inputChange={e => this.inputChange(e, 'customerStatus')}
                                />
                            )}

                        </div>

                        {!isCompanySearch && (
                            <div className="recruiters border-item">
                                <SuggestComponent
                                    defaultValue={'---None---'}
                                    label={'Recruiter in charge'}
                                    items={_recruitersList.length > 0 ? _recruitersList : AdvancedSearchView.normalizeRecruiters(userStore.recruitersList)}
                                    selectedItem={recruiterInCharge}
                                    removeItem={item => this.selectSuggestItem(item, 'recruiterInCharge')}
                                    selectItem={(item) => this.selectSuggestItem(item, 'recruiterInCharge')}
                                    selectExcludedItem={(item) => this.selectExcludedSuggestItem(item, 'recruiterInCharge')}
                                    hasExcluded={isPeopleSearch}
                                />

                                {isPeopleSearch && (
                                    <InputForm
                                        label={'First Name'}
                                        value={firstName}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'firstName')}
                                    />
                                )}

                                {isPeopleSearch && (
                                    <InputForm
                                        label={'Last Name'}
                                        value={lastName}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'lastName')}
                                    />
                                )}

                                {isPeopleSearch && (
                                    <InputForm
                                        label={'Email'}
                                        value={email}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'email')}
                                    />
                                )}

                                {isPeopleSearch && (
                                    <InputForm
                                        label={'Phone'}
                                        value={phone}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'phone')}
                                    />
                                )}

                                <InputForm
                                    label={'Last Activity From'} // Follow Up Date, Changed to lastUpdate
                                    value={followUpDateFrom}
                                    type={'date'}
                                    inputChange={e => this.inputChange(e, 'followUpDateFrom')}
                                />

                                <InputForm
                                    label={'Last Activity To'} 
                                    value={followUpDateTo}
                                    type={'date'}
                                    inputChange={e => this.inputChange(e, 'followUpDateTo')}
                                />

                                {isPeopleSearch && (

                                <SuggestWithNetworkComponent
                                        label={'Current Company'}
                                        placeholder={'Company'}
                                        selectedItem={currentCompany}
                                        classes={'edit-profile-multi-pulti'}
                                        selectItem={item => this.selectSuggestItem(item, 'currentCompany')}
                                        removeItem={item => this.selectSuggestItem(item, 'currentCompany')}
                                        selectExcludedItem={(item) => this.selectExcludedSuggestItem(item, 'currentCompany')}
                                        hasExcluded={isPeopleSearch}
                                        numberOfCharsToPredicate={2}
                                        networkRequest={companyStore.searchCompaniesByPartialNameByText}
                                    />
                                )}

                                {isPeopleSearch && (
                                    <SuggestWithNetworkComponent
                                        label={'Previous Company'}
                                        placeholder={'Company'}
                                        selectedItem={previousCompany}
                                        classes={'edit-profile-multi-pulti'}
                                        selectItem={item => this.selectSuggestItem(item, 'previousCompany')}
                                        removeItem={item => this.selectSuggestItem(item, 'previousCompany')}
                                        selectExcludedItem={(item) => this.selectExcludedSuggestItem(item, 'previousCompany')}
                                        hasExcluded={isPeopleSearch}
                                        numberOfCharsToPredicate={2}
                                        networkRequest={companyStore.searchCompaniesByPartialNameByText}
                                    />
                                )}

                                {isJobSearch && (
                                    <InputForm
                                        label={'Job Name'}
                                        value={name}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'name')}
                                    />
                                )}

                                {isJobSearch && (
                                    <SelectForm
                                        label={'Job Status'}
                                        name={'jobStatus'}
                                        value={jobStatus}
                                        selectList={_jobStatus}
                                        inputChange={e => this.inputChange(e, 'jobStatus')}
                                    />
                                )}

                            </div>
                        )}


                        {!isCompanySearch && (
                            <div className="school border-item">
                                <div className="school-wrap">
                                    <MultiComponent
                                        label={'Degrees'}
                                        items={_degreeTypes}
                                        selectedItems={selectedDegreeTypes}
                                        removeItem={this.removeMultiItem}
                                        selectItem={this.selectMultiItem}
                                        type={'selectedDegreeTypes'}
                                        classes={'locations form-item'}
                                    />

                                    {isPeopleSearch && (
                                        <>
                                            <MultiComponent
                                                label={'Degree Names'}
                                                items={_degreeNames}
                                                selectedItems={selectedDegreeNames}
                                                removeItem={this.removeMultiItem}
                                                selectItem={this.selectMultiItem}
                                                itemClicked={this.multiItemClicked}
                                                supportSpecialTags={isPeopleSearch}
                                                type={'selectedDegreeNames'}
                                                classes={'locations form-item'}
                                            />

                                            <MultiComponent
                                                label={'Education Institutions'}
                                                items={_educationInstitutions}
                                                selectedItems={selectedEducationInstitutions}
                                                removeItem={this.removeMultiItem}
                                                selectItem={this.selectMultiItem}
                                                itemClicked={this.multiItemClicked}
                                                supportSpecialTags={isPeopleSearch}
                                                type={'selectedEducationInstitutions'}
                                                classes={'locations form-item'}
                                            />
                                        </>
                                    )}

                                    <RadioButtonComponent
                                        selected={schoolType}
                                        radioSelectEv={this.radioSelect}
                                        data={data.schoolTypeOptions}
                                        label={'School Type'}
                                        type={'schoolType'}
                                        classes={'school-type'}
                                    />

                                    <RadioButtonComponent
                                        selected={summary}
                                        radioSelectEv={this.radioSelect}
                                        data={data.summary}
                                        label={'Summary'}
                                        type={'summary'}
                                        classes={'school-type'}
                                    />
                                    
                                </div>
                            </div>
                        )}

                        <div className="general border-item">
                            <div className="id-wrap">
                                <InputForm
                                    label={'ID'}
                                    value={emsBsId}
                                    type={'text'}
                                    inputChange={e => this.inputChange(e, 'emsBsId')}
                                />
                                 {isPeopleSearch && <InputForm
                                    label={'ID Card'}
                                    value={idNumber}
                                    type={'text'}
                                    inputChange={e => this.inputChange(e, 'idNumber')}
                                />}
                            </div>
                        </div>

                        <div className="sectors border-item">
                            <div className="sectors-wrapper">
                                <div className="sectors-item">
                                    <label>Sectors</label>
                                    <RolesComponent
                                        roleSelectEvent={(e, item) => this.rolesHandle(e, item, 'sector')}
                                        selectedRoles={[sector]}
                                        rolesList={_sectors}
                                    />
                                </div>
                            </div>

                            <div className="industries border-radius">
                                <div className="industries-item">
                                    <label>Industries</label>
                                    <RolesComponent
                                        roleSelectEvent={(e, item) => this.rolesHandle(e, item, 'industries')}
                                        selectedRoles={industries}
                                        rolesList={_industries[sector.elId]}
                                    />
                                </div>
                            </div>
                        </div>

                        {(isPeopleSearch || isCompanySearch) && (
                            <div className="tags">
                                <label>Product Tags</label>
                                <MultiComponent
                                    items={_products}
                                    selectedItems={tags}
                                    removeItem={item => this.removeMultiItem(item, 'tags')}
                                    selectItem={item => this.selectMultiItem(item, 'tags')}
                                    itemClicked={this.multiItemClicked}
                                    type={'tags'}                                    
                                    supportSpecialTags={isPeopleSearch}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default AdvancedSearchView;

const Top = props => {
    const { selectedTop, selectTop, submitHandle, loading, hideTabs, job, company, inputChange, keyWordsValue } = props;
    
    const clickHandle = (e, button, index) => {
        selectTop(index, 'selectedTop');
    };

    const handleCrush = () => {
        let amir = null;
        amir.toString();
        console.log('manual crush');
    }

    const handleDownloadFile = async () => {
        const body = {
            destination: "crush",
            fileName: "test.txt",
        }
        const _file = await errorStore.downloadFileFromStorage(body);
        console.log(_file);
    }

    let classes = 'gazmanov advanced-search-top';
    if (job) {
        classes += ' gazmanov-job-advanced-search-top';
    }

    const isPeopleSearch = searchStore.selectedTopAdvanced === 0;

    const handleClearAll = () => {
        searchStore.initAllAdvancedSearch();
    }

    return (
        <div className={classes}>
            <div className="gazmanov-top">
                <div className="left">
                    {!hideTabs && (
                        <TopSearchTabs
                            selectedTop={selectedTop}
                            clickHandle={clickHandle}
                        />
                    )}
                    {job && (
                        <TopJobFor
                            job={job}
                            company={company}
                        />
                    )}
                </div>
                <div className="center">
                    {isPeopleSearch && (
                        <>
                            <label>Key Words</label>
                            <input 
                                type="text"
                                value={keyWordsValue}
                                onChange={e => inputChange(e, 'keyWords')}
                            />
                        </>
                    )}
                </div>
                <div className="right">
                    <button className="clear-all" onClick={(e) => handleClearAll()}>Clear All</button>
                    <button
                        onClick={submitHandle}
                        className={loading ? 'button darkblue loading' : 'button darkblue'}>Search
                    </button>
                    {(process.env.NODE_FLAG === 'development') && (
                        <>
                                <button
                                    onClick={() => handleCrush()}
                                    className='button gray'
                                    style={{paddingRight: '3px', paddingLeft: '3px', 'marginRight': '10px', 'marginLeft': '10px'}}
                                    >
                                    Crush
                                </button>
                                <button
                                    onClick={() => handleDownloadFile()}
                                    className='button gray'
                                    style={{paddingRight: '3px', paddingLeft: '3px'}}
                                    >
                                    Download File
                                </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const TopJobFor = props => {
    const { job, company } = props;

    return (
        <div className="top-job-for">
            <legend>Search Candidates For</legend>
            <div className="top-job-for-warp">
                <DefaultImageComponent
                    url={utilsService.getCompanyImage(company)}
                    alt={'Company'}
                    company={true}
                    width={'30'}
                />

                <div>
                    <Link to={job ? `jobs/${job.slug}` : ''}><h2>{job ? job.title : ''}</h2></Link>
                    <Link to={company ? `company/${company.slug}` : ''}><p>{company ? company.name : ''}</p></Link>
                </div>
            </div>
        </div>
    );
};

const TopSearchTabs = props => {
    const data = [
        {
            elId: 1, value: 'People'
        },
        {
            elId: 2, value: 'Jobs'
        }, {
            elId: 3, value: 'Companies'
        }];

    const { selectedTop, clickHandle } = props;
    return (
        <Fragment>
            <label>Search For</label>
            <StyledRadioButtonsComponent
                buttons={data}
                activeIndex={selectedTop}
                clickHandle={clickHandle}
            />
        </Fragment>
    );
};

const RadioButtonComponent = props => {
    const { selected, radioSelectEv, label, data, type, classes, isMultiSelect, selectedIndexes } = props;

    const clickHandle = (e, button, index) => {
        if (isMultiSelect) {
            radioSelectEv(button);
        }
        else {
            radioSelectEv(index, type);
        }
    };

    return (
        <div className={`${classes} form-item`}>
            {label && (<label>{label}</label>)}
            <StyledRadioButtonsComponent
                isMultiSelect={isMultiSelect}
                buttons={data}
                activeIndex={selected}
                activeIndexes={selectedIndexes}
                clickHandle={clickHandle}
            />
        </div>
    );
};

const MultiComponent = props => {
    const { label, items, selectedItems, removeItem, selectItem, itemClicked, type, classes, supportSpecialTags,
        styledRadioButtons, styledRadioActiveIndex, styledRadioButtonClick } = props;

    const select = item => {
        selectItem(item, type);
    };
    const remove = item => {
        removeItem(item, type);
    };

    const clicked = item => {
        itemClicked(item, type)
    };

    return (
        <div className={classes}>
            <div className="row">
                {label && (<label>{label}</label>)}
                {styledRadioButtons && <StyledRadioButtonsComponent
                    buttons={styledRadioButtons}
                    activeIndex={styledRadioActiveIndex}
                    clickHandle={styledRadioButtonClick}
                />}
            </div>
            <MultiSelectComponent
                supportSpecialTags={supportSpecialTags}
                items={items}
                selectedItems={selectedItems}
                removeItem={remove}
                selectItem={select}
                itemClicked={clicked}
            />
        </div>
    );
};

class RangerChuck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: { min: props.min, max: props.max },
            isExclude: props.value.isExclude
        };
    }

    slide = val => {
        const { type, slideEv } = this.props;
        slideEv(val, type);
    };

    openMe = () => {
        this.setState({
            open: !this.state.open
        });
    };

    inputChange = (e, typeC) => {
        const { type, slideEv, min, max } = this.props;
        const { value } = this.state;
        let screenValue = { ...value }
        screenValue[typeC] = e.target.value;
        this.setState({ value: screenValue })

        const rightTop = searchStore.selectRightTop();
        let realValue = searchStore[rightTop][type]
        realValue[typeC] = e.target.value.length > 0 ? Number(e.target.value) : (typeC === 'min' ? min : max);
        slideEv(realValue, type);
    };

    formatRange() {
        const { type } = this.props;
        const rightTop = searchStore.selectRightTop();
        let realValue = searchStore[rightTop][type]
        return `${realValue.min}-${realValue.max}`;
    }

    buttonClickHandle = (isReset, isExclude) => {
        const { min, max } = this.props;
        const { type } = this.props;
        const rightTop = searchStore.selectRightTop();
        let realValue = searchStore[rightTop][type];
        if (isReset) {
            realValue = { ...realValue, max: max, min: min, isExclude: false }
            this.setState({ value: realValue, isExclude: false })
            realValue.isExclude = isExclude
            searchStore.setSearchValue(type, realValue);
        }
        else {
            this.setState({
                open: false,
                isExclude: isExclude
            });
            realValue.isExclude = isExclude;
            searchStore.setSearchValue(type, realValue);
        }
    };

    radioSelect = (index, type = 'selectedSalary') => {
        this.props.radioSelect(index, type);
    };

    render() {
        const { label, classes } = this.props;
        const { isSalary, selectedSalary } = this.props;
        const { open, value, isExclude } = this.state;

        return (
            <div className={classes + (isExclude ? ' exclude' : '')}>
                {label && (<label>{label}</label>)}
                <input type="text"
                    readOnly={true}
                    onClick={this.openMe}
                    value={this.formatRange()}

                />
                {open && (
                    <div className="ranger-chuck">
                        {isSalary && (
                            <RadioButtonComponent
                                selected={selectedSalary}
                                radioSelectEv={this.radioSelect}
                                data={data.salary_types}
                                type={'selectedSalary'}
                                classes={'salary-type'}
                            />
                        )}
                        {/* <InputRangeComponent
                            maxValue={max}
                            minValue={min}
                            wrapperClasses={'item'}
                            step={step}
                            value={value}
                            sliderOnChange={this.slide}
                        /> */}

                        <div className="range-inputs">
                            <InputForm
                                value={value.min}
                                type={'number'}
                                inputChange={e => this.inputChange(e, 'min')}
                            />

                            <span className="to">to</span>

                            <InputForm
                                value={value.max}
                                type={'number'}
                                inputChange={e => this.inputChange(e, 'max')}
                            />
                        </div>

                        <div className="button-group">
                            <button
                                onClick={() => this.buttonClickHandle(true)}
                                className="button link">Reset
                            </button>
                            <div className="add-buttons">
                                {searchStore.selectedTopAdvanced === 0 && <button
                                    onClick={() => this.buttonClickHandle(false, true)}
                                    className="button link left exclude">Exclude
                                </button>}
                                <button
                                    onClick={() => this.buttonClickHandle()}
                                    className="button link left">Add
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}