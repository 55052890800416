import React from 'react';
import ReactTooltip from 'react-tooltip';
import './processActions.scss';
import MoreMenuActionComponent from './MoreMenuAction.component';
import {HireIcon, InterestedIcon, InterestedProcessIcon, InterviewIcon, OfferIcon, PauseIcon, RejectIcon} from '../svg';
import utilsService from '../../../services/utils.service';

const ProcessActions = props => {
    const handleClick = (process, modal, type, e) => {
        e.preventDefault();
        props.actionEvent(process, modal, type);
    };

    const exposedAlready = () => {
        return props.process.status === 3;
    };

    const InviteToInterviewAction = () => {
        return (
            <div data-tip='Invite to Interview' className='action-item'
                 onClick={(e) => handleClick(props.process,
                     'isModalOpen_PromoteProcess', 'interview', e)}>
                {props.buttons && (<button
                    className="button">
                    Interview
                </button>)}
                {!props.buttons && (
                    <React.Fragment>
                        <div className="icon-wrapper">
                            <InterviewIcon/>
                            <p>Interview</p>
                        </div>
                    </React.Fragment>
                )}

            </div>
        );
    };

    const getInterestedIcon = (status) => {
        // return  <InterestedIcon/>;
        return status === 3 ? <InterestedProcessIcon/> : <InterestedIcon/>;
    };

    const GetDetailsAction = () => {
        return (
            <div data-tip='Get Details' className={exposedAlready() ? 'action-item interested' : 'action-item'}
                //  onClick={(e) => handleClick(props.process, 'isModalOpen_Expose',
                //      null, e)}>
                onClick={(e) => handleClick(props.process, 'isModalOpen_PromoteProcess','in-progress', e)}>
                {props.buttons && (<button
                    disabled={exposedAlready()}
                    className={`button green ${exposedAlready()
                        ? 'prog'
                        : ''}`}>
                    {exposedAlready()
                        ? 'Processing'
                        : ' Interested'}
                </button>)}
                {!props.buttons && (
                    <React.Fragment>
                        <div className="icon-wrapper">
                            {getInterestedIcon(props.process.status)}
                            <p>{exposedAlready()
                                ? 'Processing'
                                : ' Interested'}</p>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    };

    const InterestedAction = () => {
        return (
            <div data-tip='Interested' className={exposedAlready() ? 'action-item interested' : 'action-item'}
                 onClick={(e) => handleClick(props.process, 'isModalOpen_Interested',
                     null, e)}>
                {props.buttons && (<button
                    disabled={exposedAlready()}
                    className={`button green ${exposedAlready()
                        ? 'prog'
                        : ''}`}>
                    {exposedAlready()
                        ? 'Processing'
                        : ' Interested'}
                </button>)}
                {!props.buttons && (
                    <React.Fragment>
                        <div className="icon-wrapper">
                            {getInterestedIcon(props.process.status)}
                            <p>{exposedAlready()
                                ? 'Processing'
                                : ' Interested'}</p>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    };

    const MakeOfferAction = () => {
        return (
            <div data-tip='Make an offer' className='action-item make-offer'
                 onClick={(e) => handleClick(props.process,
                     'isModalOpen_PromoteProcess', 'offer', e)}>
                {props.buttons && (<button
                    className="button">
                    Offer
                </button>)}
                {!props.buttons && (
                    <React.Fragment>
                        <div className="icon-wrapper">
                            <OfferIcon/>
                            <p>Offer</p>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    };

    const HireAction = () => {
        return (
            <div data-tip='Hire' className='action-item'
                 onClick={(e) => handleClick(props.process,
                     'isModalOpen_PromoteProcess', 'hire', e)}>
                {props.buttons && (<button
                    className="button">
                    Hire
                </button>)}
                {!props.buttons && (
                    <React.Fragment>
                        <div className="icon-wrapper">
                            <HireIcon/>
                            <p>Hire</p>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    };

    const HoldProcessAction = () => {
        return (
            <div data-tip='Pause Process' className='action-item warning'
                 onClick={(e) => handleClick(props.process,
                     'isModalOpen_PauseProcess', null, e)}>
                {props.buttons && (<button
                    className="button warning">
                    Hold
                </button>)}
                {!props.buttons && (
                    <React.Fragment>
                        <div className="icon-wrapper">
                            <PauseIcon/></div>
                        <p>Pause</p>
                    </React.Fragment>
                )}
            </div>
        );
    };

    const RejectProcessAction = () => {
        return (
            <div data-tip='Reject' className='action-item red'
                 onClick={(e) => handleClick(props.process,
                     'isModalOpen_TerminateProcess', null, e)}>
                {props.buttons && (<button
                    className="button red">
                    Decline
                </button>)}
                {!props.buttons && (
                    <React.Fragment>
                        <div className="icon-wrapper">
                            <RejectIcon/>
                            <p>Decline</p>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    };

    // const getActions = () => {
    //     const status = props.process.status;
    //     switch (status) {
    //         case 34:
    //         case 2:
    //         case 9:
    //             return (
    //                 <React.Fragment>
    //                     <InviteToInterviewAction/>
    //                     <MakeOfferAction/>
    //                     <HireAction/>
    //                     <RejectProcessAction/>

    //                     {!props.noDots && (
    //                         <MoreMenuActionComponent
    //                             menu={[
    //                                 { modal: 'isModalOpen_AddNote', text: 'Add a note' },
    //                                 // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
    //                                 { modal: 'isModalOpen_Share', text: 'Share' }
    //                             ]}
    //                             actionEvent={(modal, e) => handleClick(props.process, modal, null,
    //                                 e)}
    //                         />
    //                     )}
    //                 </React.Fragment>
    //             );
    //         case 76:
    //         case 12:
    //         case 111:
    //             return (
    //                 <React.Fragment>
    //                     <GetDetailsAction/>
    //                     <RejectProcessAction/>
    //                 </React.Fragment>
    //             );

    //         case 3:
    //         case 70:
    //             return (
    //                 <React.Fragment>
    //                     <RejectProcessAction/>
    //                 </React.Fragment>
    //             );

    //         case 6:
    //             return (
    //                 <React.Fragment>
    //                     <InviteToInterviewAction/>
    //                     <MakeOfferAction/>
    //                     <RejectProcessAction/>
    //                     {!props.noDots && (
    //                         <MoreMenuActionComponent
    //                             menu={[
    //                                 { modal: 'isModalOpen_AddNote', text: 'Add a note' },
    //                                 // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
    //                                 { modal: 'isModalOpen_Share', text: 'Share' }
    //                             ]}
    //                             actionEvent={(modal, e) => handleClick(props.process, modal, null,
    //                                 e)}
    //                         />
    //                     )}
    //                 </React.Fragment>
    //             );
    //         case 66:
    //         case 4:
    //         case 5:
    //         case 36:
    //         case 40:
    //         case 46:
    //         case 29:
    //             return (
    //                 <React.Fragment>
    //                     <InviteToInterviewAction/>
    //                     <MakeOfferAction/>
    //                     <HireAction/>
    //                     {!props.noDots && (
    //                         <MoreMenuActionComponent
    //                             menu={[
    //                                 { modal: 'isModalOpen_AddNote', text: 'Add a note' },
    //                                 // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
    //                                 { modal: 'isModalOpen_Share', text: 'Share' }
    //                             ]}
    //                             process={props.process}
    //                             actionEvent={(process, modal, e) => handleClick(process, modal, null,
    //                                 e)}
    //                         />
    //                     )}
    //                 </React.Fragment>
    //             );
    //         case 69:
    //             return (
    //                 <React.Fragment>
    //                     <InterestedAction/>
    //                     <RejectProcessAction/>
    //                 </React.Fragment>
    //             );

    //         default:
    //             break;
    //         // case "Hired":

    //     }
    // };

    const getActions = () => {
        const process = props.process;
        if(utilsService.checkIfExposeReqeust(process)) {
            return (
                <React.Fragment>
                    <RejectProcessAction/>

                    {!props.noDots && (
                        <MoreMenuActionComponent
                            menu={[
                                { modal: 'isModalOpen_AddNote', text: 'Add a note' },
                                // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
                                { modal: 'isModalOpen_Share', text: 'Share' }
                            ]}
                            actionEvent={(modal, e) => handleClick(props.process, modal, null,e)}
                        />
                    )}
                </React.Fragment>
            );
        }
        if(utilsService.checkIfInProgress(process) || utilsService.checkIfInInterview(process) || utilsService.checkIfInOffer(process)) {
            return (
                <React.Fragment>
                    <InviteToInterviewAction/>
                    <MakeOfferAction/>
                    <HireAction/>
                    <RejectProcessAction/>

                    {!props.noDots && (
                        <MoreMenuActionComponent
                            menu={[
                                { modal: 'isModalOpen_AddNote', text: 'Add a note' },
                                // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
                                { modal: 'isModalOpen_Share', text: 'Share' }
                            ]}
                            actionEvent={(modal, e) => handleClick(props.process, modal, null,
                                e)}
                        />
                    )}
                </React.Fragment>
            );
        }
        if(utilsService.checkIfNew(process)) {
            return (

                <React.Fragment>
                    <GetDetailsAction/>
                    <RejectProcessAction/>
                </React.Fragment>
            );
        }
        if(utilsService.checkIfHold(process)) {
            return (
                <React.Fragment>
                    <InviteToInterviewAction/>
                    <MakeOfferAction/>
                    <RejectProcessAction/>
                    {!props.noDots && (
                        <MoreMenuActionComponent
                            menu={[
                                { modal: 'isModalOpen_AddNote', text: 'Add a note' },
                                // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
                                { modal: 'isModalOpen_Share', text: 'Share' }
                            ]}
                            actionEvent={(modal, e) => handleClick(props.process, modal, null,
                                e)}
                        />
                    )}
                </React.Fragment>
            );
        }
        if(utilsService.checkIfDeclineOrWithdrawn(process)) {
            return (
                <React.Fragment>
                    <InviteToInterviewAction/>
                    <MakeOfferAction/>
                    <HireAction/>
                    {!props.noDots && (
                        <MoreMenuActionComponent
                            menu={[
                                { modal: 'isModalOpen_AddNote', text: 'Add a note' },
                                // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
                                { modal: 'isModalOpen_Share', text: 'Share' }
                            ]}
                            process={props.process}
                            actionEvent={(process, modal, e) => handleClick(process, modal, null,
                                e)}
                        />
                    )}
                </React.Fragment>
            );
        }
        if(utilsService.checkIfLeadNew(process)) {
            return (
                <React.Fragment>
                    <InterestedAction/>
                    <RejectProcessAction/>
                </React.Fragment>
            );
        }
    };

    return (
            <div className={props.buttons ? 'process-actions buttons' : 'process-actions'}>
                <ReactTooltip effect='solid' disable={!props.tooltip}/>
                <div className="process-actions-body">
                    {getActions()}
                </div>
            </div>
    );
};

export default ProcessActions;
