import React, {Component} from 'react';
import {observer} from 'mobx-react';
import userStore from '../../../../stores/user.store';
import utilsService from '../../../../services/utils.service';
import EditCompanyComponent from './EditCompany.component';

@observer
class NewCompanyComponent extends Component {

    async componentDidMount() {
        document.title = 'Create New Company - Ethosia';

        if (!utilsService.isRecruiter(userStore.user)) {
            this.props.history.push('/pipelines');
            return;
        }
    }

    render() { 

        return (
            <React.Fragment>
                <EditCompanyComponent isNewCompany={true} />
            </React.Fragment>
        );
    }
}

export default NewCompanyComponent;
