import React, { PureComponent } from 'react';
import './modernSelect.scss';
import onClickOutside from 'react-onclickoutside';

class ModernSelectComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    getSelectedItem = () => {
        const { list, selected, indexMark } = this.props;
        return list.find(it => it[indexMark] === selected);
    };

    menuToggle = () => {
        if (!this.props.disabled) {
            this.setState({
                open: !this.state.open
            });
        }
    };

    selectEvent = item => {
        const { selectEvent } = this.props;
        this.setState({
            open: false
        });
        selectEvent(item);
    };

    handleClickOutside = evt => {
        this.setState({ open: false });
    };

    render() {
        const { list, indexMark, valueMark, title, customClasses, disabled } = this.props;
        const { open } = this.state;
        const selectedItem = this.getSelectedItem();

        let classes = 'modern-select';
        if (customClasses) {
            classes += ' ' + customClasses;
        }

        if (disabled) {
            classes += ' disabled';
        }

        return (
            <div className={classes}>
                {selectedItem && (
                    <div
                        onClick={this.menuToggle}
                        className="modern-select-visible-part">
                        {title && (
                            <span className="title">
                            {title}
                        </span>
                        )}

                        {selectedItem[valueMark]}
                    </div>
                )}
                {open && (
                    <div className="modern-select-list shadow3 border-radius">
                        {
                            list.map((item, idx) => {
                                return (
                                    <div
                                        onClick={() => this.selectEvent(item)}
                                        key={idx + item[indexMark]}
                                        className="modern-select-list-item">
                                        {item[valueMark]}
                                    </div>
                                );
                            })
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default onClickOutside(ModernSelectComponent);
