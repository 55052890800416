import React, {Component} from 'react';
import {observer} from 'mobx-react';
import NavigationBar from '../../components/navigationBar/NavigationBar.component';
import SendMessagesComponent from '../../components/sendMessages/SendMessages.component';
import utilsService from '../../../services/utils.service';
import userStore from '../../../stores/user.store';
import './inbox.scss';

@observer
class NewMessageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'New Message - Ethosia';
        if (!utilsService.isRecruiter(userStore.user)) {
            this.props.history.push('/pipelines');
            return;
        }
    }

    render() {
        return (
            <div className='main-content-space new-message-view'>
                <NavigationBar/>
                <div className="max-width new-message-view-wrapper">
                    <SendMessagesComponent
                        items={[]}
                        searchTo={true}
                    />
                </div>

            </div>
        );
    }
}

export default NewMessageView;
