import styled from 'styled-components';

export const ResponsiveDevice = {
  MOBILE: '@media screen and (max-width: 600px)',
  TABLET: '@media screen and (max-width: 900px)',
  LAPTOP: '@media screen and (max-width: 1200px)',
  DESKTOP: '@media screen and (max-width: 1536px)',
};

export const StyledContainer = styled.div`
  width: 100%;
  max-width: calc(1500px + 40px);
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  ${ResponsiveDevice.TABLET} {
    max-width: calc(1500px + 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const StyledWrapper = styled.div`
  min-height: 100vh;
  display: flex;

  padding-top: calc(92px + 16px);
  padding-bottom: 16px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #404040;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 2px;
  }
`;

export const StyledTitle = styled.h6`
  font-size: 24px;
  font-weight: 500;
  color: #404040;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const StyledFilters = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: #fff;
  border-radius: 12px 12px 0px 0px;

  & > .autocomplete,
  & > .text-field {
    width: 250px;
  }

  & > .input-collapse {
    width: 100%;

    .input-collapse-label {
      font-size: 16px;
    }
  }

  & > button {
    font-size: 16px;
    font-weight: 400;
    margin-left: auto;
    flex-shrink: 0;
    transition: all 100ms ease-in-out;
  }

  button.add-btn {
    padding: 10px 24px;
    border-radius: 60px;
    color: #fff;
    background-color: #307ff6;

    &:hover {
      color: #fff;
      background-color: #002a84;
    }
  }

  button.reset-btn {
    color: #6c6c6c;
    align-self: flex-end;

    &:hover {
      color: #00074f;
    }
  }

  ${ResponsiveDevice.TABLET} {
    padding: 16px;
    flex-wrap: wrap;
    gap: 16px;

    & > .text-field {
      width: 100%;
    }

    & > .autocomplete {
      order: 1;
      width: calc(50% - 8px);
    }

    & > .input-collapse {
      width: calc(50% - 8px);

      .input-collapse-label {
        font-size: 14px;
      }

      .input-collapse-header {
        margin-bottom: 0;
      }
    }
  }
`;
