import React, { Component, Fragment } from 'react';
import './addToMatches.scss';
import SuperSearchUsersProfilesInputComponent from '../forms/multiSelects/SuperSearchUsersProfilesInputComponent';
import SuperSearchJobsInputComponent from '../forms/multiSelects/SuperSearchJobsInput.component';
import SuperSearchUsersCompaniesInputComponent from '../forms/multiSelects/SuperSearchUsersCompaniesInputComponent';
import searchStore from '../../../stores/search.store';
import processStore from '../../../stores/process.store';
import profileStore from '../../../stores/profile.store';
import messagingStore from '../../../stores/messaging.store';
import { stateToHTML } from 'draft-js-export-html';
import jobStore from '../../../stores/job.store';
import companyStore from '../../../stores/company.store';
import { observer } from 'mobx-react';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import { MultiSelectComponent } from '../forms';
import SendCvComponent2 from './SendCV2.component';
import notificationsStore from '../../../stores/notifications.store';
import data from '../../../constants/data';
import {getSafeLatestInterview, isSummaryMissing} from '../../../entities/profiles';

@observer
class AddToMatchesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            candidatesErrorMessage: '',
            candidatesBlockedMessage: '',
            previousCompaniesMessage: '',
            companyApprovalMessage: '',
            alreadyhasMatch: '',
            alreadyhasProcess: '',
            isErrors: false,
            errorsList: [],
            isUsingRecruitementSystem: false
        };
    }

    getTitle(addToMatches) {
        if (addToMatches) {
            return 'Add to Matches';
        } else {
            return 'Send Candidates to Job';
        }
    }

    closeModal = () => {
        this.props.closeEvent();
    };

    isFormValid = isLeads => {
        const { sendCandidates, addToMatches } = this.props;
        const { candidatesBlockedMessage, previousCompaniesMessage, alreadyhasMatch, alreadyhasProcess } = this.state;
        let verdict = true;
        const jobsValid = searchStore.jobsSearchTo.length;
        const candidates = searchStore.profilesUsersSearchPersonsTo;
        let isAtLeastOneCandidateMissingCoveredCV = false;
        let isAtLeastOneCandidateNotAvailable = false;
        candidates.forEach(candidate => {
            const tmp = candidate.cvs && candidate.cvs.filter(cv => cv && cv?.cvType && (cv?.cvType === 2 || cv?.cvType?.elId === 2) && cv.isPrimary);
            if (!tmp || tmp.length === 0) {
                isAtLeastOneCandidateMissingCoveredCV = true;
                return false;
            }

            if (!candidate.isAvailable) {
                isAtLeastOneCandidateNotAvailable = true;
            }
        });
        if (!isLeads && !addToMatches && (isAtLeastOneCandidateMissingCoveredCV || isAtLeastOneCandidateNotAvailable)) {
            verdict = false;
        }

        const profilesValid = searchStore.profilesUsersSearchPersonsTo.length;

        if (!jobsValid || !profilesValid) {
            verdict = false;
        }

        if (!isLeads && !addToMatches && sendCandidates) {
            for (let job of searchStore.jobsSearchTo) {
                const emailList = messagingStore.mergeEmailListSelected(job);
                if (emailList.length === 0) {
                    verdict = false;
                    break;
                }
            }
        }

        if (isLeads) {
            const candidatesValid = [];

            candidates.forEach(candidate => {
                if (candidate.socialLinks && candidate.socialLinks.length) {
                    const arrOfResults = candidate.socialLinks.filter(a => a.linkType === 47);
                    if (arrOfResults.length > 0) {
                        candidatesValid.push(true);
                    }
                }
                else {
                    if (candidate.cvs && candidate.cvs.length) {
                        const publicCvs = candidate.cvs.filter(cv => cv?.cvType?.elId === 1 && cv.isPrimary);
                        if (publicCvs.length > 0) {
                            candidatesValid.push(true);
                        }
                    }
                }
            });
            if (candidatesValid.length !== candidates.length) {
                verdict = false;
            }
        }

        if (candidatesBlockedMessage) {
            verdict = false;
        }

        if (addToMatches && (alreadyhasMatch.length > 0 || alreadyhasProcess.length > 0)) {
            verdict = false;
        }

        return verdict;
    };

    addToMatchesHandle = async () => {
        this.setState({
            loader: true
        });
        const jobs = searchStore.jobsSearchTo;
        const profiles = searchStore.profilesUsersSearchPersonsTo;

        const processes = [];
        const currentUser = userStore.user;
        jobs.forEach(job => {
            profiles.forEach(profile => {
                processes.push({
                    company: job.company,
                    companySlug: job.companySlug,
                    job: job._id,
                    jobSlug: job.slug,
                    profile: profile._id,
                    profileSlug: profile.slug,
                    status: 1,
                    eventLog: [{
                        comment: '',
                        eventTypeId: 1,
                        decisionMakerEmail: currentUser.email,
                        decisionMakerName: utilsService.getDisplayName(currentUser),
                        decisionMakerUserType: currentUser.userType,
                        decisionMakerUserId: currentUser._id,
                        decisionMakerUserSlug: currentUser.slug
                    }]
                });
            });
        });

        await processStore.createProcesses(processes);
        notificationsStore.showToast(`Add to matches completed successfully`, 'success');
        this.setState({
            loader: false
        }, () => {
            this.props.requestFinished && this.props.requestFinished();
            this.props.closeEvent();
        });

    };

    sendCvHandler = async isToJobs => {
        const { isFromMatches } = this.props;
        this.setState({
            loader: true,
            isError: false,
            errorsList: []
        });

        const body = messagingStore.sendCVEmailBody;
        const bcc = messagingStore.sendCVEmailBcc;
        const cc = messagingStore.sendCVEmailCc;
        const jobs = searchStore.jobsSearchTo;
        const profiles = searchStore.profilesUsersSearchPersonsTo;

        const user = userStore.user;

        const data = [];
        for(const profile of profiles) {
            for(const job of jobs) {
                const targetParticipant = await messagingStore.findTargetParticipant(job);
                data.push({
                    process: {
                        company: job.company,
                        companySlug: job.companySlug,
                        job: job._id,
                        jobSlug: job.slug,
                        profile: profile._id,
                        profileSlug: profile.slug,
                        status: isToJobs ? 12 : 69,
                        isFromMatches: isFromMatches,
                        jobRecruiterInChargeId: job.recruiterInChargeId
                    },
                    company: {
                        id: job.company,
                        slug: job.companySlug
                    },
                    email: {
                        multiRecipientsMode: utilsService.isMultiRecipientsMode(),
                        // multiRecipientsMode: false,
                        messageBody: stateToHTML((body.getCurrentContent())),
                        messageRawBody: stateToHTML((body.getCurrentContent())),
                        toEmail: messagingStore.mergeEmailListSelected(job),
                        targetParticipantProfileId: targetParticipant ? targetParticipant._id : null,
                        targetParticipantProfileSlug: targetParticipant ? targetParticipant.slug : null,
                        senderUserId: user._id,
                        senderUserSlug: user.slug,
                        senderUserEmail: user ? user.email : null,
                        bcc: bcc,
                        cc: cc,
                        tag: 3,
                        label: 2,
                        conversationType: 1,
                        jobSlug: job.slug,
                        targetParticipantType: 2,
                        senderType: user.userType,
                        profileSlug: profile.slug,
                        profileId: profile._id,
                        cvFileId: profile.emsId,
                        action: 'send_cv_message',
                        notificationType: 'mail'
                    },
                    info: {
                        jobTitle: `${job.title} ${job.positionCode ? job.positionCode : ''}`,
                        companyName: `${job.companyName}`,
                        profileName: utilsService.getDisplayName(profile, true),
                        profileNameHeb: utilsService.getDisplayNameHebrew(profile),
                        email: utilsService.getPrimaryValue(profile.emails, 'email'),
                        phone: utilsService.getPrimaryValue(profile.phones, 'phone'),
                        profileIdNumber: profile.idNumber,
                        summary: getSafeLatestInterview(profile),
                        id: profile._id,
                        slug: profile.slug,
                        user: userStore.user
                    }
                });
            };
        };

        const response = await messagingStore.sendCvs(data);
        let isError = false;
        let someExists = false;
        const errorsList = [];
        const processAlreadyExists = [];
        response && response.forEach(res => {
            if (!res || res.isError) {
                isError = true;
                errorsList.push(res.errorMessage);
            }
            if (res.processExists || res.processExistsInSameCompany) {
                someExists = true;
                processAlreadyExists.push(res);
            }
        });
        const operationMessage = isToJobs ? 'Send CV' : 'Add Leads';
        if (isError) {
            notificationsStore.showToast(`Failed to ${operationMessage}`, 'failure');
        } else if (someExists) {
            notificationsStore.showToast(`Some CV not sent`, 'failure');
        }
        else {
            notificationsStore.showToast(`${operationMessage} completed successfully`, 'success');
        }

        this.setState({
            loader: false,
            isError: isError,
            errorsList: errorsList
        }, () => {
            if (!isError) {
                if (someExists) {
                    this.props.someExists(processAlreadyExists, response);
                } else {
                    this.props.requestFinished && this.props.requestFinished('send', response);
                    this.props.closeEvent();
                }
            }
        });

    };

    async componentDidMount() {
        const { profiles, jobsSlug } = this.props;

        const tmpProfilesSet = new Set();
        const tmpJobsSet = new Set();

        if (Array.isArray(profiles)) {
            profiles.forEach(profile => tmpProfilesSet.add(profile));
        }

        if (Array.isArray(jobsSlug)) {
            jobsSlug.forEach(jobSlug => tmpJobsSet.add(jobSlug));
        }

        const filteredJobsSlug = [...tmpJobsSet];
        const filteredProfileSet = [...tmpProfilesSet];
        if (filteredProfileSet && filteredProfileSet.length > 0) {
            await profileStore.getProfilesBySlugs(filteredProfileSet, true);
            searchStore.profilesUsersSearchPersonsTo = filteredProfileSet.map(slug => {
                return profileStore.profiles[slug];
            }).filter(Boolean);
        }

        if (filteredJobsSlug && filteredJobsSlug.length > 0) {
            const companyIdsSet = new Set();
            await jobStore.getJobsBySlugs(filteredJobsSlug);
            filteredJobsSlug.forEach(slug => {
                const job = jobStore.allJobs[slug];
                const company = companyStore.companies[job.companySlug];
                if (!company && job.company) {
                    companyIdsSet.add(job.company);
                }
            });

            if (companyIdsSet.size > 0) {
                await companyStore.getCompaniesByIds(Array.from(companyIdsSet));
            }

            const jobsData = filteredJobsSlug.map(slug => {
                const job = jobStore.allJobs[slug];
                const company = companyStore.companies[job.companySlug];

                if (company) {
                    job.companyName = company.name;
                }

                return job;
            });

            searchStore.jobsSearchTo = jobsData;
            let temp = [];
            jobsData.forEach(job => {
                const result = job.emailsForCv && job.emailsForCv.map((item) => {
                    return {
                        elId: item, value: item
                    };
                });
                const companyEmails = job.emailsFromCompanyEmails && job.emailsFromCompanyEmails.map((item) => {
                    return {
                        companyEmail: item
                    };
                });
                if (result) {
                    temp = [...temp, ...result];
                }
                if(companyEmails) {
                    temp = [...temp, ...companyEmails];
                }

            });
            messagingStore.sendCVEmailListSelected = temp;
            messagingStore.sendCVEmailList = temp;

        }

        const user = userStore.user;
        const profile = await profileStore.getProfileById(user.profileId);
        user.profileSlug = profile.slug;

        this.checkCandidateHaveDataNeeded();
    }

    checkCandidateHaveDataNeeded = async () => {
        const { addToMatches, isFromMatches } = this.props;
        const candidates = searchStore.profilesUsersSearchPersonsTo;
        let cvMissing = false;
        let interviewMissing = false;
        let isCandidateNotAvailable = false;

        this.setState({isUsingRecruitementSystem: false, companyApprovalMessage: ''});

        candidates.forEach(candidate => {
            const tmp = candidate.cvs && candidate.cvs.filter(cv => cv && cv?.cvType && (cv?.cvType === 2 || cv?.cvType?.elId === 2) && cv.isPrimary);
            if (!tmp || tmp.length === 0) {
                cvMissing = true;
            }

            if (isSummaryMissing(candidate)) {
                interviewMissing = true;
            }

            if (!candidate.isAvailable) {
                isCandidateNotAvailable = true;
            }
        });

        let errMessage = '';
        if (!addToMatches && (cvMissing || interviewMissing || isCandidateNotAvailable)) {
            errMessage = 'Some candidates are missing: ';
            if (cvMissing) {
                errMessage += 'Covered CV'
            }
            if (interviewMissing) {
                if (cvMissing) {
                    errMessage += ', ';
                }
                errMessage += 'Interview data';
            }

            if (isCandidateNotAvailable) {
                if (cvMissing || interviewMissing) {
                    errMessage += ', ';
                }
                errMessage += 'Not available'
            }
        }
        this.setState({ candidatesErrorMessage: errMessage });

        let isBlockedMessage = false;
        let isPreviousCompanies = false
        const jobsToSendTo = searchStore.jobsSearchTo;
        const logedInUserId = userStore.user._id;
        const companiesToSendTo = jobsToSendTo.map(job => job.company);
        const jobsRecInCharge = jobsToSendTo.map(job => job.recruiterInChargeId);
        let isLogInUserAlsoRecInChargeOfAllJobs = jobsRecInCharge && jobsRecInCharge.length > 0 ? true : false;
        jobsRecInCharge.forEach(ric => {
            if (!ric || ric !== logedInUserId) {
                isLogInUserAlsoRecInChargeOfAllJobs = false;
            }
        });

        this.setState({ companyApprovalMessage: '' });
        !addToMatches && companiesToSendTo.forEach(async companyId => {
            const companyManagement = await companyStore.getCompanyManagement(companyId);

            if (companyManagement.approveBeforeSendingCv && !isFromMatches && !isLogInUserAlsoRecInChargeOfAllJobs) {
                this.setState({ companyApprovalMessage: 'Company requires approval before sending, thus a new match will be created.' });
            }

            if(companyManagement.isUsingSystem) {
                this.setState({isUsingRecruitementSystem: true});
            }


            // companyStore.getCompanyManagement(companyId).then(companyManagement => {
            //     if (companyManagement.approveBeforeSendingCv && !isFromMatches && !isLogInUserAlsoRecInChargeOfAllJobs) {
            //         this.setState({ companyApprovalMessage: 'Company requires approval before sending, thus a new match will be created.' });
            //     }

            //     if(companyManagement.isUsingSystem) {
            //         this.setState({isUsingRecruitementSystem: true});
            //     }
            // });
        });

        companiesToSendTo.forEach(async companyToSendTo => {
            candidates.forEach(async candidate => {
                const blockedCompanies = utilsService.getProfileBlockedCompanies(candidate);
                if (blockedCompanies.includes(companyToSendTo)) {
                    isBlockedMessage = true;
                    return false;
                }

                if (candidate?.linkedInData?.experienceList?.some(item => item.companyId === companyToSendTo)) {
                    isPreviousCompanies = true;
                    return false;
                }
            })
        });

            this.setState({
                candidatesBlockedMessage: (isBlockedMessage ? 'Some candidates have blocking companies.' : ''),
                previousCompaniesMessage: (isPreviousCompanies ? 'Some candidates had previous work experience.' : '')
            });

        // check candidate has no matches processes in one of the jobs
        if (addToMatches) {
            const candidatesIds = candidates.map(item => item._id);
            const jobsIds = jobsToSendTo.map(item => item._id);
            let processes = []
            if (candidatesIds.length > 0 && jobsIds.length > 0) {
                processes = await processStore.getProcessesByProfileIdsAndJobsIds(candidatesIds, jobsIds,
                    undefined, 1);
            }
            this.updateCandidateMatchScreen(processes);
        }
    };

    updateCandidateMatchScreen = (processes) => {
        const candidates = searchStore.profilesUsersSearchPersonsTo;
        const jobsToSendTo = searchStore.jobsSearchTo;
        const tempCandidates = utilsService.doMeAFavor(candidates);
        let matchErrorMessage = '';
        let processErrorMessage = '';
        tempCandidates.forEach(candidateItem => {
            candidateItem.hasMatch = processes.some(processItem => {
                const hasMatch = utilsService.getMatchesStatuses().includes(processItem.status) &&
                    processItem.profile === candidateItem._id &&
                    jobsToSendTo.some(item => item._id === processItem.job);
                if (hasMatch) {
                    matchErrorMessage = 'Some candidates already have a match in one of the jobs';
                }
                return hasMatch;
            });
            candidateItem.hasProcess = processes.some(processItem => {
                const hasProcess = !utilsService.getMatchesStatuses().includes(processItem.status) &&
                    !data.unmatch_options.find(item => item.id === processItem.status) &&
                    processItem.profile === candidateItem._id &&
                    jobsToSendTo.some(item => item._id === processItem.job);
                if (hasProcess) {
                    processErrorMessage = 'Some candidates already have a process in one of the jobs';
                }
                return hasProcess;
            });
        })

        searchStore.profilesUsersSearchSetPersonsTo(tempCandidates);
        this.setState({ alreadyhasMatch: matchErrorMessage, alreadyhasProcess: processErrorMessage });
    }

    removeMultiItem = text => {
        messagingStore.sendCVEmailListSelected = messagingStore.sendCVEmailListSelected.filter(
            item => item.elId !== text);
    };

    selectMultiItem = item => {
        // If exist then remove
        let update = null;

        if (messagingStore.sendCVEmailListSelected.some(objItem => objItem.elId === item.elId)) {
            update = messagingStore.sendCVEmailListSelected.filter((objItem) => objItem.elId !== item.elId);
        } else {
            update = [...messagingStore.sendCVEmailListSelected, item];
        }

        messagingStore.sendCVEmailListSelected = update;
    };

    componentWillUnmount() {
        messagingStore._sendCVInProcess = false;
        messagingStore.sendCVEmailList = [];
        messagingStore.sendCVEmailListSelected = [];
    }

    render() {
        const { addToMatches, sendCandidates, jobsSlug, jobLimit, candidateLimit } = this.props;
        const { loader, candidatesErrorMessage, candidatesBlockedMessage, previousCompaniesMessage, companyApprovalMessage,
            errorsList, isError, alreadyhasMatch, alreadyhasProcess, isUsingRecruitementSystem } = this.state;

        return (
            <div className='add-to-matches'>
                <strong className="handle dragging-handle" />
                <h2>{this.getTitle(addToMatches)}</h2>

                <div className="add-to-matches-job-multi-select item">
                    <label>Job (write job name or use # for job id and @ for company)</label>
                    <SuperSearchJobsInputComponent
                        jobsSlug={jobsSlug}
                        limit={jobLimit}
                        addedNewCandidateHandler={this.checkCandidateHaveDataNeeded}
                    />
                </div>

                {sendCandidates && <div className="add-to-matches-job-multi-select item last">
                    <label>Company Emails</label>
                    <MultiSelectComponent
                        items={messagingStore.sendCVEmailList}
                        validation={true}
                        valid={true}
                        selectedItems={messagingStore.sendCVEmailListSelected}
                        allowCreate={true}
                        // openOnKeyDown={true}
                        removeItem={item => this.removeMultiItem(item)}
                        selectItem={item => this.selectMultiItem(item)}
                    />
                </div>}


                {sendCandidates && <div className="add-to-matches-job-multi-select item last">
                    <label>Recipients</label>
                    <SuperSearchUsersCompaniesInputComponent />
                </div>}

                <div className="add-to-matches-job-multi-select item last">
                    <label>Candidates</label>
                    <SuperSearchUsersProfilesInputComponent
                        disableUser={true}
                        isValidHandleInParent={true}
                        limit={candidateLimit}
                        addedNewCandidateHandler={this.checkCandidateHaveDataNeeded}
                        isCVNeeded={true}
                        addToMatches={addToMatches}
                    />
                    {candidatesErrorMessage && (
                        <div className="err-message">{candidatesErrorMessage}</div>
                    )}
                    {candidatesBlockedMessage && (
                        <div className="err-message">{candidatesBlockedMessage}</div>
                    )}
                    {previousCompaniesMessage && (
                        <div className="err-message">{previousCompaniesMessage}</div>
                    )}
                    {companyApprovalMessage && (
                        <div className="err-message">{companyApprovalMessage}</div>
                    )}
                    {addToMatches &&
                        <Fragment>
                            {alreadyhasMatch && <div className="err-message">{alreadyhasMatch}</div>}
                            {alreadyhasProcess && <div className="err-message">{alreadyhasProcess}</div>}
                        </Fragment>
                    }
                </div>


                {addToMatches && (
                    <AddToMatchesFooter
                        isFormValid={this.isFormValid()}
                        cancel={this.closeModal}
                        loader={loader}
                        addToMatchesHandle={this.addToMatchesHandle}
                    />
                )}

                {sendCandidates && <SendCvComponent2 />}

                {isError && (
                    <div className="add-to-matches-error-list-wrapper">
                        {errorsList.map((item, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="add-to-matches-error-list-item">
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                )}
                {isUsingRecruitementSystem && (
                    <div className="add-to-matches-error-list-wrapper">
                        <div
                            className="add-to-matches-error-list-item">
                            this company is using recruitment system
                        </div>
                    </div>
                )}

                {sendCandidates && (
                    <SendCandidatesFooter
                        isFormValid={this.isFormValid()}
                        leadsValid={this.isFormValid(true)}
                        cancel={this.closeModal}
                        loader={loader}
                        sendToLeads={() => this.sendCvHandler()}
                        sendToJob={() => this.sendCvHandler(true)}
                    />
                )}
            </div>
        );
    }
}

export default AddToMatchesComponent;

const AddToMatchesFooter = props => {
    const { cancel, addToMatchesHandle, isFormValid, loader } = props;
    let classes = 'button black';
    if (loader) {
        classes += ' loading';
    }

    if (!isFormValid) {
        classes += ' disabled';
    }

    return (
        <footer>
            <button
                onClick={cancel}
                className="button gray">Cancel
            </button>
            <button
                disabled={!isFormValid}
                onClick={addToMatchesHandle}
                className={classes}>Add
            </button>
        </footer>
    );
};

const SendCandidatesFooter = props => {
    const { cancel, sendToJob, sendToLeads, isFormValid, loader, leadsValid } = props;
    let classes = 'button black send-to-job';
    let classes2 = 'button bk-less add-to-leads';
    if (loader) {
        classes += ' loading';
        classes2 += ' loading';
    }

    if (!isFormValid) {
        classes += ' disabled';
    }

    if (!leadsValid) {
        classes2 += ' disabled';
    }

    return (
        <footer className='candidates-footer'>
            <button
                onClick={cancel}
                className="button gray">Cancel
            </button>
            <button
                disabled={!leadsValid}
                onClick={sendToLeads}
                className={classes2}>Add to leads
            </button>
            <button
                disabled={!isFormValid}
                onClick={sendToJob}
                className={classes}>Send to job
            </button>
        </footer>
    );
};

