import React, { useState, useEffect } from 'react'
import utilsService from '../../../services/utils.service';
import userStore from '../../../stores/user.store';
import DefaultImageComponent from '../DefaultImage.component';
import { ListIcon } from '../svg';
import './primarynote.scss'

const PrimaryNote = (props) => {

    const [isOpen, setIsOpen] = useState(props.isAlwaysOpen ? props.isAlwaysOpen : false);
    const [user, setUser] = useState(undefined)

    const {note} = props;

    const creatorName = note && note.creatorName ? note.creatorName : 'Unknown';
    let creatorImage = undefined;
    let noteDate = undefined;
    if(note) {
        noteDate = note.dateUpdated;
    }
    const footerText = creatorName && noteDate ? `${creatorName}, ${utilsService.getFormattedDate(noteDate)}` : '';

    const handleArrowClick = (evt, isArrowUp) => {
        if(isArrowUp) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }
    }

    useEffect(() => {

        const getCreatorProfile = async () => {
            if(note) {
                const user = await userStore.getUserById(note.creator);
                setUser(user);
            }
        }
        getCreatorProfile();
    }, [note]);

    const notesText = note && note.text ? note.text : 'Missing Primary Note';
    const isAlwaysOpen = props.isAlwaysOpen ? props.isAlwaysOpen : false;

    creatorImage = utilsService.getUserImage(user);

    return (
        <div className={props.wrapperClass}>
            <div className="note-container">
                <div className={"header " + (isAlwaysOpen ? 'header-without-open-arrow' : 'header-with-open-arrow')}>
                    <div className="list-icon-container">
                        <ListIcon />
                    </div>
                    <div className="notes-text">Notes</div>
                    {/* <div className="edit-icon-container">
                        <EditIcon />
                    </div> */}
                    {!isAlwaysOpen && isOpen && (
                        <div 
                            className="arrow-up"
                            onClick={(evt) => handleArrowClick(evt, true)}>
                        </div>
                    )}
                    {!isAlwaysOpen && !isOpen && (
                        <div 
                            className="arrow-down"
                            onClick={(evt) => handleArrowClick(evt, false)}>
                        </div>
                    )}
                    
                </div>
                {isOpen && (
                    <React.Fragment>
                        <div className="body" dangerouslySetInnerHTML={{__html: notesText}}></div>
                        {creatorName && noteDate && (
                            <div className="footer">
                                <DefaultImageComponent
                                    url={creatorImage}
                                    alt={creatorName}
                                    profileName={creatorName}
                                />
                                {footerText}
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}

export default PrimaryNote;