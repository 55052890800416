import React from 'react';
import CustomCheckboxComponent from '../customCheckbox/CustomCheckbox.component';
import './customIncludeOperator.scss';

const CustomIncludeOperator = (props) => {

    const checkAction = (e) => {
        props.checkAction(e);
    }

    return (
        <div className="custom-include-operator-wrapper">
            <label>Include:</label>
            <div className="row message-wrapper">
                <CustomCheckboxComponent
                    checkAction={(e) => checkAction(e)}
                    checked={props.checked}>
                </CustomCheckboxComponent>
                <span className="operator-message">Passed at least one condition</span>
            </div>
        </div>
    )
};

export default CustomIncludeOperator;
