import React from 'react';
import notificationsStore from '../../stores/notifications.store';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App

    const message = `${error.message || 'Something went wrong'}. Please try to reload the page`;
    // notificationsStore.showToast(message, 'failure');
    console.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <h1>Something went wrong, please reload again</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
