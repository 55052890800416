import React from 'react';

const CcBccIcons = props => {
    const { select, ccData, bccData } = props;

    return (
        <div className="new-message-super-search-input-fancy-cc-bcc">
            {[
                'Cc', 'Bcc'
            ].map((item, idx) => {
                let classes = 'new-message-super-search-input-fancy-cc';

                if (ccData && idx === 0 || bccData && idx === 1) {
                    classes += ' highlight';
                }

                return (
                    <span
                        onClick={() => select(idx)}
                        key={item + idx}
                        className={classes}>{item}</span>
                );
            })}
        </div>
    );
};
export default CcBccIcons;
