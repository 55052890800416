import React, { Component } from 'react';
import { observer } from 'mobx-react';
import searchStore from '../../../../stores/search.store';
import utilsService from '../../../../services/utils.service';
import MultiSelectComponent from '../../forms/MultiSelect.component';
import DefaultImageComponent from '../../DefaultImage.component';
import companyStore from '../../../../stores/company.store';
import {isSummaryMissing} from '../../../../entities/profiles';

@observer
class SuperSearchUsersProfilesInputComponent extends Component {
    constructor(props) {
        super(props);
        this.typingTimeout = 0;
    }

    componentWillUnmount() {
        this._mounted = false;
        clearTimeout(this.typingTimeout);
        searchStore.profilesUsersSearchSetPersonsTo([]);
        searchStore.profilesUsersSearchResults = [];
    }

    removeMultiItem = (text, index) => {
        const update = searchStore.profilesUsersSearchPersonsTo.filter(
            (obj, idx) => idx !== index);
        searchStore.profilesUsersSearchSetPersonsTo(update);
        if(this.props.addedNewCandidateHandler) {
            this.props.addedNewCandidateHandler();
        }
    };

    selectMultiItem = item => {
        // If exist then remove
        let update = null;

        const limit = this.props.limit;

        // exists user
        if(item._id) {
            if (searchStore.profilesUsersSearchPersonsTo.some(objItem => objItem._id === item._id)) {
                update = searchStore.profilesUsersSearchPersonsTo.filter((objItem) => objItem._id !== item._id);
            }
        }

        // new
        else {
            if (searchStore.profilesUsersSearchPersonsTo.some(objItem => objItem.value === item.value)) {
                update = searchStore.profilesUsersSearchPersonsTo.filter((objItem) => objItem.value !== item.value);
            }
        }

        if(!update) {
            if (limit && searchStore.profilesUsersSearchPersonsTo.length < limit) {
                update = [...searchStore.profilesUsersSearchPersonsTo, item];
            } else if (!limit) {
                update = [...searchStore.profilesUsersSearchPersonsTo, item];
            } else {
                update = searchStore.profilesUsersSearchPersonsTo;
            }
        }

        searchStore.profilesUsersSearchSetPersonsTo(update);
        if(this.props.addedNewCandidateHandler) {
            this.props.addedNewCandidateHandler();
        }
    };

    query = async text => {
        const { disableProfile, disableUser } = this.props;
        let filter = null;
        let tempText = text;
        if (disableProfile) {
            filter = 3;
        }

        else {
            const firstChar = tempText.charAt(0);

            //will search for fields with ids (bsId, slug and etc..)
            if (disableUser) {
                if (firstChar === '$') {
                    filter = 4;
                    tempText = tempText.substr(1);
                }
                else {
                    filter = 2;
                }
            }
        }

        const data = {
            text: tempText,
            filter: filter
        };

        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        this.typingTimeout = setTimeout(async () => {
            if (tempText && tempText.length > 2) {
                await searchStore.profilesUsersSearch(data);
            } else if (tempText === '') {
                searchStore.profilesUsersSearchResults = [];
            }
        }, 300);

    };

    getTitle(info, titleType) {
        let res = '';
        if (titleType === 'email') {
            if (info.email && info.userType) {
                res = info.email;
            } else if (info.emails && info.emails.length > 0) {
                res = utilsService.getPrimaryValue(info.emails, 'email');
            } else {
                res = 'No email found';
            }
        }
        if (titleType === 'phone') {
            if (info.phone) {
                res = info.phone;
            } else if (info.phones && info.phones.length > 0) {
                res = utilsService.getPrimaryValue(info.phones, 'phone');
            } else {
                res = 'No phone found';
            }
        }

        return res;
    }

    multiItemRenderer = (item, { handleClick, modifiers, index }) => {
        const { titleType = 'email' } = this.props;

        let classes = 'multi-item profile-multi-render';
        const selectedItems = searchStore.profilesUsersSearchPersonsTo.slice();
        if (selectedItems.some(objItem => objItem._id === item._id)) {
            classes += ' selected';
        }
        if (modifiers.active) {
            classes += ' active';
        }

        const contactItem = this.getTitle(item, titleType);
        const userType = utilsService.getUserTypeByType(item.userType);

        const companyPosition = this.drawCompanyPosition(item);

        return (
            <div
                className={classes}
                key={index}
                onClick={handleClick}
            >
                <div className="profile-multi-render-img">
                    <DefaultImageComponent
                        url={utilsService.getProfileImage(item)}
                        alt={utilsService.getDisplayName(item)}
                        profileName={utilsService.getDisplayName(item)}
                    />
                </div>
                <div className="profile-multi-render-info">
                    <div className="name">
                        {`${item.firstName} ${item.lastName} `}
                        <span className="user-type">
                    ({userType}) - <span>{contactItem}</span>
                       </span>
                    </div>
                    {companyPosition && (
                        <div className="company">
                            {companyPosition}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    drawCompanyPosition = item => {
        let res = '';
        if (item.position) {
            res += item.position;
        }

        if (item.company) {
            res += ' @ ' + item.company;
        }
        return res;
    };

    itemPredicate = (item, cat) => {
        return item;
    };

    tagsMultiRender = item => {
        const { titleType = 'email', isCVNeeded, addToMatches, allowCreate } = this.props;

        if(allowCreate && !item._id) {
            return (
                <span>{item.value}</span>
            )
        }
        else {
            const title = this.getTitle(item, titleType);

            if(this.addedNewCandidateHandler)         {
                this.addedNewCandidateHandler();
            }

            const companiesToSendTo = searchStore.jobsSearchTo.map(job => job.company);
            let blockedCompanyNames = '';
            if(item.talent && item.talent.wishlist && companiesToSendTo) {
                companiesToSendTo.forEach(async companyToSendTo => {
                    const blockedCompanies = utilsService.getProfileBlockedCompanies(item);
                    const isBlockedCompanyExist =  blockedCompanies.includes(companyToSendTo);
                    if(isBlockedCompanyExist) {
                        if(!companyStore.getCompanyById(companyToSendTo)) {
                            await companyStore.getCompaniesByIds([companyToSendTo]);
                        }
                        const company = companyStore.getCompanyById(companyToSendTo);
                        if(company) {
                            blockedCompanyNames += blockedCompanyNames === '' ? company.name : `,${company.name}`
                        }
                    }
                })
            }

            const hasCoveredCV = item.cvs && item.cvs.filter(cv => cv && cv?.cvType && (cv?.cvType === 2 || cv?.cvType?.elId === 2) && cv.isPrimary);
            const isNotAvailable = !item.isAvailable;

            let missingTags = '';
            if(isCVNeeded) {
                if(!hasCoveredCV || hasCoveredCV.length === 0) {
                    missingTags += '<Covered CV Missing>';
                }
                if(isSummaryMissing(item)) {
                    missingTags += missingTags !== '' ? ' <Interview is Missing>' : '<Interview is Missing>';
                }
                if(blockedCompanyNames !== '') {
                    missingTags += missingTags !== '' ? ` <${blockedCompanyNames} blocked>` : `<${blockedCompanyNames} blocked companies>`;
                }
                if(isNotAvailable) {
                    missingTags += missingTags !== '' ? ' <Not Available>' : '<Not Available>';
                }
            }

            if(addToMatches) {
                if(item.hasMatch) {
                    missingTags += missingTags !== '' ? ' <Already has a match>' : '<Already has a match>';
                }

                if(item.hasProcess) {
                    missingTags += missingTags !== '' ? ' <Already has a process>' : '<Already has a process>';
                }
            }

            if(!isCVNeeded || missingTags === '') {
                return (
                    <span
                        title={title}>
                        {`${item.firstName} ${item.lastName} <${title}> ${this.getCompanyPosition(item)}`}
                    </span>
                );
            }
            else {
                return (
                    <span
                        title={title}>
                    {`${item.firstName} ${item.lastName} <${title}> `}
                        <span className="missing-tags">
                           {`${missingTags}`}
                        </span>
                        {`${this.getCompanyPosition(item)}`}
                    </span>);
            }
        }

    };

    getCompanyPosition(item) {
        let res = '';
        if (item.company) {
            res = item.position ? item.position : '';
            res += ' - @' + item.company;
        }

        return res;
    }


    render() {
        const { allowCreate } = this.props
        const selectedItems = searchStore.profilesUsersSearchPersonsTo.slice();
        const searchResults = searchStore.profilesUsersSearchResults.slice();

        const jobs = searchStore.jobsSearchTo;

        let sortedItems = searchResults;
        if(searchResults.length > 2) {
            const firstItem = searchResults.shift();
            sortedItems = searchResults.sort((a,b) => {
                const fullNameA = `${a.firstName} ${a.lastName}`;
                const fullNameB = `${b.firstName} ${b.lastName}`;

                return fullNameA > fullNameB ? 1 : -1;
            });
            sortedItems.unshift(firstItem);
        }

        return (
            <div className='new-message-super-search-input'>
                <MultiSelectComponent
                    items={sortedItems}
                    valid={selectedItems.length > 0}
                    selectedItems={selectedItems}
                    openOnKeyDown={true}
                    query={this.query}
                    placeholder={'To'}
                    customRenderer={this.multiItemRenderer}
                    customPredicate={this.itemPredicate}
                    customTagsMultiRender={this.tagsMultiRender}
                    removeItem={(item, index) => this.removeMultiItem(item, index)}
                    selectItem={item => this.selectMultiItem(item)}
                    mainOuterClass={'custom-scroll'}
                    allowCreate={allowCreate}
                />
            </div>
        );
    }
}

export default SuperSearchUsersProfilesInputComponent;
