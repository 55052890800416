import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
    doSignInWithEmailAndPassword,
} from '../../../services/auth.service';
import userStore from '../../../stores/user.store';
import settingsStore from '../../../stores/settings.store';
import {observer} from 'mobx-react';

import './auth.scss';
import './login.scss';
import {Link} from 'react-router-dom';
import idleCheckRefreshComponent from '../../../services/idleRefresh.service';
import {LogoIcon} from '../../components/svg';
import notificationsBellStore from '../../../stores/notificationsAlerts.store';

@observer
class LoginView extends React.Component {
    handleSubmit = e => {
        const { email, password } = this.state;
        this.signIn(email, password);
        e.preventDefault();
    };

    signIn(email, password) {
        doSignInWithEmailAndPassword(email, password).then(resp => {
        }).catch(() => {
            this.setState({ errorMessage: 'Email or password are not valid' });
        });

    }

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            user: true,
            //loggedIn: false,
            message: 'Logging',
            //needChangePassword: false,
            resetPassword: false,
            errorMessage: ''
        };
    }

    inputValueChangeHandle(e, type) {
        this.setState({
            [type]: e.target.value
        });
    }

    async componentDidMount() {
        document.title = 'Login - Ethosia';

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {

            this.setState({ user: user });
            if (!user) return;
            user.getIdToken().then(token => {
                userStore.userToken = token;
                userStore.getCurrentUser(token)
                    .then(async data => {
                        if (!data) {
                            this.setState({ user: false });
                            return;
                        } else if (!data.canSignIn || data.userType !== 3) {
                            await userStore.logOut();
                            return;
                        }
                        userStore.setLastSignInDate(data._id);
                        userStore.getOnlineUsers()
                        notificationsBellStore.getNotifications()
                        this.proceedToUrl();
                    });
            });
        });
    }

    proceedToUrl = () => {
        this.props.history.push(
            settingsStore.redirectRoute ? settingsStore.redirectRoute : '/inbox'
        );
    };

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        return (
            <div>
                <main className="auth login border-radius shadow4">
                    {/* {this.state.user && !this.state.needChangePassword && */}
                    {this.state.user &&
                    (<div className="logging">{this.state.message}</div>)}

                    {!this.state.user && (
                        <React.Fragment>
                            <div className="login-icon-wrapper">
                                <LogoIcon/>
                            </div>
                            <h1>Login</h1>
                            <form className="form">
                                <div className="form-item">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="email"
                                        value={this.state.email}
                                        onChange={e => this.inputValueChangeHandle(e, 'email')}
                                    />
                                </div>

                                <div>
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="password"
                                        value={this.state.password}
                                        onChange={e => this.inputValueChangeHandle(e, 'password')}
                                    />
                                </div>
                                <div className="form-item right reset">
                                    <Link to={'/forgot-password'}>
                                        Forgot Password?
                                    </Link>
                                </div>

                                <div className="form-item center submit">
                                    {this.state.errorMessage && (
                                        <p
                                            className="login-message error">{this.state.errorMessage}</p>
                                    )}
                                    <button onClick={this.handleSubmit} className="button blue">
                                        Sign In
                                    </button>
                                </div>

                            </form>
                        </React.Fragment>
                    )}

                    {/* {this.state.needChangePassword && (
                        <div className="login-set-password">
                            <h1 className="message">{this.state.message}</h1>
                            <SetPasswordComponent
                                reset={this.state.resetPassword}
                                handlePasswordChange={(e, password) =>
                                    this.handlePasswordChange(e, password)
                                }
                            />
                        </div>
                    )} */}
                </main>
            </div>
        );
    }
}

export default LoginView;
