import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import utilsService from '../../../services/utils.service';

class SearchResultsComponent extends PureComponent {
    render() {
        const { data, active } = this.props;
        if (data.length > 10) {
            data.length = 10;
        }

        return (
            <div className='search-results shadow3 border-radius'>
                {data.map((item, idx) => {
                    return (
                        <div key={item._id}
                             className={`result-item ${idx === active ? 'active' : ''}`}>
                            <Link className='black-link'
                                  to={`/process/${item.processSlug}/profile/${item.profileSlug}`}>
                                {utilsService.capitalizeString(item.profileName)}
                                <span>{item.jobPosition}</span>
                            </Link>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default SearchResultsComponent;
