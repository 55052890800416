import React from 'react';
import ModalComponent from '../modal/Modal.component';
import profileStore from '../../../stores/profile.store';
import utilsService from '../../../services/utils.service';
import processStore from '../../../stores/process.store';
import LogComponent from '../log/Log.component';
import {CloseIcon} from '../svg';

const StatusLogProcessModal = (props) => {
    const getProcess = () => {
        return processStore.processes[props.data];
    };

    const process = getProcess();

    const getDisplayName = () => {
        const curProfile = profileStore.profiles[process.profileSlug];
        return utilsService.getDisplayName(curProfile);
    };

    const isExposed = utilsService.checkIfExposed(process);
    const getTitle = () => {
        let res = 'Timeline';
        if (isExposed) {
            res = `${getDisplayName()}'s Timeline`;
        }

        return res;
    };

    return (
        <ModalComponent onClose={() => props.closeEvent()} clickBodyToclose={true}>
            <div className='modal-box status-log-modal'>
                <div>
                    <h3 className="capitalized">{getTitle()} </h3>
                    <div onClick={() => props.closeEvent()}
                         className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>
                </div>
                <div className='scrollable-content custom-scroll'>
                    <LogComponent
                        data={getProcess()}

                    />
                </div>
                <footer>
                    <button
                        onClick={() => props.closeEvent()}
                        className='button gray min140'>
                        Close
                    </button>
                </footer>
            </div>
        </ModalComponent>
    );
};

export default StatusLogProcessModal;
