import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import './dropDownButton.scss';

class DropDownButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalType: null,
            selectorDataVisible: false,
            isSendToCompanyModalOpen: false,
            isAddToLeedsModalOpen: false,
            isDeleteModalOpen: false
        };
    }

    actionEventHandler = (modal, type) => {
        this.setState({
            [modal]: true,
            modalType: type
        });
    };

    modalCloseEvent = type => {
        this.setState({
            [type]: false
        });
    };

    handleClickOutside = evt => {
        this.setState({ selectorDataVisible: false });
    };

    render() {
        const {mainButtonText, mainButtonIcon, selectorOptions, disabled} = this.props;

        return (
            <div className="drop-down-button">
                    <React.Fragment>
                        <div className={'main-button ' + (disabled ? 'disabled' : '')}>

                            <button
                                onClick={this.props.onClickMainButton}
                                className="send-button">
                                {mainButtonIcon}
                                {mainButtonText}
                            </button>

                            <button className='selector'
                                onClick={() => this.setState({ selectorDataVisible: !this.state.selectorDataVisible })}
                            />

                            {this.state.selectorDataVisible && (
                                <div className="selector-data hidden-menu shadow4">
                                    {selectorOptions && selectorOptions.map(option => {
                                        return (
                                            <div
                                                onClick={() => this.props.onClickOptionButton(option.text)}
                                                className="hidden-menu-item">
                                                {option.text}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </React.Fragment>
            </div>
        );
    }
}

export default onClickOutside(DropDownButton);
