import React, {Component} from 'react';

const Tab = () => {
    return (
        <div/>
    )
};

class Tabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: props.selectedIndex ? Number(props.selectedIndex) : 0
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedIndex || nextProps.selectedIndex === 0) {
            this.setState({
                activeTabIndex: nextProps.selectedIndex
            });
        }

    }

    handleTabClick(tabIndex) {
        this.setState({
            activeTabIndex: tabIndex
        });

        if (this.props.onClick)
            this.props.onClick(tabIndex);

        if(this.props.onLeavingTab) {
            this.props.onLeavingTab();
        }
    }

    getClassNames = (index, child) =>{
        let classNames = `tab tab_${index}`;
        if(child.props.className){
            classNames += ` ${child.props.className}`;
        }
        if(index === this.state.activeTabIndex ){
            classNames +=' selected';
        }
        return classNames;
    };

    renderTabs() {
        const filteredChildren = this.props.children.filter(child => !!child);
        return React.Children.map(filteredChildren, (child, index) => {
            return (
                <div className={this.getClassNames(index, child)}
                     onClick={this.handleTabClick.bind(this, index)}>
                    {child.props && child.props.subtitle != null && (
                        <span className='sub-title'>{child.props.subtitle}</span>
                    )}             

                    {child.props ? 
                    <React.Fragment>
                        {child.props.label && <span className='title'>{child.props.label}</span>}
                        {child.props.icon && <div className='icon'>{child.props.icon}</div>}
                    </React.Fragment>
                    : null}

                </div>
            );
        });
    }

    renderActiveTabContent() {
        const { children } = this.props;
        const { activeTabIndex } = this.state;
        const filteredChildren = children.filter(child => !!child);
        if (filteredChildren[activeTabIndex]) {
            return filteredChildren[activeTabIndex].props.children;
        }
    }

    getClasses(classes) {
        let res = 'tab-container';
        if (classes) {
            res += ' ' + classes;
        }
        return res;
    }

    render() {
        const rows = this.props.rows || 1;
        const columns = this.props.columns || this.props.children.length / rows;
        return (
            <div className={this.getClasses(this.props.className)}>
                <div className='tabs' style={{
                    gridTemplateColumns: `repeat(${columns}, 1fr)`,
                    gridTemplateRows: `repeat(${rows}, auto)`
                }}>
                    {this.renderTabs()}
                </div>
                <div className='tab-body custom-scroll'>
                    {this.props.isUseLineSeperator && (
                        <div className='line-seperator'></div>
                    )}
                    {this.renderActiveTabContent()}
                </div>
            </div>
        );
    }
}

export {Tab, Tabs};
