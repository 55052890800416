import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {MapPointerIcon} from '../svg';
import utilsService from '../../../services/utils.service';
import ProcessActions from '../processActions/ProcessActions.component';
import DefaultImageComponent from '../DefaultImage.component';
import ProgressBar from '../progressBar/ProgressBar.component';
import MoreMenuActionComponent from '../processActions/MoreMenuAction.component';
import userStore from '../../../stores/user.store';

class ApplicantGridItem extends PureComponent {

    getRightColumns = () => {
        const { isSingleJobView, isSingleCompanyView } = this.props;
        const isRecruiter = utilsService.isRecruiter(userStore.user);

        let res = employerGridContent;
        if (isSingleJobView && !isRecruiter) {
            res = employerSingleGridContent;
        }

        if (isSingleJobView && isRecruiter) {
            res = employerSingleGridContent;
        }

        if (isSingleCompanyView && isRecruiter) {
            res = recruiterSingleCompanyContent;
        }

        if (!isSingleJobView && !isSingleCompanyView && isRecruiter) {
            res = recruiterGridContent;
        }

        return res;
    };

    renderColumns = columns => {
        const { process, searchResults } = this.props;
        const isExposed = utilsService.checkIfExposed(process);

        return columns.map((col, idx) => {
            switch (col) {
                case 'job':
                    return <JobColumn
                        key={idx}
                        process={process}
                        searchResults={searchResults}
                    />;
                case 'profile':
                    return <TalentColumn
                        key={idx}
                        process={process}
                        isExposed={isExposed}
                    />;
                case 'profile_short':
                    return <TalentShortColumn
                        key={idx}
                        process={process}
                        isExposed={isExposed}
                    />;
                case 'status':
                    return <StatusColumn
                        key={idx}
                        process={process}
                    />;
                case 'fit':
                    return <FitColumn
                        key={idx}
                        process={process}
                    />;
                case 'update':
                    return <UpdateColumn
                        key={idx}
                        process={process}
                    />;
                case 'company':
                    return <CompanyColumn
                        key={idx}
                        process={process}
                    />;
            }
        });
    };

    render() {
        const { process, index, style } = this.props;
        const columns = this.getRightColumns();
        return (
            <div className="grid-body-item"
                 style={style}
                 key={index}
            >
                {this.renderColumns(columns)}

                <div className="dots">
                    {/* <MoreMenuActionComponent
                        process={process}
                        menu={[
                            { modal: 'isModalOpen_AddNote', text: 'Add a note' },
                            // { modal: 'isModalOpen_StatusLog', text: 'Timeline' },
                            { modal: 'isModalOpen_Share', text: 'Share' }
                        ]}
                        actionEvent={(process, modal, e) => this.props.actionEventHandler(process, modal, null,
                            e)}
                    /> */}
                </div>
                <div className="actions">
                    <ProcessActions
                        buttons={false}
                        tooltip={false}
                        noDots={true}
                        process={process}
                        actionEvent={(process, modal, type) => this.props.actionEventHandler(process,
                            modal, type)}
                    />
                </div>
            </div>
        );
    }
}

export default ApplicantGridItem;

const JobColumn = props => {
    const { process, searchResults } = props;
    return (
        <div className="job-title">
            <Link className='black-link' to={'/jobs/' + process.jobSlug}>
                <strong>{process.jobPosition}</strong>
            </Link>

            {searchResults && (
                <div className="process-status">
                    <legend>
                        Status
                    </legend>
                    <p>
                        {process.statusString}
                    </p>
                </div>
            )}
        </div>
    );
};

const StatusColumn = props => {
    const { process } = props;
    const isStatusLeadPending = process.status === 70 || process.status === 3;
    return (
        <div className="status">
            <strong>{process.statusString}</strong>
            <div className="sub-status">{utilsService.getInProgressStatusByEnum(process.status)}</div>
            {isStatusLeadPending && (
                <div className="leads-pending-status">
                    Waiting for talent response
                </div>
            )}
        </div>
    );
};

const FitColumn = props => {
    const { process } = props;
    return (
        <div className="fit">
            <ProgressBar
                fit={process.fit}
            />
            <strong>{process.fit}%</strong>
        </div>
    );
};

const TalentColumn = props => {
    const { process, isExposed } = props;
    return (
        <div className={`talent ${isExposed ? '' : 'not-exposed'}`}>
            <div className="talent-profile">
                <DefaultImageComponent
                    url={process.profileImageUrl}
                    alt={process.profileName}
                    profileName={process.profileName}
                    isExposed={isExposed}
                />
            </div>
            <div className="talent-info">
                <p>
                    <Link className='black-link'
                          to={`/profile/${process.profileSlug}`}>
                        <strong className='capitalized'>{!isExposed
                            ? 'Candidate from Ethosia'
                            : process.profileName}</strong>
                    </Link>
                </p>
                <p>
                    {<MapPointerIcon/>}
                    {process.profileCity + ', ' + process.profileCountry}
                </p>
                <p>{process.profilePosition}</p>

                <div className="talent-info-grid">
                    <legend>Past</legend>
                    <p>{utilsService.getWorkedAt(process.profileData.employment, 1)}</p>
                    <legend>Education</legend>
                    <p>{process.profileData?.linkedInData?.educationList?.length > 0 && 
                        process.profileData.linkedInData.educationList[0].educationInstitution ?
                        process.profileData.linkedInData.educationList[0].educationInstitution : ''}</p>
                    <legend>Skills</legend>
                    <div className="skills">
                        {utilsService.getMatchSkills(process.skills)}
                    </div>
                </div>
            </div>

        </div>
    );
};

const TalentShortColumn = props => {
    const { process, isExposed } = props;

    const getProfileLocation = () => {
        if (!process.profileCity && !process.profileCountry) return 'N/A';
        return process.profileCity || 'N/A' + ', ' + process.profileCountry || 'N/A';
    }
    
    return (
        <div className={`talent ${isExposed ? '' : 'not-exposed'}`}>
            <div className="talent-profile">
                <DefaultImageComponent
                    url={process.profileImageUrl}
                    alt={process.profileName}
                    profileName={process.profileName}
                    isExposed={isExposed}
                />
            </div>
            <div className="talent-info">
                <p>
                    <Link className='black-link'
                          to={`/profile/${process.profileSlug}`}>
                        <strong className='capitalized'>{!isExposed
                            ? 'Candidate from Ethosia'
                            : process.profileName}</strong>
                    </Link>
                </p>
                <p>
                    {<MapPointerIcon/>}
                    {getProfileLocation()}
                </p>
                <p>{process.profilePosition}</p>
            </div>

        </div>
    );
};

const UpdateColumn = props => {
    const { process } = props;
    return (
        <div className="update">
            {process.update}
            <div>Days ago</div>
        </div>
    );
};

const CompanyColumn = props => {
    const { process } = props;

    return (
        <div className="company">
            <strong>
                {process.companyData.name}
            </strong>
        </div>
    );
};

const employerGridContent = ['job', 'profile', 'status', 'fit', 'update'];
const employerSingleGridContent = ['profile', 'status', 'fit', 'update'];
const recruiterGridContent = ['profile', 'job', 'company', 'status', 'fit', 'update'];
const recruiterSingleCompanyContent = ['profile_short', 'job', 'status', 'fit', 'update'];
