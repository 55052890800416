import React, {PureComponent} from 'react';
import { DotsIcon, DotsIconSmaller } from '../svg';
import onClickOutside from 'react-onclickoutside';
import {Link} from 'react-router-dom';
import utilsService from '../../../services/utils.service';
import CandidateAvailability from '../candidates/candidateAvailability/CandidateAvailability.component'

class MoreMenuActionComponent extends PureComponent {
    handleMenuClick = (modal, e, type) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({opened: false});
        this.props.actionEvent(this.props.process, modal, e, type);
    };

    handleClick = (func, context, e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({opened: false});
        func.call(context, this.props.process);
    };

    constructor(props) {
        super(props);
        this.state = {
            opened: false
        };
    }

    handleClickOutside = evt => {
        this.setState({ opened: false });
    };

    getClasses(icons) {
        let res = 'hidden-menu shadow4';
        if (icons) {
            res += ' menu-icons';
        }
        return res;
    }

    render() {
        const { menu, icons, smallDots, disabled, profile } = this.props;
        const { opened } = this.state;

        const dotsClass = this.props.isHoritontal ? 'dots-horizontal' : 'dots-vertical';

        return (
            <div className={'action-item action-item-more-menu ' + (disabled ? 'disabled' : '')} onClick={(e) => {this.setState({ opened: !opened }); e.stopPropagation()}}>
                <div className={opened ? `${dotsClass} active` : `${dotsClass}`}>
                    {smallDots && (<DotsIconSmaller/>)}
                    {!smallDots && (<DotsIcon/>)}
                </div>
                {!disabled && opened &&
                (<div className={this.getClasses(icons)}>
                    {profile && <CandidateAvailability profile={profile} />}
                        
                    {menu.map((menu, idx) => {
                        let classes = 'hidden-menu-item';
                        if (menu.icon) {
                            classes += ' icons';
                        }

                        if (menu.classes) {
                            classes += ' ' + menu.classes;
                        }

                        return (
                            <React.Fragment
                                key={idx}
                            >
                                {menu.isHr && (
                                    <hr/>
                                )}

                                {menu.isLink && !menu.icon && (
                                    <Link
                                        key={idx}
                                        to={menu.linkTo}
                                        className='hidden-menu-item'
                                    >{menu.text}</Link>
                                )}
                                {menu.isFunc && (
                                    <div
                                        key={idx}
                                        onClick={(e) => this.handleClick(menu.func, menu.context, e)}
                                        className={classes}>{menu.text}
                                    </div>
                                )}
                                {menu.modal && !menu.icon && (
                                    <div
                                        key={idx}
                                        onClick={(e) => this.handleMenuClick(menu.modal, e, menu.type)}
                                        className={classes}>{menu.text}
                                    </div>
                                )}

                                {menu.modal && menu.icon && (
                                    <div
                                        key={idx}
                                        onClick={(e) => this.handleMenuClick(menu.modal, e, menu.type)}
                                        className={classes}>
                                        {menu.icon && (
                                            utilsService.getRightIcon(menu.icon)
                                        )}
                                        <span className="text">{menu.text}</span>
                                    </div>
                                )}
                                {menu.isFunc && menu.icon && (
                                    <div
                                        key={idx}
                                        onClick={(e) => this.handleMenuClick(menu.modal, e, menu.type)}
                                        className={classes}>
                                        {menu.icon && (
                                            utilsService.getRightIcon(menu.icon)
                                        )}
                                        {menu.text}
                                    </div>
                                )}
                                {menu.isLink && menu.icon && (
                                    <div
                                        key={idx}
                                        className={classes}>
                                        {menu.icon && (
                                            utilsService.getRightIcon(menu.icon)
                                        )}
                                        <Link
                                            key={idx}
                                            to={menu.linkTo}
                                            className='hidden-menu-item'
                                        >{menu.text}</Link>
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>)}
            </div>
        );
    }
}

export default onClickOutside(MoreMenuActionComponent);
