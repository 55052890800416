import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import utilsService from '../../../services/utils.service';

class RecruiterSearchResultsComponent extends PureComponent {
    render() {
        const { data, active, clicked } = this.props;
        const { profiles, jobs, companies } = data;

        return (
            <div className='search-results shadow3 border-radius'>
                {profiles && profiles.length > 0 && results('Profiles', profiles, active, clicked)}
                {jobs && jobs.length > 0 && results('Jobs', jobs, active, clicked)}
                {companies && companies.length > 0 && results('Companies', companies, active, clicked)}
            </div>
        );
    }
}

export default RecruiterSearchResultsComponent;

const results = (title, data, active, clicked) => {
    return (
        <div className="search-results-section">
            <legend>{title}</legend>
            {
                data.map((item, idx) => {
                    return (
                        <div key={item._id}
                             className={`result-item ${idx === active ? 'active' : ''}`}>
                            {content(title, item, active, clicked)}
                        </div>
                    );
                })
            }
        </div>
    );
};

const content = (title, data, active, clicked) => {
    let label = '';
    let link = '';
    let subLabel = '';
    let subSubLabel = '';
    switch (title) {
        case 'Profiles':
            label = utilsService.getDisplayName(data, true);
            link = `profile/${data.slug}`;
            subLabel = data.position ? data.position : '';
            subSubLabel = data.company ? data.company : '';
            break;
        case 'Jobs':
            label = data.title;
            link = `jobs/${data.slug}`;
            subLabel = data.companyName ? data.companyName : '';
            break;
        case 'Companies':
            label = data.name;
            link = `company/${data.slug}`;
            break;
        default:
            break;
    }

    return (
        <Link className='black-link'
              to={`/${link}`}
              onClick={clicked}
        >
            {label}
            {subLabel && (
                <span>{subLabel}
                    {subSubLabel && (
                        <div className='sub-sub-label'>{subSubLabel}</div>
                    )}
                </span>
            )}
        </Link>
    );
};
