import React, {PureComponent} from 'react';
import './search.scss';
import SearchResultsComponent from './SearchResults.component';
import processStore from '../../../stores/process.store';
import {SearchIconNew} from '../svg';
import {withRouter} from 'react-router-dom';
import jobStore from '../../../stores/job.store';

class SearchComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            activeFocus: -1,
            available: false
        };
        this._mounted = false;
        this.input = React.createRef();
    }

    searchHandle = e => {
        const searchValue = e.target.value;
        if (processStore.processesPipelinesGridAll.length === 0) {
            this.getProcesses();
        }

        this.setState({ searchValue: searchValue, activeFocus: -1 });
        if (searchValue.length > 1 && processStore.processesPipelinesGridAll.length !== 0) {
            processStore.searchProcesses(searchValue);
        }
    };

    async getProcesses() {
        const jobSlugs = jobStore.getJobSlugs();
        await processStore.getProcesses(true, jobSlugs);
        await processStore.getPipelinesGridData();
    };

    onArrowClick = e => {
        const { activeFocus } = this.state;

        // ENTER
        if (e.keyCode === 13) {
            this.setState({ searchValue: '' });
            if (activeFocus !== -1) {
                const processToNavigate = processStore.searchResults[activeFocus];
                this.props.history.push(
                    `/process/${processToNavigate.processSlug}/profile/${processToNavigate.profileSlug}`
                );
            } else {
                this.props.history.push(
                    `/search-results`
                );
            }
        }

        // Arrow UP
        if (e.keyCode === 38) {
            const caret = e.target.selectionStart;
            const element = e.target;
            window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
            });
            if (activeFocus !== 0 && activeFocus !== -1) {
                this.setState({ activeFocus: activeFocus - 1 });
            }
        }

        // Arrow DOWN
        if (e.keyCode === 40) {
            if (processStore.searchResults.length - 1 > activeFocus) {
                this.setState({ activeFocus: activeFocus + 1 });
            }
        }
    };

    onSearchIconClick = () => {
        this.setState({
            available: true,
            showResults: true
        }, () => {
            this.input.current.focus();
        });
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    hideSearch = () => {
        const { searchValue } = this.state;
        setTimeout(() => {
            if (this._mounted) {
                if (!searchValue) {
                    this.setState({ available: false, showResults: false });
                } else {
                    this.setState({ showResults: false });
                }
            }
        }, 200);
    };

    render() {
        const { activeFocus, searchValue, available, showResults } = this.state;
        return (
            <div className='search'>
                <div
                    onClick={this.onSearchIconClick}
                    className={available ? 'search-area available' : 'search-area'}>
                    <SearchIconNew/>
                    <input
                        onBlur={this.hideSearch}
                        value={searchValue}
                        onChange={this.searchHandle}
                        onKeyDown={this.onArrowClick}
                        type="text"
                        className='border-radius'
                        placeholder='Search'
                        ref={this.input}
                    />
                </div>

                {this.state.searchValue.length > 1 && showResults &&
                <SearchResultsComponent
                    data={processStore.searchResults}
                    active={activeFocus}
                />
                }
            </div>
        );
    }
}

export default withRouter(SearchComponent);
