import React, {Component} from 'react';
import processStore from '../../../stores/process.store';
import './applicants.scss';
import Loader from '../loader/Loader.component';
import ApplicantGrid from './ApplicantGrid.component';
import {
    ExposeProcessModal,
    StatusLogProcessModal,
    PromoteProcessModal,
    PauseProcessModal,
    AddNoteModal,
    TerminateProcessModal
} from '../modalComponents';
import jobStore from '../../../stores/job.store';
import profileStore from '../../../stores/profile.store';
import TabsComponent from './components/Tabs.component';
import EmptySingleJobGrid from '../EmptySingleJobGrid.component';
import InterestedProcessModal from '../modalComponents/InterestedProcessModal.component';
import ShareModal from '../modalComponents/ShareModal.component';
import utilsService from '../../../services/utils.service';

class Applicants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen_Expose: false,
            isModalOpen_StatusLog: false,
            isModalOpen_PromoteProcess: false,
            isModalOpen_PauseProcess: false,
            isModalOpen_TerminateProcess: false,
            isModalOpen_AddNote: false,
            isModalOpen_Share: false,
            isModalOpen_Interested: false,
            selectedProcesses: [],
            currentProcessForModal: {
                process: null,
                profile: null,
                modalType: null
            }
        };
    }

    componentWillUnmount() {
        processStore.pipeLinesSet = false;
    }

    actionEventHandler = async (process, modal, type) => {
        const job = jobStore.jobs[process.jobSlug];
        const profile = profileStore.profiles[process.profileSlug];
        this.setState({
            [modal]: true,
            currentProcessForModal: {
                process: process,
                profile: profile,
                modalType: type,
                company: process.companyData,
                job: job
            }
        });
    };

    getClasses(classes) {
        let res = 'max-width applicants';
        if (classes) {
            res += ' ' + classes;
        }

        return res;
    }

    render() {
        const {
            classes,
            isSingleCompanyView,
            isSingleJobView,
            loader,
            data,
            searchResults,
            jobSlug,
            companySlug,
            isEmptySingleJobGrid,
            gridIndex,
            isAll
        } = this.props;

        return (
            <div className={this.getClasses(classes)}>
                {!searchResults && !loader && (
                    <div className='round-tabs'>
                        <TabsComponent
                            isSingleJobView={isSingleJobView}
                            isSingleCompanyView={isSingleCompanyView}
                            jobSlug={jobSlug}
                            companySlug={companySlug}
                            gridIndex={gridIndex}
                            isAll={isAll}
                        />
                    </div>
                )}


                <div className="applicant-grid-wrapper">
                    {loader && (
                        <Loader/>
                    )}

                    {!loader && !isEmptySingleJobGrid && (
                        <ApplicantGrid
                            companySlug={companySlug}
                            isSingleJobView={isSingleJobView}
                            searchResults={searchResults}
                            isSingleCompanyView={isSingleCompanyView}
                            data={data}
                            actionEventHandler={this.actionEventHandler}
                        />
                    )}

                    {!loader && isEmptySingleJobGrid && (
                        <EmptySingleJobGrid/>
                    )}
                </div>


                {this.state.isModalOpen_Expose && (
                    <ExposeProcessModal
                        isSingleJobView={isSingleJobView}
                        isSingleCompanyView={isSingleCompanyView}
                        closeEvent={() => this.setState({ isModalOpen_Expose: false })}
                        data={this.state.currentProcessForModal}
                    />
                )}

                {this.state.isModalOpen_Interested && (
                    <InterestedProcessModal
                        isSingleJobView={isSingleJobView}
                        isSingleCompanyView={isSingleCompanyView}
                        closeEvent={() => this.setState({ isModalOpen_Interested: false })}
                        data={this.state.currentProcessForModal}
                    />
                )}

                {this.state.isModalOpen_StatusLog && (
                    <StatusLogProcessModal
                        closeEvent={() => this.setState({ isModalOpen_StatusLog: false })}
                        data={this.state.currentProcessForModal.process.slug}
                    />
                )}

                {this.state.isModalOpen_PromoteProcess && (
                    <PromoteProcessModal
                        isSingleJobView={isSingleJobView}
                        isSingleCompanyView={isSingleCompanyView}
                        closeEvent={() => this.setState(
                            { isModalOpen_PromoteProcess: false })}
                        data={this.state.currentProcessForModal}
                    />
                )}

                {this.state.isModalOpen_PauseProcess && (
                    <PauseProcessModal
                        closeEvent={() => this.setState(
                            { isModalOpen_PauseProcess: false })}
                        data={this.state.currentProcessForModal}
                    />
                )}

                {this.state.isModalOpen_TerminateProcess && (
                    <TerminateProcessModal
                        isSingleJobView={isSingleJobView}
                        isSingleCompanyView={isSingleCompanyView}
                        closeEvent={() => this.setState(
                            { isModalOpen_TerminateProcess: false })}
                        data={this.state.currentProcessForModal}
                    />
                )}

                {this.state.isModalOpen_AddNote && (
                    <AddNoteModal
                        isSingleJobView={isSingleJobView}
                        isSingleCompanyView={isSingleCompanyView}
                        closeEvent={() => this.setState({ isModalOpen_AddNote: false })}
                        data={this.state.currentProcessForModal}
                    />
                )}

                {this.state.isModalOpen_Share && (
                    <ShareModal
                        shareType={'profile'}
                        isExposed={utilsService.checkIfExposed(this.state.currentProcessForModal.data.process)}
                        profileName={utilsService .getDisplayName(this.state.currentProcessForModal.data.profile)}
                        closeEvent={() => this.setState({ isModalOpen_Share: false })}
                        slug={this.state.currentProcessForModal.process.slug}
                        //data={this.state.currentProcessForModal}
                    />
                )}

            </div>
        );
    }
}

export default Applicants;
