import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import StyledRadioButtonsComponent from '../styledRadioButttons/StyledRadioButtons.component';
import data from '../../../constants/data';
import utilsService from '../../../services/utils.service';
import userStore from '../../../stores/user.store';

class EditEmployerRole extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            selectedRole: 0,
            selectedRoleIndex: null
        };
    }

    clickHandle = (e, button, index) => {
        this.setState({
            selectedRoleIndex: index,
            selectedRole: button.elId
        });
    };

    componentDidMount() {
        const profile = this.props.data;
        const perm = utilsService.getRole(profile, false, true);
        if (perm) {
            const index = data.employers_permissions.findIndex(q => q.elId === perm.elId);

            this.setState({
                selectedRole: perm.elId,
                selectedRoleIndex: index

            });
        }
    }

    changePermission = async () => {
        const profile = this.props.data;
        const { selectedRole } = this.state;
        const body = {
            permission: selectedRole
        };

        this.setState({
            loader: true
        });
        await userStore.setProfilePermissions(profile._id, body);

        this.setState({
            loader: false
        });

        setTimeout(() => {
            this.props.closeEvent();
        }, 1000);
    };

    render() {
        const buttons = data.employers_permissions;
        const { selectedRoleIndex, loader } = this.state;

        return (
            <ModalComponent onClose={() => this.props.closeEvent()}>
                <div className='modal-box edit-employer-role-modal'>
                    <header>
                        <h2>Edit {this.props.data.firstName}'s role</h2>
                    </header>

                    <section className="modal-body">
                        <StyledRadioButtonsComponent
                            buttons={buttons}
                            activeIndex={selectedRoleIndex}
                            clickHandle={this.clickHandle}
                        />
                    </section>

                    <footer>
                        <button
                            onClick={() => this.props.closeEvent()}
                            className='button gray'>
                            Cancel
                        </button>
                        <button
                            onClick={this.changePermission}
                            className={loader ? 'button bk-black loading' : 'button bk-black'}>
                            Save
                        </button>
                    </footer>
                </div>
            </ModalComponent>
        );
    }
}

export default EditEmployerRole;
