import { TextField } from '@brightsource/brightsource-ui-lib';
import React from 'react';

export function BsInput({ label, value, onChange, placeholder, disabled, rightIcon, type, multiline, ...props }) {
  return (
    <TextField
      size="small"
      label={label}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      onClear={() => onChange('')}
      allowClear={!disabled}
      disabled={disabled}
      type={type}
      multiline={multiline}
      rightIcon={rightIcon}
      placeholder={placeholder}
      {...props}
    />
  );
}
