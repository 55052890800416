import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import Loader from '../loader/Loader.component';
import DefaultImageComponent from '../DefaultImage.component';
import './recruitersMultiSelectBox.scss';
import '../forms/modernSelect.scss';
import messagingStore from '../../../stores/messaging.store';

class RecruitersMultiSelectBox extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loader: true,
            selectedUsersSlugs: userStore.user ? [userStore.user.slug] : []
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({
                loader: false
            }
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    menuToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    selectEvent = (user) => {
        const { selectedItemSlugs } = this.props
        let update = selectedItemSlugs === 0 ? [userStore.user.slug] : [...selectedItemSlugs];
        if (update.includes(user.slug)) {
            update = update.filter(item => item !== user.slug);
        }
        else {
            update.push(user.slug)
        }
        this.props.selectEvent(update);
    };

    clearAll = (e) => {
        e.stopPropagation();
        this.props.selectEvent([]);
    }

    handleClickOutside = evt => {
        this.setState({ open: false });
    };

    // compare = (a, b) => {
    //     if (a.value < b.value) {
    //       return -1;
    //     }
    //     if (a.value > b.value) {
    //       return 1;
    //     }
    //     // a must be equal to b
    //     return 0;
    //   }

    getRecruitersList() {
        const list = userStore.recruitersList;


        const currentUser = userStore.user;
        let respList = [];

        list.forEach(item => {
            const data = {
                displayName: utilsService.getDisplayName(item),
                _id: item._id,
                profileImage: utilsService.getProfileImage(item),
                slug: item.slug
            };

            if (item._id === currentUser._id) {
                data.displayName = ' Me'; // added space to order up
            } 
            respList.push(data);

            respList.sort((a, b) => {

                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
        });

        const me = respList.find(item => item.displayName.toLowerCase() === ' me');
        if (me) {
            respList = respList.filter(item => item.displayName.toLowerCase() !== ' me');
            respList.unshift(me);
        }

        const system = respList.find(item => item.displayName.toLowerCase() === 'system ');
        if (system) {
            respList = respList.filter(item => item.displayName.toLowerCase() !== 'system ');
            respList.unshift(system);
        }

        return respList;
    }

    getClasses(classes) {
        let res = 'modern-select select-select-box-recruiter-multi';
        if (classes) {
            res += ' ' + classes;
        }
        return res;
    }

    getItemClasses(item) {
        const { selectedItemSlugs } = this.props
        let classes = 'modern-select-list-item ';

        const isInit = selectedItemSlugs === 0 && userStore.user.slug === item.slug;
        if (isInit || (selectedItemSlugs && selectedItemSlugs.includes(item.slug))) {
            classes += 'selected'
        }
        return classes;
    }

    getDefaultImageClasses(id) {

        let classes = '';

        // if not system
        if (id !== '60084e84c7490c001cc47cf4') {
            classes = userStore.onlineRecruiters[id] ? 'online-status': 'offline-status'
        }

        return classes;
    }


    renderTitle() {
        const { showMyInboxLabel, selectedItemSlugs, hasClearAll } = this.props;
        const currentUser = userStore.user;
        let selectedItem = null;
        let selectedUserName = null
        const isInit = selectedItemSlugs === 0;
        if (isInit || (selectedItemSlugs && selectedItemSlugs.length === 1)) {
            selectedItem = userStore.users[selectedItemSlugs];
            if (!selectedItem) {
                selectedItem = currentUser;
            }
            selectedUserName = utilsService.getDisplayName(selectedItem);

        }
        return <div
            onClick={this.menuToggle}
            className="modern-select-visible-part shadow4">

            {selectedUserName ?

                //one selected
                <>
                    <DefaultImageComponent
                        url={utilsService.getProfileImage(selectedItem)}
                        alt={selectedUserName}
                        profileName={selectedUserName}
                        isExposed={true} />
                    <div className="modern-select-visible-part-name">
                        {selectedItem.slug === currentUser.slug && showMyInboxLabel ? 'My Inbox' : selectedUserName}
                    </div>
                </>
                :

                //not selected or selected more than once
                <>
                    <div className="circle">
                        <span>{selectedItemSlugs && selectedItemSlugs.length > 1 ? selectedItemSlugs.length : ''}</span>
                    </div>
                    <div className="modern-select-visible-part-name">
                        {selectedItemSlugs?.length > 1 ? 'Multiple' : 'All'}
                    </div>              
                </>
            }
            {hasClearAll && (isInit || !!selectedItemSlugs?.length) && 
                <button className='clear-button' onClick={(e) => this.clearAll(e)} >
                    Clear All
                </button>}

        </div>;
    }

    render() {
        const { open, loader } = this.state;
        const { classes, showCounter } = this.props;

        const recruitersList = this.getRecruitersList();


        return (
            <div className={this.getClasses(classes)}>
                {loader && (
                    <Loader />
                )}

                {!loader && (
                    this.renderTitle()
                )}
                {open && (
                    <div className="modern-select-list shadow4">
                        <div className="custom-scroll">
                            {
                                recruitersList.map((item) => {
                                    let recruiterCounter = messagingStore.recruitersConversationsCount[item._id]
                                    recruiterCounter = recruiterCounter > 99 ? '99+' : recruiterCounter
                                    return (
                                        <div
                                            onClick={() => this.selectEvent(item)}
                                            key={item._id}
                                            className={this.getItemClasses(item)}>

                                            <DefaultImageComponent
                                                url={item.profileImage}
                                                alt={item.displayName}
                                                profileName={item.displayName}
                                                isExposed={true}
                                                classes={this.getDefaultImageClasses(item._id)}
                                            />

                                            <div title={item.displayName} className="modern-select-unvisible-part-name">
                                                {item.displayName}
                                            </div>

                                            {showCounter && <label className='modern-select-message-counter'>{recruiterCounter}</label>}

                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    }

}

RecruitersMultiSelectBox.defaultProps = {
    showMyInboxLabel: true
};

export default onClickOutside(RecruitersMultiSelectBox);
