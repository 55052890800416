import React from 'react';
import {BellsIcon} from '../svg';
import './notifications.scss';

const Notifications = () => {
    return (
        <div className='notifications'>
            <BellsIcon/>
        </div>
    );
};

export default Notifications;
