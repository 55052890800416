import React, { Component } from 'react';
import { observer } from 'mobx-react';
import searchStore from '../../../../stores/search.store';
import utilsService from '../../../../services/utils.service';
import MultiSelectComponent from '../../forms/MultiSelect.component';
import DefaultImageComponent from '../../DefaultImage.component';
import userStore from '../../../../stores/user.store';

@observer
class SuperSearchUsersCompaniesInputComponent extends Component {


    componentWillUnmount() {
        this._mounted = false;
        searchStore.companiesUsersSearchSetPersonsTo([]);
        searchStore.companiesUsersSearchResults = [];
    }

    removeMultiItem = (text, index) => {
        const update = searchStore.companiesUsersSearchPersonsTo.filter((obj, idx) => idx !== index);
        searchStore.companiesUsersSearchSetPersonsTo(update);
    };

    selectMultiItem = item => {
        // If exist then remove
        let update = null;

        if(item.companyEmail) {
            if (searchStore.companiesUsersSearchPersonsTo.some(objItem => objItem.companyEmail === item.companyEmail)) {
                update = searchStore.companiesUsersSearchPersonsTo.filter(objItem => objItem.companyEmail !== item.companyEmail);
            }
            else {
                update = [...searchStore.companiesUsersSearchPersonsTo, item];
            }
        }
        else {
            if (searchStore.companiesUsersSearchPersonsTo.some(objItem => objItem._id === item._id)) {
                update = searchStore.companiesUsersSearchPersonsTo.filter((objItem) => objItem._id !== item._id);
            } else {
                update = [...searchStore.companiesUsersSearchPersonsTo, item];
            }
        }

        searchStore.companiesUsersSearchSetPersonsTo(update);

    };

    query = async text => {
  
        let userIds = [];
        searchStore.jobsSearchTo.forEach(element => {

            element.companyUsers.forEach(item => {
                userIds.push(item);
            })
        });

        await userStore.getUsersByIds(userIds);
        const users = userStore.findUsersByIds(userIds);
        const textArray = text.split(' ');
        const filteredUsers = users.filter(item => {
            if(!item.firstName || !item.lastName) {
                return false;
            }
            const array = [item.firstName, item.lastName];
            return array.some(element => textArray.some(text => element.toLowerCase().includes(text.toLowerCase())));
        });
        searchStore.companiesUsersSearchResults = filteredUsers;
    };

    getTitle(info, titleType) {
        let res = '';
        if (titleType === 'email') {
            if (info.email && info.userType) {
                res = info.email;
            } else if (info.emails && info.emails.length > 0) {
                res = utilsService.getPrimaryValue(info.emails, 'email');
            } else {
                res = 'No email found';
            }
        }

        return res;
    }

    multiItemRenderer = (item, { handleClick, modifiers, index }) => {
        const { titleType = 'email' } = this.props;

        let classes = 'multi-item profile-multi-render';
        if(item.companyEmail) {
            const selectedItems = searchStore.companiesUsersSearchPersonsTo.slice();
            if (selectedItems.some(objItem => objItem.companyEmail === item.companyEmail)) {
                classes += ' selected';
            }
            if (modifiers.active) {
                classes += ' active';
            }

            return (
                <div
                    className={classes}
                    key={index}
                    onClick={handleClick}>
                        <div className="company-email-render"></div>
                        <div className="profile-multi-render-info">
                            <div className="name">Company Email - {item.companyEmail}</div>
                        </div>
                </div>
            );
        }
        else {
            const selectedItems = searchStore.companiesUsersSearchPersonsTo.slice();
            if (selectedItems.some(objItem => objItem._id === item._id)) {
                classes += ' selected';
            }
            if (modifiers.active) {
                classes += ' active';
            }
    
            const contactItem = this.getTitle(item, titleType);
            const userType = utilsService.getUserTypeByType(item.userType);
            
            const companyPosition = this.drawCompanyPosition(item);
    
            return (
                <div
                    className={classes}
                    key={index}
                    onClick={handleClick}>
                    <div className="profile-multi-render-img">
                        <DefaultImageComponent
                            url={utilsService.getProfileImage(item)}
                            alt={utilsService.getDisplayName(item)}
                            profileName={utilsService.getDisplayName(item)}
                        />
                    </div>
                    <div className="profile-multi-render-info">
                        <div className="name">
                            {`${item.firstName} ${item.lastName} `}
                            <span className="user-type">
                        ({userType}) - <span>{contactItem}</span>
                           </span>
                        </div>
                        {companyPosition && (
                            <div className="company">
                                {companyPosition}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    };

    drawCompanyPosition = item => {
        let res = '';
        const profile = item.profile;
        if(profile) {
            if (profile.position) {
                res += profile.position;
            }
    
            if (profile.company) {
                res += ' @ ' + profile.company;
            }
        }
        return res;
    };

    itemPredicate = (item, cat) => {
        return item;
    };

    tagsMultiRender = item => {
        if(item.companyEmail) {
            return (
                <span
                    title={item.companyEmail}>
                    {`Company Email <${item.companyEmail}>`}
                </span>
            );
        }
        else {
            const { titleType = 'email'} = this.props;
            const title = this.getTitle(item, titleType);
    
            return (
                <span
                    title={title}>
                    {`${item.firstName} ${item.lastName} <${title}> ${this.getCompanyPosition(item)}`}
                </span>
            );
        }

    };

    getCompanyPosition(item) {
        let res = '';
        if (item.company) {
            res = item.position ? item.position : '';
            res += ' - @' + item.company;
        }

        return res;
    }


    render() {
        const selectedItems = searchStore.companiesUsersSearchPersonsTo.slice();
        const searchResults = searchStore.companiesUsersSearchResults.slice();
        const { validation, valid } = this.props
        return (
            <div className='new-message-super-search-input'>
                <MultiSelectComponent
                    items={searchResults}
                    valid={valid}
                    selectedItems={selectedItems}
                    query={this.query}
                    validation={validation}
                    placeholder={'To'}
                    customRenderer={this.multiItemRenderer}
                    customPredicate={true}
                    customTagsMultiRender={this.tagsMultiRender}
                    removeItem={(item, index) => this.removeMultiItem(item, index)}
                    selectItem={item => this.selectMultiItem(item)}
                />
            </div>
        );
    }
}

export default SuperSearchUsersCompaniesInputComponent;
