import React, { useEffect, useState } from 'react';
import './dashboardActivitiesAll.scss';
import { observer } from "mobx-react"
import { DotsIcon } from '../../../../../../components/svg';
import profileStore from '../../../../../../../stores/profile.store';
import DashboardStatusItem from '../dashboardStatusItem/DashboardStatusItem.component';

const DashboardActivitiesAll = observer((props) => {

    const { selectedRecruiterSlugs, jobs } = props

    const [candidatesAvailable, setCandidatesAvailable] = useState(null);
    const [candidatesBrowsing, setCandidatesBrowsing] = useState(null);

    useEffect(() => {

        let unmounted = false;

        const getCandidates = async () => {
            if (!unmounted) {
                setCandidatesAvailable(null);
                setCandidatesBrowsing(null);
            }
            let body = { isDeleted: false };
            if (selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0) {
                body = { ...body, recruiterSlugs: selectedRecruiterSlugs };
            }
            const [countProfilesAvailable, countProfilesBrowsing] = await Promise.all([
                profileStore.countProfilesByData({...body, allAvailable: true}),
                profileStore.countProfilesByData({...body, allAvailable: false}),
            ]);
            if (!unmounted) {
                setCandidatesAvailable(countProfilesAvailable.available);
                setCandidatesBrowsing(countProfilesBrowsing.available);
            }
        }

        getCandidates();

        return () => {
            unmounted = true;
        }

    }, [selectedRecruiterSlugs])

    const getCandidateUrl = (type) => {
        return `/candidates?recruiterId=${selectedRecruiterSlugs.join(',')}&availability=${[type]}`
    }

    const getJobUrl = (type) => {
        return `/jobs?recruiterId=${selectedRecruiterSlugs.join(',')}&status=${[type]}`
    }

    const openJobs = jobs && jobs.open;
    const holdJobs = jobs && jobs.hold;
    return (
        <div className='dashboard-activities-all-wrapper'>

            <div className="more-menu">
                <DotsIcon />
            </div>

            <div className="column">

                <div>
                    <h1 className="candidate-title">Candidates</h1>

                    <div className="line1">
                        <DashboardStatusItem number={candidatesBrowsing} name={'Browsing'} isFinishedToLoading={candidatesBrowsing !== null}
                            linkTo={getCandidateUrl(1)} />
                        <DashboardStatusItem number={candidatesAvailable} name={'Available'} isFinishedToLoading={candidatesAvailable !== null}
                            linkTo={getCandidateUrl(0)} />
                    </div>
                </div>
                <div className="bottom">
                    <h1 className="job-title">Jobs</h1>

                    <div className="line2">
                        <DashboardStatusItem number={holdJobs} name={'On\nHold'} isFinishedToLoading={holdJobs !== null}
                            linkTo={getJobUrl(2)} />
                        <DashboardStatusItem number={openJobs} name={'Open'} isFinishedToLoading={openJobs !== null}
                            linkTo={getJobUrl(1)} />
                    </div>

                </div>
            </div>
        </div>

    )
});

DashboardActivitiesAll.propTypes = {
    // bla: PropTypes.string,
};

DashboardActivitiesAll.defaultProps = {
    // bla: 'test',
};

export default DashboardActivitiesAll;
