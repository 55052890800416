import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './dashboardActivitiesJobs.scss';
import { observer } from "mobx-react"
import { DotsIcon } from '../../../../../../components/svg';
import DashboardStatusItem from '../dashboardStatusItem/DashboardStatusItem.component';
import searchStore from '../../../../../../../stores/search.store';
import utilsService from '../../../../../../../services/utils.service';
import data from '../../../../../../../constants/data';
import processStore from '../../../../../../../stores/process.store';


const DashboardActivitiesJobs = observer((props) => {

    const { selectedRecruiterSlugs, jobs } = props

    const [withProcesses, setWithProcesses] = useState(null);
    const [withoutProcesses, setWithoutProcesses] = useState(null);
    const [potentialMatches, setPotentialMatches] = useState(null);



    useEffect(() => {

        let unmounted = false

        const getProcesses = async () => {
            if (!unmounted) {
                setWithProcesses(null);
                setWithoutProcesses(null);
            }

            if (!unmounted && jobs) {

                const body = {
                    type: 'jobs',
                    status: [1, 2],
                    stages: utilsService.getInProcessesStatuses(),
                }

                let matchesBody = {
                    status: [1],
                    isAvailable: true // for matches
                }
    
                //if not all
                if (selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0) {
                    body.recruiterSlugs = selectedRecruiterSlugs;
                    matchesBody.recruiterSlugs = selectedRecruiterSlugs;
                }
    
 
                processStore.getProcessesByRecruiter(body).then((processes) => {
                    const withProcesses = getWithProcesses(processes)
                    setWithProcesses(withProcesses);
                    if (withProcesses !== null && jobs) {
                        setWithoutProcesses((jobs.open + jobs.hold) - withProcesses);
                    }
                })

                searchStore.jobMatchesCounter(matchesBody).then((counter) => {
                    setPotentialMatches(counter !== undefined ? counter : '-')
                })
                .catch((e) => {
                    setPotentialMatches('-')
                })

            }

        }

        getProcesses();

    }, [jobs])

    const getWithProcesses = (processes) => {
        if (processes && jobs) {
            const withProcessesSet = new Set();
            processes.forEach(element => {
                withProcessesSet.add(element.job)
            });
            return withProcessesSet.size
        }
        return null;

    }

    const getUrl = (other) => {
        return `/jobs?recruiterId=${selectedRecruiterSlugs.join(',')}&${other}`
    }

    return (
        <div className='dashboard-activities-jobs-wrapper'>
            <div className="more-menu">
                <DotsIcon />
            </div>

            <div className="column">

                <div>
                    <h1 className="candidate-title">Jobs Status</h1>

                    <div className="line1">
                        <DashboardStatusItem number={potentialMatches} name={'Potential Matches'}
                            linkTo={getUrl(`PotentialMatches=${data.matchesFilter.find(item => item.elId === 1).elId}&status=1`)}
                            params={{ tesValue: searchStore.jobsMatchesObjByRecId[selectedRecruiterSlugs._id] }}
                        />

                        <div>
                            <DashboardStatusItem number={withProcesses} name={utilsService.formatToInnerHtml('With\\nProcess')}
                                linkTo={getUrl(`processes=${data.processesFilter.find(item => item.elId === 1).elId}&status=1,2`)} />
                        </div>

                    </div>
                </div>
                <div className="bottom">
                    <div className="line2">
                        <div>
                            <DashboardStatusItem number={withoutProcesses} name={'Without Process'}
                                linkTo={getUrl(`processes=${data.processesFilter.find(item => item.elId === 0).elId}&status=1,2`)} />
                        </div>
                            <DashboardStatusItem number={'-'} name={utilsService.formatToInnerHtml('All\\nJobs')}
                                linkTo={`/candidates?recruiterId=${selectedRecruiterSlugs._id}`} isClicked={false} />
                    </div>
                </div>
            </div>
        </div>

    )
});

DashboardActivitiesJobs.propTypes = {
    // bla: PropTypes.string,
};

DashboardActivitiesJobs.defaultProps = {
    // bla: 'test',
};

export default DashboardActivitiesJobs
