import React from 'react';

const ContextMenu = ({top, left, items, children}) => {

    const style = {};
    if (top) { style.top = top + 'px' }
    if (left) { style.left = left + 'px' }

    return (
        <div className='context-menu shadow3' style={style}>
            { children }
        </div>
    );
};

export default ContextMenu
