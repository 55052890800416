import React, {Component} from 'react';
import {CloseIcon} from '../svg';

class ContactDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }
    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    render() {
        const {data} = this.props
        return (
            <div className="contact-details">
                <header>
                    <h2><span
                        className='capitalized'>Contact Details</span>
                    </h2>
                    {this.props.isModal && (
                        <div onClick={this.handleCloseClick
                        }
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>)}

                </header>

                <div className="contact-details-body">
                    <section>
                        <div className="phone-numbers-header">Phone numbers:</div>
                        {data && data.phones.map((item, idx) => {
                            return (
                                <label className="phone-numbers-content" key={idx}>{item.phone ? "+" + item.phone : ""}</label>
                            );
                        })}

                    </section>
                    <section>
                        <div className="email-header">Emails:</div>
                        {data && data.emails.map((item, idx) => {
                            return (
                                <label className="email-content" key={idx}>{item.email}</label>
                            );
                        })}

                    </section>

                </div>

            </div>
        );
    }
}

export default ContactDetailsComponent;
