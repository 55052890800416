import React, { useState, useEffect } from 'react';
import ModalComponent from "../modal/Modal.component";
import { CloseIcon } from '../svg';
import profileStore from '../../../stores/profile.store';
import notificationsStore from '../../../stores/notifications.store';
import data from '../../../constants/data';
import processStore from '../../../stores/process.store';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import DefaultImageComponent from '../DefaultImage.component';
import jobStore from '../../../stores/job.store';
import companyStore from '../../../stores/company.store';
import { observer } from "mobx-react"
import messagingStore from '../../../stores/messaging.store';
import SuggestWithNetworkComponent from '../forms/SuggestWithNetwork.component';



const ChangeCandidateAvailabilityModal = observer((props) => {

    const [updating, setUpdating] = useState(false);
    const [option, setOption] = useState();
    const [selectedJobSlug, setSelectedJobSlug] = useState();
    const [selectedNewCompany, setSelectedNewCompany] = useState();
    const [companies, setCompanies] = useState();
    const [filteredProcesses, setFilteredProcesses] = useState();

    const { processes } = props
    const profile = profileStore.profiles[props.profileSlug];


    const selectOptions = (e, option) => {
        setOption(option);
    };

    const handleJobSelection = (e, companySlug) => {
        setSelectedJobSlug(companySlug);
    }

    const selectSuggestItem = (item, type) => {
        if (type === 'setSelectedNewCompany') {
            setSelectedNewCompany(item);
        }
    };

    useEffect(() => {

        let unmounted = false;

        const getData = async () => {

            const promises = [];

            let filteredProcessesTemp = processes.filter(item => [34, 2, 3, 9].includes(item.status))

            const jobsIds = filteredProcessesTemp.map(item => item.job);
            promises.push(jobStore.searchJobsByIds(jobsIds));

            const companiesIds = filteredProcessesTemp.map(item => item.company);
            promises.push(companyStore.getCompaniesByIds(companiesIds));

            await Promise.all(promises);

            if(!unmounted) {
                if (filteredProcessesTemp.length > 0) {
                    setSelectedJobSlug(filteredProcessesTemp[0].jobSlug);
                }
    
                filteredProcessesTemp = filteredProcessesTemp.map(item => {
                    const company = companyStore.companies[item.companySlug];
                    const job =  jobStore.allJobs[item.jobSlug]
                    item.jobTitle = job && job.title
                    item.companyName = company && company.name;
                    item.companyImage = company && utilsService.getCompanyImage(company);
                    return item;
                });
    
                setFilteredProcesses(filteredProcessesTemp);
            }

        }

        getData();    

        return () => {
            unmounted = true;
        }

    }, [processes])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setUpdating(true);

        let profileBody = {
            isAvailable: false,
            followUpDate: Date.now(),
            user: userStore.user._id,
            userSlug: userStore.user.slug
        }

        if(option.id === 64) {
            await messagingStore.makeTalat(null, profile.slug);
            notificationsStore.showToast(`${profile.firstName} availability was changed`, 'success');
            setUpdating(false);
            props.closeEvent(true);
            return;
        }

        //hired
        if(option.id === 7) {
            const job =  jobStore.allJobs[selectedJobSlug];
            const company = companyStore.companies[job.companySlug];
            profileBody = getProfileBody(company);
        }

        //accepted another offer
        else if(option.id === 363) {
            profileBody = getProfileBody(selectedNewCompany);
        }


        const processesBody = []
        processes.forEach(item => {
            const isDeclineOrWithdrawnOrHired = utilsService.checkIfDeclineOrWithdrawnOrHired(item);
            if (!isDeclineOrWithdrawnOrHired) {
                let newStatus;
                if (item.jobSlug === selectedJobSlug) {
                    newStatus = option.id;
                }
                else {
                    const tempStatus = (option.id === 100 ? 100 : 363); 
                    const candidateOption = data.close_options_candidate_side.find(item => item.id === tempStatus);
                    newStatus = candidateOption.id;
                }
                const body = {
                    id: item._id,
                    status: newStatus,
                    processSlug: item.slug,
                    profileId: item.profile,
                    comment: "",
                    eventType: 'Close',
                    eventTypeId: newStatus,
                    decisionMakerEmail: userStore.user.email,
                    decisionMakerName: userStore.user.displayName,
                    previousStatus: item.status,
                };
                processesBody.push(body)
            }
        });

        const promisees = []
        promisees.push(profileStore.updateProfile(profile._id, profileBody));
        if (processes && processes.length > 0) {
            promisees.push(processStore.changeStatuses(processesBody, false, false, false));
        }

        const[isUpdateProfileFailed, changeStatuses] = await Promise.all(promisees);
        
        if(!isUpdateProfileFailed) {
            notificationsStore.showToast(`${profile.firstName} availability was changed`, 'success');
        }
        else {
            notificationsStore.showToast(`${profile.firstName} availability was not changed`, 'failure');

        }
        setUpdating(false);
        props.closeEvent(!isUpdateProfileFailed);
    };
    
    const getProfileBody = (company) => {
        let profileBody = {
            isAvailable: false,
            shouldValidate: false,
            user: userStore.user._id,
            userSlug: userStore.user.slug
        };
        
        profileBody = {...profileBody , 
            company: company && company.elId !== -1 ? company.name : "",
            companyId: company && company.elId !== -1 ? company._id : null,
            companySlug: company && company.elId !== -1 ? company.slug : '',
            companyEmsId: company && company.elId !== -1 ? company.emsId : -1
        }

        return profileBody;
    }

    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box candidate-availability'>
                {/* <div className='action-process '> */}
                {/* <header> */}
                <section className="modal-body ">


                    <div className="candidate-side-header">From Candidate Side</div>
                    {data.close_options_candidate_side_change_availability.map((item, idx) => {
                        return (
                            (item.id !== 7 || (filteredProcesses && filteredProcesses.length > 0)) && 
                            <div className="radio" key={idx + 'hr'}>
                                <input id={'radio-' + idx + 'hrd'} name="reason" type="radio"
                                    onChange={(e) => {
                                        selectOptions(e, item);
                                    }}
                                />
                                <label htmlFor={'radio-' + idx + 'hrd'} className="radio-label">{item.reason}</label>

                                {option && option.id === 363 && item.id === 363 &&
                                    <div className="new-company">
                                        <SuggestWithNetworkComponent
                                            defaultValue={'---Unknown---'}
                                            label={'New Company'}
                                            selectedItem={selectedNewCompany}
                                            selectItem={item => selectSuggestItem(item, 'setSelectedNewCompany')}
                                            networkRequest={companyStore.searchCompaniesByPartialNameByText}
                                        />
                                    </div>
                                }

                                {option && option.id === 7 && item.id === 7 && <div className="scroll-bar">
                                    <div className='applicant-cards-grid'>
                                        {filteredProcesses && filteredProcesses.map(process => (
                                            <div key={process._id} className='applicant-card'>
                                                <div className="radio" key={process._id}>
                                                    <input id={'radio-' + process._id} name="closeGroup" type="radio"
                                                        checked={selectedJobSlug === process.jobSlug}
                                                        onClick={(e) => {
                                                            handleJobSelection(e, process.jobSlug);
                                                        }}
                                                    />
                                                    <label htmlFor={'radio-' + process._id} className="radio-label" />
                                                </div>

                                                {companyStore.companies[process.companySlug] && <DefaultImageComponent
                                                    url={process.companyImage}
                                                    alt={process.companyName}
                                                    profileName={process.companyName}
                                                    width={40}
                                                />}
                                                <div>
                                                    <h4>{process.jobTitle}</h4>
                                                    <p className='font-gray'>{process.companyName}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                            </div>

                        );
                    })}

                </section>
                <div onClick={() => props.closeEvent()}
                    className='circle-close modal-close'>
                    <CloseIcon />
                </div>
                {/* </header> */}
                <footer>
                    <button onClick={() => props.closeEvent()}
                        className='button gray'>
                        Cancel
                        </button>
                    <button
                        disabled={!option}
                        onClick={(e) => handleSubmit(e)}
                        className={`button black handler ${!option  ? 'disabled' : ''} 
                                ${updating ? 'loading' : ''}`}>
                        Yes
                        </button>
                </footer>
            </div>
            {/* </div> */}
        </ModalComponent>
    );
});

export default ChangeCandidateAvailabilityModal;
