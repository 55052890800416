import React from 'react';
import ModernSelectComponent from '../../forms/ModernSelect.component';
import './conversationSelector.scss';
import { MailIcon3, SmsIcon, WhatsappIcon } from '../../svg';

const ConversationTypeSelectorComponent = props => {
    const { selectedId, selectHandle } = props;

    return (
        <div className='conversation-type-selector'>
            <ModernSelectComponent
                list={icons}
                indexMark='elId'
                valueMark='value'
                selected={selectedId}
                selectEvent={selectHandle}
            />
        </div>
    );
};

export default React.memo(ConversationTypeSelectorComponent);

const icons = [
    {
        elId: 1,
        value: <MailIcon3
            stroke='#000'
        />
    },
    {
        elId: 2,
        value: <SmsIcon/>
    },
    {
        elId: 3,
        value: <WhatsappIcon/>
    }
];
