import utilsService from './utils.service';

class StringTokenReplacerService {
    messageReplacers = [
        '#name#', '#firstname#', '#lastname#',
        '#sendername#', '#senderfirstname#', '#senderlastname#',
        '#talentinviteurl#', '#jobsuggestionurl#'
    ];

    messageStringTokensReplacer(str, receiver, sender, template, claimToken, jobUrls) {
        let isEnglish = true;
        if (template && template.language === 2) {
            isEnglish = false;
        }

        const receiverDisplayName = this.__getDisplayName(receiver, isEnglish);
        const receiverFirstName = this.__getFirstName(receiver, isEnglish);
        const receiverLastName = this.__getLastName(receiver, isEnglish);

        const senderDisplayName = this.__getDisplayName(sender, isEnglish);
        const senderFirstName = this.__getFirstName(sender, isEnglish);
        const senderLastName = this.__getLastName(sender, isEnglish);
        const talentInviteUrl = utilsService.getTalentInviteUrl(claimToken)
        const jobSuggestionUrl = this.__getJobSuggestionUrl(jobUrls)

        this.messageReplacers.forEach(replacer => {
            switch (replacer) {
                case '#name#':
                    str = receiverDisplayName ? this.__replacer(str, receiverDisplayName, replacer) : str;
                    break;
                case '#firstname#':
                    str = receiverFirstName ? this.__replacer(str, receiverFirstName, replacer) : str;
                    break;
                case '#lastname#':
                    str = receiverLastName ? this.__replacer(str, receiverLastName, replacer) : str;
                    break;
                case '#sendername#':
                    str = this.__replacer(str, senderDisplayName, replacer);
                    break;
                case '#senderfirstname#':
                    str = this.__replacer(str, senderFirstName, replacer);
                    break;
                case '#senderlastname#':
                    str = this.__replacer(str, senderLastName, replacer);
                    break;
                case '#talentinviteurl#':
                    str = this.__replacer(str, talentInviteUrl, replacer);
                    break;
                case '#jobsuggestionurl#':
                    str = jobSuggestionUrl ? this.__replacer(str, jobSuggestionUrl, replacer) : str;
                    break;
                default:
                    str = '';
                    break;
            }
        });

        return str;
    }

    __getDisplayName(item, isEnglish) {
        let res = '';
        if (item) {
            if (!isEnglish && item.firstNameHeb) {
                res = `${item.firstNameHeb} ${item.lastNameHeb}`;
            } else {
                res = `${item.firstName} ${item.lastName}`;
            }
        }
        return utilsService.capitalizeString(res);
    }

    __getFirstName(item, isEnglish) {
        let res = '';
        if (item) {
            if (!isEnglish && item.firstNameHeb) {
                res = `${item.firstNameHeb}`;
            } else {
                res = `${item.firstName}`;
            }
        }
        return utilsService.capitalizeString(res);
    }

    __getLastName(item, isEnglish) {
        let res = '';
        if (item) {
            if (!isEnglish && item.lastNameHeb) {
                res = `${item.lastNameHeb}`;
            } else {
                res = `${item.lastName}`;
            }
        }
        return res;
    }

    __replacer(str, item, token) {
        let res = '';
        const reg = new RegExp(token, 'gi');
        if (item) {
            res = str.replace(reg, item);
        }
        return res;
    }

    __getJobSuggestionUrl(jobUrls) {
        const baseURL = window.location.origin;
        let res = '';
        if (jobUrls && jobUrls.length > 0) {
            res = jobUrls.map(i => `${baseURL}/jobs/${i}`).join(', ');
        }
        return res;
    }

}

const stringTokenReplacerService = new StringTokenReplacerService();
export default stringTokenReplacerService;
