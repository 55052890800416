import {action, observable} from 'mobx';

class NotificationsStore {
    @observable toasts = [];

    @action.bound
    /**
     *
     * @param {string} message
     * @param {'success' | 'failure'} type
     * @param timeoutMilliSec
     */
    showToast(message, type, timeoutMilliSec = 6000) {
        const dateNow = Date.now();
        const data = {
            id: dateNow,
            message: message,
            timeoutMilliSec: timeoutMilliSec,
            type: type
        };
        this.toasts.push(data);
    }

    @action.bound
    removeToast(id) {
        this.toasts = this.toasts.filter(q => q.id !== id);
    }

    @action.bound
    removePreviousToast() {
        this.toasts = this.toasts.filter(q => q.id >= Date.now());
    }
}

const notificationsStore = new NotificationsStore();

export default notificationsStore;
