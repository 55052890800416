import React from 'react';
  
const MobileUnderConstructionView = () => {
  return (
    <div>
      <div className="max-width page-not-found">
        <h1>Mobile web site will be ready soon.</h1>
        <h1>Please login from Desktop computer.</h1>
        <h1>&copy; Ethosia</h1>
      </div>
    </div>
  );
};

export default MobileUnderConstructionView;
