import React from 'react';
import ModalComponent from "../modal/Modal.component";
import CustomCheckboxComponent from "../customCheckbox/CustomCheckbox.component";
import StyledRadioButtonsComponent from "../styledRadioButttons/StyledRadioButtons.component";

const SetJobAlertModal = (props) => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box set-alert-modal'>
                <header>
                    <h2>Set Alert</h2>
                    <div onClick={() => props.closeEvent()}
                         className='circle-close modal-close'>
                        <i className="fas fa-times-circle"/>
                    </div>
                </header>

                <section className="modal-body form">
                    <form>
                        <div className="item">
                            <div className="form-item">
                                <label>Task</label>
                                <select name="">
                                    <option value="">1</option>
                                </select>
                            </div>

                            <div className="form-item">
                                <label>Assign To</label>
                                <input type="text"/>
                            </div>
                        </div>


                        <div className="item">
                            <div className="form-item">
                                <label>Date</label>
                                <input type="date"/>
                            </div>

                            <div className="form-item">
                                <label>Entity (Talent/Job/Company)</label>
                                <input type="text"/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="form-wrapper">
                                <div className="form-item different-margin">
                                    <label>Urgency</label>
                                    <StyledRadioButtonsComponent buttons={['Low', 'Medium', 'High']}/>
                                </div>
                                <div className="form-item">
                                    <label>
                                        <CustomCheckboxComponent/>
                                        Send Alert by email
                                    </label>
                                </div>
                            </div>
                            <div className="form-item">
                                <label>Message (Job details will be attached at the bottom of the email)</label>
                                <textarea/>
                            </div>
                        </div>

                    </form>
                </section>
                <footer>
                    <button onClick={() => props.closeEvent()}
                            className='button gray min140'>
                        Cancel
                    </button>
                    <button
                        onClick={() => props.closeEvent()}
                        className='button red min140 float-right'>
                        Close Position
                    </button>
                </footer>
            </div>
        </ModalComponent>
    );
};

export default SetJobAlertModal;
