import React from 'react';
import ModalComponent from '../modal/Modal.component';
import InterviewInfoComponent from '../actions/InterviewInfo.component';

const InterviewInfoModalComponent = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box modal-box-interview-info'>
                <InterviewInfoComponent
                    isSingleJobView={props.isSingleJobView}
                    isModal={true}
                    closeEvent={() => props.closeEvent()}
                    data={props.data}
                />
            </div>
        </ModalComponent>

    );
};

export default InterviewInfoModalComponent;
