import React from 'react';
import './fit.scss';
import utilsService from '../../../services/utils.service';

const FitComponent = props => {
    const h = 206 - 80;
    const val = 126 - (h / 100 * props.fit) + 80;
    const root = document.documentElement;
    root.style.setProperty('--stroke-size', val.toString());
    return (
        <div className='fit-component'>
            <svg width="51" height="51" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <circle id="circle2" className="circle_animation2" r="20" cy="22" cx="23" strokeWidth="4"
                            stroke="#d4d4d4" fill="none"/>
                </g>
                <g>
                    <circle id="circle" className="circle_animation" r="20" cy="22" cx="23" strokeWidth="4"
                            stroke="#6fdb6f" fill="none"/>
                </g>
            </svg>
            <div className="fit-number">
                {props.fit + '%'}
            </div>
            <div className="status">
                {utilsService.getStatusByEnum(props.status)}
            </div>
        </div>
    );
};

export default React.memo(FitComponent);
