import React from 'react';
import data from '../../../constants/data';
import { MultiSelectComponent, TextAreaForm, InputForm } from '../forms';
import jobStore from '../../../stores/job.store';
import profileStore from '../../../stores/profile.store';
import SuggestWithNetworkComponent from '../forms/SuggestWithNetwork.component';
import companyStore from '../../../stores/company.store';
import utilsService from '../../../services/utils.service';

const ProcessOverviewBottomDataComponent = props => {
    const {
        actionId, jobSlug, profileSlug,
        selectOptionsHandle,
        selectOptions,
        emailToSendEvent, commentToSend, processCommentEvent,
        selectedNewCompany, handleNewCompanySelected, removeItem, selectItem,
        salaryOfferEvent, salaryOffer
    } = props;

    const job = jobStore.allJobs[jobSlug];
    const profile = profileStore.profiles[profileSlug];

    const emailsToSend = props.emailsToSend;

    const getRightContent = () => {
        switch (actionId) {
            case 1:
                return <CloseJob
                    job={job}
                />;
            case 4:
                return <ExposeProfile
                    profile={profile}
                    emailToSendEvent={emailToSendEvent}
                    emailsToSend={emailsToSend}
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                    removeItem={removeItem}
                    selectItem={selectItem}
                />;
            case 2:
                return <Decline
                    profile={profile}
                    selectOptions={selectOptions}
                    selectOptionsHandle={selectOptionsHandle}
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                />;
            case 3:
                return <WithDrawn
                    profile={profile}
                    selectOptions={selectOptions}
                    selectOptionsHandle={selectOptionsHandle}
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                    selectedNewCompany={selectedNewCompany}
                    handleNewCompanySelected={handleNewCompanySelected}
                />;
            case 51:
                return <UpdateStatus
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                />;
            case 53:
                return <UpdateStatus
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                />;
            case 54:
                return <Offer
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                    salaryOffer={salaryOffer}
                    salaryOfferEvent={salaryOfferEvent}
                />;
            case 55:
                return <Offer
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                    salaryOffer={salaryOffer}
                    salaryOfferEvent={salaryOfferEvent}
                />;
            case 56:
                return <Unmatched
                    profile={profile}
                    selectOptions={selectOptions}
                    selectOptionsHandle={selectOptionsHandle}
                    processCommentEvent={processCommentEvent}
                    commentToSend={commentToSend}
                />;

            default:
                break;
        }
    };

    return (
        <div className='process-overview-process-bottom-data'>
            {getRightContent()}
        </div>
    );
};

export default ProcessOverviewBottomDataComponent;


const CloseJob = props => {
    const { job } = props;
    return (
        <div className='process-overview-process-bottom-data-item'>
            <h3>You are about to close job - {job.title}</h3>
            <p>Slug url: <span>{job.slug}</span></p>
            <p>BsId: <span>{job.bsId}</span></p>
        </div>
    );
};

const ExposeProfile = props => {
    // const { emailsToSend, emailToSendEvent, commentToSend, processCommentEvent, removeItem, selectItem } = props;
    const { emailsToSend, commentToSend, processCommentEvent, removeItem, selectItem } = props;

    let normalizedEmailsToSend = [];
    if (emailsToSend && emailsToSend.length > 0) {
        normalizedEmailsToSend = emailsToSend.map(email => {
            if (email && email.toLowerCase().includes('@applynow.io')) {
                return null;
            }

            return { elId: email, value: email };
        });
    }
    normalizedEmailsToSend = normalizedEmailsToSend.filter(email => email);

    return (
        <div className='process-overview-process-bottom-data-item'>
            {/* <InputForm
                label={'Send to:'}
                value={emailToSend}
                type={'text'}
                valid={emailToSend}
                inputChange={emailToSendEvent}
            /> */}
            <label>Email:</label>
            <MultiSelectComponent
                items={normalizedEmailsToSend}
                allowCreate={true}
                validation={true}
                valid={normalizedEmailsToSend && normalizedEmailsToSend.length > 0}
                selectedItems={normalizedEmailsToSend}
                // removeItem={item => this.removeMultiItem(item, 'selectedEmails')}
                // selectItem={item => this.selectMultiItem(item, 'selectedEmails')}
                removeItem={removeItem}
                selectItem={selectItem}
            />

            <TextAreaForm
                label={'Comment:'}
                value={commentToSend}
                type={'text'}
                valid={commentToSend}
                inputChange={processCommentEvent}
            />
        </div>
    );
};

const MoveToNew = () => {
    return (
        <div className='process-overview-process-bottom-data-item'>
            <h3>You are about to change process status to NEW</h3>
        </div>
    );
};

const Decline = props => {
    const { selectOptionsHandle, commentToSend, processCommentEvent, selectOptions } = props;
    return (
        <div className='process-overview-process-bottom-data-item'>
            <label>Reason:</label>
            <div className="process-overview-process-bottom-data-item-radios">
                {data.decline_options_recruiter_side.map((item, idx) => {
                    return (
                        <div className="radio" key={idx}>
                            <input id={'radio-' + idx} name="reason" type="radio" d
                                defaultChecked={selectOptions && item.id === selectOptions.id}
                                onChange={(e) => {
                                    selectOptionsHandle(e, item);
                                }}
                            />
                            <label htmlFor={'radio-' + idx} className="radio-label">{item.reason}</label>
                        </div>
                    );
                })}
            </div>

            <TextAreaForm
                label={'Comment:'}
                value={commentToSend}
                type={'text'}
                valid={commentToSend}
                inputChange={processCommentEvent}
            />
        </div>
    );
};

const WithDrawn = props => {
    const { selectOptionsHandle, commentToSend, processCommentEvent, selectOptions,
        selectedNewCompany, handleNewCompanySelected } = props;

    return (
        <div className='process-overview-process-bottom-data-item'>
            <label>Reason:</label>
            <div className="process-overview-process-bottom-data-item-radios">
                {data.decline_options_candidate_side.map((item, idx) => {
                    return (
                        <div className="radio" key={idx + 'hr'}>
                            <input id={'radio-' + idx + 'hrd'} name="reason" type="radio"
                                onChange={(e) => {
                                    selectOptionsHandle(e, item);
                                }}
                            />
                            <label htmlFor={'radio-' + idx + 'hrd'} className="radio-label">{item.reason}</label>

                            {selectOptions && selectOptions.reason === 'Accepted Another Offer'
                                && item.reason === 'Accepted Another Offer' &&
                                <div className="new-company">
                                    <SuggestWithNetworkComponent
                                        defaultValue={'---Unknown---'}
                                        label={'New Company'}
                                        selectedItem={selectedNewCompany}
                                        selectItem={item => handleNewCompanySelected(item, 'selectedNewCompany')}
                                        numberOfCharsToPredicate={2}
                                        networkRequest={companyStore.searchCompaniesByPartialNameByText}
                                        moreParams={{ hasJobs: true }}
                                    />
                                </div>
                            }
                        </div>

                    );
                })}
            </div>

            <TextAreaForm
                label={'Comment:'}
                value={commentToSend}
                type={'text'}
                valid={commentToSend}
                inputChange={processCommentEvent}
            />
        </div>
    );
};

const Unmatched = props => {
    const { selectOptionsHandle, commentToSend, processCommentEvent } = props;
    return (
        <div className='process-overview-process-bottom-data-item unmatched'>
            <label className='reason'>Reasons:</label>
            <div className="process-overview-process-bottom-data-item-radios">
                {data.unmatch_options.map((item, idx) => {
                    return (
                        <div className="radio" key={idx + 'hr'}>
                            <input id={'radio-' + idx + 'hrd'} name="reason" type="radio"
                                onChange={(e) => {
                                    selectOptionsHandle(e, item);
                                }}
                            />
                            <label htmlFor={'radio-' + idx + 'hrd'} className="radio-label">{item.reason}</label>
                        </div>

                    );
                })}
            </div>

            <TextAreaForm
                label={'Comment:'}
                value={commentToSend}
                type={'text'}
                valid={true}
                inputChange={processCommentEvent}
            />
        </div>
    );
};


const UpdateStatus = props => {
    const { commentToSend, processCommentEvent } = props;
    return (
        <div className='process-overview-process-bottom-data-item'>
            <TextAreaForm
                label={'Comment:'}
                value={commentToSend}
                type={'text'}
                valid={commentToSend}
                inputChange={processCommentEvent}
            />
        </div>
    );
};

const Offer = props => {
    const { commentToSend, processCommentEvent, salaryOffer, salaryOfferEvent } = props;
    return (
        <div className='process-overview-process-bottom-data-item'>
            <TextAreaForm
                label={'Comment:'}
                value={commentToSend}
                type={'text'}
                valid={commentToSend}
                inputChange={processCommentEvent}
            />
            <InputForm
                label={'Salary Offer:'}
                defaultValue={salaryOffer}
                type={'text'}
                valid={utilsService.validateSalary(salaryOffer)}
                pattern={"[0-9]{4,}"}
                inputChange={salaryOfferEvent}
            />
        </div>
    );
};
