import React from 'react';
import DefaultImageComponent from './DefaultImage.component';

const RoundImage = ({ imageUrl, height, alt, profileName }) => {
    return (
        <div className="image-cropper" style={{height: height + 'px', width: height + 'px'}}>
            <DefaultImageComponent
                url={imageUrl}
                alt={alt}
                profileName={profileName}
            />
        </div>
    );
};

export default RoundImage;
