import React from 'react';
import {withRouter} from 'react-router';
import ReactGA from 'react-ga';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            const state =  this.props.location.state;
            if(!(state && state.shouldNotScrollToTop)) {
                window.scrollTo(0, 0);
            }
        }

        if (process.env.NODE_ENV === 'production') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
