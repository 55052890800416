import React, {PureComponent} from 'react';
import './backButton.scss';
import {withRouter} from 'react-router-dom';

class BackButtonComponent extends PureComponent {

    clickHandle = e => {
        e.preventDefault();
        this.props.history.goBack();
    };

    render() {
        return (
            <div>
                <button
                    onClick={this.clickHandle}
                    className='back-link shadow4'>
                    Back
                </button>
            </div>
        );
    }
}

export default withRouter(BackButtonComponent);
