const bucketUrl = 'https://storage.googleapis.com/bff-prod-223208.appspot.com/';

const _url = {
    profile_image: bucketUrl + 'profile-images/',
    cv: bucketUrl + 'resume/',
    default_profile_image: bucketUrl + 'profile-images/default-profile.png',
    logo: bucketUrl + 'profile-images/ethosia-logo.png',
    company_logo: bucketUrl + 'company-logos/',
    default_company_logo: bucketUrl + 'company-logos/default-company.jpg'

};

export default _url;
