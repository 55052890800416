import React, {Component} from 'react';
import ModalComponent from '../modal/Modal.component';
import DefaultImageComponent from '../DefaultImage.component';

export default class CloseJobModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProfileSlug: ''
        };
    }

    handleCandidateSelection(e, profileSlug) {
        const { selectedProfileSlug } = this.state;
        let selection = '';
        if (selectedProfileSlug !== profileSlug) {
            selection = profileSlug;
        }
        this.setState(
            {
                selectedProfileSlug: selection
            }
        );
    }

    render() {

        const { jobTitle, processes } = this.props;
        const { selectedProfileSlug } = this.state;
        return (
            <ModalComponent onClose={() => this.props.cancelEvent(-1)}>
                <div className='modal-box close-job-modal'>
                    <header>
                        <h2>Close job - {jobTitle} </h2>
                        <span>Did any of the following candidates fill this position?</span>
                        <div
                            className='circle-close modal-close'>
                            <i className="fas fa-times-circle"/>
                        </div>
                    </header>

                    <section className="modal-body">
                        <div className="scroll-bar">
                            <div className='applicant-cards-grid'>
                                {processes.map(process => (
                                    <div key={process._id} className='applicant-card'>
                                        <div className="radio" key={process._id}>
                                            <input id={'radio-' + process._id} name="closeGroup" type="radio"
                                                   checked={selectedProfileSlug === process.profileSlug}
                                                   onClick={(e) => {
                                                       this.handleCandidateSelection(e, process.profileSlug);
                                                   }}
                                            />
                                            <label htmlFor={'radio-' + process._id} className="radio-label"/>
                                        </div>

                                        <DefaultImageComponent
                                            width={40}
                                            url={process.profileImageUrl}
                                            alt={process.profileName}
                                            profileName={process.profileName}
                                            isExposed={true}
                                        />
                                        <div>
                                            <h4>{process.profileName}</h4>
                                            <p className='font-gray'>{process.profileData && process.profileData.position}, {process.profileData && process.profileData.company}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* <div className="non-of-above">
                            <label>
                                <CustomCheckboxComponent/>
                                None of the above
                            </label>
                        </div> */}
                    </section>
                    <footer>
                        <button onClick={() => this.props.cancelEvent(-1)}
                                className='button gray min140'>
                            Cancel
                        </button>
                        <button
                            onClick={() => this.props.closeEvent(selectedProfileSlug)}
                            className='button black min140 float-right'>
                            Close
                        </button>
                    </footer>
                </div>
            </ModalComponent>
        );
    };
}
