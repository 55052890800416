import { Button, Icon, Tab, Tabs } from '@blueprintjs/core';
import { useIsMobile } from '@brightsource/brightsource-ui-lib';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResponsiveDevice, StyledContainer, StyledTitle, StyledWrapper } from '../../../../constants/styles';
import metaDataStore from '../../../../stores/meta-data.store';
import NavigationBar from '../../../components/navigationBar/NavigationBar.component';
import MessageTemplates from '../userDetails/components/messageTemplates/MessageTemplates.component';
import PasswordTab from './PasswordTab';
import PersonalInfoTab from './PersonalInfoTab';
import PreferencesTab from './PreferencesTab';

function UserAccount() {
  const isMobile = useIsMobile();
  const [selectedTabId, setSelectedTabId] = useState(-1);

  useEffect(() => {
    document.title = 'User Details - Ethosia';

    metaDataStore.getCountries();
  }, []);

  useEffect(() => {
    setSelectedTabId(isMobile ? -1 : 0);
  }, [isMobile]);

  const routes = [
    { id: 0, label: 'Personal Info', panel: <PersonalInfoTab /> },
    { id: 1, label: 'Password', panel: <PasswordTab /> },
    { id: 2, label: 'Notifications', panel: <PreferencesTab /> },
    { id: 3, label: 'Message Templates', panel: <MessageTemplates /> },
  ];

  const selectedTab = routes.find((route) => route.id === selectedTabId);

  return (
    <Wrapper className="user-account">
      <NavigationBar />

      <StyledContainer>
        {isMobile && <StyledTitle style={{ marginBottom: 24 }}>My Profile</StyledTitle>}

        {isMobile && selectedTab && (
          <Subtitle minimal icon="chevron-left" onClick={() => setSelectedTabId(-1)}>
            {selectedTab.label}
          </Subtitle>
        )}

        <Tabs
          vertical
          animate={false}
          selectedTabId={selectedTabId}
          onChange={setSelectedTabId}
          className={isMobile && selectedTabId !== -1 ? 'hideNavigation' : ''}
        >
          {routes.map((route) => (
            <Tab
              key={route.id}
              {...route}
              title={
                <TabTitle>
                  {route.label}
                  {isMobile && <Icon icon="chevron-right" />}
                </TabTitle>
              }
            />
          ))}
        </Tabs>
      </StyledContainer>
    </Wrapper>
  );
}

export default UserAccount;

const Wrapper = styled(StyledWrapper)`
  ${ResponsiveDevice.TABLET} {
    background-color: #fff;
  }

  .bp3-tabs.bp3-vertical {
    display: flex;
    gap: 4px;
    height: 100%;

    &.hideNavigation > .bp3-tab-list {
      display: none;
    }

    .bp3-tab-list {
      padding: 32px;
      gap: 32px;
      flex-basis: 360px;

      ${ResponsiveDevice.TABLET} {
        padding: 0;
        gap: 0;
        flex-basis: 100%;
        border-top: 1px solid #eeeff2;
      }
    }

    .bp3-tab-panel {
      flex: 1;

      padding: 32px 40px;
      background-color: #fff;
      border: 1px solid #eeeff2;
      border-radius: 20px;

      ${ResponsiveDevice.TABLET} {
        border-width: 0;
        padding: 32px 16px;
      }
    }

    .bp3-tab {
      font-size: 18px;
      font-weight: 500;

      &:not([aria-disabled='true']) {
        color: #6c6c6c;
      }

      ${ResponsiveDevice.TABLET} {
        margin: 0;
        padding: 12px 14px;
        border-bottom: 1px solid #eeeff2;
        font-size: 20px;
      }
    }

    .bp3-tab[aria-selected='true'],
    .bp3-tab:not([aria-disabled='true']):hover {
      color: #307ff6;
      background-color: transparent;
    }
  }

  .user-details-message-templates {
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
  }
`;

const TabTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Subtitle = styled(Button)`
  min-height: 40px;
  border-top: 1px solid #eeeff2;
  border-bottom: 1px solid #eeeff2;

  font-size: 20px;
  font-weight: 500;
  color: #404040;
  position: relative;

  .bp3-icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
