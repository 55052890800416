import React from 'react';
import {SearchIcon2} from '../../../components/svg';
import BackButtonComponent from '../../../components/backButton/BackButton.component';

const NoResultsComponents = () => {
    return (
        <div>
            <div className="max-width page-not-found search-not-found">
                <SearchIcon2/>
                <h1>Sorry, no results found</h1>
                <BackButtonComponent/>
            </div>
        </div>
    );
};

export default NoResultsComponents;
