import Config from 'Config';
import errorStore from '../stores/error.store';
import userStore from '../stores/user.store';
import idleCheckRefresh from './idleRefresh.service';


const fetchMode = { mode: 'cors' };

class FetchService {

    authenticatedGet = async (url) => {
        const stackTrace = new Error().stack;
        await idleCheckRefresh.verifyToken()
        const headers = {
            'Authorization': `Bearer ${userStore.userToken}`,
            'sender': 'Front End',
            'userId': userStore.user ? userStore.user._id : ''
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response, stackTrace));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });;

    };

    authenticatedPost = async (url, body) => {
        const stackTrace = new Error().stack;
        await idleCheckRefresh.verifyToken()
        const headers = {
            'Authorization': `Bearer ${userStore.userToken}`,
            'Content-Type': 'application/json',
            'sender': 'Front End',
            'userId': userStore.user ? userStore.user._id : ''
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response, stackTrace));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });;
    };

    authenticatedPut = async (url, body) => {
        const stackTrace = new Error().stack;
        await idleCheckRefresh.verifyToken(true)
        const headers = {
            'Authorization': `Bearer ${userStore.userToken}`,
            'Content-Type': 'application/json',
            'sender': 'Front End',
            'userId': userStore.user ? userStore.user._id : ''
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response, stackTrace));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });
    };

    authenticatedPatch = async (url, body) => {
        const stackTrace = new Error().stack;
        await idleCheckRefresh.verifyToken()
        const headers = {
            'Authorization': `Bearer ${userStore.userToken}`,
            'Content-Type': 'application/json',
            'sender': 'Front End',
            'userId': userStore.user ? userStore.user._id : ''
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response, stackTrace));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });;
    };

    authenticatedDelete = async (url, body) => {
        const stackTrace = new Error().stack;
        await idleCheckRefresh.verifyToken()
        const headers = {
            'Authorization': `Bearer ${userStore.userToken}` ,
            'sender': 'Front End',
            'userId': userStore.user ? userStore.user._id : ''
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response, stackTrace));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });;
    };

    patch = async (url, body) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });;
    };
    post = async (url, body) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });;
    };
    get = async (url) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const request = new Request(`${Config.serverUrl}${url}`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        ).catch(err => {
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        });;
    };

    errorHandler = error => {
        if (error.status === 401) {
            window.location.reload(true);
        } else {
            return error;
        }
    };

    checkResponse = async (response, stackTrace) => {
        const status = response.status;
        if (status === 200 || status === 201) {
            const responseJson1 = await response.json();
            if(responseJson1.data?.error && responseJson1.data?.statusCode) {
                this.sendErrorToBackend(responseJson1, stackTrace);
            }
            return responseJson1;
        }
        else if(status === 403) {
            // Authorization error. log out.
            userStore.logOut();
        }
        else {
            const responseJson2 = await response.json();
            this.sendErrorToBackend(responseJson2, stackTrace);
            return [{isError: true, errorMessage: 'Internal Server Error. Please Send Again.'}];
        }
    };

    sendErrorToBackend = (response, stackTrace) => {
        const errorObject = {
            userId: userStore.user._id,
            userEmail: userStore.user.email,
            sender: 'Front End',
            status: response.code,
            requestId: response.data.requestId,
            body: response.data.senderBody,
            message: response.data.message,
            stacktrace: stackTrace,
            type: 'Fetch Error',
            url: response.data.url,
            href: window.location.href
        }

        // we don't want to send external error more than once
        if(response.data.url !== '/api/external-error') {
           errorStore.sendError(errorObject);
        }
    }
}

const fetchService = new FetchService();
export default fetchService;
