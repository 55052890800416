import React from 'react';
import './rightSidePreview.scss';
import DefaultImageComponent from '../DefaultImage.component';
import utilsService from '../../../services/utils.service';
import SocialLinks from '../socialLinks/SocialLinks.component';
import jobStore from '../../../stores/job.store';
import companyStore from '../../../stores/company.store';
import { JobLightIcon, MailIcon3, SmsIcon } from '../svg';
import processStore from '../../../stores/process.store';
import { Link } from 'react-router-dom';


const RightSidePreviewComponent = props => {
    const { profile, jobSlug, companySlug, process } = props;

    return (
        <div className='fancy-profile-job'>
            <ProfileBox
                profile={profile}
                process={process}
            />
            <JobBox
                processSlug={process}
                jobSlug={jobSlug}
                companySlug={companySlug}
            />
        </div>
    );
};

export default RightSidePreviewComponent;

const ProfileBox = ({ profile, process }) => {
    const displayName = utilsService.getDisplayName(profile);
    let birthday = null;
    let birthdayStr = '';
    if (profile) {
        birthday = utilsService.getCurrentAge(profile.birthday);
        if (birthday) {
            birthdayStr = `(${birthday})`;
        }
    }
    return (
        <div className="fancy-profile-job-profile">
            {profile && (
                <div className="fancy-profile-job-profile-item fancy-profile-job-item">
                    <DefaultImageComponent
                        url={utilsService.getProfileImage(profile)}
                        alt={displayName}
                        profileName={displayName}
                        isExposed={true}
                    />
                    <div className="fancy-profile-job-item-data">
                        <p className='title'>
                            <Link target="_blank" to={`/profile/${profile.slug}`}>
                                {`${displayName} ${birthdayStr}`}
                            </Link>
                        </p>
                        <p className="sub-title">{utilsService.getProfileAddress(profile)}</p>
                        <div className="icons">
                            <SocialLinks
                                links={profile.socialLinks}
                            />
                        </div>

                        <p className="info-data">
                            <span>BsID:</span>
                            <span
                                className='cont'
                            >{profile.bsId}
                            </span>
                        </p>

                        <p className="info-data">
                            <span>
                                <SmsIcon/> 
                            </span>
                            <span
                                className='cont'
                            >
                                {utilsService.getPrimaryValue(profile.phones, 'phone')}
                            </span>
                        </p>
                        <p className="info-data">
                            <span><MailIcon3 stroke={'#000000'}/></span>
                            <span
                                className='cont'
                            >{utilsService.getPrimaryValue(profile.emails, 'email')}</span>
                        </p>
                        <p className="info-data-big">
                            <span>
                                <JobLightIcon/> 
                            </span>
                            <span
                                className='cont'
                            >{profile.position}</span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};


const JobBox = ({ jobSlug, companySlug, processSlug }) => {
    const job = jobStore.allJobs[jobSlug];
    const company = companyStore.companies[companySlug];
    const process = processStore.allProcesses[processSlug];

    return (
        <div className="fancy-profile-job-profile">
            {job && company && (
                <div className="fancy-profile-job-profile-item">
                    <DefaultImageComponent
                        url={utilsService.getCompanyImage(company)}
                        alt={company.name}
                        profileName={company.name}
                    />
                    <div className="fancy-profile-job-item-data">
                        <p className='title'>
                            <Link target="_blank" to={`/jobs/${job.slug}`}>
                                {job.title}
                            </Link>
                        </p>
                        <p className="sub-title">
                            <Link target="_blank" to={`/company/${company.slug}`}>
                                {company.name}
                            </Link>
                        </p>
                        <p className="sub-sub-title">
                            {utilsService.getProfileAddress(company)}
                        </p>

                        <div className="items">
                            <p className="title">Job bsID</p>
                            <p className="val">{job.bsId}</p>
                        </div>

                        {process && (
                            <div className="items">
                                <p className="title">Process bsID</p>
                                <p className="val">{process.bsId}</p>
                            </div>
                        )}

                        {process && (
                            <div className="items">
                                <p className="title">Process status</p>
                                <p className="val">{utilsService.getStatusByEnum(process.status)}</p>
                            </div>
                        )}

                        {job.positionType && (
                            <div className="items">
                                <p className="title">Position Type</p>
                                <p className="val">{job.positionType.value}</p>
                            </div>
                        )}

                        {job.salary > 0 && (
                            <div className="items">
                                <p className="title">Salary</p>
                                <p className="val">{job.salary}</p>
                            </div>
                        )}

                        {job.industries && job.industries.length !== 0 && (
                            <div className="items">
                                <p className="title">Industries</p>
                                <p className="val">{job.industries[0].value}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
