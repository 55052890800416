import { InputCollapse, TextField } from '@brightsource/brightsource-ui-lib';
import React from 'react';
import { useController } from 'react-hook-form';

export function InputField({
  name,
  control,
  onChange: externalOnChange,

  label = '',
  placeholder = '',
  defaultExpanded = false,
  disabled = false,
  disableCollapse = false,
  readonly = false,
  multiline = false,
  rows,
  type,
  rightIcon,
}) {
  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <InputCollapse
      label={label}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      disableCollapse={disableCollapse}
    >
      <TextField
        size="small"
        inputRef={ref}
        name={name}
        value={value}
        onChange={(event) => {
          onChange(event);
          externalOnChange && externalOnChange();
        }}
        onBlur={onBlur}
        error={!!error}
        helperText={error?.message}
        placeholder={placeholder}
        allowClear={!disabled}
        onClear={() => {
          onChange('');
          externalOnChange && externalOnChange();
        }}
        readonly={readonly}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        type={type}
        rightIcon={rightIcon}
      />
    </InputCollapse>
  );
}
