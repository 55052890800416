import { Pagination } from '@brightsource/brightsource-ui-lib';
import React from 'react';

export function BsPagination({ totalPage, currentPage, onPageChange }) {
  return (
    <Pagination
      count={totalPage}
      page={currentPage + 1}
      onChange={(event, page) => {
        // setSelectedList([]);
        onPageChange(page - 1);
      }}
      sx={{
        paddingTop: '12px',
        paddingBottom: '12px',

        '.MuiPagination-ul': {
          justifyContent: 'center',
        },
      }}
    />
  );
}
