import React from 'react';
import utilsService from '../../../../../services/utils.service';
import Transportations from '../../../../components/transportations/Transportations.component';

const JobSummaryRequirement = props => {
    const { job, company } = props;
    const skills = utilsService.filterSkillsFromMandatorySkills(job.skills, job.mandatorySkills);
    return (
        <div className="job-summery-req-container">
            {company?.size && (
                <div className="req-container">
                    <div className="key">Organization Size</div>
                    <div className="value">{utilsService.getObjValue(company.size)}</div>
                </div>
            )}
            {job.seniorityLevel && job.seniorityLevel.length > 0 && (
                <div className="req-container">
                    <div className="key">Seniority</div>
                    <div className="value">{job.seniorityLevel[0].value}</div>
                </div>
            )}
            {job.specialization && job.specialization.length > 0 && (
                <div className="req-container">
                    <div className="key">Category</div>
                    <div>{job.specialization.join(', ')}</div>
                </div>
            )}

            {job.education && job.education.length > 0 && (
                <div className="req-container">
                    <div className="key">Education</div>
                    <div className="value">{utilsService.joinByComma(job.education, 'value')}</div>
                </div>
            )}


            {job.roles && job.roles.length > 0 && (
                <div className="req-container">
                    <div className="key">Roles</div>
                    <div className="value">{utilsService.joinByComma(job.roles, 'value')}</div>
                </div>
            )}

            {job.languages && job.languages.length > 0 && (
                <div className="req-container">
                    <div className="key">Language</div>
                    <div className='value'>{utilsService.joinByComma(utilsService.normalizeLanguages(job.languages), 'value')}</div>
                </div>
            )}

            {((job.skills && job.skills.length > 0 && job.skills[0]) || (job.mandatorySkills && job.mandatorySkills.length > 0 && job.mandatorySkills[0])) && (
                <div className="req-container skill-contr">
                    <div className="key">Skills</div>
                    <div className="skills">
                        {utilsService.getSkills(job.mandatorySkills, true)}
                        {utilsService.getSkills(skills, false)}
                    </div>
                </div>
            )}
            {company && company.industryDistrict && (
                <div className="req-container transportation-wrapper">
                    <div className="key">Transportation</div>
                    <Transportations industryDistrict={company.industryDistrict} />
                </div>
            )}

        </div>
    );
};

export default React.memo(JobSummaryRequirement);
