import fetchService from "../../../services/fetch.service";

export const billApiClient = {
  saveOrder: (body) => {
    return fetchService.authenticatedPost('/v2/billings/orders', body);
  },
  savePayment: (billingId, body) => {
    return fetchService.authenticatedPost(`/v2/billings/${billingId}/payments`, body);
  },
  findMany: ({ search, page = 0, limit = 10, companyId, jobId, profileSlug, projectTypeId, orderMonth, paymentMonth, paymentStatus }) => {
    const searchParams = new URLSearchParams();
    const offset = page * limit;

    searchParams.set('offset', offset);
    searchParams.set('limit', limit + '');

    companyId && searchParams.set('companyId', companyId);
    jobId && searchParams.set('jobId', jobId);
    profileSlug && searchParams.set('profileSlug', profileSlug);
    orderMonth && searchParams.set('orderMonth', orderMonth);
    paymentMonth && searchParams.set('paymentMonth', paymentMonth);
    paymentStatus && searchParams.set('paymentStatus', paymentStatus);
    projectTypeId && searchParams.set('projectTypeId', projectTypeId);

    return fetchService.authenticatedGet(`/v2/billings?${searchParams.toString()}`);
  },
  cancelBill: (id) => {
    return fetchService.authenticatedDelete(`/v2/billings/${id}?isCancel=true`, {
      isCancel: true
    });
  }
}
