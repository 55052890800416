import React from 'react';
import {  TextAreaForm } from '../../forms';
import SuperInput from './SuperInput.component';
import SuperSearchUsersProfilesInputComponent from '../../forms/multiSelects/SuperSearchUsersProfilesInputComponent';
import utilsService from '../../../../services/utils.service';
import PredefinedInboxMessagesComponent from '../sendConversation/PredefinedInboxMessages.component';
import SelectMessagingTemplateComponent from '../../selectMessagingTemplate/SelectMessagingTemplate.component';

const SmsMessaging = props => {
    const {
        chooseTemplateEvent, inputChange,
        profiles, removeSelection, smsBody, searchTo, selectedPersons, replyTo, preDefinedTextHandle, conType, isDisabled
    } = props;

    const countSmses = () => {
        return (parseInt(smsBody.length / 70) + 1);
    };

    const replyToData = getReplyToData(replyTo, profiles);

    return (
        <div className='modal-sms-messaging new-message-super-search-input-fancy'>
            <div className="email-top-sms">
                <SelectMessagingTemplateComponent
                    chooseTemplateEvent={chooseTemplateEvent}
                    conType={conType}
                />

                {conType !== 3 && <PredefinedInboxMessagesComponent
                    preDefinedTextHandle={preDefinedTextHandle}
                />}
            </div>
            {searchTo && !replyTo && (
                <SuperSearchUsersProfilesInputComponent
                    selectedPersons={selectedPersons}
                    titleType='phone'
                    isCVNeeded={false}
                />
            )}

            {!searchTo && !replyTo && (
                <SuperInput
                    profiles={profiles}
                    removeSelection={removeSelection}
                />
            )}

            {replyTo && (
                <div className="new-message-reply-to-static">
                    To: <span>{replyToData.name} ({replyToData.phone})</span>
                </div>
            )}

            <TextAreaForm
                value={smsBody}
                wrapperClasses='sms-body'
                type={'text'}
                disabled={isDisabled}
                inputChange={e => inputChange(e, 'smsBody')}
            />
            <div className="sms-counter">
                <div className='sms-counter-item'>
                    {smsBody.length} <span>Characters</span>
                </div>
                <div className='sms-counter-item'>{countSmses()} <span>SMS</span></div>
            </div>
        </div>
    );
};

const getReplyToData = (replyTo, profiles) => {
    const temp = {};

    if (replyTo && profiles) {
        const profile = profiles[0];
        temp.name = utilsService.getDisplayName(profile);
        temp.email = utilsService.getPrimaryValue(profile.emails, 'email');
        temp.phone = utilsService.getPrimaryValue(profile.phones, 'phone');
    }

    return temp;
};

export default SmsMessaging;
