import {
  AnchorButton,
  Button,
  Checkbox,
  FocusStyleManager,
  Icon,
  InputGroup,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@blueprintjs/core';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import { ToggleButton, useIsMobile } from '@brightsource/brightsource-ui-lib';
import React, { useEffect, useMemo, useState } from 'react';
import utilsService from '../../../services/utils.service';
import messagingStore from '../../../stores/messaging.store';
import userStore from '../../../stores/user.store';
import DefaultImageComponent from '../DefaultImage.component';
import LoaderComponent from '../loader/Loader.component';
import { SearchEmpty, SearchNormal } from '../svg';

import './selectRecruiterDropdown.scss';
FocusStyleManager.onlyShowFocusOnTabs();

function SelectRecruiterDropdown(props) {
  const isMobile = useIsMobile();

  const [selectedSlugList, setSelectedSlugList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [assignedItem, setAssignedItem] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showOffline, setShowOffline] = useState(false);

  const { recruiters, categories, onFilterChange, selectedItem, isAssignedTo = false } = props;

  const [
    userList,
    currentUser,
    onlineRecruiters,
    selectedSlugs,
    recruitersCount,
    conversationStatusFilterSelect,
  ] = [
    userStore.users,
    userStore.user,
    userStore.onlineRecruiters,
    messagingStore.conversationInboxSelect,
    messagingStore.recruitersConversationsCount,
    messagingStore.conversationStatusFilterSelect,
  ];

  useEffect(() => {
    onSyncData();
  }, [selectedSlugs, conversationStatusFilterSelect, selectedItem]);

  const onSyncData = () => {
    setSelectedSlugList(selectedSlugs === 0 ? [currentUser.slug] : [...selectedSlugs]);
    setAssignedItem(selectedItem);

    if (conversationStatusFilterSelect.id === 6666) {
      setSelectedCategory(conversationStatusFilterSelect.subId);
    } else {
      setSelectedCategory(-1);
    }
  };

  const onRecruiterSelect = (recruiter) => {
    if (isAssignedTo) {
      setAssignedItem(recruiter.selected ? null : recruiter);
    }

    if (recruiter.selected) {
      const newSelectedSlugList = selectedSlugList.filter((slug) => recruiter.slug !== slug);
      setSelectedSlugList(newSelectedSlugList);
    } else {
      setSelectedSlugList((prevList) => [...prevList, recruiter.slug]);
    }
  };

  const onCategorySelect = (categoryId) => {
    if (categoryId === selectedCategory) {
      setSelectedCategory(-1);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  const onApply = () => {
    onFilterChange(selectedSlugList, selectedCategory, assignedItem);
  };

  const onClearAll = () => {
    onFilterChange([], -1);
  };

  const recruiterList = useMemo(() => {
    const list = recruiters.map((item) => {
      const name = utilsService.getDisplayName(item);
      const profileImage = utilsService.getProfileImage(item);
      const counter = !isAssignedTo && recruitersCount[item._id];
      const status = onlineRecruiters[item._id] ? 'online-status' : 'offline-status';
      const isAssignedRecruiter = assignedItem ? assignedItem.slug === item.slug : false;

      return {
        id: item._id,
        slug: item.slug,
        selected: isAssignedTo ? isAssignedRecruiter : selectedSlugList.includes(item.slug),
        displayName: item._id === currentUser._id ? ' Me' : name,
        counter: counter > 99 ? '99+' : counter,
        statusClassName: name === 'system ' ? '' : status,
        profileImage,
        isOffline: !onlineRecruiters[item._id],
      };
    });

    const filteredList = list.filter((item) => {
      const recruiterName = item.displayName.toLowerCase();
      const condition1 = recruiterName.includes(searchValue.toLowerCase());
      const condition2 = showOffline ? item.isOffline : true;

      return condition1 && condition2;
    });

    const sortedList = utilsService.sortRecruiterList(filteredList, isAssignedTo);

    // put selected items to top
    if (!isAssignedTo && selectedSlugs && selectedSlugs.length > 0) {
      selectedSlugs.forEach((slug) =>
        utilsService.putItemToTop(sortedList, (item) => item.slug === slug)
      );
    }

    if (isAssignedTo && selectedItem) {
      utilsService.putItemToTop(sortedList, (item) => item.slug === selectedItem.slug);
    }

    return sortedList;
  }, [
    recruiters,
    currentUser,
    recruitersCount,
    selectedSlugList,
    onlineRecruiters,
    searchValue,
    selectedItem,
    isAssignedTo,
    selectedSlugs,
    assignedItem,
    showOffline,
  ]);

  const categoryList = useMemo(() => {
    if (!categories) return [];

    const filteredList = Object.values(categories).filter((item) => {
      const categoryName = item.value.toLowerCase();
      return categoryName.includes(searchValue.toLowerCase());
    });

    return filteredList;
  }, [categories, searchValue]);

  // if not selected or selected multi recruiters -> selectedRecruiter = undefined
  const [selectedRecruiterLabel, selectedRecruiter] = useMemo(() => {
    if (
      selectedSlugs === 0 ||
      (selectedSlugs.length === 1 && selectedSlugs[0] === currentUser.slug)
    )
      return ['My Inbox', currentUser];

    if (selectedSlugs.length === 0) return ['All'];

    if (selectedSlugs.length === 1) {
      const foundUser = userList[selectedSlugs[0]];
      const recruiter = foundUser || currentUser;
      return [utilsService.getDisplayName(recruiter), recruiter];
    }

    return ['Multiple'];
  }, [selectedSlugs, currentUser, userList]);

  const selectedRecruiterIcon = useMemo(() => {
    // all or multiple
    if (!selectedRecruiter) {
      return (
        <div className="select-recruiter-circle">
          {selectedSlugs && selectedSlugs.length > 1 && <span>{selectedSlugs.length}</span>}
        </div>
      );
    }

    return (
      <DefaultImageComponent
        url={utilsService.getProfileImage(selectedRecruiter)}
        alt={utilsService.getDisplayName(selectedRecruiter)}
        profileName={utilsService.getDisplayName(selectedRecruiter)}
      />
    );
  }, [selectedRecruiter, selectedSlugs]);

  const [recruiterLabel, recruiterIcon] = useMemo(() => {
    if (isAssignedTo)
      return [
        selectedItem ? utilsService.getDisplayName(selectedItem) : 'No assignment',
        <strong>Assigned to:</strong>,
      ];

    return [selectedRecruiterLabel, selectedRecruiterIcon];
  }, [selectedRecruiterLabel, selectedRecruiterIcon, isAssignedTo, selectedItem]);

  const searchInput = () => (
    <InputGroup
      className="select-recruiter-search"
      placeholder="Search"
      rightElement={
        <>
          {Boolean(searchValue) && (
            <Icon
              icon="cross"
              color="#A4A4A4"
              size={isAssignedTo ? 16 : 20}
              onClick={() => setSearchValue('')}
            />
          )}
          <SearchNormal />
        </>
      }
      value={searchValue}
      onChange={(event) => setSearchValue(event.target.value)}
    />
  );

  const emptyList = (list) =>
    Boolean(searchValue) &&
    !list.length && (
      <div className="select-recruiter-empty">
        <SearchEmpty />
        <span>
          No results for "<strong>{searchValue}</strong>"
        </span>
      </div>
    );

  const mainContent = () => (
    <Tabs onChange={() => setSearchValue('')}>
      <Tab
        title={!isAssignedTo && 'Personal'}
        id="personal"
        panel={
          <>
            {searchInput()}
            {emptyList(recruiterList)}

            {recruiterList.length > 0 && (
              <Menu>
                {recruiterList.map((recruiter) => (
                  <MenuItem
                    key={recruiter.id}
                    className={recruiter.selected ? 'selected' : undefined}
                    text={recruiter.displayName}
                    label={recruiter.counter || ''}
                    onClick={() => onRecruiterSelect(recruiter)}
                    icon={
                      <div className="menu-item-left">
                        <Checkbox
                          checked={recruiter.selected}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <DefaultImageComponent
                          url={recruiter.profileImage}
                          alt={recruiter.displayName}
                          profileName={recruiter.displayName}
                          classes={recruiter.statusClassName}
                        />
                      </div>
                    }
                  />
                ))}
              </Menu>
            )}
          </>
        }
      />

      {categories && (
        <Tab
          title="Groups"
          id="groups"
          panel={
            <>
              {searchInput()}
              {emptyList(categoryList)}

              {categoryList.length > 0 && (
                <Menu>
                  {categoryList.map((category) => (
                    <MenuItem
                      key={category.elId}
                      text={category.value}
                      className={selectedCategory === category.elId ? 'selected' : undefined}
                      onClick={() => onCategorySelect(category.elId)}
                      icon={
                        <Checkbox
                          checked={selectedCategory === category.elId}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Menu>
              )}
            </>
          }
        />
      )}
    </Tabs>
  );

  const clearAllButton = (label = 'Clear', isOpen = true) => {
    const isDisabled =
      !recruiterList.length || (!selectedSlugList.length && selectedCategory === -1);

    // if (isDisabled && hideOnDisabled) return null;

    return (
      <AnchorButton
        minimal
        disabled={isDisabled}
        className={Classes.POPOVER2_DISMISS.concat(' clear-all')}
        onClick={(e) => {
          !isOpen && e.stopPropagation();
          onClearAll();
        }}
      >
        {label}
      </AnchorButton>
    );
  };

  const [dropdownClassName, popoverClassName] = [
    `select-recruiter-dropdown ${isAssignedTo ? 'assigned-to' : ''}`,
    `select-recruiter-popover ${isAssignedTo ? 'assigned-to' : ''}`,
  ];

  const handleOpened = () => {
    if (!isMobile) return;
    document.querySelector('body').style.overflow = 'hidden';
  };

  return (
    <Popover2
      className={dropdownClassName}
      popoverClassName={popoverClassName}
      placement="bottom-start"
      onClosed={() => {
        onSyncData();
        setSearchValue('');

        document.querySelector('body').style.overflow = 'auto';
      }}
      onOpened={handleOpened}
      modifiers={{
        arrow: { enabled: false },
        offset: {
          enabled: true,
          options: { offset: [0, isAssignedTo ? 5 : 10] },
        },
      }}
      content={
        <div className="select-recruiter-content">
          {!isAssignedTo && (
            <div className="select-recruiter-header">
              <span>Filters</span>

              {recruiters.length > 0 && <ToggleButton label='Offline' checked={showOffline} onChange={() => setShowOffline(x => !x)} />}
              
              <div className='select-recruiter-actions'>
                <AnchorButton
                  minimal
                  onClick={() => setSelectedSlugList(recruiterList.map((rec) => rec.slug))}
                >
                  Select All
                </AnchorButton>

                {clearAllButton()}
              </div>
            </div>
          )}

          {recruiters.length > 0 ? (
            mainContent()
          ) : (
            <div className="select-recruiter-loading">
              <LoaderComponent />
            </div>
          )}

          {recruiters.length > 0 && (
            <div className="select-recruiter-bottom">
              <Button minimal fill className={Classes.POPOVER2_DISMISS} onClick={onApply}>
                Apply
              </Button>
            </div>
          )}
        </div>
      }
      renderTarget={({ isOpen, ref, ...targetProps }) => (
        <Button
          {...targetProps}
          elementRef={ref}
          alignText="left"
          text={recruiterLabel}
          icon={recruiterIcon}
          rightIcon={
            <>
              {!isAssignedTo && !isOpen && clearAllButton('Clear All', isOpen)}
              <Icon icon="chevron-down" size={isAssignedTo ? 12 : 16} />
            </>
          }
        />
      )}
    />
  );
}

export default SelectRecruiterDropdown;
