import React from 'react';
import PropTypes from 'prop-types';
import './dashboardStatusItem.scss';
import { observer } from "mobx-react"
import { Link } from 'react-router-dom';


const DashboardStatusItem = observer((props) => {

    const { linkTo, hasTwoValues, isClicked } = props

    const hasDot = (name) => {
        switch (name) {
            case 'New Jobs':
            case 'New':
            case 'Suggestions':
                return true;
            default:
                return false;
        }
    }


    const getNumberClass = (name) => {
        let className = '';
        switch (name) {
            case 'New Jobs':
            case 'New':
            case 'Suggestions':
                className = 'blue1'
                break;
            case 'Without process':
            case 'On Hold':
            case 'Closed Jobs':
            case 'Decline':
            case 'Rejected':
                className = 'red'
                break;
            case 'Hired':
                className = 'green'
                break;
            default:
        }
        if (props.number === null) {
            className = className + ' button loading';
        }
        return className;
    }

    const getNameClass = (name) => {
        switch (name) {
            case 'New Jobs':
            case 'New':
            case 'Suggestions':
                return 'blue';
            default:
                return '';
        }
    }

    const OneValue = () => {
        return (
            isClicked ?
                <h1 className={getNumberClass(props.name)}>
                    {hasDot(props.name) && <div className="dot" />}
                    <Link to={linkTo}>
                        {props.number}
                    </Link>
                </h1>
                :
                <h1 className={getNumberClass(props.name)}>
                    {hasDot(props.name) && <div className="dot" />}
                    {props.number}
                </h1>
        )
    }

    const TwoValues = () => {
        return (
            isClicked ?
                <Link to={linkTo}>
                    <div className='two-values-wrapper'>
                        <div className="number-top-wrapper">
                            <h3 className="top2">{props.number}</h3>
                        </div>
                        <span className="slash" />
                        <div className="number-bottom-wrapper">
                            <h3 className="bottom2">{props.secondNumber}</h3>
                        </div>
                    </div>
                </Link>
                :
                <div className='two-values-wrapper'>
                    <div className="number-top-wrapper">
                        <h3 className="top2">{props.number}</h3>
                    </div>
                    <span className="slash" />
                    <div className="number-bottom-wrapper">
                        <h3 className="bottom2">{props.secondNumber}</h3>
                    </div>
                </div>
        )
    }

    return (
        <div className='dashboard-status-item-wrapper'>
            {hasTwoValues ?
                <TwoValues />
                :
                <OneValue />}
            <h2 className={getNameClass(props.name)} dangerouslySetInnerHTML={{ __html: props.name }}>
            </h2>
        </div>

    )
});

DashboardStatusItem.propTypes = {

};

DashboardStatusItem.defaultProps = {
    hasDot: false,
    linkTo: '',
    isClicked: true
};

export default DashboardStatusItem
