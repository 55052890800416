import React, { PureComponent } from 'react';
import utilsService from '../../../../services/utils.service';
import { MarkIcon, EditIcon, ListIcon, MailIcon2 } from '../../../components/svg';
import DefaultImageComponent from '../../../components/DefaultImage.component';
import MoreMenuActionComponent from '../../../components/processActions/MoreMenuAction.component';
import FitComponent from '../../../components/fit/Fit.component';
import dayjs from 'dayjs';
import StarsComponent from '../../../components/stars/Stars.component';
import profileStore from '../../../../stores/profile.store';
import userStore from '../../../../stores/user.store';
import { Tab, Tabs } from '../../../components/Tabs.component';
import Notes from './notes/Notes.component';
import ContactWithCoordinator from '../../../components/ContactWithCoordinator.component'
import ContactDetailsModal from '../../../components/modalComponents/ContactDetailsModal';
import EmployerInfoModalComponent from '../../../components/modalComponents/EmployerInfoModal.component';
import ShowInfo from './ShowInfo/ShowInfo.component';
import metaDataStore from '../../../../stores/meta-data.store';
import Loader from '../../../components/loader/Loader.component';
import { Link } from 'react-router-dom';
import companyStore from '../../../../stores/company.store';
import InterviewInfoModalComponent from '../../../components/modalComponents/InterviewInfoModal.component';
import StatusBarComponent from '../../../components/statusBar/StatusBar.component';
import PrimaryNote from '../../../components/primarynote/PrimaryNote.component';
import notesStore from '../../../../stores/notes.store';
import './CandidateOverview.component.2.scss'
import { observer } from 'mobx-react';
import messagingStore from '../../../../stores/messaging.store';
import { Icon } from '@blueprintjs/core';
import { LinkedinLinksSection } from "./CandidateOverview/LinkedinLinksSection/LinkedinLinksSection";
import { getLatestInterview } from '../../../../entities/profiles';
import { LocationIcon } from "../../../components/svg";

@observer
class CandidateOverviewComponent2 extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            contactsOpen: false,
            contactData: null,
            loader: false,
            isEmploymentInfoModalOpen: false,
            isInterviewInfoModalOpen: false,
            isContactDetailsModalOpen: false
        };

        this.rolesParent = {};
        this._isMounted = false;

    }

    static getAddress(profile, key) {
        let res = '';
        if (profile && profile.locationData) {
            res = profile.locationData[key];
        }

        return res;
    }

    static getDesiredLocationAddress(profile) {
        let res = '';
        if (profile && profile.desiredLocations) {
            res = profile.desiredLocations.map(dl => dl.value).join(', ');
        }

        return res;
    }

    getIndustries(industires = []) {
        const count = industires.length;
        return industires.map((industry, index) => utilsService.getObjValue(industry) + `${count - 1 !== index ? ', ' : ''}`);
    }

    getLanguages(profile) {
        if (!profile) return [];

        const hasProfileLanguages = Array.isArray(profile.languages) && profile.languages.length > 0;
        const linkedInLanguages = profile.linkedInData?.languages || [];
        const langs = hasProfileLanguages ? profile.languages : linkedInLanguages;
        const count = langs.length;
        const proficiencyMap = {
            1: "Elementary",
            2: "Limited working",
            3: "Professional working",
            4: "Full professional",
            5: "Native or Bilingual",
        };

        return langs.map((lang, index) => {
            const languageName = hasProfileLanguages
              ? utilsService.getObjValue(lang.language, 'name')
              : utilsService.getObjValue(lang, 'languageName');
              const languageLevel = linkedInLanguages[index]?.level
              const languageProficiency = proficiencyMap[languageLevel] || "";

            return languageName  + `${languageProficiency ? ` (${languageProficiency})` : ''}` + `${count - 1 !== index ? ', ' : ''}`;
        })
    }

    getSkills(skills, mandatorySkills) {
        return utilsService.buildMatchList(skills, mandatorySkills);
    }

    getBirthday(birthday) {
        let res = '';
        if (birthday) {
            const now = Date.now();
            const nowInYears = dayjs(now).format('YYYY');
            const birthInYears = dayjs(birthday).format('YYYY');
            res = Number(nowInYears) - Number(birthInYears);
        }
        return res;
    }

    normalizeContactsData = data => {
        const { contactsOpen } = this.state;
        if (data) {
            const dataTemp = this.generateContactsData(data);
            this.setState({
                contactsOpen: !contactsOpen,
                allContactsData: data,
                contactData: dataTemp,
            });
        } else {
            this.setState({
                contactData: null,
                contactsOpen: !contactsOpen
            });
        }
    };

    generateContactsData(data) {
        return {
            email: utilsService.getPrimaryValue(data.emails, 'email'),
            phone: utilsService.getPrimaryValue(data.phones),
            socialLinks: data.socialLinks
        };
    }

    skills = () => {
        const { job, profile } = this.props;
        let res = '';
        if (job) {
            res = utilsService.getMatchSkills(this.getSkills(profile.skills, job.skills));
        } else {
            const skills = utilsService.filterSkillsFromMandatorySkills(profile.skills, profile.primarySkills);
            const primarySkillsCss = utilsService.getSkills(profile.primarySkills, true);
            const skillsCss = utilsService.getSkills(skills, false);
            res = primarySkillsCss.concat(skillsCss);
        }

        return res;
    };

    getDataForMoreMenu() {
        const { profile } = this.props;
        let temp = [];
        temp = [
            { modal: 'isModalOpen_AddToMatches', text: 'Add to Matches', icon: 'plus', classes: 'plus' }, //
            { modal: 'isModalOpen_SendToCompany', text: 'Send CV', icon: 'sendTo', classes: 'send-cv' },
            { modal: 'isModalOpen_mark_as_withdrawn', text: 'Mark As Unavailable', icon: 'withdrawn' }, //
            { modal: 'isModalOpen_newMessage', text: 'Send Message', icon: 'mail2', classes: 'mail' },
            { modal: 'isModalOpen_sendSuggest', text: 'Send Suggest', icon: 'sendSuggest' },

            { modal: 'isModalOpen_Share', text: 'Share Profile', icon: 'share', classes: 'share-profile' },
            { modal: 'isModalOpen_Share', text: 'Invite As Employer', icon: 'inviteAsEmployer', classes: 'invite-as-employer' }, //       
            {
                modal: 'isModalOpen_block_from_messages', text: `${profile.settings && profile.settings.isBlockFromEmails ? 'Unblock' : 'Block'} Messages`,
                icon: 'blockFromEmails', classes: 'block-from-messages'
            },
            { modal: 'isModalOpen_Share', text: 'Print CV', icon: 'printCV', classes: 'print-cv' },
        ];

        const showSendTalentInviteOption = [1,5].includes(profile.talent?.status) &&
            (utilsService.isScrapedLastHalfYear(profile) || profile.talent.wishlist?.inviteRequirements?.linkedinNotRequired);
       
        if (showSendTalentInviteOption) {
            temp.push({ modal: 'isModalOpen_SendTalentInvite', text: 'Invite As Talent', icon: 'inviteAsTalent', classes: 'invite-as-talent' })

            // if on pending and has claim token
            if (profile.talent?.status === 1 && profile.talent?.claimToken) {
                temp.push({ modal: 'isModalOpen_ShareTalentInvite', text: 'Share Talent Invite', icon: 'share', classes: 'share-profile' });
            }
        }
        return temp;
    }

    generateBackGroundData(profile) {
        const backgroundDataArray = [];


        const birthday = this.getBirthday(profile.birthday);
        if (birthday) {
            backgroundDataArray.push({ legend: 'Age', value: birthday });
        }

        const city = CandidateOverviewComponent2.getAddress(profile, 'city');
        const area = CandidateOverviewComponent2.getAddress(profile, 'adminName');
        const country = CandidateOverviewComponent2.getAddress(profile, 'country');
        if (city && country) {
            backgroundDataArray.push({
                legend: 'Location',
                value: area ? `${city}, ${area}, ${country}` : `${city}, ${country}`
            })
        }

        const desiredLocation = CandidateOverviewComponent2.getDesiredLocationAddress(profile);
        if (desiredLocation) {
            backgroundDataArray.push({
                legend: 'Desired Location',
                value: `${desiredLocation}`
            })
        } else if (area) {
            backgroundDataArray.push({
                legend: 'Desired Location',
                value: `${area}`
            })
        }

        backgroundDataArray.push({ legend: 'BsId', value: profile.bsId });

        const industry = this.getIndustries(profile.industries);
        if (industry && industry.length > 0) {
            backgroundDataArray.push({ legend: 'Industry', value: industry })
        }

        const seniority = utilsService.getObjValue(profile.seniority);
        if (seniority) {
            backgroundDataArray.push({ legend: 'Seniority level', value: seniority })
        }

        const roles = utilsService.getRoles(profile.roles);
        if (roles && roles.length > 0) {
            backgroundDataArray.push({ legend: 'Roles', value: roles })
        }

        const education = profile?.linkedInData?.educationList?.length > 0 ? profile.linkedInData.educationList[0].degreeName : '';
        if (education) {
            backgroundDataArray.push({
                legend: 'Education',
                value: education
            })
        }

        const school = profile?.linkedInData?.educationList?.length > 0 ? profile.linkedInData.educationList[0].educationInstitution : '';
        if (school) {
            backgroundDataArray.push({
                legend: 'School',
                value: school
            })
        }

        const languages = this.getLanguages(profile);
        if (languages && languages.length > 0) {
            backgroundDataArray.push({
                legend: 'Languages',
                value: languages
            })
        }

        const skills = this.skills();
        if (skills && skills.length > 0) {
            backgroundDataArray.push({
                legend: 'Skills',
                value: skills
            })
        }

        return backgroundDataArray;
    }

    bringMetaDataCategories() {
        const categories = [
            'rolesParent',
            'roles'

        ];
        return categories.map(cat => {
            return metaDataStore.getCategory(cat, true);
        });

    }

    async componentDidMount() {
        const { profile, process, job } = this.props;
        this._isMounted = true;
        const resp = await profileStore.getContactInfo(profile, process, job);
        if (this._isMounted) {
            this.normalizeContactsData(resp);

            const promises = this.bringMetaDataCategories();
            if (profile.companySlug && profile.companySlug.length > 0) {
                promises.push(companyStore.getCompanyBySlug(profile.companySlug))
            }
            await Promise.all(promises);
            const { rolesParent } = metaDataStore.categories;

            this.rolesParent = Object.values(rolesParent);
            const company = companyStore.companies[profile.companySlug];
            const promisses = [];
            promisses.push(notesStore.getNotes(profile._id, 'profile'));
            if (company) {
                promisses.push(companyStore.getCompanyManagement(company._id));
            }
            await Promise.all(promisses);
            notesStore.buildData(profile._id, 'profile');

            this.setState({ loader: false, company: company });
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const prevProfile = prevProps.profile;
        const { isProfileSearchResults, profile } = this.props;

        if (isProfileSearchResults && (prevProfile._id !== profile._id)) {
            const resp = await profileStore.getContactInfo(profile);
            this.normalizeContactsData(resp);
            if (profile.companySlug) {
                await companyStore.getCompanyBySlug(profile.companySlug);
                const company = companyStore.companies[profile.companySlug];
                this.setState({ company: company });
            }
            else {
                setTimeout(() => {
                    this.setState({ company: null });
                }, 200);
            }
        }
        else if (prevProfile.companySlug !== profile.companySlug) {
            if (profile.companySlug) {
                await companyStore.getCompanyBySlug(profile.companySlug);
                const company = companyStore.companies[profile.companySlug];
                if (company) {
                    await companyStore.getCompanyManagement(company._id);
                    this.setState({ company: company });
                }
            }
            else {
                setTimeout(() => {
                    this.setState({ company: null });
                }, 200);
            }

        }
        else if (prevProfile.isAvailable !== profile.isAvailable) {
            await profileStore.getProfileById(profile._id, true);
        }

        if (prevProfile._id !== profile._id) {
            await notesStore.getNotes(profile._id, 'profile');
            notesStore.buildData(profile._id, 'profile');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { profile, process, job, disableActions, editIconClick, messageIconClick, showEditIcon, withScroll, newTabOpen } = this.props;
        const { loader, contactsOpen, contactData, isContactDetailsModalOpen, isEmploymentInfoModalOpen, isInterviewInfoModalOpen, allContactsData, company } = this.state;
        const backgroundData = this.generateBackGroundData(profile);
        const isExposed = utilsService.checkIfExposed(process);
        const profileName = utilsService.getDisplayName(profile);
        const isRecruiterUser = utilsService.isRecruiter(userStore.user);

        const location = profile.linkedInData?.locationData?.cityId
          ? profile.linkedInData?.locationData?.city +
            ", " +
            profile.linkedInData?.locationData.country
          : profile.linkedInData?.location?.address_raw;

        let shouldHideMoreDetails = false;
        if (process) {
            shouldHideMoreDetails = isRecruiterUser && utilsService.getStatusByEnum(process.status) !== 'New';
        }
        const education = profile.linkedInData?.educationList?.length > 0 && profile.linkedInData?.educationList[0];

        let wrapperClasses = 'profile-info-wrapper';
        if (withScroll) {
            wrapperClasses += ' custom-scroll';
        }

        const isUser = !!profile.userType;

        const notes = notesStore.notesToShow;
        const primaryNote = notes.find(note => note.eventTypeId === 1);
        const companyManagment = company && companyStore.companyManagement[company._id];
        const statusActionFailed = profile.talent?.statusActionSucceeded === false && profile.talent.status === 4 // if profilng and failed
        const latestInterview = getLatestInterview(profile.summaries);

        return (
            <div className="overviewWrapper custom-scroll">
                {loader ? <Loader />
                    :
                    <div className="overviewContainer main-details shadow4 profile-overview">
                        <div className='profile-overview-top'>

                            {!!profile.talent?.status && <div className='invite-talent-status'>
                                <StatusBarComponent text={utilsService.getInviteTalentStatus(profile?.talent?.status, profile)} isFailed={statusActionFailed} />
                            </div>}

                            <Icon 
                                icon='chevron-left' 
                                size={20} 
                                color='#007dff' 
                                className='back-icon'
                                onClick={() => messagingStore.activeInboxMode = messagingStore.inboxMode.MAIN}
                            />

                            {messageIconClick && <div className="message-item" onClick={messageIconClick}>
                                <MailIcon2 />
                            </div>}

                            {isRecruiterUser && showEditIcon && (
                                <div className="edit-item" onClick={editIconClick}>
                                    <EditIcon />
                                </div>)}

                            {!disableActions && (
                                <MoreMenuActionComponent
                                    process={process}
                                    menu={this.getDataForMoreMenu()}
                                    actionEvent={(process, modal, e) => this.props.actionEventHandler(process, modal, null, e)}
                                    profile={profile}
                                />
                            )}


                            {process && (
                                <div className="progress-and-fit">
                                    <FitComponent
                                        fit={process.fit}
                                        status={process.status}
                                    />
                                </div>
                            )}

                            <div className="profile-display">
                                <div className="profile-image">
                                    <DefaultImageComponent
                                        url={utilsService.getProfileImage(profile)}
                                        alt={profileName}
                                        profileName={profileName}
                                        isExposed={isExposed}
                                        updatedAt={profile?.updatedAt}
                                    />
                                </div>
                            </div>

                            <h1 className='profile-name-capitalized'>
                                {isUser && (
                                    profileName
                                )}
                                {!isUser && (
                                    <Link className='black-link'
                                        target={newTabOpen ? '_blank' : null}
                                        to={`/profile/${profile.slug}`}
                                    >
                                        {profileName}
                                    </Link>
                                )}
                            </h1>
                            <h2 className='profile-position'>{utilsService.getProfilePosition(profile)}</h2>
                            <div className={latestInterview.summary ? 'company-name with-summary' : 'company-name'}>
                                {latestInterview.summary && (
                                    <div
                                        onClick={() => this.setState({ isInterviewInfoModalOpen: true })}
                                        className="summary-more-info">
                                        <ListIcon />
                                    </div>
                                )}
                                <ShowInfo label={company ? company.name : ''}
                                    markLabel={companyManagment && companyManagment.sapCode !== 0}
                                    shouldHideMoreDetails={true} link={company && `/company/${company.slug}`}
                                    onClick={() => this.setState({ isEmploymentInfoModalOpen: true })}
                                />
                            </div>
                            
                            <div className='location-and-status'>
                                <StatusBarComponent text={utilsService.getCandidateAvailabilityLabel(profile)} />
                                {location && <span className='location'> <LocationIcon/> {location || ''}</span>}
                            </div>

                            {education && <h2 className="education">
                                {education.degreeType}
                                {education.degreeType ? ', ' : ''}
                                {education.degreeName}
                            </h2>}
                            
                            {profile.isDiscrete && <div className="claimed">
                                <MarkIcon />
                                <label>Discreet Search</label>
                            </div>}
                        </div>
                        <div className={wrapperClasses}>
                            {isRecruiterUser && (
                                <DetailsComponent
                                    shouldHideMoreDetails={shouldHideMoreDetails}
                                    contactsOpen={contactsOpen}
                                    isRecruiterUser={isRecruiterUser}
                                    contactsData={contactData}
                                    getContactDetails={this.getContactDetails}
                                    profile={profile}
                                    backgroundData={backgroundData}
                                    job={job}
                                    primaryNote={primaryNote}
                                    showInfoClick={() => this.setState({ isContactDetailsModalOpen: true })}
                                />
                            )}

                            {!isRecruiterUser && (
                                <div className='blue-tabs overview-tabs'>
                                    <Tabs>
                                        <Tab label='Details'>
                                            <DetailsComponent
                                                process={process}
                                                contactsOpen={contactsOpen}
                                                isRecruiterUser={isRecruiterUser}
                                                contactsData={isRecruiterUser ? this.generateContactsData(profile) : contactData}
                                                getContactDetails={this.getContactDetails}
                                                profile={profile}
                                                changeRankEvent={this.changeRankEvent}
                                                backgroundData={backgroundData}
                                                showInfoClick={() => this.setState({ isContactDetailsModalOpen: true })}
                                            />

                                        </Tab>
                                        <Tab label='Discussion'>
                                            <div className="notes-component">
                                                <Notes
                                                    process={process}
                                                    job={job}
                                                    profile={profile}
                                                />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            )}


                            {isEmploymentInfoModalOpen && (
                                <EmployerInfoModalComponent
                                    closeEvent={() => this.setState({ isEmploymentInfoModalOpen: false })}
                                    data={profile.employment}
                                />
                            )}

                            {isInterviewInfoModalOpen && (
                                <InterviewInfoModalComponent
                                    closeEvent={() => this.setState({ isInterviewInfoModalOpen: false })}
                                    data={profile}
                                />
                            )}


                            {isContactDetailsModalOpen && (
                                <ContactDetailsModal
                                    closeEvent={() => this.setState({ isContactDetailsModalOpen: false })}
                                    data={allContactsData}
                                />
                            )}
                        </div>

                    </div>}
            </div>
        );
    }
}

export default CandidateOverviewComponent2;

const ContactsComponent = props => {
    const { data, showInfoClick, shouldHideMoreDetails, profile } = props;
    const isRecruiterUser = utilsService.isRecruiter(userStore.user);

    return (
        <div className={'contant-info open'}>
            {data && (
                <div className="bottom">
                    <div className={"background-info " + (isRecruiterUser ? "top1 " : "hide-border")}>
                        <div className='background-info-item'>
                            <div className="grid-view">
                                <legend>Mobile</legend>
                                {profile.settings && profile.settings.isBlockFromEmails &&
                                    <div className="status red" />}
                            </div>
                            <ShowInfo label={data.phone} onClick={showInfoClick} shouldHideMoreDetails={shouldHideMoreDetails} />
                        </div>
                        <div className='background-info-item'>
                            <div className="grid-view">
                                <legend>E-mail</legend>
                                {profile.settings && profile.settings.isBlockFromEmails &&
                                    <div className="status red" />}
                            </div>
                            <ShowInfo label={data.email} onClick={showInfoClick} shouldHideMoreDetails={shouldHideMoreDetails} />
                        </div>
                        {data.socialLinks && data.socialLinks.length > 0 && (
                            <div className='background-info-item'>
                                <legend>Social</legend>
                                <div>
                                    <LinkedinLinksSection links={data.socialLinks} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const DetailsComponent = props => {
    const {
        contactsOpen, isRecruiterUser, contactsData, profile, backgroundData, getContactDetails,
        showInfoClick, shouldHideMoreDetails, primaryNote
    } = props;

    const company = companyStore.companies[profile.companySlug];
    return (
        <React.Fragment>
            <ContactsComponent
                shouldHideMoreDetails={shouldHideMoreDetails}
                open={contactsOpen}
                data={contactsData}
                isRecruiter={isRecruiterUser}
                getContactDetails={getContactDetails}
                showInfoClick={showInfoClick}
                profile={profile}
            />
            <div className="background-info">
                <div className='background-info-item'>
                    <legend>Ranking</legend>
                    <div>
                        <StarsComponent
                            rank={profile.rankingFromRecruiter}
                        />
                    </div>
                </div>
                <div className='background-info-item'>
                    <legend>Salary</legend>
                    <div>{profile.desiredSalary ? `₪${profile.desiredSalary}` : `N/A`}</div>
                </div>
            </div>
            <div className="background-info">
                {utilsService.renderBackgroundData(backgroundData)}
            </div>
            <div className="background-info">
                <div className='background-info-item'>
                    <legend>{'Company size'}</legend>
                    <div>{company && company.size ? company.size.value : 'N/A'}</div>
                </div>
                <div className='background-info-item'>
                    <legend>Position Type </legend>
                    <div>{utilsService.joinByComma(profile.positionTypes, 'value') || 'N/A'}</div>
                </div>
            </div>

            <PrimaryNote
                wrapperClass={'notes-wrapper-class'}
                note={primaryNote}
            />

            <div className="background-info no-margin">

                <ContactWithCoordinator type={"recruiter"} name={utilsService.getRecruitersName(profile)} image={utilsService.getRecruiterInChargeProfileImage(profile)} />

            </div>
        </React.Fragment>
    );
};
