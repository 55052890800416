import React, {PureComponent} from 'react';
import {List, WindowScroller, AutoSizer} from 'react-virtualized';
import ApplicantGridItem from './ApplicantGridItem.component';
import processStore from '../../../stores/process.store';
import {FilterIcon} from '../svg';
import CandidateForFilterComponent from './components/CandidateForFilter.component';
import utilsService from '../../../services/utils.service';
import userStore from '../../../stores/user.store';

class ApplicantGrid extends PureComponent {
    rowRenderer = ({ index, isScrolling, isVisible, key, parent, style }) => {
        const { data, isSingleJobView, searchResults, isSingleCompanyView } = this.props;
        const process = data[index];

        return (
            <ApplicantGridItem
                key={key}
                index={key}
                searchResults={searchResults}
                style={style}
                process={process}
                isSingleJobView={isSingleJobView}
                isSingleCompanyView={isSingleCompanyView}
                actionEventHandler={this.props.actionEventHandler}
            />

        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.theGrid.forceUpdateGrid();
    }

    noDataRender = () => (
        <div className="grid-body-item no-data">
            No Data
        </div>
    );

    ifTabIsRejected() {
        let result = false;
        if (processStore.tableLastIndex && processStore.tableLastIndex === 7) {
            result = true;
        }
        return result;
    }

    matchRightCssClass = (isSingleJobView, isRecruiter, isSingleCompanyView) => {
        let cssClasses = 'grid-table';
        if (isSingleJobView || isSingleCompanyView) {
            cssClasses += ' single-page';
        }

        if (this.ifTabIsRejected()) {
            cssClasses += ' rejected-tab';
        }

        if (isRecruiter && !isSingleJobView && !isSingleCompanyView) {
            cssClasses += ' recruiter';
        }
        return cssClasses;
    };

    constructor(props) {
        super(props);
        this.state = {
            showRejectedFilter: false,
            showCandidateForFilter: false,
            rejectedLabel: 'All',
            candidateForLabel: processStore.candidateForDefaultValue
        };
    }

    showRejectedFilter = () => {
        this.setState({ showRejectedFilter: !this.state.showRejectedFilter });
    };
    showCandidateForFilter = () => {
        this.setState({ showCandidateForFilter: !this.state.showCandidateForFilter });
    };

    changeRejectedLabel = label => {
        this.setState({ rejectedLabel: label });
    };

    filterColumns = (columnKey, e) => {
        const { isSingleJobView } = this.props;
        const htmlFilterClassName = 'column-filter';
        const htmlAscClassName = 'filter-asc';
        const htmlDescClassName = 'filter-desc';

        let htmlClassToAdd = htmlDescClassName;
        let isDesc = true;

        if (e.target.classList.contains(htmlDescClassName)) {
            htmlClassToAdd = htmlAscClassName;
            isDesc = false;
        }

        const elements = Array.from(document.getElementsByClassName(htmlFilterClassName));
        elements.forEach(el => {
            el.classList.remove(htmlFilterClassName, htmlAscClassName, htmlDescClassName);
        });

        e.target.classList.add(htmlFilterClassName, htmlClassToAdd);

        processStore.sortApplicantsColumnByKey(columnKey, isDesc, isSingleJobView);
    };

    headerData(isSingleJobView, showCandidateForFilter, isRecruiter, isSingleCompanyView, companySlug) {
        const candidate = {
            classes: 'applicant-grid-header-candidate',
            text: processStore.candidateForDefaultValue,
            icon: <FilterIcon/>,
            showCandidateForFilter: showCandidateForFilter,
            func: this.showCandidateForFilter,
            isCandidate: true,
            companySlug: companySlug
        };

        const company = {
            classes: 'applicant-grid-header-company',
            text: 'Company'
        };

        const temp = [
            {
                classes: 'applicant-grid-header-talent',
                text: 'Candidate details'
            },
            {
                classes: 'applicant-grid-header-Status',
                text: 'Status'
            },
            {
                classes: 'applicant-grid-header-fit',
                text: 'Match',
                func: this.filterColumns,
                funcKey: 'fit'
            }, {
                classes: 'applicant-grid-header-update',
                text: 'Last Update',
                func: this.filterColumns,
                funcKey: 'update'
            }
        ];

        if (!isSingleJobView && !isRecruiter && !isSingleCompanyView) {
            temp.unshift(candidate);
        }

        if (isRecruiter && !isSingleJobView && !isSingleCompanyView) {
            temp.splice(1, 0, candidate);
            temp.splice(2, 0, company);
        }

        if (isSingleCompanyView) {
            temp.splice(1, 0, candidate);
        }

        return temp;
    }

    headerItem = (props, idx) => {
        const { classes, text, showCandidateForFilter, func, funcKey, isCandidate, companySlug } = props;

        return (
            <React.Fragment
                key={idx + classes}
            >
                {func && (
                    <li
                        key={idx + classes}
                        className={classes}
                        onClick={event => func(funcKey, event)}
                    >
                        <div className="text">
                            {text}
                        </div>
                        {isCandidate && (
                            <React.Fragment>
                                <FilterIcon/>
                                {showCandidateForFilter && (
                                    <CandidateForFilterComponent
                                        companySlug={companySlug}
                                        clickOutside={event => func(funcKey, event)}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </li>
                )}

                {!func && (
                    <li
                        key={idx + classes}
                        className={classes}
                    >
                        {text}
                    </li>
                )}
            </React.Fragment>
        );
    };

    getRowHeight(isSingleCompanyView, isSingleJobView) {
        let res = 205;
        if (isSingleCompanyView) {
            res = 133;
        }

        if (isSingleJobView) {
            res = 257;
        }
        return res;
    }

    render() {
        const { data, isSingleJobView, isSingleCompanyView, companySlug } = this.props;
        const { showCandidateForFilter } = this.state;
        const isRecruiter = utilsService.isRecruiter(userStore.user);
        const headerData = this.headerData(isSingleJobView, showCandidateForFilter, isRecruiter, isSingleCompanyView, companySlug);
        return (
            <div className={this.matchRightCssClass(isSingleJobView, isRecruiter, isSingleCompanyView)}>
                <header>
                    <ul>
                        {headerData.map((head, idx) => {
                            return (
                                this.headerItem(head, idx)
                            );
                        })}
                    </ul>
                </header>

                <div className="grid-body">
                    {data && data.length === 0 && (
                        this.noDataRender()
                    )}

                    <WindowScroller>
                        {({ isScrolling, onChildScroll, scrollTop, height }) => {
                            return (
                                <AutoSizer disableHeight>{
                                    ({ width }) => {
                                        return (
                                            <List
                                                ref={(ref) => this.theGrid = ref}
                                                className='virtualized-grid-list'
                                                autoHeight
                                                height={height}
                                                rowCount={data ? data.length : 0}
                                                rowHeight={this.getRowHeight(isSingleCompanyView, isSingleJobView)}
                                                rowRenderer={this.rowRenderer}
                                                scrollTop={scrollTop}
                                                width={width}
                                            />
                                        );
                                    }
                                }</AutoSizer>);
                        }}</WindowScroller>


                </div>
            </div>
        );
    }
}

export default ApplicantGrid;
