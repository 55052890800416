import React from 'react';
import ModalComponent from '../modal/Modal.component';
import InterestedProcessComponent from '../actions/InterestedProcess.component';

const InterestedProcessModal = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box expose-modal'>
                <InterestedProcessComponent
                    isSingleJobView={props.isSingleJobView}
                    isSingleCompanyView={props.isSingleCompanyView}
                    isModal={true}
                    closeEvent={() => props.closeEvent()}
                    data={props.data}
                />
            </div>
        </ModalComponent>
    );
};

export default InterestedProcessModal;
