import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';
import userStore from './user.store';
import dayjs from 'dayjs';
import errorStore from './error.store';
import crypto from 'crypto';

class NotesStore {
    @observable processNotes = {};
    @observable profileNotes = {};
    @observable jobNotes = {};
    @observable companyNotes = {};
    @observable notesToShow = [];
    @observable notesToShowForActivities = [];

    @observable profilesNotesEditorHtml = {
        notes: ""
    }

    async deleteNote(noteId, id, what) {
        const notes = await fetchService.authenticatedDelete(`/notes/${noteId}`);
        let arr = [];
        if(notes.data) {
            switch(what) {
                case 'process':
                    arr = this.processNotes;
                    break;
                case 'profile':
                    arr = this.profileNotes;
                    break;
                case 'job':
                    arr = this.jobNotes;
                    break;
                case 'company':
                    arr = this.companyNotes;
                    break;
                default:
                    break;
            }

            if(arr[id]) {
                arr[id] = arr[id].filter(item => item._id !== noteId);
            }

            this.buildData(id, what);
        }
    }

    async resetPrimaryNote(newPrimaryNoteId, id, field) {
        let notes = [];
    
        switch (field) {
          case 'profile':
            notes = this.profileNotes[id];
            break;
          case 'job':
            notes = this.jobNotes[id];
            break;
          case 'company':
            notes = this.companyNotes[id];
            break;
          default:
        }
    
        const currentPrimaryNote = notes.find((note) => note._id !== newPrimaryNoteId && note.eventTypeId === 1);
    
        if (currentPrimaryNote) {
          currentPrimaryNote.eventTypeId = 2; // old primary note will be converted to general note
          await fetchService.authenticatedPatch(`/notes/${currentPrimaryNote._id}`, currentPrimaryNote);
        }
    }

    async updateNote(noteId, newText, id, what, isUpdateTackStatus, tagId) {

        let arr = [];
        switch(what) {
            case 'process':
                arr = this.processNotes;
                break;
            case 'profile':
                arr = this.profileNotes;
                break;
            case 'job':
                arr = this.jobNotes;
                break;
            case 'company':
                arr = this.companyNotes;
                break;
            default:
                return;
        }

        const note = arr[id].find(note => note._id === noteId);
        if(isUpdateTackStatus) {
            note.isTackOn = note.isTackOn ? !note.isTackOn : true;
        }
        else {
            note.text = newText;
        }
        note.eventTypeId = tagId;

        if(tagId === 1) {
            note.isTackOn = true;
        }

        const notes = await fetchService.authenticatedPatch(`/notes/${noteId}`, note);
        
        if(notes.data) {
            if(arr[id]) {
                let oldNote = arr[id].find(item => item._id === noteId);
                oldNote = note;
            }
            this.buildData(id, what);
        }
    }

    @action.bound
    async getNotes(id, what) {
        switch (what) {
            case 'process':
                if (!this.processNotes[id]) {
                    const body = {
                        value: id,
                        field: 'process'
                    };
                    const notes = await fetchService.authenticatedPost('/notes-query', body);
                    const obj = {
                        [id]: notes.data
                    };

                    this.processNotes = Object.assign(obj, this.processNotes);
                }
                break;
            case 'profile':
                // check if type array in case it's an object (from primary request)
                if (!this.profileNotes[id] || !Array.isArray(this.profileNotes[id])) {
                    const body1 = {
                        value: id,
                        field: 'profile'
                    };
                    const notes1 = await fetchService.authenticatedPost('/notes-query', body1);
                    const obj1 = {
                        [id]: notes1.data
                    };
                    delete this.profileNotes[id];
                    this.profileNotes = Object.assign(obj1, this.profileNotes);
                }
                break;
            case 'job':
                if (!this.jobNotes[id]) {
                    const body = {
                        value: id,
                        field: 'job'
                    };
                    const notes = await fetchService.authenticatedPost('/notes-query', body);
                    this.jobNotes[id] = notes.data;
                }
                break;
            case 'company':
                if (!this.companyNotes[id]) {
                    const body = {
                        value: id,
                        field: 'company'
                    };
                    const notes = await fetchService.authenticatedPost('/notes-query', body);
                    this.companyNotes[id] = notes.data;
                }
                break;
            default:
                break;
        }

        userStore.onlineRecruiters[userStore.user?._id] = 1;

        await this.buildData(id, what);
    }

    async createNote(body, id, what) {
        const note = await fetchService.authenticatedPost('/notes', body);
        switch (what) {
            case 'process':
                if (this.processNotes[id] && this.processNotes[id].length > 0) {
                    this.processNotes[id].push(note.data);
                } else {
                    this.processNotes[id] = [note.data];
                }
                break;            
            case 'profile':
                if (this.profileNotes[id] && this.profileNotes[id].length > 0) {
                    this.profileNotes[id].push(note.data);
                } else {
                    this.profileNotes[id] = [note.data];
                }
                break;
            case 'job':
                    if (this.jobNotes[id] && this.jobNotes[id].length > 0) {
                        this.jobNotes[id].push(note.data);
                    } else {
                        this.jobNotes[id] = [note.data];
                    }
                    break;
            case 'company':
                if (this.companyNotes[id] && this.companyNotes[id].length > 0) {
                    this.companyNotes[id].push(note.data);
                } else {
                    this.companyNotes[id] = [note.data];
                }
                break;
            default: 
                break;
        }
    }

    @action.bound
    async buildData(id, what, screen) {
        this.notesToShow = [];
        let arrOfNotes = [];
        switch (what) {
            case 'process':
                if (this.processNotes[id] && this.processNotes[id].length > 0) {
                    arrOfNotes = Object.values(this.processNotes[id]);
                }
                break;
            case 'profile':
                if (this.profileNotes[id] && this.profileNotes[id].length > 0) {
                    arrOfNotes = Object.values(this.profileNotes[id]);
                }
                break;
            case 'job':
                    if (this.jobNotes[id] && this.jobNotes[id].length > 0) {
                        arrOfNotes = Object.values(this.jobNotes[id]);
                    }
                    break;
            case 'company':
                if (this.companyNotes[id] && this.companyNotes[id].length > 0) {
                    arrOfNotes = Object.values(this.companyNotes[id]);
                }
                break;
            default:
                break;
        }

        if(screen === "activities") {
            this.notesToShowForActivities = [];
            const keys = []
            arrOfNotes.forEach(item => {
                if(keys.indexOf(item.creator) === -1) {
                    keys.push(item.creator)
                }
            })

            await userStore.getUsersByIds(keys);
            arrOfNotes.forEach(processNoteItem => {
                const userKey = Object.keys(userStore.users).find(item => item === processNoteItem.creatorSlug)
                const value = userStore.users[userKey];
                processNoteItem.name = value && `${value.firstName} ${value.lastName}`;
                this.notesToShowForActivities.push(processNoteItem);
            })

            this.notesToShowForActivities = this.notesToShowForActivities.sort((a, b) => b.dateCreated - a.dateCreated);
        }
        else {
            this.notesToShow = arrOfNotes.map(note => {
                note.memberInfo = userStore.users[note.creatorSlug];
                note.dateFormated = dayjs(parseInt(note.dateCreated, 10)).format('MMMM D, YYYY');
                return note;
            });

            const creators = this.notesToShow.map((note) => note.creator).filter((creator) => creator);
            await userStore.getUsersByIds(creators);

            const fetchedCreators = creators.map((creator) => {
              const slug = userStore.findUserSlugById(creator);
              return userStore.users[slug];
            });

            this.notesToShow.forEach((note) => {
              const user = fetchedCreators.find((creator) => creator && creator._id === note.creator);

              if (user) {
                note.creatorUser = user;
              }
            });
        }
    }

    initProfilesNotesEditorHtml = () => {
        this.profilesNotesEditorHtml.notes = "";
    }

    async getPrimaryNotes(ids, what) {
        let response = {};
        let notes = [];
        switch(what) {
            case 'job':
                const stacktrace = new Error().stack
                response = await fetchService.authenticatedPost('/notes-query', {onlyPrimary: true, field: what, ids: ids});
                notes = response.data;

                // to resolve temporary bug while sends null id or sends profile id instead company id
                if(notes && !Array.isArray(notes)) {
                    const errorObject = {
                        userId: userStore.user._id,
                        userEmail: userStore.user.email,
                        sender: 'Front End',
                        requestId: crypto.randomBytes(16).toString('hex'),
                        type: 'Bad Request',
                        type2: 'type1',
                        stackTrace: stacktrace,
                        href: window.location.href,
                        notes: notes,
                        body: {onlyPrimary: true, field: what, ids: ids}
                    }

                    await errorStore.sendError(errorObject);
                }
                Array.isArray(notes) && notes.forEach(note => {
                    if(note && note.job) {
                        this.jobNotes[note.job] = note;
                    }
                });
                break;
            case 'profile':
                response = await fetchService.authenticatedPost('/notes-query', {onlyPrimary: true, field: what, ids: ids});
                notes = response.data;
                Array.isArray(notes) && notes.forEach(note => {
                    if(note && note.profile) {
                        this.profileNotes[note.profile] = note;
                    }
                });
                break;
            default:
                break;
        }
    }

    // new version
    async createNote2(id, field, body) {
        if (body.eventTypeId === 1) {
          body.isTackOn = true;
          this.resetPrimaryNote('', id, field);
        }
    
        const response = await fetchService.authenticatedPost('/notes', body);
        if (!response?.data) return;
    
        if (field === 'profile') {
          const currentNotes = this.profileNotes[id] ?? [];
          this.profileNotes[id] = [...currentNotes, response.data];
        }
    
        if (field === 'job') {
          const currentNotes = this.jobNotes[id] ?? [];
          this.jobNotes[id] = [...currentNotes, response.data];
        }
    
        if (field === 'company') {
          const currentNotes = this.companyNotes[id] ?? [];
          this.companyNotes[id] = [...currentNotes, response.data];
        }
    
        this.buildData(id, field);
    }
    
    async deleteNote2(noteId, id, field) {
        const response = await fetchService.authenticatedDelete(`/notes/${noteId}`);
        if (!response?.data) return;
    
        if (field === 'profile') {
          this.profileNotes[id] = this.profileNotes[id].filter((note) => note._id !== noteId);
        }
    
        if (field === 'job') {
          this.jobNotes[id] = this.jobNotes[id].filter((note) => note._id !== noteId);
        }
    
        if (field === 'company') {
          this.companyNotes[id] = this.companyNotes[id].filter((note) => note._id !== noteId);
        }
    
        this.buildData(id, field);
    }
    
    async updateNote2(noteId, id, field, body) {
        if (body.eventTypeId === 1) {
          body.isTackOn = true;
          this.resetPrimaryNote(noteId, id, field);
        }
    
        const response = await fetchService.authenticatedPatch(`/notes/${noteId}`, body);
        if (!response?.data) return;
    
        if (field === 'profile') {
          const index = this.profileNotes[id].findIndex((note) => note._id === noteId);
          if (index === -1) return;
    
          this.profileNotes[id][index] = { ...this.profileNotes[id][index], ...response.data };
        }
    
        if (field === 'job') {
          const index = this.jobNotes[id].findIndex((note) => note._id === noteId);
          if (index === -1) return;
    
          this.jobNotes[id][index] = { ...this.jobNotes[id][index], ...response.data };
        }
    
        if (field === 'company') {
          const index = this.companyNotes[id].findIndex((note) => note._id === noteId);
          if (index === -1) return;
    
          this.companyNotes[id][index] = { ...this.companyNotes[id][index], ...response.data };
        }
    
        this.buildData(id, field);
    }
}

const notesStore = new NotesStore();

export default notesStore;
