import dayjs from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import relativeTime from 'dayjs/plugin/relativeTime';
import _url from '../constants/url';
import React from 'react';
import data from '../constants/data';
import {
    DuplicateIcon,
    EditIcon, ForwardIcon,
    JobLightIcon, ListIcon,
    MessageLightIcon,
    SendLightIcon, SendToIcon,
    ShareLightIcon,
    TaskLightIcon,
    InterviewIcon,
    HireIcon,
    OfferIcon,
    RejectIcon,
    InterestedIcon, MailIcon3, SmsIcon, WhatsappIcon, ChatIcon, DiscussionsIcon,
    WithdrawnIcon, InviteAsEmployerIcon, InviteAsTalentIcon, BlockFromEmailsIcon,
    BlockFromPhonesIcon, PrintCVIcon, PlusIcon, ProjectIcon, SendToSuggestIcon, AddToMatchesIcon,
    MergeCandidateIcon, SendCvIcon, MailIcon4, StarIcon3, ExposedIcon, TagIcon, AttachmentIcon2,
} from '../app/components/svg';
import userStore from '../stores/user.store';
import companyStore from '../stores/company.store';
import metaDataStore from '../stores/meta-data.store';

dayjs.extend(relativeTime);

class UtilsService {
    deepFind = (obj, path) => {
        const paths = path.split('.');
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
            if (current[paths[i]] === undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    };

    getPassedDaysFromDate = date => {
        let res = '';
        if (date) {
            const theDate = new Date(date);
            const now = Date.now();
            const diffInDays = Math.ceil((now - theDate) / 86400000);
            res = diffInDays.toString();
        }

        return res;
    };

    normalizeApiData = (data, id = 'slug') => {
        const tempData = {};
        if (data) {
            for (let i = 0; i < data.length; i++) {
                tempData[data[i][id]] = data[i];
            }
        }
        return tempData;
    };

    mergeApiData = (dataFromApi, localData) => {
        return Object.assign(localData, dataFromApi);
    };

    filterStoreIds = (ids, storeKeys) => {
        return ids.filter(x => !storeKeys.includes(x));
    };

    filterUniqIds = (ids = [], items = []) => {
        const uniqIds = this.uniqArrayValues(ids);
        const storeIds = items.map(item => item._id);
        return this.filterStoreIds(uniqIds, storeIds);
    }

    getAddress = (profile, key) => {
        let res = '';
        if (profile && profile.locationData) {
            res = profile.locationData[key];
        }

        return res;
    }

    filterStoreIdsWithSlugs = (ids, items) => {
        const res = [];
        const values = Object.values(items);

        ids.forEach(id => {
            const user = values.find(val => val._id === id);
            if (!user) {
                res.push(id);
            }
        });

        return res;
    };

    uniqArrayValues = arr => {
        return Array.from(new Set(arr));
    };

    getUserImage = user => {
        if (!user)
            return '';
        let id = user.profile && user.profile.bsId;
        if (!id) {
            id = user.emsId;
            if (!id) {
                return ''
            }
        }
        const res = `${_url.profile_image + id}.jpg`;
        return res;
    };

    getProfileImage = profile => {
        let res = '';
        if (profile && profile.profileImageId && profile.profileImageId !== 'NULL') { // New profileimageId includes extension. Old profileImageId didn't have extsion.
            if (profile.profileImageId.includes('.')) {
                res = `${_url.profile_image}${profile.profileImageId}`;
            }
            else {
                res = `${_url.profile_image}${profile.profileImageId}.jpg`;
            }
        }
        else if (profile && profile.profileImageUrl && profile.profileImageUrl !== 'NULL') {
            res = profile.profileImageUrl;
        }
        else if (profile && profile.emsId) {
            res = `${_url.profile_image}${profile.emsId}.jpg`;
        }
        return res;
    };

    async getHrManager(job) {
        if (!job) {
            return [undefined];
        }
        const hrManagerNames = job.hrManagers ? await Promise.all(job.hrManagers?.map(async hrManagerId => {
            const hrManager = await userStore.getUserById(hrManagerId);
            if (hrManager) {
                return hrManager;
            }
            else {
                return undefined;
            }
        })) : [];

        return hrManagerNames;
    }

    getRecruitersName(profile) {
        const recruiterInCharge = profile && profile.recruiterInChargeId ? userStore.recruitersList.find(rec => rec._id === profile.recruiterInChargeId) : undefined;
        if (recruiterInCharge) {
            return `${recruiterInCharge.firstName} ${recruiterInCharge.lastName}`;
        }
        else {
            return 'None';
        }
    }

    getRecruiterInChargeProfileImage = profile => {
        let res = '';
        const recruiterInCharge = profile && profile.recruiterInChargeId ? userStore.recruitersList.find(rec => rec._id === profile.recruiterInChargeId) : undefined;
        if (recruiterInCharge && recruiterInCharge.profileImageId) {
            res = `${_url.profile_image +
                recruiterInCharge.profileImageId}.jpg`;
        }
        return res;
    };

    splitArrayIntoChunks = (arr, len) => {
        const chunks = [];
        let i = 0;
        const n = arr.length;

        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }

        return chunks;
    };

    getCompanyImage = (company) => {
        let res = '';
        if (company && company.profileImageUrl && company.profileImageUrl !==
            'NULL' && !company.profileImageUrl.includes('http')) {
            res = _url.company_logo + company.profileImageUrl;
        } else if (company && company.emsId) {
            res = `${_url.company_logo +
                company.emsId}.png`;
        } else if (company && company.bsId) {
            res = `${_url.company_logo +
                company.bsId}.png`;
        }

        return res;
    };

    getDisplayName = (profile, capitalize = false) => {
        if (profile && profile.firstName === 'na' && profile.lastName === 'na') {
            let name = 'Pending';
            const email = profile.email;
            if (email) {
                capitalize = false;
                const tmp = email.split('@');
                if (tmp && tmp.length > 0) {
                    name = tmp[0];
                }
            }

            return capitalize ? this.capitalizeString(name) : name;
        } else {
            let resp = profile ? `${profile.firstName || ''} ${profile.lastName || ''}` : '';
            if (resp && capitalize) {
                resp = this.capitalizeString(resp);
            }

            return utilsService.capitalizeString(resp);
        }
    };

    getDisplayNameHebrew = profile => {
        let res = '';
        if (profile) {
            if (profile.firstNameHeb && profile.lastNameHeb) {
                res = `${profile.firstNameHeb} ${profile.lastNameHeb}`;
            } else if (profile.firstName && profile.lastName) {
                res = `${profile.firstName} ${profile.lastName}`;
            } else if (profile.emails && profile.emails.length > 0) {
                res = this.getPrimaryValue(profile.emails, 'email');
            }
        }
        return res;
    };

    getDisplayUser = (user) => {
        if (!user) return null;

        return {
            ...user,
            elId: user._id,
            value: utilsService.getDisplayName(user),
        }
    };

    getDurationDate = (start, end) => {
        let res = '';
        if (start && Number(start) && end && Number(end)) {
            const startN = dayjs(Number(start));
            const endN = dayjs(Number(end));
            if (start == end) {
                res = ''
            } else {
                res = startN.from(endN, true);
            }
        }
        if (start && Number(start) && !end || !Number(end)) {
            const startN = dayjs(Number(start));
            const endN = dayjs();
            res = startN.from(endN, true);
        }

        return res;
    };

    // getDates = (start, end) => {
    //     let res = '';
    //     if (start && Number(start) && end && Number(end)) {
    //         res = `${dayjs(Number(start)).format('YYYY')} - ${dayjs(Number(end)).format('YYYY')}`;
    //     }
    //     return res;
    // };

    getDates = (start, end, dates='') => {
        if (!start && !end) {
            return dates
        }
        start = (start && Number(start)) ? dayjs(Number(start)).format('YYYY') : 'Present';
        end = (end && Number(end)) ? dayjs(Number(end)).format('YYYY') : 'Present';
        return `${start} - ${end}`;
    };

    getDatesWithDurationDate = (start, end) => {
        let res = '';
        if (start && Number(start) && end && Number(end)) {
            res = `${dayjs(Number(start)).format('MM/YY')} - ${dayjs(Number(end)).format('MM/YY')} [${this.getDurationDate(
                start, end)}]`;
        }

        return res;
    };

    createQuickDetailsData = (data, process) => {
        const degree = data.linkedInData?.educationList?.length > 0 ? data.linkedInData.educationList[0].degreeName : '';
        const schoolName = data.linkedInData?.educationList?.length > 0 ? data.linkedInData.educationList[0].educationInstitution : '';
        const cumLaude = utilsService.getValueFromArray(data.education, 0, 'cumLaude');

        const education = `${degree}, ${schoolName} ${cumLaude ? ', ' +
            cumLaude : ''}`;

        return {
            name: utilsService.getDisplayName(data),
            profileImageUrl: data.profileImageUrl,
            id: data.emsId,
            currentPosition: data.position,
            currentCompany: data.company,
            location: data.address ? data.address.city + ', ' + data.address.country : '',
            education: education,
            educationDegree: degree,
            educationSchoolName: schoolName,
            previousPosition: this.getValueFromArray(data.employment, 1, 'position'),
            previousCompany: this.getValueFromArray(data.employment, 1, 'companyName'),
            currentStatus: this.getStatusByEnum(process.status),
            fit: process.fit
        };
    };

    decodeBase64UTF16LE = (base64str) => {
        const binaryStr = atob(base64str);
        const cpep = [];
        for (let i = 0; i < binaryStr.length; i += 2) {
            cpep.push(
                binaryStr.charCodeAt(i) |
                (binaryStr.charCodeAt(i + 1) << 8)
            );
        }

        return String.fromCharCode.apply(String, cpep);
    };

    generateBase64Download = (base64String, fileName) => {
        let a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + base64String; //Image Base64 Goes here
        a.download = fileName; //File name Here
        a.click(); //Downloaded file
    }

    getStatusByEnum = (idStr, detailedInProgress) => {
        const id = Number(idStr);
        switch (id) {
            case 76:
            case 12:
            case 111:
                return 'New';
            case 3: //request for details
            case 34: //exposed
            case 70: // leads request for details.
                if (detailedInProgress) {
                    return this.getInProgressStatusByEnum(idStr);
                }
                else {
                    return 'In-progress';
                }
            case 2:
                return 'Interview';
            case 69:
                return 'Leads';
            case 9:
                return 'Offered';
            case 66:
            case 4:
            case 36:
            case 361:
            case 362:
            case 363:
            case 100:
                return 'Withdrawn';
            case 5:
            case 40:
            case 42:
            case 46:
            case 461:
            case 462:
            case 463:
            case 464:
            case 29:
            case 291:
            case 64:
                return 'Decline';
            case 6:
                return 'Hold';
            case 7:
                return 'Hired';
            // case -1:
            //     return 'Suggested';
            case 10:
                return 'Suggested';
            case 846:
                return 'Waiting to upload';
            case 826:
                return 'Waiting for approval';
            case 825:
                return 'Self match';
            case 15:
                return 'Talent potential match';
            case 1:
                return 'Pre match';
            default:
                return '';
        }
    };

    isMatchStatus = (statusNumber) => {
        switch (statusNumber) {
            case 1:
            case 825:
            case 826:
                return true;

            default:
                return false;
        }
    }

    getInProgressStatusByEnum = idStr => {
        const id = Number(idStr);
        switch (id) {
            case 3: //request for details
            case 70: // Leasd request for details.
                return 'Request';
            case 34: //exposed
                return 'Exposed';
            default:
                return '';
        }
    };

    getPublishStatusString = status => {
        switch (status.elId) {
            case 0:
                return 'Unpublish';
            case 1:
                return 'Publish';
            case 2:
                return 'Discreet';
            default:
                return '';
        }
    }

    getIdsArrayByStatus = status => {
        switch (status) {
            case 'New':
                return [76, 12, 111]
            case 'Phone interview':
                return [8]
            case 'Request': //in progress
                return [3, 70]
            case 'Exposed': //in progress
                return [34]
            case 'Interview':
                return [2]
            case 'Leads':
                return [69]
            case 'Offered':
                return [9]
            case 'Withdrawn':
                return [66, 4, 36, 361, 362, 363, 100]
            case 'Decline':
                return [5, 40, 42, 46, 461, 462, 463, 464, 29, 291, 64]
            case 'Hold':
                return [6];
            case 'Hired':
                return [7];
            case 'Suggested':
                return [10];
            case 'Waiting to upload':
                return 846;
            // case 'Sourced':
            //     return [10];
            default:
                return [];
        }
    };

    getStageLastUpdate = (processes, stage) => {
        const processesList = processes.filter(item =>
            this.getIdsArrayByStatus(stage).includes(item.status))
        const lastUpdatedProcess = processesList.map(item => item.lastUpdate);
        const lastUpdatedTime = Math.max.apply(Math, lastUpdatedProcess);
        return lastUpdatedTime;
    }

    calculateStage = (processes) => {
        const statuses = utilsService.getStatusesByProcesses(processes);
        if (statuses.hired > 0) {
            return 'Hired'
        }
        else if (statuses.offered > 0) {
            return 'Offered'
        }
        else if (statuses.interview > 0) {
            return 'Interview'
        }
        else if (statuses['in-progress'] > 0) {
            return 'In-progress'
        }
        else if (statuses.leads > 0) {
            return 'Leads'
        }
        else if (statuses.new > 0) {
            return 'New'
        }
        else if (statuses.decline > 0 || statuses.withdrawn > 0) {
            let lastUpdatedDeclineTime = 0;
            let lastUpdatedWithdrawnTime = 0;
            if (statuses.decline > 0) {
                lastUpdatedDeclineTime = this.getStageLastUpdate(processes, 'Decline')
            }

            if (statuses.withdrawn > 0) {
                lastUpdatedWithdrawnTime = this.getStageLastUpdate(processes, 'Withdrawn')
            }

            return lastUpdatedDeclineTime > lastUpdatedWithdrawnTime ?
                'Decline' : 'Withdrawn';
        }
        else {
            return 'Created'
        }
    }

    checkIfSuggested = process => {
        const status = process.status;
        if (status === 10) {
            return true;
        }

        return false;
    };

    checkIfDeclineOrWithdrawn = process => {
        const status = process.status;
        switch (status) {
            case 66:
            case 4:
            case 36:
            case 361:
            case 362:
            case 363:
            case 100:
            case 5:
            case 40:
            case 42:
            case 46:
            case 461:
            case 462:
            case 463:
            case 464:
            case 29:
            case 291:
            case 64:
                return true;
            default:
                return false;
        }
    };

    checkIfDeclineOrWithdrawnOrHired = process => {
        const status = process.status;
        switch (status) {
            case 66:
            case 4:
            case 36:
            case 361:
            case 362:
            case 363:
            case 100:
            case 5:
            case 40:
            case 42:
            case 46:
            case 461:
            case 462:
            case 463:
            case 464:
            case 29:
            case 291:
            case 7:
            case 64:
                return true;
            default:
                return false;
        }
    };

    checkIfStatusLabelExistsInProcess = (process, statuses) => {
        let statusToString = this.getStatusByEnum(process.status, true).toLowerCase();
        return statuses.includes(statusToString);
    }

    getInProcessesStatuses = (includesNew = true) => {
        const array = [
        ...utilsService.getIdsArrayByStatus('Request'),
        ...utilsService.getIdsArrayByStatus('Exposed'),
        ...utilsService.getIdsArrayByStatus('Interview'),
        ...utilsService.getIdsArrayByStatus('Offered')];

        if(includesNew) {
            array.push(...utilsService.getIdsArrayByStatus('New'));
        }
        return array;
    }

    getStages = (stages = [], processes) => {
        if (processes === 0) return utilsService.getInProcessesStatuses(false);

        const stageIds = stages.reduce((ids, elId) => {
        const option = data.stage.find((item) => item.elId === elId);
        const value = option ? option.value : '';
          return [...ids, ...utilsService.getIdsArrayByStatus(value)];
        }, []);

        if (processes === 1) return [...stageIds, ...utilsService.getInProcessesStatuses(false)];

        return stageIds;
    }

    shouldCloseAllOpenProcesses = (status) => {
        return data.close_options_candidate_side.some(item => item.id === status);
    }

    checkIfLeadNew = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 69:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfExposeReqeust = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 3:
                case 70:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfNew = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 12:
                case 111:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfInProgress = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 3:
                case 34:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfInInterview = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 2:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfInOffer = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 9:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfLead = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 69:
                case 70:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfHold = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 6:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfHired = process => {
        let res = false;
        if (process && process.status) {
            const status = process.status;
            switch (status) {
                case 7:
                    res = true;
                    break;
            }
        }

        return res;
    };

    checkIfExposed = process => {
        return true;
    };


    getValueFromArray = (arr, index, value = 'value') => {
        let res = '';
        if (arr && Array.isArray(arr) && arr.length > index) {
            res = arr[index][value];
        }

        return res;
    };

    getCurrentAge(birthday) {
        let res = '';
        const dateNow = Date.now();
        if (birthday) {
            const years = Number(dayjs(birthday).format('YYYY'));
            const yearsNow = Number(dayjs(dateNow).format('YYYY'));
            res = yearsNow - years;
        }

        return res;
    }

    getBirthday(timeInMills) {
        return new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(timeInMills);
    }

    getWorkedAt = (empl, index) => {
        let result = '';
        if (empl && empl[index]) {
            result = `${empl[index].position} at ${empl[index].companyName}`;
        }
        return result;
    };

    validatePasswords = (password, password2) => {
        let res = { valid: true, message: '' };
        if (!password || !password2) {
            res.valid = false;
            res.message = 'New password should not be empty';
        }

        if (password !== password2) {
            res.valid = false;
            res.message = 'New passwords should be equal';
        }

        if (password.length < 6) {
            res.valid = false;
            res.message = 'New password should minimum 6 characters';
        }

        return res;
    };

    validateEmail(emailToValidate) {
        const emailToValidateLowerCase = emailToValidate.toLowerCase();
        const expr = new RegExp(/^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/);
        return expr.test(emailToValidateLowerCase);
    }

    validateSalary(salaryToValidate) {
        const positive = salaryToValidate > 0;
        const expr = new RegExp(/[1-9](\d{3})/);
        return positive && expr.test(salaryToValidate);
    }

    validateCompanyEmail(value, companyMails) {
        let res = false;
        if (value && companyMails) {
            if (value && value.split('@')[1]) {
                const splited = value.split('@')[1];
                if (typeof companyMails === 'object') {
                    companyMails.forEach(mail => {
                        if (mail === splited) {
                            res = true;
                        }
                    });
                } else {
                    res = splited === companyMails;
                }
            }
        }

        return res;
    }

    getPrimaryValue(arr, value) {
        let res = '';
        if (arr && arr.length > 0) {
            for (const item of arr) {
                if (item.isPrimary) {
                    res = value ? item[value] : item;
                    break;
                }
            }

            if (!res) {
                res = value ? arr[0][value]: arr[0];
            }
        }
        return res;
    }

    getSkills = (skills, isMandatory) => {
        const res = [];
        if (skills && skills.length > 0) {
            skills.forEach((skill, idx) => {
                if (skill) {
                    res.push(
                        <div
                            key={idx + " " + skill.elId}
                            className={'skill-tag ' + (isMandatory ? 'mandatory ' : '')}>
                            {skill.value}
                        </div>
                    );
                }
            });
        }

        return res;
    };
    getSkills2 = (skills, isMandatory) => {
        const res = [];
        if (skills && skills.length > 0) {
            skills.forEach((skill, idx) => {
                if (skill) {
                    res.push(
                        <div
                            key={idx + " " + skill.elId}
                            className={isMandatory ? 'mandatory-skill' : 'not-mandatory-skill'}>
                            {skill.value}
                        </div>
                    );
                }
            });
        }

        return res;
    };

    getMatchSkills = skills => {
        const res = [];
        if (skills && skills.length > 0) {
            skills.forEach((skill, idx) => {
                if (skill) {
                    res.push(
                        <div
                            key={idx + skill.elId}
                            className={`skill-tag ${skill.match ? 'match' : ''}`}>
                            {skill.value}
                        </div>
                    );
                }
            });
        }

        return res;
    };

    getElIdsFromArray(arr) {
        let res = [];
        if (arr) {
            res = arr.map(item => {
                return (item.elId);
            });
        }
        return res;
    }

    buildSalaryLimit = (profileSalary, jobSalary) => {
        return {
            value: jobSalary,
            match: (profileSalary * 1.1) >= jobSalary && (profileSalary * 0.85) <= jobSalary
        };
    };

    buildMatchList = (processList, jobList) => {
        const res = [];
        if (processList && processList.length > 0 && jobList && jobList.length > 0)
            processList.forEach(processItem => {
                if (processItem && processItem.value) {
                    res.push({
                        value: processItem.value,
                        elId: processItem.elId,
                        match: jobList.findIndex(item => item != null && item.elId === processItem.elId) > -1
                    });
                }
            });
        return res;
    };

    extractDataByElIdAndValue = (array) => {
        const newArray = array.map(item => {
            return {
                elId: item.elId,
                value: item.value
            }
        })

        return newArray;
    }

    buildQuery = array => {
        let res = "";
        for (let i = 0; i < array.length; i++) {

            if (i === 0) {
                res = `${res}${array[i]}`;
            }
            else {
                res = `${res}&${array[i]}`;
            }

        }

        return res;
    }

    queryParamsParser = params => {
        let res = {};
        if (params) {
            const removedAskSign = params.split('?')[1];
            const queries = removedAskSign.split('&');
            queries.forEach(query => {
                const splitedQuery = query.split('=');
                res[splitedQuery[0]] = splitedQuery[1];
            });
        }

        return res;
    };

    getRandomBackgroundColor(name) {
        const colors = ['#456bb0', '#707070', '#1a99ff', '#9ad1fa', '#9aa1fa'];
        const colorsLength = colors.length;
        let res = colors[Math.floor(Math.random() * colorsLength)];
        const letters = {
            a: 0,
            b: 1,
            c: 2,
            d: 3,
            e: 4,
            f: 5,
            g: 6,
            h: 7,
            i: 8,
            j: 9,
            k: 10,
            l: 11,
            m: 12,
            n: 13,
            o: 14,
            p: 15,
            q: 16,
            r: 17,
            s: 18,
            t: 19,
            u: 20,
            v: 21,
            w: 22,
            x: 23,
            y: 24,
            z: 25
        };
        if (name) {
            const id = letters[name.charAt(0).toLowerCase()];
            const h = id / colorsLength;
            let jp = h | 0;
            if (jp > 4) {
                jp = 4;
            }
            res = colors[jp];
        }

        return res;
    }

    getObjValue(obj, value = 'value', emptyValue = '') {
        let res = emptyValue;
        if (obj && obj[value]) {
            res = obj[value];
        }
        return res;
    }

    joinByComma(array, key) {
        let res = '';
        if (Array.isArray(array)) {
            if (key) {
                array = array.map(val => {
                    if (val && val[key] !== undefined) {
                        return val[key];
                    }

                    return null;
                });
            }
            res = array.join(', ');
        }

        return res;
    }

    renderBackgroundData(data) {
        const tempData = [];
        data.forEach((item, idx) => {
            if (item.value) {
                const classes = item.classes ? `background-info-item ${item.classes}` : 'background-info-item';
                const isDiv = this.isDivBackgroundData(item.legend);
                tempData.push(
                    <div className={classes} key={idx}>
                        <legend>{item.legend}</legend>
                        {isDiv && (<div>{item.value}</div>)}
                        {!isDiv && (<p>{item.value}</p>)}
                    </div>
                );
            }
        });

        return tempData;
    }

    isDivBackgroundData(legend) {
        switch (legend) {
            case 'Skills':
            case 'Social':
            case 'Technology':
            case 'Industries':
            case 'Business Model':
            case 'Tags':
            case 'Specialties':
            case 'Benefits':
                return true;

            default:
                return false;
        }
    }

    getCompaniesArrayUI = (items) => {
        const res = [];
        if (items && items.length > 0) {
            items.forEach((item, idx) => {
                if (item) {
                    res.push(
                        <div
                            key={idx + item.elId}
                            className={'company-tag'}>
                            {item.value}
                        </div>
                    );
                }
            });
        }

        return res;
    };

    doMeAFavor(item) {
        if (item) {
            return JSON.parse(JSON.stringify(item));
        }
    }

    getRole(profile, getDefault, isObj) {
        let res = getDefault ? 'Role not set' : null;
        const role = profile.permission;
        if (role) {
            const perm = data.employers_permissions.find(q => q.elId === role);
            if (perm) {
                res = isObj ? perm : perm.value;
            }
        }
        return res;
    }

    getListOfYears() {
        const res = [];
        const dateNow = Date.now();
        let yearNow = Number(dayjs(dateNow).format('YYYY'));
        let counter = 120;

        while (counter !== 0) {
            res.push(yearNow);
            counter--;
            yearNow--;
        }
        return res;
    }

    getCompanyLocation(locations, company) {
        let res = false;
        if (company && company.location && company.location.cityId && locations) {
            const loc = locations[company.location.cityId];
            res = {
                city: loc ? {
                    elId: loc.elId,
                    value: loc.city,
                    regionId: loc.regionId
                } : null,
                street: company.location.street,
                building: company.location.building,
                industryDistrictId: company.location.industryDistrictId,
                industryDistrict: company.location.industryDistrict
            }
        }
        return res;
    }

    getProfileAddress(profile) {
        let res = '';
        if (profile) {
            if (profile.locationData) {
                if (profile.locationData.city) {
                    res += profile.locationData.city;
                }

                if (profile.locationData.country) {
                    if (res) {
                        res += ', ';
                    }
                    res += profile.locationData.country;
                }

            }
        }
        return res;
    }

    normalizeMetaLocationsFromDB(arr, withCountry = false) {
        const tempArr = arr && arr.map(item => {
            const cityToShow = item.bsLocationName;
            const loction = {
                elId: item.elId,
                regionId: item.regionId,
                value: withCountry ? `${cityToShow} , ${item.country}` : cityToShow
            }
            return loction;
        });

        tempArr.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        return tempArr;
    }

    normalizeMetaLocations(arr, withCountry = false) {
        const tempArr = [];

        arr = Object.values(arr);

        const leng = arr.length;
        for (let i = leng - 1; i >= 0; i--) {
            const item = arr[i];
            if (item.city !== 'U//N' || (item.cityHebrew && item.cityHebrew !== 'U//N')) {
                const cityToShow = (item.city !== 'U//N') ? item.city : item.cityHebrew;
                tempArr.push({
                    elId: item.elId,
                    regionId: item.regionId,
                    value: withCountry ? `${cityToShow} , ${item.country}` : cityToShow
                });
            }
        }

        tempArr.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

        return tempArr;
    }

    normalizeIndustryDistrictArray(arr) {
        const tempArr = [];

        arr = Object.values(arr);

        const leng = arr.length;
        for (let i = leng - 1; i >= 0; i--) {
            const item = arr[i];
            tempArr.push({
                elId: item.IndustryAreaId,
                value: item.IndustryArea
            });
        }

        tempArr.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

        return tempArr;
    }

    normalizeIndustryDistrict(obj) {
        let tempObj = {
            elId: obj && obj.industryDistrictId,
            value: obj && obj.industryDistrict
        }
        return tempObj;
    }

    isRecruiter(user) {
        if (user) {
            return user.userType === 3;
        } else {
            if(userStore.user) {
                return userStore.user.userType === 3;
            }
            else {
                return null;
            }
        }
    }

    getCategoriesByRoles = (selectedRoles, roles) => {
        const categories = [];
        selectedRoles.forEach(item => {
            const isItemExistsInArray = categories.some(element => element.elId === item.catId)
            if (!isItemExistsInArray && roles.some(role => role.catId === item.catId)) {
                categories.push({ elId: item.catId, value: item.category });
            }
        })

        return categories;
    }

    normalizeRolesFull(roles, subRoles) {
        const rolesFull = [];

        roles = Object.values(roles);
        subRoles = Object.values(subRoles);

        const rolesLength = roles.length;
        for (let i = rolesLength - 1; i >= 0; i--) {
            const role = roles[i];
            rolesFull.push(role);
        }

        const subRolesLength = subRoles.length;
        for (let i = subRolesLength - 1; i >= 0; i--) {
            const subRole = subRoles[i];
            rolesFull.push(subRole);
        }

        return rolesFull;
    }

    normalizeRoles(roles, subRoles) {
        const temp = {
            parentRoles: {},
            subRoles: {}
        };

        roles = Object.values(roles);
        subRoles = Object.values(subRoles);

        const rolesLength = roles.length;
        for (let i = rolesLength - 1; i >= 0; i--) {
            const role = roles[i];
            if (!temp.parentRoles[role.catId]) {
                temp.parentRoles[role.catId] = [];
            }
            temp.parentRoles[role.catId].push({
                elId: role.elId, value: role.value
            });
        }

        const subRolesLength = subRoles.length;
        for (let i = subRolesLength - 1; i >= 0; i--) {
            const subRole = subRoles[i];
            if (!temp.subRoles[subRole.roleParentId]) {
                temp.subRoles[subRole.roleParentId] = [];
            }
            temp.subRoles[subRole.roleParentId].push({
                elId: subRole.elId,
                value: subRole.value
            });
        }

        return temp;
    }

    normalizeCompaniesArray(companiesIds) {
        const companies = companiesIds.map(item => {
            const company = companyStore.getCompanyById(item)
            return company ? { elId: company._id, value: company.name } : null
        })

        return companies;
    }

    normalizeCompanyObject(companyId) {
        const company = companyStore.getCompanyById(companyId)
        if (company) {
            return {
                elId: company._id,
                value: company.name
            }
        }

        return company;
    }

    sortSeniorityLevels(senLvls) {
        let senLvlArr = Object.values(senLvls);
        senLvlArr.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
        senLvlArr = senLvlArr.filter(a => a.elId !== 18);
        return senLvlArr;
    }

    sortTopSkillsV2(topSkills) {
        const topSkillsTemp = utilsService.doMeAFavor(topSkills)
        Object.values(topSkillsTemp).forEach(element => {
            element = element.sort((a, b) => {
                return a.rank - b.rank;
            });
        });
        return topSkillsTemp;

    }

    getPredefinedLanguages(langs) {
        const temp = [];
        data.prefered_langs.forEach(item => {
            if (langs[item]) {
                temp.push(langs[item]);
            }
        });

        return temp;
    }

    getSalaryRanges(salaryType) {
        const temp = {
            min: 0,
            max: 250000
        };

        if (Number(salaryType) === 2) {
            temp.min = 20;
            temp.max = 2000;
        }

        return temp;
    }

    getRightIcon(type, className) {
        switch (type) {
            case 'addToJob':
                return <JobLightIcon />;
            case 'share':
                return <ShareLightIcon />;
            case 'addTask':
                return <TaskLightIcon />;
            case 'message':
                return <MessageLightIcon />;
            case 'send':
                return <SendLightIcon />;
            case 'edit':
                return <EditIcon />;
            case 'duplicate':
                return <DuplicateIcon />;
            case 'forward':
                return <ForwardIcon />;
            case 'list':
                return <ListIcon />;
            case 'sendTo':
                return <SendToIcon />;
            case 'interview':
                return <InterviewIcon />;
            case 'hire':
                return <HireIcon />;
            case 'comment':
                return <MessageLightIcon />;
            case 'offer':
                return <OfferIcon />;
            case 'expose':
                return <SendLightIcon />;
            case 'reject':
                return <RejectIcon />;
            case 'interested':
                return <InterestedIcon />;
            case 'mail2':
                return <MailIcon3 className={className} />;
            case 'mail4':
                return <MailIcon4 className={className} />
            case 'withdrawn':
                return <WithdrawnIcon />;
            case 'inviteAsEmployer':
                return <InviteAsEmployerIcon />
            case 'inviteAsTalent':
                return <InviteAsTalentIcon />
            case 'blockFromEmails':
                return <BlockFromEmailsIcon />
            case 'blockFromPhones':
                return <BlockFromPhonesIcon />
            case 'printCV':
                return <PrintCVIcon />
            case 'plus':
                return <PlusIcon />
            case 'project':
                return <ProjectIcon />
            case 'sendSuggest':
                return <SendToSuggestIcon />
            case 'addToMatches':
                return <AddToMatchesIcon />
            case 'mergeCandidates':
                return <MergeCandidateIcon />
            case 'sendCV':
                return <SendCvIcon className={className} />
            case 'starred':
                return <StarIcon3 className={className} />
            case 'tag':
                return <TagIcon className={className} />
            case 'exposed':
                return <ExposedIcon className={className} />
            case 'attachment2':
                return <AttachmentIcon2 className={className} />
            case 'type':
                return <ChatIcon className={className} />
            case 'sms':
                return <SmsIcon className={className} />
            case 'whatsapp':
                return <WhatsappIcon className={className} />


            default:
                return;
        }
    }

    getUserTypeByType(type) {
        switch (type) {
            case 2:
                return 'Employer';
            case 3:
                return 'Recruiter';
            case 4:
                return 'Talent';                
            default:
                return 'Disabled';
        }
    }

    validPasswordOneUpperCaseLatter(password) {
        return password.match(/[A-Z]/);
    }

    validPasswordOneLowerCaseLatter(password) {
        return password.match(/[a-z]/);
    }

    validPasswordOneNumber(password) {
        return password.match(/[0-9]/);
    }

    validPasswordSpecialCharacter(password) {
        return password.match(/[!@#$%^&*(),.?":{}|<>]/g);
    }

    stripHtml(str) {
        return str.replace(/<[^>]*>?/gm, '');
    }

    removeHtmlHunk(str) {
        str = str.replace(/^\s*$(?:\r\n?|\n)/gm, "");
        str = str.replace(/ +(?= )/g, '');

        return str;
    }

    capitalizeString(str) {
        let resp = '';
        if (str) {
            resp = str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }

        return resp;
    }

    getFormattedCustomerStatus(customerStatusNumber) {
        switch (customerStatusNumber) {
            case 1:
                return 'Active';
            case 5:
                return 'Potential';
            case 10:
                return 'Closed';
            default:
                return 'Unknown';
        }
    }

    getFormattedDate(timeInMills, type) {
        let formattedDate = '';
        if (timeInMills && type === 1) {
            return dayjs(Number(timeInMills)).format('MM/YY')
        } else if (timeInMills) {
            /** will return date like: 2 Feb, 2020  */
            if (type === 2) {
                formattedDate = dayjs(Number(timeInMills)).format('DD MMMM, YYYY');
            }

            /** will return time like: 3:45  */
            else if (type === 3) {
                formattedDate = dayjs(Number(timeInMills)).format('H:mm');
            }

            /** will return time like: November 21, 2018 13:11  */
            else if (type === 4) {
                formattedDate = dayjs(Number(timeInMills)).format('MMMM D, YYYY H:mm');
            }

            /** will return time like: 18/08/2021  */
            else if (type === 5) {
                formattedDate = dayjs(Number(timeInMills)).format('DD/MM/YY');
            }

            /** will return time like: 12.2.2020 | 11:32  */
            else if (type === 6) {
                formattedDate = dayjs(Number(timeInMills)).format('D.M.YYYY | HH:mm');
            }

            /** will return date like: Feb 2, 2020  */
            else {
                formattedDate = dayjs(Number(timeInMills)).format('MMMM D, YYYY');

                let index = formattedDate.indexOf(' ');
                if (index === -1) {
                    return formattedDate;
                }

                // will takes only 3 chars from month and concatenate all the others
                formattedDate = formattedDate.slice(0, 3) + formattedDate.slice(index, formattedDate.length);
            }

            return formattedDate;
        }

    }

    getProfilePosition = (profile) => {
        let position = profile.linkedInData && profile.linkedInData.currentPosition;
        if (!position && this.getRoles(profile.roles) && this.getRoles(profile.roles).length > 0) {
            position = this.getRoles(profile.roles)[0];
        }
        if (!position) {
            position = profile.employment && profile.employment.length > 0 &&
                profile.employment[0].position;
        }
        if (!position) {
            position = ''
        }

        return position;
    }

    getRoles(roles = []) {
        if (roles && roles.length > 0) {
            const count = roles.length;
            return roles.map((role, index) => utilsService.getObjValue(role) + `${count - 1 !== index ? ', ' : ''}`)
        };
    }

    /** will return date like: 1990-11-17  */
    getFormattedDateForInputType = (time) => {
        const date = new Date(time);
        const dateFormatted = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" + ("0" + (date.getDate())).slice(-2);
        return dateFormatted;
    };

    dateAgo(date) {
        return dayjs().from(dayjs(date), true);
    }

    getFullYearsAgo(years) {

        // Get the current date
        const currentDate = new Date();

        // Calculate the date exactly two years ago
        const twoYearsAgo = new Date(currentDate);
        twoYearsAgo.setFullYear(currentDate.getFullYear() - years);

        // Get the timestamp in milliseconds
        const yearsAgoInMillis = twoYearsAgo.getTime();

        return yearsAgoInMillis;
    }

    getSmartDate(date, now = Date.now()) {
        const milliInDay = 86400000;
        const theDifference = now - date;
        const daysTotal = parseInt(theDifference / milliInDay, 10);

        switch (daysTotal) {
            case 0:
                return dayjs(date).format('H:mm');
            case 1:
                return `${dayjs(date).format('H:mm')}`;
            default:
                return dayjs(date).format('MMM D YYYY, H:mm');
        }
    }

    clearHtmlFromDangerousKthulhu(html) {
        return sanitizeHtml(html, {
            allowedTags: [
                'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'ul', 'ol',
                'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'hr', 'br', 'div',
                'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td'],
            allowedAttributes: []
        });
    }

    clearHtmlFromDangerousKthulhuFromHtml(html) {
        return sanitizeHtml(html, {
            allowedTags: [],
            allowedAttributes: []
        });
    }

    /** checks if manager regarding to seniority */
    isManager = elId => {
        switch (Number(elId)) {
            case 14:
            case 5:
            case 3:
            case 2:
                return true;
            default:
                return;
        }
    };

    filterColumn(columnKey, e, funcCallback) {
        const htmlFilterClassName = 'column-filter';
        const htmlAscClassName = 'filter-asc';
        const htmlDescClassName = 'filter-desc';

        let htmlClassToAdd = htmlDescClassName;
        let isDesc = true;

        if (e.target.classList.contains(htmlDescClassName)) {
            htmlClassToAdd = htmlAscClassName;
            isDesc = false;
        }

        const elements = Array.from(document.getElementsByClassName(htmlFilterClassName));
        elements.forEach(el => {
            el.classList.remove(htmlFilterClassName, htmlAscClassName, htmlDescClassName);
        });

        e.target.classList.add(htmlFilterClassName, htmlClassToAdd);
        funcCallback(columnKey, isDesc);
    }

    isJobOpen = isOpen => {
        return isOpen ? 'Open' : 'Close';
    };

    removeJunkFromMessageBody = message => {
        // DON'T TOUCH
        let res = message;
        if (message === '<p><br /></p>') {
            res = '';
        }

        if (message === '<html><head></head><body><div dir="ltr"><br></div><br></body></html>') {
            res = '';
        }

        return res;
    };

    removeWrongCharsFromString = (text) => {
        const array = ['<p>', '</p>', '<ol>', '</ol>', '<u>', '</u>',
            '<strong>', '</strong>', '<li>', '</li>', '<div>', '</div>', '<blockquote>',
            '</blockquote>', '\\n', '\\t', '<font face="Segoe UI Symbol">?</font>',
            ' <font face="Segoe UI Symbol">', '<font face="Segoe UI" size=3>',
            '<font face=Arial size=3>', '<font>', '/font>',
            '<<ul>', '< <ul>', '</ul>', '< <ul>', 'ul>', '<'];

        let newText = text && text.split('&amp;#32;').join(' ');
        newText = newText && newText.split('&amp;#47;').join('.')
        newText = newText && newText.split('&amp;#47;').join('.');
        newText = newText && newText.split('&amp;quot;').join('"');
        newText = newText && newText.split('&amp;').join('&');
        newText = newText && newText.replace(/<[^>]*>?/gm, '');
        newText = newText && newText.split('&#32;').join(' ');

        if (newText) {
            for (let i = 0; i < array.length; i++) {
                newText = newText.split(array[i]).join(' ');
            }
        }

        return newText;
    }

    formatToInnerHtml(str) {
        let array = ['?', '\\r', '\\t'];

        let newText = str;
        newText = newText && newText.split('\\n').join('<br>');
        for (let i = 0; i < array.length; i++) {
            newText = newText.split(array[i]).join(' ');
        }

        return newText;
    }

    addSomeHtmlToTextMessages = message => {
        let output = '';
        if (message) {
            const splitedMessage = message.split('\n');
            splitedMessage.forEach(item => {
                output += `<p>${item}</p>`;
            });
        }

        return output;
    };

    getConversationsProviderIcon = id => {
        const providersIconsMap = {
            '1': <MailIcon3 />,
            '2': <SmsIcon />,
            '3': <WhatsappIcon />,
            '4': <ChatIcon />,
            '5': <DiscussionsIcon />
        };
        return providersIconsMap[id];
    };


    getJobStatus = (jobStatus, isCapitalize) => {
        let status = "";
        switch (jobStatus) {
            case 0:
                status = 'close'
                break;
            case 1:
                status = 'open'
                break;
            case 2:
                status = 'hold'
                break;

            default:
                break;
        }

        return isCapitalize ? utilsService.capitalizeString(status) : status
    };

    getJobStatusColor = (jobStatus) => {
        const status = this.getJobStatus(jobStatus);

        if (status === 'open') return '#34c35f';
        if (status === 'hold') return '#ffd800';

        return '#f75245'
    };

    isTwoObjectsEquivalent = (a, b) => {
        // Create arrays of property names
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            const propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName] && JSON.stringify(a[propName]) !== JSON.stringify(b[propName])) {
                return false;
                // return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }

    getDir = text => {
        let dir = 'lrt';
        const isHeb = text.match(/[\u0590-\u05FF]/i);
        if (isHeb) {
            dir = 'rtl';
        }

        return dir;
    };

    getStatusesByProcesses = (processes) => {
        const statuses = {
            suggested: 0,
            matches: 0,
            sourced: 0,
            leads: 0,
            new: 0,
            request: 0, //in progress
            exposed: 0, //in progress
            interview: 0,
            offered: 0,
            hired: 0,
            decline: 0,
            withdrawn: 0,
        };

        processes.forEach(process => {
            let statusToString = this.getStatusByEnum(process.status, true).toLowerCase();
            statuses[statusToString]++;
        });

        return statuses;
    }

    calculateJobsStatuses = (jobs, isToday) => {
        const statuses = {
            open: 0,
            hold: 0,
            close: 0,
        };

        jobs.forEach(job => {
            if (isToday) {
                const todayStartDay = this.getStartDay(new Date().getTime());
                if (job.statusLastUpdate > todayStartDay) {
                    const statusToString = this.getJobStatus(job.status);
                    statuses[statusToString]++;
                }
            }
            else {
                const statusToString = this.getJobStatus(job.status);
                statuses[statusToString]++;
            }
        });

        return statuses;
    }

    getProfilesStatuses = (profiles) => {
        const statuses = {
            active: 0,
            maybe: 0,
            'not active': 0,
        };

        profiles.forEach(profile => {
            const status = data.availabilityOptionsForCandidates.find
                (item => item.elId === profile.status).value.toLowerCase();
            statuses[status]++;
        });

        return statuses;
    }


    getLastUpdate = (process, isFormatted, formatedType) => {
        if (!process) return 'N/A';
        
        let lastUpdate;
        if (process.eventLog && process.eventLog.length > 0) {
            const firstItemUpdate = process.eventLog[0].lastUpdate;
            const lastItemUpdate = process.eventLog[process.eventLog.length - 1].lastUpdate;
            lastUpdate = firstItemUpdate > lastItemUpdate ? firstItemUpdate : lastItemUpdate
        }
        else {
            lastUpdate = process.dateCreated;
        }

        if (isFormatted) {
            return utilsService.getFormattedDate(lastUpdate, formatedType);
        }
        return lastUpdate
    }

    getLastEventLog = (process) => {
        let lastEventLog;
        if (process.eventLog && process.eventLog.length > 0) {
            const firstItemUpdate = process.eventLog[0].lastUpdate;
            const lastItemUpdate = process.eventLog[process.eventLog.length - 1].lastUpdate;
            lastEventLog = firstItemUpdate > lastItemUpdate ?
                process.eventLog[0] : process.eventLog[process.eventLog.length - 1]
            return lastEventLog;
        }

        return null;

    }

    getStartDay = date => {
        let startDate = new Date(date);
        startDate.setHours(0, 0, 0, 0);
        startDate = startDate.getTime();
        return startDate;
    };

    getEndDay = date => {
        let endDate = new Date(date);
        endDate.setHours(23, 59, 59, 0);
        endDate = endDate.getTime();
        return endDate;
    };

    filterSelectedTags = (array, isMandatory, isExcluded) => {
        if (isMandatory) {
            return array.filter(item => item.type === 1);
        }
        else if (isExcluded) {
            return array.filter(item => item.type === 2);
        }
        else {
            return array.filter(item => !item.type || item.type === -1);
        }
    };


    //return skills that not appear in mandatory skills
    filterSkillsFromMandatorySkills = (skills, mandatorySkills) => {
        const newArray = skills && skills.filter(skill => !mandatorySkills ||
            !mandatorySkills.some(element => element?.elId === skill?.elId))
        return newArray
    };

    mergeSkillsAndMandatorySkills = (skills, mandatorySkills) => {
        let skillsFiltered = this.filterSkillsFromMandatorySkills(skills, mandatorySkills);
        if (!skillsFiltered) {
            skillsFiltered = [];
        }
        if (!mandatorySkills) {
            mandatorySkills = [];
        }
        mandatorySkills.forEach(item => {
            item.type = 1;
        })
        return [...mandatorySkills, ...skillsFiltered];
    }

    getEmailList = job => {
        if (job && job.emailsForCv && job.emailsForCv.length > 0) {
            return job.emailsForCv;
        }
    };

    isUserAdmin = (userEmail) => {
        const adminEmails = [
            "amir@brightsource.com",
            "yadin@brightsource.com",
            "tomer@brightsource.com",
            "yadin@ethosia.com",
            "eyal@ethosia.com"
        ];

        const isUserAdmin = adminEmails.includes(userEmail);
        return isUserAdmin;
    }

    isAvailable = isAvailable => {
        return isAvailable ? 'Available' : ' Not Available';
    };

    calculateAvailability = calculatedAvailability => {
        let res = 'Low';
        if (calculatedAvailability >= 60) {
            res = 'Medium';
        }
        if (calculatedAvailability >= 89) {
            res = 'High';
        }
        return res;
    };

    getCandidateAvailabilityLabel = (profile) => {
        if (profile.isAvailable) {
            return "Available";
        }
        else {
            const sixMonthAgo = Date.now() - 15552000000; // 6 month
            if (profile.followUpDate > sixMonthAgo) {
                return "Maybe";
            }
            else {
                return "Not Available"
            }
        }
    }

    getEmailToInvite = (profile) => {
        let emails = [];
        if(profile) {
            emails = profile.emails?.
                 filter(item => item.email && item.isTalentApprovedInvite)
                .map(item => item.email)
        }

        return emails;
    }

    getInviteTalentStatus = (talentStatus, profile = {}) => {

        switch(talentStatus) {
            case 1:
                const isReinvite = this.isSentMarketingToJoinMessage(profile)
                if(isReinvite) {
                    return 'Reinvite'
                }
                return 'Pending'
            case 2:
                return 'Activated';
            case 3:
                return 'Deleted'
            case 4:
                return 'Profiling'
            case 5:
                const isScraped = this.isScrapedLastHalfYear(profile)
                const linkedinNotRequired = profile.talent?.wishlist?.inviteRequirements?.linkedinNotRequired
                if(isScraped || linkedinNotRequired) {
                    return 'Ready To Invite'
                }
                return 'Request To Join'

            default:
                return null;
        }

    }

    isSentMarketingToJoinMessage = profile => {
        return profile?.outboundMessages?.find(item => item.name ===  "תזכורת לשיווקית להצטרפות")
    };

    isScrapedLastHalfYear = profile => {
        const halfYearAgo = new Date();
        halfYearAgo.setMonth(halfYearAgo.getMonth() - 6);
        const lastHalfYearTimestamp = halfYearAgo.getTime();

        return !!(
            profile?.linkedInData?.homeUpdateAt &&
            profile?.linkedInData?.experiencesUpdateAt &&
            profile?.linkedInData?.educationsUpdateAt &&
            profile?.linkedInData?.skillsUpdateAt &&
            profile?.linkedInData?.languagesUpdateAt &&
            profile?.linkedInData?.dateUpdated >= lastHalfYearTimestamp
        );
    };

    getCandidateAvailabilityNumber = (profile) => {
        if (profile.isAvailable) {
            return 0;
        }
        else {
            const sixMonthAgo = Date.now() - 15552000000; // 6 month
            if (!profile.isAvailable && profile.followUpDate && profile.followUpDate <= sixMonthAgo) {
                return 1;
            }
            else {
                return 2
            }
        }
    }

    getProfileBlockedCompanies = profile => {
        let blockedCompanies = [];
        if (profile && profile.talent && profile.talent.wishlist && profile.talent.wishlist.selectedBlockedCompanies && profile.talent.wishlist.selectedBlockedCompanies) {
            blockedCompanies = [...profile.talent.wishlist.selectedBlockedCompanies];
        }
        blockedCompanies.push(profile.companyId);

        return blockedCompanies;
    }

    cleanUpMultipleSpaces = (text) => {
        if (text) {
            return text.replace(/\s\s+/g, ' ');
        }
        else {
            return text;
        }
    }

    cleanUnescapedCharacters  = (str) => {
        return str.replace(/\u200f/, '');
    }

    cleanUpThePhoneStr(phoneStr) {
        let num = false;
        if (phoneStr && phoneStr.match(/^((\+91)?-?[0\+\(]*\d{2,3}[-\s\)]*\.?\d{3,5}[-\s]*\.?[0-9]{4})$/)) {
            const withoutWhiteSpacesStr = phoneStr.replace(/\s+/g, '');
            const numberStr = withoutWhiteSpacesStr.replace(/\D/g, '');
            if (numberStr) {
                const twoFirstNumbers = numberStr.substring(0, 2);
                if (twoFirstNumbers === '97') {
                    let numberWithoutExt = numberStr.replace('972', '0');
                    const twoFirstNumbersAfterFormat = numberWithoutExt.substring(0, 2);
                    if (twoFirstNumbersAfterFormat === '00') {
                        numberWithoutExt = numberWithoutExt.substr(1);
                    }
                    num = numberWithoutExt.substring(0, 3) + '-' + numberWithoutExt.substring(3);
                } else if (twoFirstNumbers === '05') {
                    num = numberStr.substring(0, 3) + '-' + numberStr.substring(3);
                } else if (this.isHomeNumberLegal(numberStr)) {
                    num = numberStr.substring(0, 2) + '-' + numberStr.substring(2);
                } else if (this.isCompanyNumberLegal(numberStr)) {
                    num = numberStr.substring(0, 3) + '-' + numberStr.substring(3);
                }

            }
        }
        return num;
    }

    extractPhones = (profile) => {
        const phonesObject = {};
        profile && profile.phones.forEach(phone => {
            if (phone.phoneType) {
                phonesObject[phone.phoneType] = phone.phone;
            }
            else {
                phonesObject['Personal Mobile'] = phone.phone;
            }

        });

        return phonesObject;
    }


    isHomeNumberLegal(numberStr) {
        const twoFirstNumbers = numberStr.substring(0, 2);
        switch (twoFirstNumbers) {
            case '02':
            case '03':
            case '04':
            case '08':
            case '09':
                return true;

            default:
                return false;

        }
    }

    isCompanyNumberLegal(numberStr) {
        const threeFirstNumbers = numberStr.substring(0, 3);
        switch (threeFirstNumbers) {
            case '071':
            case '072':
            case '073':
            case '074':
            case '076':
            case '077':
            case '079':
                return true;

            default:
                return false;
        }
    }

    isHebrewCharsValid(text) {
        if (!text) {
            return true;
        }
        else {
            const hebrewChars = new RegExp("^[\u0590-\u05FF-'\"`. ]+$");
            return hebrewChars.test(text);
        }

    }

    isContainsHebrewChars = text => {

        const hebrewChars = new RegExp("[\u0590-\u05FF`]");
        return hebrewChars.test(text);
    };

    getEmailToSend = job => {
        const emails = job.emailsForCv;
        let email = null;
        if (emails && emails.length > 0) {
            email = emails[0];
        }
        return email;
    }

    getActionIdByType = (type) => {
        switch (type) {
            case "in-progress":
            case "expose":
                return 4;
            case "interview":
                return 53;
            case "offer":
                return 54;
            case "hire":
                return 55;
            case "decline":
                return 2;
            case "withdrawn":
                return 3;
            default:
                return 0;
        }
    }

    replaceUrl = (history, pathname, search, state) => {
        history.replace({
            pathname: pathname,
            search: search,
            state: state
        });
    }

    getDetailedStage = (stageEnum, status) => {
        if (stageEnum === 'In-progress') {
            const newStage = utilsService.getStatusByEnum(status, true);
            return <label className="label2">{newStage}</label>
        }
        else if (stageEnum === 'Decline' || stageEnum === 'Withdrawn') {
            const reason = utilsService.getRejectReason(status);
            return <label className="label2">{reason ? reason.reason : ''}</label>
        }
        return;
    }

    getRejectReason = (status) => {
        const rejectReasons = [...data.decline_options_recruiter_side,
        ...data.decline_options_candidate_side];
        const rejectReason = rejectReasons.find(item => item.id === status);
        return rejectReason;
    }

    formatSalary = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' :
            Math.sign(num) * Math.abs(num)
    }

    getProcessesStatuses = () => {
        return [
            ...this.getIdsArrayByStatus('New'),
            ...this.getIdsArrayByStatus('Request'),
            ...this.getIdsArrayByStatus('Exposed'),
            ...this.getIdsArrayByStatus('Interview'),
            ...this.getIdsArrayByStatus('Leads'),
            ...this.getIdsArrayByStatus('Offered'),
            ...this.getIdsArrayByStatus('Withdrawn'),
            ...this.getIdsArrayByStatus('Decline'),
            ...this.getIdsArrayByStatus('Hold'),
            ...this.getIdsArrayByStatus('Hired'),
            ...this.getIdsArrayByStatus('Hold'),
            ...this.getIdsArrayByStatus('Suggested'),
            ...this.getIdsArrayByStatus('Sourced')
        ]
    }

    getMatchesStatuses = () => {
        return [1, 825, 826];
    }

    inboxTimeLeft = timeInMlSec => {
        let res = 'time left';
        if (timeInMlSec) {
            if (timeInMlSec < 86400000) { // 24 hours
                if (timeInMlSec > 3600000) { // 1 hour
                    res = `${(timeInMlSec / 3600000 | 0)}h left`;
                }
                else if (timeInMlSec > 0) { // less than hour and > 0
                    res = `${(timeInMlSec / 60000 | 0)}m left`;
                }
            }
        }

        return res;
    };

    getDisplayLinks = (socialLinks = [], website = '') => {
        const links = socialLinks.reduce((allLinks, item) => {
            let key = '';

            if (item.linkType === 47) key = 'linkedin';
            if (!allLinks.linkedin && item.linkType === 43) key = 'linkedin';
            if (item.linkType === 41 || item.linkType === 52) key = 'facebook';
            if (item.linkType === 51) key = 'twitter';

            return key ? { ...allLinks, [key]: item.link } : allLinks;
        }, {});

        if (website) {
            links.website = website;
        }

        return links;
    }

    getLinkedinPublicProfileName = (socialLinks) => {
        if (!socialLinks) {
            return null;
        }
        let linkedinItem = socialLinks.find(item => item.linkType === 47);
        if (linkedinItem) {
            let link = linkedinItem.link;
            return utilsService.getCleanLinkedinPublicProfileName(link);
        }
        else
            return null;
    };

    getCleanLinkedinPublicProfileName = (link) => {
        if (link && link.includes('/in/')) {
            const n = link.indexOf('/in/');
            const newLink = link.substring(n);
            if (newLink) {
                let res = newLink.split('/');
                if (res.length >= 2) {
                    return res[2];
                }
            }
        }
        else
            return null;
    }

    getDisplayNameConversationScreen = (item) => {
        let name = (item.targetParticipantProfileId || item.targetParticipantUserId)
            && utilsService.getDisplayName(item.receiver);
        if (!name && item.metaData) {
            if (item.metaData.companyName) {
                name = item.metaData.companyName;
            }
        }

        if (!name) {
            name = item.email;
        }

        if (!name) {
            name = item.telephoneNumber;
        }

        if (!name) {
            name = 'System';
        }

        if (item.conversationType === 5) {
            name = '';
            if (item.metaData.companyName) {
                name += item.metaData.companyName + ' - ';
            }

            if (item.metaData.jobName) {
                name += item.metaData.jobName;
            }
        }

        return name;
    }

    // getLinkedinPublicProfileName = (socialLinks) => {
    //     if (!socialLinks){
    //         return null;
    //     }
    //     let linkedinItem = socialLinks.find(item => item.linkType === 47);
    //     if(linkedinItem) {
    //         let link = linkedinItem.link;
    //         const expr = new RegExp("^https?://((www|\w\w)\.)?linkedin.com/((in/[^/]+/?)|(pub/[^/]+/((\w|\d)+/?){3}))$");
    //         if(expr.test(link)) {
    //             let res = link.split('in/');
    //             if(res.length >= 1) {
    //                 return res[1];
    //             }
    //         }
    //     }
    //     return "";
    // }

    containsAll = (arr1, arr2, value = 'elId') => {
        return arr2.every(arr2Item => arr1.some(arr1Item => arr1Item[value] === arr2Item[value]));
    }

    prepareListToDropdown = array => {
        return array.map((element, index) => {
            return {
                elId: index + 1,
                value: element
            }
        });
    }

    isMailFromType = (text, type) => {
        switch (type) {
            case 'linkedin':
                if (text.toLowerCase().includes('@linkedin.com')) {
                    return true;
                }
                return false

            default:
                return false;
        }
    }

    getPrivateEmail = (profile) => {
        let emailObject = profile.emails && profile.emails.find(item => item.emailType !== 'Business Email Email' && item.isPrimary === true);

        // search private and primary
        if (emailObject) {
            return emailObject.email;
        }
        else if (!emailObject) {

            //search private
            emailObject = profile.emails && profile.emails.find(item => item.emailType !== 'Business Email Email');
            if (emailObject) {
                return emailObject.email;
            }
        }
        return null;
    }

    isTwoArraysEqual = (arr1, arr2, value) => this.containsAll(arr1, arr2, value) && this.containsAll(arr2, arr1, value);

    isMultiRecipientsMode = () => {
        // return process.env.NODE_ENV !== 'production' ? true : false;
        return true;
    }

    parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
    };

    isEthosiaCompany = (id) => {
        switch(id) {
            // ethosia
            case '5c519c636a6e9443e7fa8b58':
            // ethosia ness ziona
            case '5cc53f885b18b6001ca136ac':
            //e thosia haifa
            case '5cc1a4535b18b6001c9b7aca':
                return true;

            default:
                return false;
        }
    }

    // if pipeline includes sendCV => assign sender to recruiter send cv, if not => assign sender to acctualy recruiter 
    getProcessSender = (proccesItem) => {

        const findMinEventLog = filreredEventLogs => {
            let eventLogToGetAssigner;
            // if eventLog have sendCV pipeline => assigne to recruiter who send the CV
            const sendCvEvents = filreredEventLogs.filter(i => i.eventTypeId === 12)
            if (sendCvEvents?.length > 0) {
                // find newest sendCV
                const maxDateCreated = Math.max(...(sendCvEvents.map(item => item.dateCreated)));
                if (!isNaN(maxDateCreated)) {
                    eventLogToGetAssigner = sendCvEvents.find(item => item.dateCreated == maxDateCreated);
                }
            } 
            // if dont have sendCV pipeline, assigne to actual recruiter 
            else {
                const currentPipeline = proccesItem.status
                const eventLogOfCurrentPipeline = filreredEventLogs.filter(i => i.eventTypeId == currentPipeline)
                // find newest event
                const maxDateCreated = Math.max(...(eventLogOfCurrentPipeline.map(item => item.dateCreated)));
                if (!isNaN(maxDateCreated)) {
                    eventLogToGetAssigner = eventLogOfCurrentPipeline.find(item => item.dateCreated == maxDateCreated);
                }
            } 


            // old logic
            // const minDateCreated = Math.min(...(filreredEventLogs.map(item => item.dateCreated)));
            // let eventLogItemByMinDateCreated = filreredEventLogs.find(item => item.dateCreated == minDateCreated);

            // // if new recruiter send cv then he will be the sender
            // if (eventLogItemByMinDateCreated.eventTypeId === 12) {
            //     const maxDateCreated = Math.max(...(filreredEventLogs.map(item => item.eventTypeId === 12 && item.dateCreated)));
            //     if (!isNaN(maxDateCreated) && (maxDateCreated > minDateCreated)) {
            //         eventLogItemByMinDateCreated = filreredEventLogs.find(item => item.dateCreated == maxDateCreated);
            //     }
            // }

            return eventLogToGetAssigner;
        }

        const eventLog = proccesItem.eventLog;
        if(eventLog && eventLog.length) {
            const filreredEventLogs = eventLog.filter(item => item && item.eventTypeId !== 825  && item.eventTypeId !== 1 &&
                item.eventTypeId !== 105 && !(item.eventTypeId >= 831 && item.eventTypeId <= 837));
            if (filreredEventLogs && filreredEventLogs.length) {
                const eventLogItemByMinDateCreated = findMinEventLog(filreredEventLogs);
                const Sender = userStore.getUserById2(eventLogItemByMinDateCreated?.decisionMakerUserId);
                return utilsService.getDisplayName(Sender);
            }
        }

        return '';
    }

    getRejectReasonByEventLog = (process) => {
        const eventLog = this.getLastEventLog(process);
        if(eventLog) {
            const allRejectItems = [
                ...data.decline_options_recruiter_side,
                ...data.decline_options_candidate_side
            ]
            const item = allRejectItems.find(item => item.id === process.status);
            if(item) {
                return item.reason
            }
        }
        return '';
    }

    getUserStatus = (user) => {

        // talent
        if(this.isTalentUser(user)) {
            return this.getInviteTalentStatus(user.talentStatus, user);
        }
        else {
            const isActivated = user.isActivated;
            const canSignIn = user.canSignIn;
            let isRevoked = false;
            if(user && user.isRevoke) {
                isRevoked = true;
            }

            if(isRevoked) {
                return 'Revoke';
            }
            else if(!isActivated && !canSignIn) {
                return 'Not Active';
            }
            else if(isActivated && !canSignIn) {
                return 'Pending';
            }
            else if(isActivated && canSignIn) {
                return 'Active';
            }
        }

        return '';
    }

    generateReplyHtmlContent = (user, messageBody) => {

        const date = new Date()
        const dayName = date.toString().split(' ')[0]
        const shortMonth = date.toLocaleString('default', { month: 'short' });
        const dayAndYear = dayjs(date.getTime()).format('D, YYYY');
        const time = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })

        return  `<div dir="ltr">
        <br>On ${dayName}, ${shortMonth} ${dayAndYear} at ${time} ${this.getDisplayName(user)} (Ethosia HR) &lt;
            <a href="mailto:info@ethosia.com">
                info@ethosia.com</a>
            &gt; wrote:
        </div>
        <blockquote style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
        ${messageBody}
        </blockquote>`
    }

    removeStyleTags = htmlString => {
        return htmlString.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');
    }

    putItemToTop = (list, onFindItemIdx) => {
        const index = list.findIndex(onFindItemIdx);

        if (index !== -1) {
          const [item] = list.splice(index, 1);
          list.unshift(item);
        }
    }

    sortRecruiterList = (recruiterList, isAssignedTo) => {
        const sortedList = recruiterList.sort((a, b) => {
          if (a.displayName < b.displayName) return -1;
          if (a.displayName > b.displayName) return 1;
          return 0;
        });

        // push me and system to the top
        this.putItemToTop(sortedList, (recruiter) => recruiter.displayName.toLowerCase() === ' me');
        this.putItemToTop(sortedList, (recruiter) => recruiter.displayName.toLowerCase() === 'system ');

        return sortedList;
    }

    // chec if all profiles or users have valid phone number
    hasPhones = (array) => {
        let result = true;
        for (let item of array) {

            // user
            let res = item.phone;

            // profile
            if (!res && item?.phones?.length > 0) {
                res = utilsService.getPrimaryValue(item.phones, 'phone');
            }

            if (!res) {
                result = false;
                break;
            }
        };

        return result;
    }

    filterAndSortMetadata = (list, key, isSorted = true) => {
        if (!list) return [];

        // start with a string character
        const regex = new RegExp(/^[A-Za-z]/);

        const filteredList = list.filter((item) => item[key] && item[key] !== 'Unknown' && regex.test(item[key] ?? ''));

        if (!isSorted) return filteredList;

        return filteredList.sort((a, b) => {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        });
    }

    sortOptionsByOrder = (options = []) => {
        return options.sort((a, b) => a.order - b.order);
    }

    normalizeLanguages = (languages = []) => {
        return languages.map(lang => ({ ...lang, value: lang.name ?? lang.value }))
    }

    normalizeSeniorityLevels = (levels = []) => {
        const sortedLevels = this.sortOptionsByOrder(levels);
        return sortedLevels.filter(level => level.elId !== 18);
    }

    getTalentInviteUrl = (claimToken) => {
        return this.convertToTalentBaseURL() + `/claim/${claimToken}`
    }

    getBrightsourceJobUrl = (jobSlug) => {
        return this.convertToTalentBaseURL() + `/job/${jobSlug}`
    }

    convertToTalentBaseURL = () => {
        switch(window.location.origin) {
            case "http://localhost:3000":
                return "http://localhost:7000"
            case "https://rec-test1.firebaseapp.com":
                return "https://talent-test1.firebaseapp.com"
            case "https://rec.ethosia.co.il":
                return "https://talents.brightsource.com"

            default:
                return "";
        }
    }

    convertToJerusalemTime = milliseconds => {

        const date = new Date(milliseconds);
        date.toLocaleString('en-US', { timeZone: 'Asia/Jerusalem' })

        // Set the time components to 00:00:00 (start of the day)
        date.setHours(0, 0, 0, 0);

        // Extract the timestamp in milliseconds from the Jerusalem Date object
        const jerusalemMilliseconds = date.getTime();
        return jerusalemMilliseconds;
    }

    isRecruiterAdmin = () => {
        return userStore.user.permission === 5 && userStore.user.userType === 3
    }

    getTechnologies = (tags = []) => {
        if (!Array.isArray(tags)) return [];

        const specialties = metaDataStore.categoryOptions.specialties ?? metaDataStore.categories.specialties;
        const options = Array.isArray(specialties) ? specialties : [];

        return tags
          .map((tag) => typeof tag === 'number' ? options.find((option) => option.elId === tag) : tag)
          .filter((tag) => tag);
    }

    getWorldCityValue = (data, showAdminName = true, emptyValue = '') => {
        if (!data) return emptyValue;

        const city = data.cityAscii ?? data.city;
        const adminName = data.adminNameAscii ?? data.adminName;
        const country = data.country;

        if (!city || !country) return emptyValue;

        return adminName && showAdminName ? `${city}, ${adminName}, ${country}` : `${city}, ${country}`;
    };

    getWorldCityOption = (data) => {
        if (!data) return null;
        
        const cityId = data.cityId ?? data._id;
        const cityValue = this.getWorldCityValue(data, true);

        return {
            ...data,
            elId: cityId,
            value: cityValue,
        }
    }

    getMetadataValue = (mItem, key = 'value', emptyValue = 'N/A') => {
        return mItem ? mItem[key] : emptyValue;
    }

    findMetadataValue = (arr = [], elId, key = 'value', emptyValue = 'N/A') => {
        const mItem = arr.find(item => item.elId === elId);
        return mItem ? mItem[key] : emptyValue;
    }

    isTalentUser = (user) => user.userType === 4 && user.talentStatus !== undefined;
    isScraperUser = (user) => user.userType === 4 && user.talentStatus === undefined;
}

const utilsService = new UtilsService();
export default utilsService;
