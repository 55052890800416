import React, {PureComponent} from 'react';
import {InputForm} from '../../../components/forms';
import {TrashIcon} from '../../../components/svg';

class FromTheNews extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            link: ''
        };
    }

    addHandle = () => {
        const { addLink } = this.props;
        const { link } = this.state;
        addLink(link);
        this.setState({
            link: ''
        });
    };

    removeHandle = (idx) => {
        const { removeLink } = this.props;
        removeLink(idx);
    };

    render() {
        const { list } = this.props;
        const { link } = this.state;

        return (
            <div className='branches-locations from-the-web'>
                <div className="entry">
                    <InputForm
                        value={link}
                        type={'url'}
                        inputChange={e => this.setState({ link: e.target.value })}
                    />
                    <button
                        onClick={this.addHandle}
                        className="button black">
                        Add
                    </button>
                </div>

                {list && list.length > 0 && (
                    <div className="branches-list border-radius">
                        {
                            list.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="branch"
                                    >
                                        <div>
                                            <strong>{item}</strong>
                                        </div>

                                        <div>
                                            <div
                                                onClick={() => this.removeHandle(idx)}
                                                className="remove-icon">
                                                <TrashIcon/>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default FromTheNews;
