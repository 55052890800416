import { Button } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { ResponsiveDevice } from '../../../../constants/styles';
import { doPasswordUpdate, doSignInWithEmailAndPassword } from '../../../../services/auth.service';
import notificationsStore from '../../../../stores/notifications.store';
import userStore from '../../../../stores/user.store';
import { InputField } from '../../../components/form-fields/InputField';

const schema = yup.object().shape({
  currentPassword: yup.string().required('Missing current password'),
  newPassword: yup
    .string()
    .required('Missing new password')
    .min(6, 'Password must be at least 6 characters'),
  retypePassword: yup.string().test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.newPassword === value;
  }),
});

function PasswordTab() {
  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      retypePassword: '',
    },
    resolver: yupResolver(schema),
  });

  const handleSave = async (payload) => {
    if (!userStore.user) return;

    try {
      await doSignInWithEmailAndPassword(userStore.user.email, payload.currentPassword);
      await doPasswordUpdate(payload.newPassword);

      notificationsStore.showToast('Updated password successfully', 'success');
      reset({ currentPassword: '', newPassword: '', retypePassword: '' });
    } catch (error) {
      const errorMessage = error ? error.message : 'Failed to update password';
      notificationsStore.showToast(errorMessage, 'failure');
    }
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(handleSave)}>
        <InputField
          label="Current password"
          name="currentPassword"
          control={control}
          type="password"
          disableCollapse
        />

        <InputField
          label="New password"
          name="newPassword"
          control={control}
          type="password"
          disableCollapse
        />

        <InputField
          label="Retype password"
          name="retypePassword"
          control={control}
          type="password"
          disableCollapse
        />

        <Button
          type="submit"
          className="save-btn"
          minimal
          disabled={!formState.isDirty || formState.isSubmitting}
        >
          Save
        </Button>
      </form>
    </Wrapper>
  );
}

export default PasswordTab;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    width: 100%;
    max-width: 400px;

    ${ResponsiveDevice.TABLET} {
      max-width: 100%;
    }
  }

  label.MuiFormLabel-root {
    color: #404040;
    font-size: 16px;
    font-weight: 400;
  }

  p.MuiFormHelperText-root {
    position: absolute;
    left: 0;
    top: 100%;
  }

  .bp3-button.save-btn {
    margin-right: auto;
    padding: 14px 32px;
    border-radius: 60px;
    background-color: #307ff6;
    transition: all 150ms ease-in-out;

    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;

    &.bp3-disabled {
      background-color: #c7c7c7;
      color: #fff;
    }

    &:hover {
      color: #fff;
      background-color: #002a84;

      &.bp3-disabled {
        background-color: #c7c7c7;
        color: #fff;
      }
    }
  }
`;
