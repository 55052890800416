import React, {memo} from 'react';
import NavigationBar from '../../components/navigationBar/NavigationBar.component';
import './RecruiterLayout.scss';
import classNames from 'classnames';

export function RecruiterLayout({ children, className, ...rest }) {
  return (
    <RecruiterContainer className={className} {...rest}>
      <NavigationBar/>
      <RecruiterContent>
        {children}
      </RecruiterContent>
    </RecruiterContainer>
  );
}

export function RecruiterContainer({ children, className, ...rest }) {
  return (<div className={classNames('layout-container', className)} {...rest}>
    {children}
  </div>);
}

export function RecruiterContent({ children, center = true, className, ...rest }) {
  return (<main className={classNames("layout-content", center && "center-layout", className)} {...rest}>
    {children}
  </main>);
}

export const RecruiterHeader = memo(({ title, renderRightContent }) => {
  return <div className={'layout-header'}>
    <h1 className='title'>{title}</h1>

    <div>
      {renderRightContent?.()}
    </div>
  </div>
})
