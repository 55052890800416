import crypto from 'crypto';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import 'react-virtualized/styles.css';
import PipelinesView from './scenes/pipelines/Pipelines.view';
import BonusList from './scenes/billing/BonusList.view';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import LoginView from './scenes/auth/Login.view';
import ReactGA from 'react-ga';

import 'react-select/dist/react-select.css';
import 'react-input-range/lib/css/index.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import '../styles/app.scss';

import userStore from '../stores/user.store';
import settingsStore from '../stores/settings.store';
import ScrollToTop from './components/ScrollToTop.component';
import MailActionsView from './scenes/actions/MailActions.view';
import PageNotFoundView from './scenes/common/PageNotFound.view';
import PermissionRequiredView from './scenes/common/PermissionRequired.view';
import ForgotPasswordView from './scenes/auth/ForgotPasswordView';
import SearchResultsView from './scenes/searchResults/SearchResults.view';
import SearchResultsView2 from './scenes/searchResults/SearchResults2.view';

import Toast from './components/toast/Toast.component';
import MobileUnderConstructionView from './scenes/common/MobileUnderConstruction.view';
import Loader from './components/loader/Loader.component';
import NewMessageView from './scenes/inbox/NewMessage.view';
import SignupView from './scenes/auth/Signup.view';
import NewCompanyComponent from './scenes/user/editCompany/NewCompany.component';
import Dashboard from './scenes/dashboard/Dashboard.view2';
import ResetPassword from "./scenes/auth/ResetPassword.view";
import IconsListView from './internal/IconsList.view';
import NotificationsView from './scenes/notifications/Notifications.view';
import DocTest from './scenes/docsTest/DocTest';
import errorStore from '../stores/error.store';
import UserAccount from './scenes/user/user-account';

import ErrorBoundary from './components/ErrorBoundary.component';

const Profile = (lazy(() => (import('./scenes/talent/Profile.view2'))));
const NewProfile = (lazy(() => (import('./scenes/talent/NewProfile.view'))));
const AdvancedSearchResultsView = (lazy(() => (import('./scenes/advancedSearch/results/AdvancedSearchResults.view'))));

const Inbox = (lazy(() => (import('./scenes/inbox/Inbox.view'))));

const CompanyDetailsView = (lazy(() => (import('./scenes/user/companyDetails/CompanyDetails.view'))));
const SettingsView = (lazy(() => (import('./scenes/settings/Settings.view'))));


const SingleJobView = (lazy(() => (import('./scenes/jobs/SingleJob.view'))));
const CompaniesView = (lazy(() => (import('./scenes/companies/Companies.view'))));
const DuplicateJobView = (lazy(() => (import('./scenes/jobs/newJob/DuplicateJob.view'))));
const JobsNavigator = (lazy(() => (import('./scenes/jobs/JobsNavigator.view'))));

const TitleManagement = (lazy(() => (import('./scenes/settings/TitleManagement.component'))));
const AdminSettingsComponent = (lazy(() => (import('./scenes/user/AdminSettings.component'))));
const SearchSettingsOverviewPage = (lazy(() => (import('./scenes/search-settings/SearchSettingsOverviewPage'))));

const BillingPage = (lazy(() => (import('./scenes/billing/BillingPage'))));
const BillingOverviewPage = (lazy(() => (import('./scenes/billing/BillingOverviewPage'))));
const MainCandidates = (lazy(() => (import('./scenes/candidates'))));
const AddEditJob = (lazy(() => (import('./scenes/main-jobs/AddEditJob'))));
const SearchPage = (lazy(() => (import('./scenes/search'))));
const CompanyPage = (lazy(() => (import('./scenes/companies/Company'))));
const SettingsPage = (lazy(() => (import('./scenes/settings'))));
const JobDetailsPage = (lazy(() => (import( './scenes/jobs/JobDetails'))));

// TODO: remove old pages
const CompanyView = (lazy(() => (import('./scenes/company/Company.view'))));
const OldProfile = (lazy(() => (import('./scenes/talent/Profile.temp'))));

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-130291709-1');
    hotjar.initialize(1243684, 6);
}

global.errorDataBeforeCrush = null;
global.bodyBeforeCrush = null;


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
        global.stack = new Error().stack;
        settingsStore.setRedirectRoute(rest.location.pathname + rest.location.search);

        return (
            !userStore.logged || userStore.needUpdatePass
                ? <Redirect to='/login' />
                : <Component {...props} />
        );
    }} />
);

const isMobileDevice = () => {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

const sendErrorToBackend = (error) => {
    const errorObject = {
        userId: userStore.user._id,
        userEmail: userStore.user.email,
        sender: 'Front End',
        requestId: crypto.randomBytes(16).toString('hex'),
        message: error && error.message,
        stacktrace: error && error.stack,
        type: 'Crush',
        href: window.location.href
    }

    if (global.errorDataBeforeCrush) {
        errorObject.responseFromServer = global.errorDataBeforeCrush;
    }

    if (global.bodyBeforeCrush) {
        errorObject.body = global.bodyBeforeCrush;
    }

    errorStore.sendError(errorObject);
}

const App = () => {
    const [error, setError] = useState('');

    useEffect(() => {
        window.revision = process.env.REACT_APP_REVISION;

        window.onerror = (message, file, line, column, errorObject) => {
            setError(`${message || 'Something went wrong'}. Please try to reload the page`);

            sendErrorToBackend(errorObject)
            return false;
        }

        window.onunhandledrejection = (err) => {
            sendErrorToBackend(err.reason)
            return false;
        }

    }, [])

    return (
        <div>
            {/* <ModalDialog isOpen={!!error} showHeader={false}>
                <p>{error}</p>
                <ButtonV2 variant='solid' style={{ marginTop: 24 }} onClick={() => window.location.reload()}>Reload</ButtonV2>
            </ModalDialog> */}

            <Router>
                <ErrorBoundary>
                    <ScrollToTop>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Redirect from="/" exact to="/inbox" />
                                <PrivateRoute path='/pipelines/:gridIndex' component={PipelinesView} />
                                <PrivateRoute path='/billing/:slug' component={BillingPage} />
                                <PrivateRoute path='/billing' component={BillingPage} />
                                <PrivateRoute path='/billingList' component={BillingOverviewPage} />

                                <PrivateRoute path='/bonus-list/:slug' component={BonusList} />
                                <PrivateRoute path='/notifications' component={NotificationsView} />
                                <PrivateRoute path='/pipelines' component={PipelinesView} />
                                <PrivateRoute path='/candidates' component={MainCandidates} />
                                {/* <PrivateRoute path='/user/details' component={DetailsView} /> */}
                                <PrivateRoute path='/user/details' component={UserAccount} />
                                <PrivateRoute path='/user/company-details'
                                    component={CompanyDetailsView} />
                                {/* <PrivateRoute path='/settings-new'
                                            component={SettingsView}/> */}
                                {/* <PrivateRoute path='/settings'
                                            component={CompanyDetailsView}/> */}
                                <PrivateRoute path='/settings'
                                    component={SettingsPage} />
                                <PrivateRoute path='/title/:value'
                                    component={TitleManagement} />
                                <PrivateRoute path='/user/admin-settings'
                                    component={AdminSettingsComponent} />
                                <PrivateRoute path='/jobs/:slug' component={JobDetailsPage} />
                                <PrivateRoute path='/oldjobs/:slug' component={SingleJobView} />
                                <PrivateRoute path='/jobs' component={JobsNavigator} />
                                <PrivateRoute path='/companies' component={CompaniesView} />
                                <PrivateRoute path='/new-job/:companySlug' component={AddEditJob} />
                                <PrivateRoute path='/new-job' component={AddEditJob} />
                                <PrivateRoute path='/edit-job/:slug' component={AddEditJob} />
                                <PrivateRoute path='/duplicate-job/:slug' component={DuplicateJobView} />
                                <PrivateRoute path='/oldcompany/:id' component={CompanyView} />
                                <PrivateRoute path='/company/:id' component={CompanyPage} />

                                <PrivateRoute path='/create-new-company' component={NewCompanyComponent} />
                                <PrivateRoute path='/permission-required'
                                    component={PermissionRequiredView} />
                                <PrivateRoute path='/search-results'
                                    component={SearchResultsView} />
                                <PrivateRoute path='/search-results2'
                                    component={SearchResultsView2} />
                                <PrivateRoute path='/process/:processId/profile/:profileId'
                                    component={Profile} />
                                <PrivateRoute path='/profile/:profileId'
                                    component={Profile} />
                                <PrivateRoute path='/oldprofile/:profileId'
                                    component={OldProfile} />
                                <PrivateRoute path='/new-message'
                                    component={NewMessageView} />
                                <PrivateRoute path='/inbox'
                                    component={Inbox} />
                                <PrivateRoute path='/search'
                                    component={SearchPage} />
                                <PrivateRoute path='/search-advanced-results'
                                    component={AdvancedSearchResultsView} />
                                <PrivateRoute path='/search-settings'
                                            component={SearchSettingsOverviewPage} />

                                <PrivateRoute path='/test-docs'
                                    component={DocTest} />

                                <PrivateRoute path='/actions/:action/:processId'
                                    component={MailActionsView} />

                                <PrivateRoute path='/profile/:profileId' component={Profile} />
                                <PrivateRoute path='/create-new-profile' component={NewProfile} />
                                <PrivateRoute path='/dashboard' component={Dashboard} />
                                <PrivateRoute path='/icons-list' component={IconsListView} />

                                <Route path='/forgot-password' component={ForgotPasswordView} />
                                <Route path='/reset-password' component={ResetPassword} />
                                <Route path='/login' component={LoginView} />
                                <Route path='/signup/:token' component={SignupView} />
                                <Route component={PageNotFoundView} />
                                <Route path='/under-construction' component={MobileUnderConstructionView} />
                            </Switch>
                        </Suspense>
                    </ScrollToTop>
                </ErrorBoundary>
            </Router>
            <Toast />
        </div>
    );
};

export default App;
