import React, { Component } from 'react';
import userStore from '../../../stores/user.store';
import processStore from '../../../stores/process.store';
import data from '../../../constants/data';
import { CloseIcon } from '../svg';
import { SuggestComponent } from '../forms';
import companyStore from '../../../stores/company.store';
import utilsService from '../../../services/utils.service';
import profileStore from '../../../stores/profile.store';


class TerminateProcessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            option: null,
            disabled: true,
            showAddComment: false,
            comment: '',
            companies: null,
            selectedNewCompany: null,
            selectedJobSlug: null,
            filteredProcesses: null
        };

        this._isMounted = false;

    }

    componentDidMount = async () => {
        this._isMounted = true;

        await companyStore.getCompaniesNames();
        const companies = companyStore.companiesNamesWithJobs.map(item => {
            return {
                elId: item.slug,
                value: item.name,
                companyId: item._id,
                slug: item.slug,
                bsId: item.bsId,
                emsId: item.emsId,
                name: item.name,
                _id: item._id
            };
        });

        if (this._isMounted) {
            this.setState({ companies: companies });
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectOptions = (e, option) => {
        const { selectedNewCompany } = this.state
        this.setState({ option: option, disabled: (option.reason === "Accepted Another Offer" && !selectedNewCompany) });
        if (option.id === 291) {
            this.setState({ showAddComment: true });
        } else {
            this.setState({ showAddComment: false });
        }
    };

    showHideAddComment(isToShow) {
        this.setState({ showAddComment: isToShow });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ updating: true });
        const { process } = this.props.data;
        const { comment, option, selectedNewCompany } = this.state;

        const id = process._id;
        if ((option.reason === 'Accepted Another Offer' || option.reason === 'Remain in his current Position')) {
            //in order to close all processes regarding to candidate and change availability
            const processes = await processStore.getProcessesByProfileId(process.profileId);

            let profileBody = {
                isAvailable: false,
                followUpDate: Date.now(),
                user: userStore.user._id,
                userSlug: userStore.user.slug
            }

            if(option.reason === 'Accepted Another Offer' && selectedNewCompany) {
                profileBody = this.getProfileBody(selectedNewCompany);
            }

            const processesBody = []

            // close all relevant processes 
            processes.forEach(item => {
                const isDeclineOrWithdrawn = utilsService.checkIfDeclineOrWithdrawn(item);
                if (!isDeclineOrWithdrawn) {
                    const body = {
                        id: item._id,
                        status: option.id,
                        processSlug: item.slug,
                        profileId: item.profile,
                        comment: option.reason,
                        eventType: 'Close',
                        eventTypeId: option.id,
                        decisionMakerEmail: userStore.user.email,
                        decisionMakerName: userStore.user.displayName,
                        previousStatus: item.status,
                    };
                    processesBody.push(body)
                }
            });

            const promises = []
            promises.push(profileStore.updateProfile(process.profileId, profileBody));
            if (processes && processes.length > 0) {
                promises.push(processStore.changeStatuses(processesBody, this.props.isSingleJobView, this.props.isSingleCompanyView, false));
            }

            await Promise.all(promises);
        }
        else {
            const commentToSend = option.reason + (comment !== '' ? ':' + comment : '');
            const body = {
                status: option.id,
                profileId: process.profileId,
                comment: option.id === 291 ? commentToSend : option.reason,
                eventType: 'Rejection',
                eventTypeId: option.id,
                decisionMakerEmail: userStore.user.email,
                decisionMakerName: userStore.user.displayName,
                previousStatus: process.status
            };

            await processStore.changeStatus(id, body, this.props.isSingleJobView, this.props.isSingleCompanyView);

        }

        this.setState({ updating: false });
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.successEvent();
        }
    };

    getProfileBody = (company) => {
        let profileBody = {
            isAvailable: false,
            user: userStore.user._id,
            userSlug: userStore.user.slug
        };
        
        profileBody = {...profileBody , 
            company: company && company.elId !== -1 ? company.name : "",
            companyId: company && company.elId !== -1 ? company._id : null,
            companySlug: company && company.elId !== -1 ? company.slug : '',
            companyEmsId: company && company.elId !== -1 ? company.emsId : -1
        }

        return profileBody;
    }

    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    selectSuggestItem = (item, type) => {
        if (type === 'selectedNewCompany') {
            this.setState({ [type]: item, disabled: false });
        }
    };

    render() {
        const { option, companies, selectedNewCompany } = this.state;
        return (
            <div className={`action-process ${this.state.showAddComment ? 'terminate-process-open' : 'terminate-process-close'}`}>
                <header>
                    <h2><span
                        className='capitalized'> {this.props.data.profile.firstName} {this.props.data.profile.lastName} </span>
                        Doesn't match?
                    </h2>
                    {this.props.isModal && (
                        <div onClick={this.handleCloseClick
                        }
                            className='circle-close modal-close'>
                            <CloseIcon />
                        </div>)}

                    <h3>
                        Tell us why you decided to disqualify so we can better match in the future
                    </h3>
                </header>

                <div className="reason-body">
                    <section className="action-process-body">
                        <div className="recruiter-side-header">From Our Side</div>
                        {data.decline_options_recruiter_side.map((item, idx) => {
                            return (
                                <div className="radio" key={idx}>
                                    <input id={'radio-' + idx} name="reason" type="radio"
                                        onChange={(e) => {
                                            this.selectOptions(e, item);
                                        }}
                                    />
                                    <label htmlFor={'radio-' + idx} className="radio-label">{item.reason}</label>

                                </div>

                            );
                        })}

                    </section>
                    <section className="action-process-body">
                        <div className="candidate-side-header">From Candidate Side</div>
                        {data.decline_options_candidate_side.map((item, idx) => {
                            return (
                                <div className="radio" key={idx + 'hr'}>
                                    <input id={'radio-' + idx + 'hrd'} name="reason" type="radio"
                                        onChange={(e) => {
                                            this.selectOptions(e, item);
                                        }}
                                    />
                                    <label htmlFor={'radio-' + idx + 'hrd'} className="radio-label">{item.reason}</label>
                                
                                    {option && option.reason === "Accepted Another Offer" && item.reason === "Accepted Another Offer" &&
                                        <div className="new-company">
                                            <SuggestComponent
                                                defaultValue={'---Unknown---'}
                                                isLoading={!companies}
                                                label={'New Company'}
                                                items={companies ? companies : []}
                                                selectedItem={selectedNewCompany}
                                                selectItem={item => this.selectSuggestItem(item, 'selectedNewCompany')}

                                            />
                                        </div>
                                    }
                                
                                </div>

                            );
                        })}

                    </section>

                </div>

                {this.state.showAddComment && (<div className="comment form-item">
                    <div>Add a note</div>
                    <textarea
                        value={this.state.comment}
                        onChange={(e) => {
                            this.setState({ comment: e.target.value });
                        }}
                    />
                </div>)}

                <div className='footer'>
                    <button
                        onClick={this.handleCloseClick}
                        className='button gray'>
                        Cancel
                    </button>
                    <button
                        onClick={this.handleSubmit}
                        disabled={this.state.disabled}
                        className={`button black done-button-old  
                            ${!option || (option.reason === "Accepted Another Offer" && !selectedNewCompany) ? 'disabled' : ''} 
                            ${this.state.updating ? 'loading' : ''}`}>
                        Done
                    </button>
                </div>
            </div>
        );
    }
}

export default TerminateProcessComponent;
