import React from 'react';
import './fancySwitchButton2.scss';

const FancySwitchButtonComponent2 = props => {

    const { checked, readOnly, checkAction, id, isLeftSideLabel } = props;
    return (
        <div className='fancy-switch-button2'>

            {isLeftSideLabel && (
                <span className={`text ${checked ? 'text-checkbox-on' : ''}`}>{checked ? props.lables[0] :  props.lables[1]}</span>    
            )}

           
            <div className="onoffswitch">
                <div>
                    <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id={'myonoffswitch' + id}
                        checked={checked}
                        readOnly={readOnly}
                        onChange={event => checkAction(event)}

                    />
                    <label className="onoffswitch-label" htmlFor={'myonoffswitch' + id}/>
                </div>
            </div>

        </div>
    );
};

FancySwitchButtonComponent2.propTypes = {

};
  
FancySwitchButtonComponent2.defaultProps = {
lables: ['On','Off'],
checked: false,
};

export default React.memo(FancySwitchButtonComponent2);
