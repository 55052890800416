import React from 'react';
import ModalComponent from '../modal/Modal.component';
import SuperSearchUsersProfilesInputComponent from '../forms/multiSelects/SuperSearchUsersProfilesInputComponent';
import { CloseIcon } from '../svg';
import profileStore from '../../../stores/profile.store';
import searchStore from '../../../stores/search.store';

class ReassignCvToProfileModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false
        };
    }

    changeOwnershipHandle = async () => {
        const { attachment, closeEvent, isInbox, attachmentObjectId } = this.props;

        if (searchStore.profilesUsersSearchPersonsTo.length === 0) {
            return;
        }

        this.setState({
            loader: true
        });

        let attachmentId_ = null;
        if (attachment) {
            attachmentId_ = attachment._id;
        }

        const profileId = searchStore.profilesUsersSearchPersonsTo[0]._id;
        await profileStore.changeCvOwnership({
            attachmentObjectId,
            attachmentId: attachmentId_,
            profileId: profileId,
            isInbox: isInbox
        });

        this.setState({
            loader: false
        });
        setTimeout(() => {
            closeEvent();
        }, 700);
    };

    render() {
        const { closeEvent } = this.props;
        const { loader } = this.state;

        let buttonClasses = 'button darkblue-new';
        if (loader) {
            buttonClasses += ' loading';
        }

        return (
            <ModalComponent onClose={() => closeEvent()}>
                <div className='modal-box find-profile-modal'>
                    <header>
                        <h2>Change Cv ownership</h2>
                    </header>
                    <div onClick={closeEvent}
                         className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>

                    <div className="find-profile">
                        <label>Candidate</label>
                        <SuperSearchUsersProfilesInputComponent
                            disableUser={true}
                            isValidHandleInParent={true}
                            limit={1}
                            isCVNeeded={false}
                        />
                    </div>
                    <button
                        onClick={this.changeOwnershipHandle}
                        className={buttonClasses}>
                        Change
                    </button>
                </div>
            </ModalComponent>

        );
    }
}

export default ReassignCvToProfileModalComponent;
