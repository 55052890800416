import React, { Component } from 'react';
import utilsService from "../../../services/utils.service";
import Loader from "../../components/loader/Loader.component";
import {
    LogoIcon,
    LogoIcon2,
    PasswordHideIcon,
    PasswordShowIcon,
    SignupInternalServerError
} from "../../components/svg";
import { confirmPasswordReset, verifyResetingPassword } from "../../../services/auth.service";

import './resetPassword.scss';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            email: '',
            password: '',
            password2: '',
            isError: false,
            isShowPassword: false,
            isShowLoader: false,
            isPasswordValid: false,
            internalServerError: false,
            isLinkValid: true,
            code: null,
            validation: {},
            error: ''
        };
    }

    proceedToUrl = () => {
        this.props.history.push(
            '/login'
        );
    };

    async componentDidMount() {
        document.title = 'Reset Password - Ethosia';

        const parsedSearch = utilsService.queryParamsParser(this.props.location.search);
        const actionCode = parsedSearch.oobCode;
        if (!actionCode) {
            this.setState({ loader: false, isLinkValid: false });
        }

        verifyResetingPassword(actionCode)
            .then(email => {
                this.setState({ loader: false, email, isLinkValid: true, code: actionCode });
            }, error => {
                this.setState({ loader: false, error: error.message, isLinkValid: false });
            });
    }

    validatePassword(password) {
        const validPaterns = {};
        validPaterns.lengths = password.length >= 8;
        validPaterns.upper = !!utilsService.validPasswordOneUpperCaseLatter(password);
        validPaterns.lower = !!utilsService.validPasswordOneLowerCaseLatter(password);
        validPaterns.number = !!utilsService.validPasswordOneNumber(password);
        validPaterns.specia = !!utilsService.validPasswordSpecialCharacter(password);

        return validPaterns;
    }

    inputOnChange = e => {
        const password = e.target.value;
        const validation = this.validatePassword(password);
        this.setState({ password, validation })
    };

    inputOnChangePassword2 = e => {
        const password2 = e.target.value;
        const { password, validation } = this.state;
        if (password !== '' && password === password2) {
            validation.equality = true
        }
        let allValid = true;

        const validationKeys = ['lengths', 'upper', 'lower', 'number', 'specia', 'equality'];

        validationKeys.forEach(key => {
            if (!validation[key]) {
                allValid = false
            }
        });

        this.setState({ password2, validation, isPasswordValid: allValid });
    };

    addContainerClass() {
        if (this.state.internalServerError) {
            return " internal-server-error";
        } else if (!this.state.isLinkValid) {
            return " auth-invalid-link";
        } else
            return "";
    }

    selectContainerClass() {
        if (this.state.internalServerError)
            return "signup-internal-server-error";
        else if (!this.state.isLinkValid)
            return "signup-invalid-link";
        else
            return "reset-password-page";
    }

    showPassword = () => {
        this.setState({
            isShowPassword: !this.state.isShowPassword
        });
    };

    submitHandle = () => {
        const { password, isPasswordValid, code } = this.state;
        if (isPasswordValid) {
            this.setState({
                isShowLoader: true
            });
            confirmPasswordReset(code, password).then(() => {
                this.proceedToUrl();
            }).catch(e => {
                this.setState({ loader: false, isShowLoader: false, error: e.message, internalServerError: true });
            })
        }
    };

    render() {
        const {
            loader,
            isLinkValid,
            internalServerError,
            isShowPassword,
            isError,
            password,
            password2,
            isShowLoader,
            validation,
            isPasswordValid,
            error
        } = this.state;
        return (
            <div>
                {loader && (
                    <Loader/>
                )}
                {!loader && (
                    <main className={"auth border-radius shadow4" + (this.addContainerClass())}>
                        <div className={this.selectContainerClass()}>
                            {!internalServerError && (
                                <div className="login-icon-wrapper">
                                    <LogoIcon/>
                                </div>
                            )}
                            {isLinkValid && !internalServerError && (
                                <React.Fragment>
                                    <h1>Reset Password</h1>
                                    <p className='reset-password-label'>Enter new password</p>
                                    <PasswordInput
                                        label='New Password'
                                        isShowPassword={isShowPassword}
                                        isError={isError}
                                        password={password}
                                        isShowLoader={isShowLoader}
                                        classes={'password'}
                                        inputChange={this.inputOnChange}
                                        showPassword={this.showPassword}
                                    />

                                    <PasswordInput
                                        label='Retype Password'
                                        isShowPassword={isShowPassword}
                                        isError={isError}
                                        password={password2}
                                        isShowLoader={isShowLoader}
                                        classes={'retype-password'}
                                        inputChange={this.inputOnChangePassword2}
                                        showPassword={this.showPassword}
                                    />

                                    <ValidationBox
                                        validation={validation}
                                    />

                                    <button
                                        onClick={this.submitHandle}
                                        className={"black reset-password-button" + (isShowLoader ? " disabled-button" : " button")}
                                        disabled={isShowLoader && !isPasswordValid}>
                                        Submit
                                    </button>
                                </React.Fragment>
                            )}
                            {!isLinkValid && !internalServerError && (
                                <div className="invalid-link-container">
                                    <div className="invalid-link-header">Invalid link</div>
                                    <div className="invalid-link-body">This link is no longer valid. Please contact
                                        Ethosia for a new link.
                                    </div>
                                </div>
                            )}
                            {internalServerError && (
                                <div className="internal-server-error-container">
                                    <SignupInternalServerError className="internal-server-error-icon"/>
                                    <div className="internal-server-error-header">Sorry</div>
                                    <div
                                        className="internal-server-error-body">{error ? error : 'An internal server error has occured. Please try again later.'}
                                    </div>
                                    <button className="button black retry-button"
                                            onClick={(e) => this.setState({ internalServerError: false })}>
                                        Retry
                                    </button>
                                </div>
                            )}
                        </div>
                        {isShowLoader && (
                            <div className="signup-loader">
                                <Loader/>
                            </div>
                        )}
                    </main>
                )}
            </div>
        );
    }
}

export default ResetPassword;

const PasswordInput = props => {
    const { label, isShowPassword, isError, password, isShowLoader, classes } = props;
    const { inputChange, showPassword } = props;
    return (
        <div className={classes}>
            <label className="lbl">{label}</label>
            <div className="password-container">
                <input
                    autoComplete="new-password"
                    type={isShowPassword ? "text" : "password"}
                    className={"password " + (isError && !password ? 'error-border' : '')}
                    value={password}
                    onChange={inputChange}
                    disabled={isShowLoader}
                />
                {isShowPassword && (
                    <div onClick={showPassword}>
                        <PasswordShowIcon
                            className="password-icon"
                            disabled={isShowLoader}
                        />
                    </div>
                )}
                {!isShowPassword && (
                    <div onClick={showPassword}>
                        <PasswordHideIcon
                            className="password-icon"
                            disabled={isShowLoader}
                        />
                    </div>
                )}
            </div>
            {isError && !password && (
                <label className="error">Missing</label>
            )}
        </div>
    )
};

const ValidationBox = props => {
    const { validation } = props;

    const validationData = [
        {
            title: 'Minimum 8 characters',
            valid: validation.lengths
        },
        {
            title: 'One upper case character',
            valid: validation.upper
        },
        {
            title: 'One number',
            valid: validation.number
        },
        {
            title: 'One lower case character',
            valid: validation.lower
        },
        {
            title: 'One special character',
            valid: validation.specia
        },
        {
            title: 'Password equal',
            valid: validation.equality
        },
    ];

    return (
        <div className="password-hint-container">
            {validationData.map((hints, idx) => {
                let classes = 'password-hint-renderFilteredItems';
                if (hints.valid) {
                    classes += ' hint-pass-ok';
                }

                return (
                    <div
                        key={idx}
                        className={classes}>
                        {hints.title}
                    </div>
                )
            })}

        </div>
    )

};
