import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';


class JobsBoardsStore {

    @observable publishedJob = {};

    @action.bound
    async getPublishedJob(jobId) {

        const response = await fetchService.authenticatedGet(`/publish-job?id=${jobId}`);
        if(response && response.data) {
            this.publishedJob = response.data;
        } else {
            this.publishedJob = {};
        }

        return response && response.data;
        
    }

    @action.bound
    async publishJob(jobId, body) {
        const response = await fetchService.authenticatedPost(`/publish-job/${jobId}`, body);
        let isSucceeded = false;
        if(response && response.data && response.data.job) {
            this.publishedJob = response.data;
            isSucceeded = true
        }
        return isSucceeded;
    }

}

const jobsBoardsStore = new JobsBoardsStore();

export default jobsBoardsStore;