import {
    doRefreshToken, doSignOut
} from './auth.service';
import userStore from '../stores/user.store';
import firebase from 'firebase';
import { auth } from './firebase.service';

class IdleCheckRefreshComponent {

    checkTime = () => {
        if (window.lastActivity <= Date.now() - 300000) {
            console.log('Refreshing the token');
            doRefreshToken().then(res => {
                userStore.userToken = res;
            });
        }
    };

    registerIdleChecker = () => {
        window.lastActivity = Date.now();
        document.addEventListener('click', () => {
            window.lastActivity = Date.now();
        });

        window.setInterval(this.checkTime, 180000);
    };

    registerRefreshToken = () => {
        window.setInterval(this.verifyToken, 180000);
    };

    verifyToken = async () => {
        if (auth.currentUser) {
            const token = await auth.currentUser.getIdToken();
            userStore.userToken = token;
        }
        
        // return new Promise((resolve, reject) => {

        //     const handleSuccess = (token) => {
        //         userStore.userToken = token;
        //         resolve();
        //     }

        //     const handleError = () => {
        //         resolve();
        //     }

        //     auth.onAuthStateChanged(user => {
        //         console.log({user})
        //         if (!user) {
        //             return handleError();
        //         }

        //         user.getIdToken()
        //             .then(googleJwt => {
        //                 return handleSuccess(googleJwt);
        //             })
        //             .catch(() => {
        //                 return handleError();
        //             })
        //     })
        // })
    };

}

const idleCheckRefreshComponent = new IdleCheckRefreshComponent();

export default idleCheckRefreshComponent;
