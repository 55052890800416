import React from 'react';
import ContextMenu from '../ContextMenu.component';
import {Link, NavLink} from 'react-router-dom';
import userStore from '../../../stores/user.store';
import {InviteMemberModal} from '../modalComponents';
import utilsService from '../../../services/utils.service';
import VersionShowComponent from '../VersionShow.component';
import {ChatsNew, LogoIcon2} from '../svg';
import Notifications from '../notifications/Notifications.component';
import SearchComponent from '../search/Search.component';
import './empNavigationBar.scss';
import permissionsService from '../../../services/permissions.service';
import DefaultImageComponent from '../DefaultImage.component';

class EmployerNavigationBar extends React.PureComponent {

    static logOut() {
        userStore.logOut();
    }

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen_Invite: false,
            showVersion: false
        };
    }

    render() {
        const user = userStore.user;
        let profileName;
        if (user) {
            profileName = utilsService.getDisplayName(user);
        }

        const name = user ? user.displayName : 'no user logged';
        let wrapperStyles = 'top-black-bar';

        const isRecruiter = utilsService.isRecruiter(userStore.user);
        return (
            <header className={wrapperStyles}>
                <nav className="emp-navigation-bar max-width">
                    {/*LOGO*/}
                     <Link to={isRecruiter ? "/dashboard" : "/"}>
                        <LogoIcon2/>
                    </Link>

                    {/*NAVIGATION LINKS*/}
                    <div className="navigation-menu">
                        <NavLink to="/pipelines"
                                 activeClassName='active'>Candidates</NavLink>
                        <NavLink to="/jobs" activeClassName='active'>Jobs</NavLink>

                    </div>

                    <div className="menu-right">

                        <SearchComponent/>

                        {/*BUTTONS */}

                        <div className="icons hide">
                            <div className="chat-notification">
                                <ChatsNew/>
                            </div>
                            <Notifications/>
                        </div>
                        <div className="buttons">
                            {permissionsService.canInviteTeam(user) && (
                                <button className="button bk-white-less invite"
                                        onClick={() => this.setState({ isModalOpen_Invite: true })}>
                                    Invite
                                </button>
                            )}
                            <Link className='button bk-white-less' to="/new-job">
                                New Job
                            </Link>
                        </div>
                        {/*USER MENU*/}

                        <div className="icon-menu">
                            {user && (<div className='menu-container'>
                                    <ContextMenu top="64">
                                        <div>
                                            <div className='menu-header'>
                                                <span className="profile-name-capitalized">{name}</span>
                                            </div>
                                            <div className='menu-item'>
                                                <Link to='/user/details'>
                                                    My Account
                                                </Link>
                                            </div>
                                            {/* <div className='menu-item'><Link to='/user/company-details'>Company
                                                Settings</Link>
                                            </div> */}
                                            <div className='menu-item'><Link to='/settings'>Settings</Link>
                                            </div>
                                            <div className='menu-item log-out'>
                                                <a onClick={() => EmployerNavigationBar.logOut()}>Sign Out</a>
                                            </div>
                                        </div>
                                    </ContextMenu>
                                    <div className="profile-pic">
                                        <DefaultImageComponent
                                            url={utilsService.getProfileImage(user)}
                                            alt={profileName}
                                            profileName={profileName}
                                            isExposed={true}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </nav>

                {this.state.isModalOpen_Invite && (
                    <InviteMemberModal
                        closeEvent={() => this.setState({ isModalOpen_Invite: false })}
                    />
                )}

                {this.state.showVersion && (
                    <VersionShowComponent/>
                )}
            </header>
        );
    }
}

export default EmployerNavigationBar;
