import React from 'react';
import {Link} from 'react-router-dom';
import utilsService from '../../../../services/utils.service';

const TopEditCompany = props => {
    const { save, formLoader, id } = props;
    const isRecruiter = utilsService.isRecruiter();
    // let toUrl = isRecruiter && id ? `/company/${id}`: '/user/company-details';
    let toUrl = isRecruiter && id ? `/company/${id}`: '/settings';
    return (
        <div className='gazmanov edit-company-top'>
            <div className="gazmanov-top">
                {!props.isNewCompany && (
                    <div className="left">
                        Edit Company Details
                    </div>
                )}
                {props.isNewCompany && (
                    <div className="left">
                        Create New Company
                    </div>
                )}
                <div className="right">
                    <Link className='button bk-less' to={toUrl}>
                        Cancel
                    </Link>

                    <button
                        onClick={save}
                        className={formLoader ? 'button bk-darkblue loading' : 'button bk-darkblue'}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TopEditCompany;
