import { ToggleButton, debounce } from '@brightsource/brightsource-ui-lib';
import React, { useCallback, useState } from 'react';
import { styled } from 'styled-components';
import notificationsStore from '../../../../stores/notifications.store';
import userStore from '../../../../stores/user.store';

const data = [
  { label: 'System reminders', id: 'notifications' },
  { label: 'Update me if a candidate advances in a different process', id: 'candidate_got_offer' },
  { label: 'Get updates when candidates remove their application', id: 'candidate_withdrawn' },
  { label: 'Send me daily summary email', id: 'dailySummary' },
  { label: 'Send me weekly summary email', id: 'weeklySummary' },
];

function PreferencesTab() {
  const [preferences, setPreferences] = useState(() => {
    const { settings } = userStore.user;

    const emailSettings = data.reduce((obj, item) => {
      const isChecked = Boolean(settings && settings.email && settings.email[item.id]);
      return { ...obj, [item.id]: isChecked };
    }, {});

    return emailSettings;
  });

  const onSavePreferences = async (payload) => {
    try {
      await userStore.changeSettings(payload);
      notificationsStore.showToast('Email settings updated successfully.', 'success');
    } catch (error) {
      console.log('Failed to change settings', error);
    }
  };

  const debounceSavePreferences = useCallback(debounce(onSavePreferences, 500), []);

  const onChange = (id) => {
    const isChecked = Boolean(preferences[id]);
    const newPreferences = { ...preferences, [id]: !isChecked };

    setPreferences(newPreferences);
    debounceSavePreferences({ key: `email.${id}`, value: !isChecked });
  };

  return (
    <Wrapper>
      {/* <h6>Emails & Notifications</h6> */}

      {data.map((item) => (
        <StyledToggleButton
          key={item.id}
          label={item.label}
          checked={Boolean(preferences[item.id])}
          onChange={() => onChange(item.id)}
          className={item.hasDivider ? 'has-divider' : undefined}
        />
      ))}

      {/* <Button className="save-btn" minimal onClick={onSave}>
        Save
      </Button> */}
    </Wrapper>
  );
}

export default PreferencesTab;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  h6 {
    color: #6c6c6c;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
  }

  .bp3-button.save-btn {
    margin-left: auto;
    padding: 16px 32px;
    border-radius: 60px;
    background-color: #307ff6;
    transition: all 150ms ease-in-out;

    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;

    &:hover {
      color: #fff;
      background-color: #002a84;
    }
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  min-height: 40px;

  &.toggle-button {
    display: flex;
    align-items: center;
  }

  &.has-divider {
    padding-top: 8px;
    padding-bottom: 24px;
    border-bottom: 1px solid #c7c7c7;
  }

  .toggle-container {
    width: 100%;
    // max-width: 400px;

    & > * {
      margin-top: 0;
    }
  }

  .toggle-button-text {
    font-weight: 500;
  }
`;
