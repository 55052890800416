import React from 'react';
import utilsService from '../../../services/utils.service';
import ModalComponent from "../modal/Modal.component";
import {CloseIcon} from '../svg';
import jobStore from '../../../stores/job.store';


const EmailMessageModal = props => {

    const { profiles, jobs } = props;

    const isJobScreen = window.location.href.includes('/jobs') ? true : false

    const handleSubmit = async (e) => {
        props.closeEvent();

    };

    const getNames = () => {
        const filteredNames = profiles.filter(person => person.suggestedJobs)
        const names = filteredNames.map(item => utilsService.getDisplayName(item));
        return names.join("\r\n");
    }

    const getJobs = () => {
        const filteredJobs = jobs.filter(job => {
            return profiles[0].suggestedJobs?.includes(job)
        })
        const names = filteredJobs.map(item => {
            const jobData = jobStore.jobs[item]
            return jobData.title
        });
        return names.join("\r\n");
    }
    
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box  email-message'>
                <div className='action-process'>
                    <header>
                        <h2>{`These ${isJobScreen ? 'profiles' : 'jobs'} have been sent job suggestion:`} <br/> <br/> {isJobScreen ? getNames() : getJobs()}</h2>
                        <div onClick={() => props.closeEvent()}
                            className='circle-close modal-close'>
                            <CloseIcon />
                        </div>
                    </header>
                    <footer>
                        <button
                            onClick={(e) => handleSubmit(e)}
                            className={`button black handler`}>
                            Ok
                        </button>
                    </footer>
                </div>
            </div>
        </ModalComponent>
    );
};

export default EmailMessageModal;
