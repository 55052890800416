import React from 'react';
import ModalComponent from "../modal/Modal.component";
import PromoteProcessComponent from "../actions/PromoteProcess.component";

const PromoteProcessModal = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box promote-status-modal'>
                <PromoteProcessComponent
                    isSingleJobView={props.isSingleJobView}
                    isSingleCompanyView={props.isSingleCompanyView}
                    isModal={true}
                    closeEvent={() => props.closeEvent()}
                    data={props.data}
                />
            </div>
        </ModalComponent>
    );
};

export default PromoteProcessModal;
