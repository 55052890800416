import React, { Component } from 'react';
import './messagesTemplatesList.scss';
import { EditIcon, MailIcon3, SmsIcon, TrashIcon } from '../../../../../../components/svg';
import messagingStore from '../../../../../../../stores/messaging.store';
import { observer } from 'mobx-react';
import userStore from '../../../../../../../stores/user.store';
import Loader from '../../../../../../components/loader/Loader.component';
import utilsService from '../../../../../../../services/utils.service';

@observer
class MessagesTemplatesListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        if (messagingStore.messagesTemplateList.length === 0) {
            await messagingStore.getMessagingTemplates({ field: 'userId', value: userStore.user._id });
        }
        this.setState({
            loader: false
        });
    }

    selectHandler = id => {
        messagingStore.messagesTemplateType = id;
        messagingStore.filterMessagingTemplates(id);
    };

    deleteHandle = async _id => {
        this.setState({
            loader: true
        });

        await messagingStore.deleteMessagingTemplate(_id);

        this.setState({
            loader: false
        });
    };

    removeSystemTemplates(templatesList) {
        return templatesList.filter(d => d.templateUserType !== 2);
    }

    render() {
        const { loader } = this.state;
        const { editHandle } = this.props;
        const unFilteredTemplatesList = [...messagingStore.messagesTemplateListFiltered];
        const selectedCon = messagingStore.messagesTemplateType;

        const templatesList = this.removeSystemTemplates(unFilteredTemplatesList);
        const isEmpty = templatesList.length === 0;

        return (
            <div className='user-details-message-templates-list'>
                {loader && (
                    <Loader/>
                )}

                {!loader && (
                    <>
                        <TemplatesSelector
                            selected={selectedCon}
                            selectHandler={this.selectHandler}
                        />

                        <div className="user-details-message-templates-list-items-wrap">
                            {isEmpty && (
                                <div className="user-details-message-templates-list-item-empty">
                                    No templates
                                </div>
                            )}
                            {templatesList.map(item => {
                                return (
                                    <TemplateItem
                                        data={item}
                                        key={item._id}
                                        deleteHandle={this.deleteHandle}
                                        editHandle={editHandle}
                                    />
                                );
                            })}
                        </div>

                    </>
                )}
            </div>
        );
    }
}

export default MessagesTemplatesListComponent;

const TemplateItem = props => {
    const { data, deleteHandle, editHandle } = props;
    return (
        <div className="user-details-message-templates-list-item">
            <div className="user-details-message-templates-list-item-labling">
                <label>
                    Name
                </label>
                <h4 dir={utilsService.getDir(data.name)}>{data.name}</h4>
            </div>
            <div className="user-details-message-templates-list-item-labling">
                <label>
                    Subject
                </label>
                <h4 dir={utilsService.getDir(data.name)}>{data.subject}</h4>
            </div>

            <div className="user-details-message-templates-list-item-labling">
                <label>
                    Language
                </label>
                <p className="lang">{getLang(data.language)}</p>
            </div>
            <div dir={utilsService.getDir(data.body)} className="user-details-message-templates-list-item-body">
                {normalizeText(data.body)}
            </div>
            <footer>
                <div className="user-details-message-templates-list-item-footer-icon"
                     onClick={() => editHandle(data)}
                >
                    <EditIcon/>
                </div>
                <div
                    onClick={() => deleteHandle(data._id)}
                    className="user-details-message-templates-list-item-footer-icon">
                    <TrashIcon/>
                </div>
            </footer>
        </div>
    );
};

function normalizeText(text) {
    let res = text;
    if (text) {
        const textFormated = text.replace('\\n', '↵');
        res = textFormated.replace('n\\', '↵');
    }

    return res;
}

const TemplatesSelector = props => {
    const { selectHandler, selected } = props;

    return (
        <div className='user-details-message-templates-selector'>
            {_templatesSelectorList.map(item => {
                const isSelected = selected === item.id;
                let classes = 'user-details-message-templates-selector-item';
                if (isSelected) {
                    classes += ' selected';
                }
                return (
                    <div
                        key={item.id}
                        onClick={() => selectHandler(item.id)}
                        className={classes}>
                        {item.value}
                    </div>
                );
            })}
        </div>
    );
};

const _templatesSelectorList = [
    {
        id: 0,
        value: 'All'
    },
    {
        id: 1,
        value: <MailIcon3
            stroke={'#000'}
        />
    },
    {
        id: 2,
        value: <SmsIcon/>
    }
];

function getLang(lang) {
    let res = 'Eng';
    if (lang && lang === 2) {
        res = 'Heb';
    }
    return res;
}
