import React, { PureComponent } from 'react';
import ModalComponent from '../modal/Modal.component';
import SendConversationComponent from '../sendMessages/sendConversation/SendConversation.component';

class SendConversationModalComponent extends PureComponent {
    render() {
        const { items, closeEvent, replyTo, hideSubject, subject, body, hideTemplate, isDiscretePosition, 
            type, languageSelectEvent, operationType, conType, selectedEmailTemplate, jobUrls } = this.props;

        return (
            <ModalComponent onClose={closeEvent}>
                <div className='modal-box messaging2-modal'>
                    <SendConversationComponent
                        hideTemplate={hideTemplate}
                        replyTo={replyTo}
                        items={items}
                        subject={subject}
                        body={body}
                        isDiscretePosition={isDiscretePosition}
                        closeEvent={closeEvent}
                        type={type}
                        operationType={operationType}
                        languageSelectEvent={languageSelectEvent}
                        conType={conType}
                        selectedEmailTemplate={selectedEmailTemplate}
                        jobUrls={jobUrls}
                    />
                </div>
            </ModalComponent>
        );
    }
}

export default SendConversationModalComponent;
