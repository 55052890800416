import React from 'react';
import utilsService from '../../../services/utils.service';
import { Link } from 'react-router-dom';
import messagingStore from '../../../stores/messaging.store';
import './processesAlreadyExist.scss';
import notificationsStore from '../../../stores/notifications.store';

class ProcessAlreadyExistsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    sendCv = async (data, idx) => {
        this.setState({
            ['sending' + idx]: true,
            ['isError' + idx]: false,
            ['errorMessage' + idx]: ''
        });

        const process = data.dataOrigin.process;
        process.forceCreateProcess = true;

        // we don't want to change current process from new to offered, only send cv again
        const inProcessStatusIds = utilsService.getInProcessesStatuses();
        if(data.processExists && inProcessStatusIds.includes(data.processDataCurrent[0].status)) {
            process.status = data.processDataCurrent[0].status;
            process.comment = 'Resend';
        }

        const response = await messagingStore.sendCvs([data.dataOrigin]);
        if(response.isError) {
            this.setState({
                ['sending' + idx]: false,
                ['isError' + idx]: true,
                ['errorMessage' + idx]: 'Internal Server Error. Please Send Again.'
            });
        }
        if (response && response.length > 0) {
            const responseItem = response[0];
            if (!responseItem || responseItem.isError) {
                this.setState({
                    ['sending' + idx]: false,
                    ['isError' + idx]: true,
                    ['errorMessage' + idx]: responseItem.errorMessage
                });
            }
            else {
                notificationsStore.showToast(`Send CV completed successfully`, 'success');
            }
        }

        this.setState({
            ['sending' + idx]: false,
            ['sent' + idx]: true
        });
        
        this.props.sentCallback(response);
    };

    getSendButtonStatus(idx, state) {
        let text = 'Force send';
        let classes = 'button bk-darkblue';
        let isDisabled = false;

        if (state['sending' + idx]) {
            text = 'Sending';
            classes = 'button loading bk-darkblue';
        }
        else if (state['isError' + idx]) {
            text = 'Re-Send';
            classes = 'button bk-darkblue';
        }
        else if (state['sent' + idx]) {
            text = 'Sent';
            classes = 'button sent new-gray disabled-opacity-less';
            isDisabled = true;
        }

        return { text, classes, isDisabled };
    }

    render() {
        const { data } = this.props;

        return (
            <div className='processes-already-exists'>
                <h2>List of processes that already exist</h2>
                <div className="processes-already-exists-list">
                    {data.map((item, idx) => {
                        const { text, classes, isDisabled } = this.getSendButtonStatus(idx, this.state);

                        const dataOrigin = item.dataOrigin;
                        return (
                            <div
                                key={idx + '_process-item-lope'}
                                className="processes-already-exists-item">
                                 <div className="processes-already-exists-item-list">
                                    <label>Company:</label>
                                    <p className="item">
                                        <Link className='black-link'
                                              target='_blank'
                                              to={`/company/${dataOrigin.process.companySlug}`}
                                        >
                                            {utilsService.removeWrongCharsFromString(dataOrigin.info.companyName)}
                                        </Link>
                                    </p>
                                </div>                                   
                                <div className="processes-already-exists-item-list">
                                    <label>Job:</label>
                                    <p className="item">
                                        <Link className='black-link'
                                              target='_blank'
                                              to={`/job/${dataOrigin.process.jobSlug}`}
                                        >
                                            {utilsService.removeWrongCharsFromString(dataOrigin.info.jobTitle)}
                                        </Link>
                                    </p>
                                </div>
                                <div className="processes-already-exists-item-list">
                                    <label>Profile:</label>
                                    <p className="item">
                                        <Link className='black-link'
                                              target='_blank'
                                              to={`/profile/${dataOrigin.info.slug}`}
                                        >
                                            {dataOrigin.info.profileName}
                                        </Link>
                                    </p>
                                </div>  
                                {item.processExists && 
                                    <div className="processes-already-exists-item-list">
                                        <label>Process Status:</label>
                                        <p className="item">
                                            {utilsService.getStatusByEnum(item.processDataCurrent[0].status)}
                                        </p>
                                    </div>
                                }
                                {item.processExistsInSameCompany && 
                                    <div className="processes-already-exists-item-list">
                                        <label>Pervious Process:</label>
                                        <div>
                                            {item.processDataCurrent.map(processData => {
                                                const reason = utilsService.getRejectReasonByEventLog(processData)
                                                return (
                                                    <p className="item pervious-process">
                                                        <label>
                                                            {`${processData.jobTitle}, `}
                                                        </label>
                                                        <label>
                                                            {`${utilsService.getLastUpdate(processData, true, 5)}, `}
                                                        </label>
                                                        <label title={reason}>
                                                            {`${utilsService.getStatusByEnum(processData.status)}, `}
                                                        </label>                                                       
                                                        <Link className='black-link'
                                                                target='_blank' 
                                                                to={`/jobs/${processData.jobSlug}?process=${processData.slug}&tab=2`}>
                                                            {'Process'}
                                                        </Link>
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                <button
                                    disabled={isDisabled}
                                    onClick={() => isDisabled ? null : this.sendCv(item, idx)}
                                    className={classes}>
                                    {text}
                                </button>
                                {this.state['isError' + idx] && (
                                    <div className="error-message">
                                        {this.state['errorMessage' + idx]}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default ProcessAlreadyExistsComponent;
