import React from 'react';
import ModalComponent from "../modal/Modal.component";
import {CloseIcon} from '../svg';


const JobDiscreteAlertModal = props => {


    const handleSubmit = async (e) => {
        props.okEvent();
    };


    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box discrete-job'>
                <div className='action-process'>
                    <header>
                        <h2>{'Attention, this job is discrete, Are you still want to update your candidate ?'}</h2>
                        <div onClick={() => props.closeEvent()}
                            className='circle-close modal-close'>
                            <CloseIcon />
                        </div>
                    </header>
                    <footer>
                        <button onClick={() => props.closeEvent()}
                            className='button gray'>
                            Cancel
                        </button>
                        <button
                            onClick={(e) => handleSubmit(e)}
                            className={`button black handler`}>
                            Ok
                        </button>
                    </footer>
                </div>
            </div>
        </ModalComponent>
    );
};

export default JobDiscreteAlertModal;
