import firebase from 'firebase/app';
import 'firebase/auth';

const prodConfig = {
    apiKey: "AIzaSyDY4QvwgV2Dx4sy4H1LQIRiAxK3hJD2i2Y",
    authDomain: "brightsource-prod.firebaseapp.com",
    projectId: 'brightsource-prod'
};

const devConfig = {
    apiKey: "AIzaSyDeUGus981J3DtLLQoC08IXy-0V6TehoWU",
    authDomain: "rec-test1.firebaseapp.com",
    projectId: 'rec-test1'
};

const config = process.env.FIREBASE === 'production' ? prodConfig : devConfig;

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth,
};
