import fetchService from "../services/fetch.service";

class ErrorStore {

    async sendError(body) {
        const response = await fetchService.post(`/external-error`, body);
        return response.data
    }

    async downloadFileFromStorage(body) {
        const response = await fetchService.authenticatedPost('/download-file', body)
        return response.data;
    }
}

export default new ErrorStore();