import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import Loader from '../loader/Loader.component';
import DefaultImageComponent from '../DefaultImage.component';
import './selectBox.scss';
import '../forms/modernSelect.scss';

class RecruitersSelectBox extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loader: true
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        if(this._isMounted) {
            this.setState({
                    loader: false
                }
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    menuToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    selectEvent = item => {
        this.props.selectEvent(item);
        this.setState({
            open: false
        });
    };

    handleClickOutside = evt => {
        this.setState({ open: false });
    };

    // compare = (a, b) => {
    //     if (a.value < b.value) {
    //       return -1;
    //     }
    //     if (a.value > b.value) {
    //       return 1;
    //     }
    //     // a must be equal to b
    //     return 0;
    //   }

    getRecruitersList(showAll) {
        const list = userStore.recruitersList;


        const currentUser = userStore.user;
        let respList = [];

        list.forEach(item => {
            const data = {
                displayName: utilsService.getDisplayName(item),
                _id: item._id,
                profileImage: utilsService.getProfileImage(item),
                slug: item.slug
            };

            if (item._id === currentUser._id) {
                data.displayName = ' Me'; // added space to order up
                respList.unshift(data);
            } else {
                respList.push(data);
            }

            respList.sort((a, b) => {
                if (a.displayName < b.displayName) {
                  return -1;
                }
                if (a.displayName > b.displayName) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              }); 
        });
        
        const system = respList.find(item => item.displayName.toLowerCase() === 'system ');
        if(system) {
            respList = respList.filter(item => item.displayName.toLowerCase() !== 'system ');
            respList.unshift(system);
        }

        if (showAll) {
            respList.unshift({ displayName: 'Show All', slug: 'all', _id: 'all', firstName: 'Show', lastName: 'All' });
        }

        return respList;
    }

    getClasses(classes) {
        let res = 'modern-select select-select-box';
        if (classes) {
            res += ' ' + classes;
        }
        return res;
    }

    render() {
        const { open, loader } = this.state;
        const { selectedItem, classes, showMyInboxLabel, showAll } = this.props;

        const recruitersList = this.getRecruitersList(showAll);

        const currentUser = userStore.user;
        let chosenItem = null;

        if (selectedItem === 'all') {
            chosenItem = recruitersList[0];
        } else {
            chosenItem = userStore.users[selectedItem];
        }

        if (!chosenItem) {
            chosenItem = currentUser;
        }

        const selectedUserName = utilsService.getDisplayName(chosenItem);

        return (
            <div className={this.getClasses(classes)}>
                {loader && (
                    <Loader/>
                )}

                {!loader && chosenItem && (
                    <div
                        onClick={this.menuToggle}
                        className="modern-select-visible-part">

                        <DefaultImageComponent
                            url={utilsService.getProfileImage(chosenItem)}
                            alt={selectedUserName}
                            profileName={selectedUserName}
                            isExposed={true}
                        />
                        <div className="modern-select-visible-part-name">
                            {chosenItem._id === currentUser._id && showMyInboxLabel ? 'My Inbox' : selectedUserName}
                        </div>
                        {/*<div className="modern-select-visible-part-counter">*/}
                        {/*    */}
                        {/*</div>*/}
                    </div>
                )}
                {open && (
                    <div className="modern-select-list shadow4">
                        <div className="custom-scroll">
                            {
                                recruitersList.map((item) => {

                                    return (
                                        <div
                                            onClick={() => this.selectEvent(item)}
                                            key={item._id}
                                            className="modern-select-list-item">

                                            <DefaultImageComponent
                                                url={item.profileImage}
                                                alt={item.displayName}
                                                profileName={item.displayName}
                                                isExposed={true}
                                            />
                                            <div className="modern-select-unvisible-part-name">
                                                {item.displayName}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

RecruitersSelectBox.defaultProps = {
    showMyInboxLabel: true
};

export default onClickOutside(RecruitersSelectBox);
