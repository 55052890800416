import React, {PureComponent} from 'react';

class SuggestSkillsV2Component extends PureComponent {
    filterList(list, hidden) {
        return list.filter(item => !hidden.includes(item));
    }

    getSkillsList(listObj, listOfParents) {
        const tempArr = [];

        if (listOfParents.length > 0 && listObj) {
            listOfParents.forEach(item => {
                tempArr.push.apply(tempArr, listObj[item]);
            });
        } else if (listObj) {
            const keys = Object.keys(listObj);
            tempArr.push.apply(tempArr, listObj[keys[0]]);
        }

        return tempArr;
    }

    getRelevantSkills(skills, topSkills, selectedRoles, hiddenList, limit) {
        let tempArr = [];

        if (selectedRoles.length > 0 && topSkills) {         
            let mergedSkills = []; 
            selectedRoles.forEach(roleId => {
                if(!mergedSkills.includes(topSkills[roleId])) {
                    const tempSkill = topSkills[roleId];
                    if(tempSkill) {
                        mergedSkills.push.apply(mergedSkills, tempSkill.map(item => {
                            if(skills[item.elId]) {
                                item.value = skills[item.elId].value;
                                return item;
                            }
                            return null;
                        }).filter(item => item))
                    }
                }
            });

            for (let i = 0; i < mergedSkills.length ; i++) {
                if(!hiddenList || !hiddenList.includes(mergedSkills[i].elId)) {
                    tempArr.push(mergedSkills[i]);
                }
            }

            tempArr = tempArr.sort((a, b) => a.rank - b.rank).slice(0, limit);
        }


        return tempArr;
    }

    renderAllButton(renderSkills) {
        return (
            <div
                className='suggest-item all'
                key='jd9d9awdja9wd9ajwd'
                onClick={() => this.props.addRelevantSkill(renderSkills)}
            >
                All
            </div>
        );
    }

    render() {

        const { topSkills, selectedRoles, skills, hiddenSuggestedList } = this.props;
        const relevantSkills = this.getRelevantSkills(skills, topSkills, selectedRoles, hiddenSuggestedList, 8);

        return (
            <div className='suggested-wrapper'>
                {relevantSkills && relevantSkills.length > 0 && (
                    <React.Fragment>
                        {this.renderAllButton(relevantSkills)}
                        {relevantSkills.map(skill => {
                            return (
                                <div
                                    className='suggest-item skill-tag'
                                    onClick={() => this.props.addRelevantSkill(skill)}
                                    key={skill.elId}
                                > {`+${skill.value}`}

                                </div>
                            );
                        })}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default SuggestSkillsV2Component;
