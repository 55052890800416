import React from 'react';
import data from '../constants/data';

class PermissionsService {
    canInviteTeam(profile) {
        let res = false;
        const perm = profile.permission;
        if (perm) {
            res = perm === 2 || perm === 5;
        }

        return res;
    }
}

const permissionsService = new PermissionsService();
export default permissionsService;
