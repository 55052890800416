import React from 'react';
import ModalComponent from '../modal/Modal.component';
import ContactDetailsComponent from '../actions/ContactDetails.component';

const ContactDetailsModal = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box terminate-process-modal modal-box-contact-details'>
                <ContactDetailsComponent
                    isSingleJobView={props.isSingleJobView}
                    isModal={true}
                    closeEvent={() => props.closeEvent()}
                    data={props.data}
                />
            </div>
        </ModalComponent>

    );
};

export default ContactDetailsModal;
