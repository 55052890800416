import React from 'react';
import PropTypes from 'prop-types';
import './dashboardCandidatesTodayJobs.scss';
import { observer } from "mobx-react"
import { DotsIcon } from '../../../../../../components/svg';
import DashboardStatusItem from '../dashboardStatusItem/DashboardStatusItem.component';
import utilsService from '../../../../../../../services/utils.service';
import data from '../../../../../../../constants/data';


const DashboardCandidatesTodayJobs = observer((props) => {

    const { selectedRecruiterSlugs, jobs } = props

    const getStatusUrl = (status, lastUpdated) => {
        return `/jobs?recruiterId=${selectedRecruiterSlugs.join(',')}&status=${status}&lastUpdated=${lastUpdated}`
    }

    const todayOpenJobs = jobs && jobs.todayOpenJobs;
    const todayOnHoldJobs = jobs && jobs.todayHoldJobs;
    const todayOnCloseJobs = jobs && jobs.todayCloseJobs;
    return (
        <div className='dashboard-candidates-today-jobs-wrapper'>
            <div className="more-menu">
                <DotsIcon />
            </div>

            <h1 className="title">Today Jobs Activities</h1>

            <div className="column">

                <div>
                    <DashboardStatusItem number={todayOpenJobs} name={'New Jobs'}
                        linkTo={getStatusUrl(`${data.jobStatus.find(item => item.elId === 1).elId}`,
                            `${data.lastUpdatedStatus.find(item => item.elId === 0).elId}`)}
                    />
                </div>
                <div>
                    <DashboardStatusItem number={todayOnHoldJobs} name={'On Hold'}
                        linkTo={getStatusUrl(`${data.jobStatus.find(item => item.elId === 2).elId}`,
                            `${data.lastUpdatedStatus.find(item => item.elId === 0).elId}`)}
                    />
                </div>
                <div>
                    <DashboardStatusItem number={todayOnCloseJobs} name={'Closed Jobs'}
                        linkTo={getStatusUrl(`${data.jobStatus.find(item => item.elId === 0).elId}`,
                            `${data.lastUpdatedStatus.find(item => item.elId === 0).elId}`)}
                    />
                </div>
            </div>
        </div>

    )
});

DashboardCandidatesTodayJobs.propTypes = {
    // bla: PropTypes.string,
};

DashboardCandidatesTodayJobs.defaultProps = {
    // bla: 'test',
};

export default DashboardCandidatesTodayJobs
