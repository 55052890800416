import React from 'react';
import dayjs from 'dayjs';
import utilsService from '../../../services/utils.service';

const TimeLineItemComponent = (props) => {
  return (
    <React.Fragment>
      <header className="timeline-header">
          <span className="tag log-tag">{utilsService.getStatusByEnum(props.data.eventTypeId)}</span>
      </header>
      <div className="timeline-item">
        <div className="timeline-marker"/>
        <div className="timeline-content">
          <p className="timeline-heading">
              {dayjs(parseInt(props.data.lastUpdate, 10)).format('MMM YYYY')}
          </p>
          <p>{props.data.comment}</p>
          <p
            className='log-author'>{`${props.data.decisionMakerName}, ${props.data.decisionMakerEmail}`}</p>
        </div>

      </div>
    </React.Fragment>
  );
};
export default TimeLineItemComponent;
