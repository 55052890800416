import React, {Component} from 'react';
import processStore from '../../../../stores/process.store';
import {Tab, Tabs} from '../../Tabs.component';
import {observer} from 'mobx-react';
import data from '../../../../constants/data';
import utilsService from '../../../../services/utils.service';
import userStore from '../../../../stores/user.store';

@observer
class TabsComponent extends Component {
    componentDidMount() {
        processStore.getTableIndex();

        const { isSingleJobView, jobSlug, isSingleCompanyView, companySlug, isAll } = this.props;
        if (isSingleJobView) {
            processStore.setSingleJobTableCounters(jobSlug);
        }

        if (isSingleCompanyView) {
            processStore.setSingleCompanyTableCounters(companySlug, isAll);
        }
    }

    renderRightTabs(isSingleJobView, isSingleCompanyView) {
        let rightData = data.applicant_table_options;

        const isRecruiter = utilsService.isRecruiter(userStore.user);
        if (!isRecruiter && (isSingleJobView || isSingleCompanyView )) {
            rightData = data.applicant_table_options_single_job;
        }

        return rightData.map((opt, id) => {
            let counter = processStore.processesPipelinesStatusCounter[opt];

            if (isSingleJobView) {
                counter = processStore.processesSingleJobStatusCounter[opt];
            }

            if (isSingleCompanyView) {
                counter = processStore.processesCompanyStatusCounter[opt];
            }

            return (
                <Tab
                    key={id}
                    subtitle={counter}
                    label={opt}/>
            );
        });
    }

    getSelectedIndex(isSingleJobView, isSingleCompanyView) {
        let selectedIndex = processStore.tableLastIndex;
        if (isSingleJobView) {
            selectedIndex = processStore.tableLastIndexSingleJob;
        }

        if (isSingleCompanyView) {
            selectedIndex = processStore.tableLastIndexCompanyJob;
        }

        return selectedIndex;
    }

    render() {
        const { isSingleJobView, jobSlug, isSingleCompanyView, companySlug } = this.props;
        let { gridIndex } = this.props;

        const tabs = this.renderRightTabs(isSingleJobView, isSingleCompanyView);
        const selectedIndex = this.getSelectedIndex(isSingleJobView, isSingleCompanyView);

        if (gridIndex && (gridIndex < 0 || gridIndex > 8)) {
            gridIndex = undefined;
        }

        if (gridIndex) {
            processStore.filterProcesses(gridIndex);
        }

        const isRecruiter = utilsService.isRecruiter(userStore.user);


        return (
            <Tabs className={`${isRecruiter ? 'recruiter' : ''}`}
                selectedIndex={gridIndex ? gridIndex : selectedIndex}
                onClick={(idx) => {
                    if (isSingleJobView) {
                        processStore.filterProcessesByStatusAndJob(idx, jobSlug, isRecruiter);
                    } else if (isSingleCompanyView) {
                        processStore.filterProcessesByStatusAndCompany(idx, companySlug, isRecruiter);
                    } else {
                        processStore.filterProcesses(idx);
                    }
                }}>

                {tabs}

            </Tabs>
        );
    }
}

export default TabsComponent;
