import React, { useState, useEffect } from 'react';
import './candidateAvailability.scss';
import DropdownWithSubDirectories from '../../dropdown/dropdownWithSubDirectories/DropdownWithSubDirectories.component';
import data from '../../../../constants/data';
import utilsService from '../../../../services/utils.service';
import ChangeCandidateAvailabilityModal from '../../modalComponents/ChangeCandidateAvailabilityModal';
import processStore from '../../../../stores/process.store';
import profileStore from '../../../../stores/profile.store';
import notificationsStore from '../../../../stores/notifications.store';
import userStore from '../../../../stores/user.store';

const CandidateAvailability = (props) => {
    const { profile } = props;

    const [isAvailable, setIsAvailable] = useState(profile ? profile.isAvailable : false);
    const [isModalOpenMarkAsWithdrawn, setIsModalOpenMarkAsWithdrawn] = useState(false);


    useEffect(() => {
        if(profile.isAvailable !== isAvailable) {
            setIsAvailable(profile.isAvailable)
        }

    }, [profile])

    const getStatusClasses = () => {

        let className = 'status ';
        if (isAvailable) {
            className = className + ' available'
        }
        else {
            className = className + ' notAvailable'
        }
        return className;


    }

    const handleTitleFilterClick = (column, item) => {
        if (item.elId === 0 && isAvailable) {
            setIsModalOpenMarkAsWithdrawn(true);
        }
        else if (item.elId === 1 && !isAvailable) {
            updateProfile();
        }

    }

    const updateProfile = async () => {
        const profileBody = {
            isAvailable: true,
            shouldValidate: false,
            followUpDate: Date.now(),
            user: userStore.user._id,
            userSlug: userStore.user.slug
        };

        await profileStore.updateProfile(profile._id, profileBody);
        notificationsStore.showToast(`${profile.firstName} availability was changed`, 'success');
        setIsAvailable(true);
    }

    const handleCloseEvent = (isSuccessful) => {
        if(isSuccessful) {
            setIsAvailable(false);
        }
        setIsModalOpenMarkAsWithdrawn(false);
    }

    return (
        <div className="profile-status">
            <DropdownWithSubDirectories currentColumnName={utilsService.isAvailable(isAvailable)}
                list={data.profileAvailability} titleFilterClick={(column, item) => handleTitleFilterClick(column, item)}
                leftIcon={getStatusClasses()} rightIcon='arrow' />

            {isModalOpenMarkAsWithdrawn && (
                <ChangeCandidateAvailabilityModal
                    closeEvent={(isSuccessful) => handleCloseEvent(isSuccessful) }
                    profileSlug={profile.slug}
                    processes={Object.values(processStore.processes)}
                />
            )}
        </div>
    )
};

CandidateAvailability.propTypes = {
    // bla: PropTypes.string,
};

CandidateAvailability.defaultProps = {
    // bla: 'test',
};

export default CandidateAvailability;
